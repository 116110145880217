import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Nav,
  NavDropdown,
  Dropdown,
  Table,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import Successful from "../../assets/images/Successful.svg";
import User from "../../assets/images/user.jpg";
import { connect } from "react-redux";
import TournamentService from "../../services/tournament.service";
import Moment from "react-moment";
import moment from "moment";
import CallSheetService from "../../services/callsheet.service";
import {
  showLoader,
  hideLoader,
  showSuccessAlert,
  showWarningAlert,
} from "../../common/actions/application";
import AuthService from "../../services/auth.service";
import {
  useHistory,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import MyContestsService from "../../services/mycontests.service";

function ConfirmContesty(props) {
  const history = useHistory();

  const [tournament, setTournament] = useState();
  const [callsheetId, setCallsheetId] = useState();
  const [user, setUser] = useState({});
  const [callSheetId, setCallSheetId] = useState();
  const [userTourCallsheets, setUserTourCalsheets] = useState();
  const [imdbScore, setImdbScore] = useState();
  const [rtScore, setRtScore] = useState();

  useEffect(() => {
    getTournamentDetails();
    getCallSheetByTourId();
  }, [props.id]);

  useEffect(() => {
    getLoginUser();
  }, []);

  useEffect(() => {
    getCallSheetById();
  }, [props.callSheetId]);

  const getCallSheetByTourId = async () => {
    let inputParams = { tournamentId: props.id };
    const resp = await MyContestsService.getCallSheetsByTourId(inputParams);
    if (resp) {
      let usersCallsheet = resp.data.filter(
        (value) => value.UserId == user?.id && value.Status == "Submited"
      );
      setUserTourCalsheets(usersCallsheet);
    }
  };

  const getTimeRemaining = (tournamentStartDate) => {
    // const future = moment(tournamentStartDate);
    // return future.endOf("day").fromNow();
    const now = moment();
    const future = moment(tournamentStartDate);
    const diff = future.diff(now);
    const diffDuration = moment.duration(diff);
    return (
      diffDuration.days() * 24 +
      diffDuration.hours() +
      "hrs " +
      diffDuration.minutes() +
      "mins"
    );
  };

  const getLoginUser = () => {
    const response = AuthService.getAuth();
    if (response) {
      //setUserId(response.data.id);
      setUser(response.data);
    }
  };

  const getTournamentDetails = async () => {
    const response = await TournamentService.getTournamentById(props.id);
    if (response) {
      response.data.Starting_in = getTimeRemaining(response.data.Starting_in);
      setTournament(response.data);
    }
  };

  const getCallSheetById = async () => {
    const response = await CallSheetService.getCallSheetById(props.callSheetId);
    if (response) {
      setCallsheetId(response.data);
    }
  };

  const submitCallSheet = async () => {
		const callSheetInputPrams = {
			TournamentId: tournament?.id,
			UserId: user?.id,
			Status: "Submitted",
			PlayerName: user?.Screen_Name,
			ActorsList: callsheetId?.ActorsList,
			StartDate: tournament?.Starts,
			EndDate: tournament?.EndDate,
			SubmitedOn: new Date(Date.now()),
			MCCriticsScore: Number(rtScore),
			IMDbUserScore: Number(imdbScore),
			TotalSalary: Number(callsheetId?.TotalSalary),
			IMDbUserScoreDiff: 0,
			MCCriticsScoreDiff: 0,
			RemaingSalary: Number(callsheetId?.RemaingSalary),
			IsActive: true,
			CreatedOn: new Date(Date.now()),
		};
		props.props.dispatch(showLoader());
		await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
			if (resp && resp.success) {
				props.onHide();
				let callSheetId = resp.data.id;
				props.props.dispatch(showSuccessAlert("Your call sheet submitted successfully!"));
				props.props.dispatch(hideLoader());
				history.push("/submited?c=" + callSheetId);
			}
		});
	};

  return (
    <>
      <Modal
        {...props}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="adventure-popup main-popup cnf-contest"
      >
        {/* className="main-popup cnf-contest" */}
        <Modal.Header>
          <Modal.Title
            className="popup-heading"
            id="contained-modal-title-vcenter"
          >
            <img src={Successful} /> Confirm Contest Entry
          </Modal.Title>
          <Button className="popup-close" onClick={() => props.onHide()}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-content">
            <h2>{tournament?.Tournament_Name}</h2>
            <div className="pop-row">
              <div className="movie-type-box">
                <h5>Starts on</h5>
                <p>
                  <Moment format="dddd,hh:mm a">{tournament?.Starts}</Moment>
                </p>
              </div>

              <div className="movie-type-box">
                <h5>Prizes</h5>
                <p>{tournament?.PrizesPool}</p>
              </div>

              <div className="movie-type-box">
                <h5># of Players / Total Players
</h5>
                <p>
                  {tournament?.No_Of_Players}/{tournament?.Total_Players}
                </p>
              </div>

              <div className="movie-type-box">
                <h5>Starting In</h5>
                <p>{tournament?.Starting_in}</p>
              </div>

			
              <div className="movie-type-box">
                <h5>Total Salary</h5>
                <p>{callsheetId?.TotalSalary}</p>
              </div>
            
            </div>
            {/* movie type row closed */}
            {!(tournament?.No_Of_Players < tournament?.Total_Players) ? (
              <p style={{ color: "#800022" }}>
                Max Players allowed to participate reached!
              </p>
            ) : tournament?.MaxCallsheets <= userTourCallsheets?.length ? (
              <p>"You have reached your limit of Call sheets already!"</p>
            ) : (
              <h2>
                {user?.Screen_Name +
                  " " +
                  "(" +
                  (userTourCallsheets?.length + 1) +
                  ")"}{" "}
                Call Sheet
              </h2>
            )}
            {/* <h2>{ !(tournament?.No_Of_Players < tournament?.Total_Players )? "Max Players allowed to participate reached!":user?.Screen_Name +"(1) Call Sheet"}</h2> */}
            
			

			<div className="tiebreaker-box">
                <Row>
                  <Col md={12}>
                    <h6>Tiebreaker</h6>
                  </Col>
                </Row>
                <div className="tiebreaker-row">
                  <div className="tiebreaker-cnt">
                    <p>Enter Highest RT Critics Score (0 - 100%)</p>
                  </div>
                  <div className="btntie">
                    <InputGroup className="txt-score">
                      <FormControl
                        type="number"
                        min="0"
                        max="100"
                        step="any"
                        placeholder="0-100%"
                        aria-label="Enter Highest RT  Critics Score (0 - 100%)"
                        aria-describedby="Enter Highest RT  Critics Score (0 - 100%)"
                        defaultValue={callsheetId?.MCCriticsScore}
                        onChange={(e) => setRtScore(e.target.value)}
                        //disabled={isGameLock}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="tiebreaker-row">
                  <div className="tiebreaker-cnt">
                    <p>Enter Highest IMDb User Score (0.0 - 10.0)</p>
                  </div>
                  <div className="btntie">
                    <InputGroup className="txt-score">
                      <FormControl
                        type="number"
                        min="0"
                        max="10"
                        step={0.1}
                        precision={2}
                        placeholder="0.0 - 10"
                        aria-label="Enter Highest IMDb  User Score (0.0 - 10.0)"
                        aria-describedby="Enter Highest IMDb  User Score (0.0 - 10.0)"
                        defaultValue={callsheetId?.IMDbUserScore}
                        onChange={(e) => setImdbScore(e.target.value)}
                        //disabled={isGameLock}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            {/* movie type row closed */}
            <div className="pupup-btn-row">
              <p
                onClick={() => props.onHide()}
                style={{ marginLeft: "700px", color: "#03fcdf" }}
              >
                CANCEL
              </p>
              {tournament?.No_Of_Players < tournament?.Total_Players ? (
                <button className="pink-btn" onClick={submitCallSheet}>
                  CONFIRM ENTRY
                </button>
              ) : null}
            </div>
          </div>
          {/* ============ popup-content closed ===========  */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmContesty;
