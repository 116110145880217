import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './common/root/rootReducer';
import { applyMiddleware } from "redux"
import thunk from "redux-thunk"
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// const Pushy = require("pushy-sdk-web");

// Pushy.register({ appId: "620d6acdca130a4f54f564db" })
// 	.then(function (deviceToken) {
// 		// Print device token to console
// 		console.log("Pushy device token: " + deviceToken);

// 		// Send the token to your backend server via an HTTP GET request
// 		//fetch('https://your.api.hostname/register/device?token=' + deviceToken);

// 		// Succeeded, optionally do something to alert the user
// 	})
// 	.catch(function (err) {
// 		// Handle registration errors
// 		console.error(err);
// 	});

// import { register } from "../public/service-worker.js";
// register();

// if ("serviceWorker" in navigator) {
// 	navigator.serviceWorker
// 		.register("./service-worker.js")
// 		.then(function (registration) {
// 			console.log("SW registration succeeded with scope:", registration.scope);
// 		})
// 		.catch(function (e) {
// 			console.log("SW registration failed with error:", e);
// 		});
// }

ReactDOM.render(
	<Provider store={createStore(rootReducer, applyMiddleware(thunk))}>
		<BrowserRouter>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
