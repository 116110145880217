import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class MyContestsService {
	getMyCallSheets = async () => {
		return await axios.get(API_URL + "call-sheets").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getCallSheetsByStatus = async (inputParams) => {
		return await axios.post(API_URL + "get-callsheet-by-status", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getLiveCallSheets = async (inputParams) => {
		return await axios.post(API_URL + "get-live-callsheets", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getLiveCallSheetsByUserId = async (inputParams) => {
		return await axios.post(API_URL + "get-live-callsheets-by-userid", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getCompletedCallSheets = async (inputParams) => {
		return await axios.post(API_URL + "get-completed-callsheets", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getLiveCallSheetsByUserIdandTourId = async (inputParams) => {
		return await axios.post(API_URL + "get-callsheet-by-userId", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getCallSheetsByTourId = async (inputParams) => {
		return await axios.post(API_URL + "get-callsheet-by-tournamentId", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getAllLiveCallSheets = async (inputParams) => {
		return await axios.post(API_URL + "get-all-live-callsheets", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	computeTournamentResults = async (inputParams) => {
		return await axios.post(API_URL + "compute-tournament-results", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTournamentResults = async (inputParams) => {
		return await axios.post(API_URL + "run-winners-api", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new MyContestsService();