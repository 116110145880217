import React from "react";
import { Container } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import Faq from '../../assets/images/Support-FAQb-anner.png';


const TermsAndConditions = () => {
  return (
    <div className="terms-conditons-page">
      <HeaderTop />

      <div className="content-faq">
        <Container fluid>

          <div className="Banner" style={{ backgroundImage: 'url(' + Faq + ')', backgroundSize: 'auto' }}>
            <div className="banner-content">
              <h1>I don’t like it. I don’t agree with it. But I accept it.</h1>
            </div>
          </div>


          <div className="terms-conditons">
            <div className="term-cnd">
              <p>
                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.
              </p>
            </div>

            <div className="term-cnd">
              <h3>Your Agreement</h3>
              <p>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site. </p>
              <p>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.</p>
            </div>

            <div className="term-cnd">
              <h3>Linked Sites</h3>
              <p>
                This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
              </p>
            </div>

            <div className="term-cnd">
              <h3>Forward Looking Statements</h3>
              <p>
                This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
              </p>
            </div>

            <div className="term-cnd">
              <h3>Disclaimer of Warranties and Limitation of Liability</h3>
              <p>
                This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
              </p>
            </div>

            <div className="term-cnd">
              <h3>Forward Looking Statements</h3>
              <p>This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided, nothing within the Site shall be construed as conferring any license under our or any third party’s intellectual property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the foregoing, you acknowledge and agree that all content available through and used to operate the Site and its services is protected by copyright, trademark, patent, or other proprietary rights. You agree not to: (a) modify, alter, or deface any of the trademarks, service marks, trade dress (collectively “Trademarks”) or other intellectual property made available by us in connection with the Site; (b) hold yourself out as in any way sponsored by, affiliated with, or endorsed by us, or any of our affiliates or service providers; (c) use any of the Trademarks or other content accessible through the Site for any purpose other than the purpose for which we have made it available to you; (d) defame or disparage us, our Trademarks, or any aspect of the Site; and (e) adapt, translate, modify, decompile, disassemble, or reverse engineer the Site or any software or programs used in connection with it or its products and services. </p>
              <p>The framing, mirroring, scraping or data mining of the Site or any of its content in any form and by any method is expressly prohibited.</p>
            </div>

          </div> {/* terms-conditons box closed */}
        </Container>
      </div>{/*  content closed  */}

      <FooterBottom />

    </div>

  )
}
export default TermsAndConditions;


