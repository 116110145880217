import React from "react";
import { Container, Row, Col, Dropdown, } from "react-bootstrap";
import '../../../assets/css/main.css';
import '../css/style.css';

import LoginImg from '../../../assets/images/Login-Signup-Illustration.svg'
import Logo from '../../../assets/images/logo.png'
import Video from '../../../assets/images/Camera-Icon.svg'
import Director from '../../../assets/images/DirectorChair-Icon.svg'
import DirectorClap from '../../../assets/images/DirectorClap-Icon.svg'
import Close from '../../../assets/images/Close.svg'
import Google from '../../../assets/images/google.png'
import Facebook from '../../../assets/images/facebook.png'
import Twitter from '../../../assets/images/twitter.png'


import { connect } from 'react-redux';


function SignUpPage() {
  return (
		<div className="main-div">
			<div className="left">
				<div className="content">
					<div className="top">
						<img src={Logo} alt="Logo" />
					</div>

					<div className="middle">
						<img src={LoginImg} alt="illustration" />
					</div>

					<div className="bottom">
						<h3>
							Play the Stars. <strong>Be </strong> Your Own Star
						</h3>
						<div className="links">
							<button type="button"> FAQ</button>
							<img src={Video} alt="camera" />
							<button type="button">TERMS &amp; CONDITIONS</button>
							<img src={Director} alt="director-chair" />
							<button type="button">ABOUT US</button>
							<img src={DirectorClap} alt="Director-clap" />
							<button type="button">CONTACT US</button>
						</div>
					</div>
				</div>
			</div>
			{/* ======== left closed ======== */}
			<div className="right">
				<div className="right-box">
					<div className="close">
						<img src={Logo} alt="Logo" className="logo-mobile" /> <img src={Close} alt="cancel" />
					</div>
					<div className="form-content">
						<div className="tabs">
							<button className="active"> SignUp </button>
							<button className="notactive"> Login </button>
						</div>

						<div className="heading">
							<h1>Are you ready for your close-up?</h1>
						</div>

						<form className="form">
							<div className="form-g">
								<label for="email">
									<span>Screen Name</span>
								</label>
								<input name="name" type="text" />
								<span className="er">The screen name is available</span>
							</div>
							<div className="form-g ">
								<label for="email">
									<span>Birthdate</span>
								</label>
								<div className="birthday-row">
									<div className="birthday-date">
										<label for="email">
											<span>Month</span>
										</label>
										<select>
											<option value="Orange">january</option>
											<option value="Orange">january</option>
											<option value="Orange">january</option>
										</select>
									</div>

									<div className="birthday-date">
										<label for="email">
											<span>Date</span>
										</label>
										<select>
											<option value="Orange">1</option>
											<option value="Radish">2</option>
											<option value="Cherry">3</option>
										</select>
									</div>

									<div className="birthday-date">
										<label for="year">
											<span>Year</span>
										</label>
										<select>
											<option value="Orange">1986</option>
											<option value="Radish">1987</option>
											<option value="Cherry">1988</option>
										</select>
									</div>
								</div>
							</div>
							{/* form group closed */}
							<div className="form-g ">
								<div className="gender-row">
									<div className="gender-col">
										<div className="form-group">
											<input name="gender" type="checkbox" id="male" /> <label for="male"> Male </label>
										</div>
									</div>

									<div className="gender-col">
										<div className="form-group">
											<input name="gender" type="checkbox" id="female" /> <label for="female">Female</label>
										</div>
									</div>

									<div className="gender-col">
										<div className="form-group">
											<input name="gender" type="checkbox" id="others" /> <label for="others">Others</label>
										</div>
									</div>
								</div>
							</div>
							{/* form group closed */}
							<button className="login">
								<span>Continue</span>
							</button>
							<div className="new-player">
								<button className="signup-go">
									<span>
										<i className="fa fa-long-arrow-left" aria-hidden="true"></i> Go Back
									</span>
								</button>
							</div>
							<div className="or">
								<p>
									Or &nbsp; <span>Signup With</span>
								</p>
							</div>
							<div className="social-login-button">
								<button id="google">
									<img src={Google} alt="google" />
									<span>Continue with </span> Google
								</button>
								<button id="facebook">
									<img src={Facebook} alt="facebook" />
									<span>Continue with</span> Facebook
								</button>
								<button id="twitter">
									<img src={Twitter} alt="twitter" />
									<span>Continue with </span>Twitter
								</button>
							</div>
						</form>

						<div className="terms sign-up-terms">
							<p>
								By creating an account, you are agreeing to <span>Terms &amp; Conditions</span> of CinemaDraft
							</p>
						</div>

						<div className="bottom mobile-view-footer">
							<div className="links">
								<button type="button"> FAQ</button>
								<img src={Video} alt="camera" />
								<button type="button"> TERMS &amp; CONDITIONS</button>
								<img src={Director} alt="director-chair" />
								<button type="button">ABOUT US</button>
								<img src={DirectorClap} alt="Director-clap" />
								<button type="button">CONTACT US</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ========== right closed ============= */}
		</div>
	);
}


const mapStateToProps = state => ({});
export default connect(mapStateToProps)(SignUpPage);


