import axios from "axios";
import authHeader from './auth-header';

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class TourTalentPoolService {
	getTourTalentPoolList = async (page) => {
		if(page!=undefined){
			return await axios.get(`${API_URL}tour-talent-pools?filter[limit]=10&filter[skip]=${(page-1)*10}`, { headers: authHeader() }).then((response) => {
				if (response) {
					// console.log(response)
					return response.data;
				}
			});

		}else{
			return await axios.get(API_URL + "tour-talent-pools", { headers: authHeader() }).then((response) => {
			if (response) {
				return response.data;
			}
		});
		}
		
		
		
	};

	upsertTourTalentPool = async (inputParams) => {
		return await axios.post(API_URL + "tour-talent-pools", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};
	updateTourTalentPool = async (inputParams) => {
		console.log(inputParams)
		return await axios.post(API_URL + "update-talantpool-by-id", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	upsertCSVTourTalentPool = async (inputParams) => {
		return await axios.post(API_URL + "tour-talent-pools-from-cvs", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	updateIsActive = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-tourtalentpool", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new TourTalentPoolService();