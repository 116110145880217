import axios from "axios";
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BASE_URL;

class TransactionService {
	getTourTalentPoolList = async () => {
		return await axios.get(API_URL + "search-transactions", { headers: authHeader() }).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};

	getTransactions = async (inputParams) => {
		return await axios.post(API_URL + "search-transactions", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};
}

export default new TransactionService();