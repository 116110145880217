import React from "react";
import '../../../assets/css/main.css';
import '../css/style.css';
import LoginImg from '../../../assets/images/Login-Signup-Illustration.svg'
import Logo from '../../../assets/images/logo.png'
import Video from '../../../assets/images/Camera-Icon.svg'
import Director from '../../../assets/images/DirectorChair-Icon.svg'
import DirectorClap from '../../../assets/images/DirectorClap-Icon.svg'
import Close from '../../../assets/images/Close.svg'
import Google from '../../../assets/images/google.png'
import Facebook from '../../../assets/images/facebook.png'
import Twitter from '../../../assets/images/twitter.png'

import AuthService from "../../../services/auth.service";
import { Link } from "react-router-dom";

export default class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			User: { email: "", password: "", profile_photo: "" },
			error: "",
			success: "",
			isLogin: false,
			//passwordShown: false
		};
	}

	handleInputs = (key) => (e) => {
		let newUser = {
			...this.state.User,
			[key]: e.target.value,
		};
		this.setState({
			User: newUser,
		});
	};

	register = async () => {
		if ((this.state.User.email != "") & (this.state.User.password != "")) {
			AuthService.login(this.state.User.email, this.state.User.password).then(
				(resp) => {
					if (resp) {
						if (resp?.data.role_id === "user") this.props.history.push("/");
						else if (resp.data.role_id === "admin") this.props.history.push("/admin-dashboard");
					} else
						this.setState({
							error: "Your email or password is incorrect",
						});
				},
				(error) => {
					this.setState({
						error: "Your email or password is incorrect",
					});
				}
			);
		} else {
			this.setState({
				error: "Please enter email and password",
			});
		}
	};

	render() {
		return (
			<div className="main-div">
				<div className="left">
					<div className="content">
						<div className="top">
							<Link to={"/"}>
								<img src={Logo} alt="Logo" />
							</Link>
						</div>

						<div className="middle">
							<img src={LoginImg} alt="illustration" />
						</div>

						<div className="bottom">
							<h3>
								Play the Stars. <strong>Be </strong> Your Own Star
							</h3>
							<div className="links">
								<button type="button"> FAQ</button>
								<img src={Video} alt="camera" />
								<button type="button"> TERMS &amp; CONDITIONS</button>
								<img src={Director} alt="director-chair" />
								<button type="button">ABOUT US</button>
								<img src={DirectorClap} alt="Director-clap" />
								<button type="button">CONTACT US</button>
							</div>
						</div>
					</div>
				</div>
				{/* ======== left closed ======== */}
				<div className="right">
					<div className="right-box">
						<div className="close">
							<img src={Logo} alt="Logo" className="logo-mobile" />
							<Link to={"/"}>
								<img src={Close} alt="cancel" />
							</Link>
						</div>
						<div className="form-content">
							<div className="tabs">
								<Link to={"/register"}>
									<button className="notactive"> SignUp </button>
								</Link>
								<Link to={"/login"}>
									<button className="active"> Login </button>
								</Link>
							</div>

							<div className="heading">
								<h1>You had my curiosity. But now you have my attention…</h1>
							</div>

							<form className="form">
								<div className="form-g">
									<label htmlFor="email">
										<span>Email address</span>
									</label>
									<input
										name="email"
										type=""
										value={this.state.User.email}
										onChange={this.handleInputs("email")}
									/>
								</div>
								<div className="form-g">
									<label htmlFor="password">
										<span>Password</span>
									</label>

									<input
										className="password-eye"
										name="password"
										type={this.state.passwordShown ? "text" : "password"}
										value={this.state.User.password}
										onChange={this.handleInputs("password")}
									/>
									<a className="eye-pwd">
										<i
											className={!this.state.passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
											onClick={() => {
												this.setState({ passwordShown: !this.state.passwordShown });
											}}
											aria-hidden="true"></i>
									</a>
								</div>
								<div className="forgot login-check  form-group-sign">
									<label className="check">
										<input type="checkbox" />
										<span className="MuiTouchRipple-root"></span>
										Keep me Logged in
									</label>
									<Link to="/forgotpassword">
										<button>
											<span> Forgot Password</span>
										</button>
									</Link>
								</div>
								{this.state.error && <div className="alert alert-danger">{this.state.error}</div>}

								<button className="login" type="button" onClick={this.register}>
									<span>Login</span>
								</button>
								<div className="new-player">
									<button>
										<span> New player?</span>
									</button>
									<Link to={"/register"}>
										<button className="signup-go">
											<span>SignUp</span>
										</button>
									</Link>
								</div>
								<div className="social-login-button">
									<button id="google">
										<img src={Google} alt="google" />
										<span>Continue with </span> Google
									</button>
									<button id="facebook">
										<img src={Facebook} alt="facebook" />
										<span>Continue with</span> Facebook
									</button>
									<button id="twitter">
										<img src={Twitter} alt="twitter" />
										<span>Continue with </span>Twitter
									</button>
								</div>
							</form>

							<div className="terms">
								<p>
									By creating an account, you are agreeing to <span>Terms &amp; Conditions</span> of
									CinemaDraft
								</p>
							</div>

							<div className="bottom mobile-view-footer">
								<div className="links">
									<button type="button"> FAQ</button>
									<img src={Video} alt="camera" />
									<button type="button"> Terms &amp; Conditions</button>
									<img src={Director} alt="director-chair" />
									<button type="button">About US</button>
									<img src={DirectorClap} alt="Director-clap" />
									<button type="button">Contact US</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* ========== right closed ============= */}
			</div>
		);
	}
}


