import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class MyCallSheetService {
	getCallSheetsByStatus = async (inputParams) => {
		return await axios.post(API_URL + "get-callsheet-by-status", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	deleteCallSheetsById = async (callsheetId) => {
		return await axios.delete(API_URL + "call-sheets/" + callsheetId).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	deleteCallSheetsById2 = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-callsheet", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new MyCallSheetService();