import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class TournamentService {
	getTournamentsList = async () => {
		return await axios.get(API_URL + "get-tournament-list").then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getCompletedTournamentsList = async () => {
		return await axios.get(API_URL + "get-completed-tournament-list").then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTournamentById = async (id) => {
		return await axios.get(API_URL + "get-tournament-by-id/" + id).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	addTournament = async (inputParams) => {
		return await axios.post(API_URL + "create-tournament", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getAllTournamentsList = async () => {
		return await axios.get(API_URL + "get-all-tournament-list").then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteTour = async (tourId) => {
		return await axios.delete(API_URL + "update-isactive-tournament/" + tourId).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteTour2 = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-tournament", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	updateTourStatus = async (inputParams) => {
		return await axios.post(API_URL + "update-complete-tournament", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	checkForExistingCallsheet = async (inputParams) => {
		console.log(inputParams)
		return await axios.post(API_URL + "check-for-existing-callsheet", inputParams).then((response) => {
			if (response.status == 200) {
				console.log(response.data)
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new TournamentService();
