import React, { memo, useCallback, useRef, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Table, Button, Modal, Form } from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from "../../components/footer/index";

import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
	showLoader,
	hideLoader,
	showSuccessAlert,
	showWarningAlert,
	showErrorAlert,
	hideWarningAlert,
	deleteConfrimFalse,
} from "../../common/actions/application";

import AdminSideNav from "../../components/adminSidebar/index";
import "bootstrap-daterangepicker/daterangepicker.css";
import lookupdataService from "../../services/lookupdata.service";

const _ = require("lodash");

function AdminSettings(props) {
	const [modalShow, setModalShow] = React.useState(false);
	const [key, setKey] = useState("Movie Settings");
	const [isEdit, setIsEdit] = useState(false);
	const [actorTypes, setActorTypes] = useState([]);
	const [editActorType, setEditActorType] = useState({});
	const [deleteActorType, setDeleteActorType] = useState({});
	// const [isPlatformEdit, setIsPlatformEdit] = useState(false);
	const [modalPlatformShow, setModalPlatformShow] = React.useState(false);
	const [platforms, setPlatforms] = useState([]);
	const [editPlatform, setEditPlatform] = useState({});
	const [deletePlatform, setDeletePlatform] = useState({});

	const [modalGenreShow, setModalGenreShow] = React.useState(false);
	const [genres, setGenres] = useState([]);
	const [editGenre, setEditGenre] = useState({});
	const [deleteItem, setDeleteItem] = useState({});

	const [movieType, setMovieType] = useState([]);
	const [modalTitleShow, setModalTitleShow] = useState(false);
	const [editTitle, setEditTitle] = useState({});
	const [isDesc, setIsDesc] = useState(false);

	useEffect(() => {
		getLookupList();
	}, []);

	const getLookupList = async () => {
		props.dispatch(showLoader());
		const response = await lookupdataService.getLookUpData();
		setMovieType(response.filter((x) => x.TypeId == 1));
		setPlatforms(response.filter((x) => x.TypeId == 2));
		setGenres(response.filter((x) => x.TypeId == 3));
		// let response = [{ "id": 1, "Value": "HeadLiner", "CreatedOn": "11/11/2021" }];
		// setActorTypes(response);
		// let response2 = [{ "id": 1, "Value": "Netflix", "CreatedOn": "11/11/2021" },
		// { "id": 1, "Value": "Lifetime", "CreatedOn": "11/11/2021" }];
		// setPlatforms(response2);

		// let response3 = [{ "id": 1, "Value": "Comedy", "CreatedOn": "11/11/2021" },
		// { "id": 1, "Value": "SI-FI", "CreatedOn": "11/11/2021" }];
		// setGenres(response3);
		props.dispatch(hideLoader());
	};

	const editActorTy = (data) => {
		setEditActorType(data);
		setModalShow(true);
		setIsEdit(true);
	};

	const editGenreFun = (data) => {
		setEditGenre(data);
		setModalGenreShow(true);
		setIsEdit(true);
	};

	const editTitleTy = (data) => {
		setEditTitle(data);
		setModalTitleShow(true);
		setIsEdit(true);
	};

	const upsertGenric = async (data) => {
		props.dispatch(showLoader());
		if (isEdit) data.id = editTitle.id;
		await lookupdataService.upsertLookUpData(data).then((resp) => {
			if (resp.data) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getLookupList();
			}
			props.dispatch(hideLoader());
		});
	};

	if (props.deleteConfrim) {
		props.dispatch(showLoader());
		lookupdataService.deleteLookUpDataById(deleteItem.id).then((resp) => {
			if (resp) {
				getLookupList();
				props.dispatch(deleteConfrimFalse());
				props.dispatch(hideWarningAlert());
			}
		});
		props.dispatch(hideLoader());
	}

	const sortSetting = (tableName, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedSetting;
		if (label === "Value") {
			sortedSetting = _.orderBy(tableName, [(table) => table.Value.toLowerCase().trim()], orderByFlag);
		} else {
			sortedSetting = _.orderBy(tableName, label, orderByFlag);
		}
		tableName === movieType
			? setMovieType(sortedSetting)
			: tableName === platforms
			? setPlatforms(sortedSetting)
			: setGenres(sortedSetting);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	return (
		<div className="dashboard">
			<div className="admin-setting">
				<AdminHeaderTop />
				<div className="d-flex">
					<Col className="left-side-nav">
						<AdminSideNav placement="start" name="start" />
					</Col>
					<div className="right-side-content">
						<div className="content border-nav" style={{ padding: "26px 40px" }}>
							<Container fluid>
								<div className="table-main desktop-view">
									<h3>Settings</h3>
									<div className="p-t-20">
										<Tabs
											id="controlled-tab-example"
											activeKey={key}
											onSelect={(k) => setKey(k)}
											className="mb-3">
											<Tab eventKey="Movie Settings" title="Movie Settings">
												{/* <h4>Movie Settings</h4> */}
												<div>
													{/* <h4>Add Actor Type</h4>
                                                    <div style={{ "borderBottom": "1px solid #800022" }}>
                                                        <div className="add-new">
                                                            <button className="pull-right blue-btn" type="button" onClick={() => {
                                                                setEditActorType({}); setIsEdit(false); setModalShow(true);
                                                            }} >Add Actor Type</button>
                                                            <ActerTypeModal
                                                                show={modalShow}
                                                                isEdit={isEdit}
                                                                editActorType={editActorType}
                                                                onHide={() => setModalShow(false)}
                                                                onSubmited={(data) => upsertTournament(data)} />
                                                        </div>
                                                        <Table striped hover>
                                                            <thead>
                                                                <tr>
                                                                    <th><span># <i className="fa fa-long-arrow-down" aria-hidden="true"></i></span></th>
                                                                    <th><span>Actor Type<i className="fa fa-long-arrow-down" aria-hidden="true"></i></span></th>
                                                                    <th><span>Created On  <i className="fa fa-long-arrow-down" aria-hidden="true"></i></span></th>
                                                                    <th><span><i className="fa fa-long-arrow-down" aria-hidden="true"></i></span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {actorTypes?.map((actorType, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{actorType.Value}</td>
                                                                                <td>
                                                                                    <Moment format="M/DD/yyyy, h:mm a">{actorType.CreatedOn}</Moment>
                                                                                </td>

                                                                                <td>
                                                                                    <Button onClick={() => {
                                                                                        editActorTy(actorType);
                                                                                    }}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
                                                                                    <Button onClick={() => {
                                                                                        setDeleteActorType(actorType);
                                                                                        props.dispatch(showWarningAlert("Do wish to delete this tournamnet?", undefined, "delete-action"));
                                                                                    }} style={{ marginLeft: "5px" }} ><i className="fa fa-trash" aria-hidden="true"></i></Button>
                                                                                </td>
                                                                            </tr>
                                                                        </>)
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div> */}
													<br />
													<h4>Add Title Type</h4>
													<div style={{ borderBottom: "1px solid #800022" }}>
														<div className="add-new">
															<button
																className="pull-right blue-btn"
																type="button"
																onClick={() => {
																	setEditTitle({});
																	setIsEdit(false);
																	setModalTitleShow(true);
																}}>
																Add Title
															</button>
															<TitleModal
																count={movieType.length + 1}
																show={modalTitleShow}
																isEdit={isEdit}
																editTitle={editTitle}
																onHide={() => setModalTitleShow(false)}
																onSubmited={(data) => upsertGenric(data)}
															/>
														</div>
														<Table striped hover>
															<thead>
																<tr>
																	<th>
																		<span>
																			# <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(movieType, "Value")}>
																			<span>
																				Title
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(movieType, "CreatedOn")}>
																			<span>
																				Created On
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<span>
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																</tr>
															</thead>
															<tbody>
																{movieType?.map((platform, index) => {
																	return (
																		<tr key={index + platform.CreatedOn}>
																			<td>{index + 1}</td>
																			<td>{platform.Value}</td>
																			<td>
																				<Moment format="M/DD/yyyy, h:mm a">{platform.CreatedOn}</Moment>
																			</td>
																			<td>
																				<Button
																					onClick={() => {
																						editTitleTy(platform);
																					}}>
																					<i className="fa fa-pencil" aria-hidden="true"></i>
																				</Button>
																				<Button
																					onClick={() => {
																						setDeleteItem(platform);
																						props.dispatch(
																							showWarningAlert(
																								"Do wish to delete this Title?",
																								undefined,
																								"delete-action"
																							)
																						);
																					}}
																					style={{ marginLeft: "5px" }}>
																					<i className="fa fa-trash" aria-hidden="true"></i>
																				</Button>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
													<br />
													<h4>Add Platform</h4>
													<div style={{ borderBottom: "1px solid #800022" }}>
														<div className="add-new">
															<button
																className="pull-right blue-btn"
																type="button"
																onClick={() => {
																	setEditPlatform({});
																	setIsEdit(false);
																	setModalPlatformShow(true);
																}}>
																Add Platform
															</button>
															<PlatformModal
																count={platforms.length + 1}
																show={modalPlatformShow}
																isEdit={isEdit}
																editPlatform={editPlatform}
																onHide={() => setModalPlatformShow(false)}
																onSubmited={(data) => upsertGenric(data)}
															/>
														</div>
														<Table striped hover>
															<thead>
																<tr>
																	<th>
																		<span>
																			# <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(platforms, "Value")}>
																			<span>
																				Platform
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(platforms, "CreatedOn")}>
																			<span>
																				Created On
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<span>
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																</tr>
															</thead>
															<tbody>
																{platforms?.map((platform, index) => {
																	return (
																		<tr key={index + platform.CreatedOn}>
																			<td>{index + 1}</td>
																			<td>{platform.Value}</td>
																			<td>
																				<Moment format="M/DD/yyyy, h:mm a">{platform.CreatedOn}</Moment>
																			</td>
																			<td>
																				<Button
																					onClick={() => {
																						editActorTy(platform);
																					}}>
																					<i className="fa fa-pencil" aria-hidden="true"></i>
																				</Button>
																				<Button
																					onClick={() => {
																						setDeleteItem(platform);
																						props.dispatch(
																							showWarningAlert(
																								"Do wish to delete this Platform?",
																								undefined,
																								"delete-action"
																							)
																						);
																					}}
																					style={{ marginLeft: "5px" }}>
																					<i className="fa fa-trash" aria-hidden="true"></i>
																				</Button>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
													<br />
													<h4>Add Genre</h4>
													<div>
														<div className="add-new">
															<button
																className="pull-right blue-btn"
																type="button"
																onClick={() => {
																	setEditGenre({});
																	setIsEdit(false);
																	setModalGenreShow(true);
																}}>
																Add Genre
															</button>
															<GenreModal
																count={genres.length + 1}
																show={modalGenreShow}
																isEdit={isEdit}
																editGenre={editGenre}
																onHide={() => setModalGenreShow(false)}
																onSubmited={(data) => upsertGenric(data)}
															/>
														</div>
														<Table striped hover>
															<thead>
																<tr>
																	<th>
																		<span>
																			# <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(genres, "Value")}>
																			<span>
																				Genre
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortSetting(movieType, "CreatedOn")}>
																			<span>
																				Created On
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<span>
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																</tr>
															</thead>
															<tbody>
																{genres?.map((genre, index) => {
																	return (
																		<tr key={index + genre.CreatedOn}>
																			<td>{index + 1}</td>
																			<td>{genre.Value}</td>
																			<td>
																				<Moment format="M/DD/yyyy, h:mm a">{genre.CreatedOn}</Moment>
																			</td>
																			<td>
																				<Button
																					onClick={() => {
																						editGenreFun(genre);
																					}}>
																					<i className="fa fa-pencil" aria-hidden="true"></i>
																				</Button>
																				<Button
																					onClick={() => {
																						setDeleteItem(genre);
																						props.dispatch(
																							showWarningAlert(
																								"Do wish to delete this Genre?",
																								undefined,
																								"delete-action"
																							)
																						);
																					}}
																					style={{ marginLeft: "5px" }}>
																					<i className="fa fa-trash" aria-hidden="true"></i>
																				</Button>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
												</div>
											</Tab>
											<Tab eventKey="profile" title="Profile">
												1 tab
											</Tab>
											<Tab eventKey="contact" title="Contact">
												1 tab
											</Tab>
											<Tab eventKey="Notifications" title="Notifications">
												Notifications
											</Tab>
										</Tabs>
									</div>
								</div>
							</Container>
						</div>
						{/*  content closed  */}
					</div>
				</div>
			</div>

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({ deleteConfrim: state.application.deleteConfrim });
export default connect(mapStateToProps)(AdminSettings);

function TitleModal(props) {
	const [form, setForm] = useState({});

	useEffect(() => {
		setForm({});
		// setTalentPool(props.editTournamnet.TourTalentPoolId);
	}, [props.editTitle]);

	const [errors, setErrors] = useState({});
	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		props.editTitle[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { Value } = form;
		const newErrors = {};

		if (!Value || Value === "") newErrors.Value = "cannot be blank!";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const { Value } = form;

			let data = {
				TypeId: 1,
				ValueId: props.count,
				Value: Value,
				IsActive: true,
				CreatedOn: new Date(Date.now()),
				ModifiedOn: new Date(Date.now()),
			};
			props.onHide();
			props.onSubmited(data);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.isEdit ? "Edit Title Type" : "Add Title Type"}
				</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form onSubmit={handleSubmit} role="form">
						<Row>
							<Col md={12}>
								<div className="create-callsheet">
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Title Type Name</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editTitle.Value}
												type="text"
												placeholder="Title Type Name"
												onChange={(e) => setField("Value", e.target.value)}
												isInvalid={!!errors.Value}
											/>
											<Form.Control.Feedback type="invalid">{errors.Value}</Form.Control.Feedback>
										</div>
									</Form.Group>
								</div>
							</Col>
						</Row>
						<div className="pupup-btn-row">
							<Button onClick={props.onHide} className="red-btn">
								CANCEL
							</Button>
							{props.isEdit ? (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									EDIT TITLE TYPE
								</Button>
							) : (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									SAVE TITLE TYPE
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function PlatformModal(props) {
	const [form, setForm] = useState({});

	useEffect(() => {
		setForm({});
		// setTalentPool(props.editTournamnet.TourTalentPoolId);
	}, [props.editPlatform]);

	const [errors, setErrors] = useState({});
	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		props.editPlatform[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { Value } = form;
		const newErrors = {};

		if (!Value || Value === "") newErrors.Value = "cannot be blank!";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const { Value } = form;

			let data = {
				TypeId: 2,
				ValueId: props.count,
				Value: Value,
				IsActive: true,
				CreatedOn: new Date(Date.now()),
				ModifiedOn: new Date(Date.now()),
			};
			props.onHide();
			props.onSubmited(data);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.isEdit ? "Edit Platform" : "Add Platform"}
				</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form onSubmit={handleSubmit} role="form">
						<Row>
							<Col md={12}>
								<div className="create-callsheet">
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Platform Name</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editPlatform.Value}
												type="text"
												placeholder="Platform Name"
												onChange={(e) => setField("Value", e.target.value)}
												isInvalid={!!errors.Value}
											/>
											<Form.Control.Feedback type="invalid">{errors.Value}</Form.Control.Feedback>
										</div>
									</Form.Group>
								</div>
							</Col>
						</Row>
						<div className="pupup-btn-row">
							<Button onClick={props.onHide} className="red-btn">
								CANCEL
							</Button>
							{props.isEdit ? (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									EDIT PLATFORM
								</Button>
							) : (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									SAVE PLATFORM
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function GenreModal(props) {
	const [form, setForm] = useState({});

	useEffect(() => {
		setForm({});
		// setTalentPool(props.editTournamnet.TourTalentPoolId);
	}, [props.editGenre]);

	const [errors, setErrors] = useState({});
	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		props.editGenre[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { Value } = form;
		const newErrors = {};

		if (!Value || Value === "") newErrors.Value = "cannot be blank!";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const { Value } = form;

			let data = {
				TypeId: 3,
				ValueId: props.count,
				Value: Value,
				IsActive: true,
				CreatedOn: new Date(Date.now()),
				ModifiedOn: new Date(Date.now()),
			};
			props.onHide();
			props.onSubmited(data);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.isEdit ? "Edit Genre" : "Add Genre"}
				</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form onSubmit={handleSubmit} role="form">
						<Row>
							<Col md={12}>
								<div className="create-callsheet">
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Genre Name</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editGenre.Value}
												type="text"
												placeholder="Genre Name"
												onChange={(e) => setField("Value", e.target.value)}
												isInvalid={!!errors.Value}
											/>
											<Form.Control.Feedback type="invalid">{errors.Value}</Form.Control.Feedback>
										</div>
									</Form.Group>
								</div>
							</Col>
						</Row>
						<div className="pupup-btn-row">
							<Button onClick={props.onHide} className="red-btn">
								CANCEL
							</Button>
							{props.isEdit ? (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									EDIT GENRE
								</Button>
							) : (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									SAVE GENRE
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}
