import React, { memo, useCallback, useRef, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";

import CSVReader from "react-csv-reader";
import {
	Container,
	Row,
	Col,
	Button,
	Nav,
	Modal,
	Dropdown,
	Table,
	Form,
	InputGroup,
	FormControl,
	ThemeProvider,
} from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from "../../components/footer/index";
import HeaderBanner from "../../assets/images/HeaderBanner.svg";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import User from "../../assets/images/user.jpg";
import Netflix from "../../assets/images/Netflix-Logo.svg";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import Callsheet from "../../assets/images/callsheet.svg";

import TournamentService from "../../services/tournament.service";
import { SpinnerRoundFilled } from 'spinners-react';
import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
	showLoader,
	hideLoader,
	showSuccessAlert,
	showWarningAlert,
	showErrorAlert,
	hideWarningAlert,
	deleteConfrimFalse,
} from "../../common/actions/application";

import { showTournamentPopup } from "../../common/actions/genericPopupAction";
import { useConnectWallet } from "../../common/redux/hooks";
import { createNewTournament, getAllTournaments } from "../../common/web3/api/fetchTournaments";
import { tournamentfactoryContract } from "../../common/web3/contract";
import AdminSideNav from "../../components/adminSidebar/index";
import ScoreService from "../../services/score.service";
import TourTalentPoolService from "../../services/tourtalentpool.service";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import TourTalentPool from "../../models/tourtalentpool.model";
import TalentPoolService from "../../services/talentpool.service";
import { set } from "lodash";
import { map } from "jquery";

const _ = require("lodash");

function AdminTalentPool(props) {
	const inputFile = useRef(null);
	const [modalShow, setModalShow] = React.useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isShowAddPool, setIsShowAddPool] = useState(false);
	const { web3, address } = useConnectWallet();
	const [editMovie, setEditMovie] = useState({});
	const [deleteTour, setDeleteTour] = useState({});
	const [scoreTalentPool, setScoreTalentPool] = useState([]);
	const [talentPools, setTalentPools] = useState([]);
	const [isActorEdit, setIsActorEdit] = useState(false);
	const [editActor, setEditActor] = useState({});
	const [deleteTalentPool, setDeleteTalentPool] = useState({});
	const [scores, setScores] = useState([]);
	const [unfilteredScores, setUnfilteredScores] = useState([]);
	const [selectedScores, setSelectedScores] = useState([]);
	const [downloadTalentPool, setDownloadTalentPool] = useState([]);
	const [isDesc, setIsDesc] = useState(false);
	const [isTitles, setIsTitles] = useState("PoolTitles");
	// const [talentPoolMovies, setTalentPoolMovies] = useState([]);
	const [talentPoolTable, setTalentPoolTable] = useState([]);
	const [talentPoolName, setTalentPoolName] = useState("");
	const [talentpoolModal, setTalentpoolModal] = useState();
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [mulDeleteTour, setMulDeleteTour] = useState([]);
	const [talentPoolDel, setTalentPoolDel] = useState([]);
	const [csvData, setCsvData] = useState([]);
	const [currentPage,SetCurrentPage]=useState(1);
	const [totalPage,SetTotalPage]=useState(1);

	useEffect(() => {
		getTalentPoolList(currentPage);
	}, []);

	useEffect(() => {
		poolTitlesManage("PoolTitles");
		setScores([]);
	}, [isShowAddPool]);

	const onButtonClick = () => {
		
		if (csvData[0] !== undefined) {
			// props.dispatch(showWarningAlert("Do Want to Upload CSV?", undefined, "delete-action"));
		//	console.log(inputFile.current);
		//	console.log(csvData);
		} else {
			props.dispatch(showWarningAlert("Please select a file for upload"));
		}
	};

	const getTalentPoolList = async (cPage) => {
		props.dispatch(showLoader());
		const response = await TourTalentPoolService.getTourTalentPoolList(cPage);
		console.log(response)
		// console.log(response);
		// let data = [{ "id": 1, "PoolName": "Pool 1", "ScoreCount": 10, "TournamnetsCount": 11, "CreatedOn": "11/11/2021" }];
		setTalentPools(response.data.res);
	SetTotalPage(Math.ceil(response.data.count.count/10))

		setTalentPoolTable(response.data.res);
		poolTitlesManage("PoolTitles");
		props.dispatch(hideLoader());
	};

	const getAllMovies = async (scoresss) => {
		//props.dispatch(showLoader());
		const response = await ScoreService.getAllScore();
		response.data.forEach((element) => {
			element.isSelected = false;
		});
		if (isTitles === "PoolTitles") {
			setScores(scoresss);
		} else {
			setScores(response.data);
		}
		setUnfilteredScores(response.data);

		//props.dispatch(hideLoader());
	};
	
	const pagination=(action)=>{
		if(action==="+"){
			SetCurrentPage(currentPage+1)
			getTalentPoolList(currentPage+1)
		}else{
			SetCurrentPage(currentPage-1)
			getTalentPoolList(currentPage-1)
		}

	}
	// const getActorsByScoreId = async (id) => {
	// 	// props.dispatch(showLoader());
	// 	// const response = await ScoreService.getAllScore();
	// 	// // response.data.forEach(num => {
	// 	// //   num.Total_Score = Number(num.Total_Score).toFixed(2);
	// 	// // });
	// 	// debugger
	// 	// setScores(response.data);
	// 	// props.dispatch(hideLoader());
	// };

	const upsertMovie = async (movieData) => {
		props.dispatch(showLoader());
		if (isEdit) movieData.id = editMovie.id;
		await TournamentService.addScore(movieData).then((resp) => {
			if (resp.data) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getTalentPoolList(currentPage);
			}
			props.dispatch(hideLoader());
		});
	};

	const upsertActor = async (actorData) => {
		props.dispatch(showLoader());
		if (isEdit) actorData.id = editActor.id;
		await TournamentService.addScore(actorData).then((resp) => {
			if (resp.data) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getTalentPoolList(currentPage);
			}
			props.dispatch(hideLoader());
		});
	};

	const poolTitlesManage = (filterType) => {
		if (filterType === "PoolTitles") {
			let filteredScores = [];
			if (selectedScores.length > 0) {
				filteredScores = _.filter(talentPools, function (o) {
					return _.includes(selectedScores, o.id);
				});
				setScores(selectedScores);
			}
		} else if (filterType === "All") {
			setScores(unfilteredScores);
		} else {
			isTitles == "PoolTitles" ? setScores(unfilteredScores) : setScores(unfilteredScores);
		}
		setIsTitles(filterType);
	};

	const setPoolEditMode = async () => {
		setIsShowAddPool(!isShowAddPool);
	};

	const toggelScoreIsSelcted = (position) => {
		let newArr = [...scores];
		newArr[position].isSelected = !newArr[position].isSelected;
		setScores(newArr);
	};

	const addMovie = async (selectedMovie, index) => {
		if (selectedScores.find((movie) => movie.id == selectedMovie.id)) {
			props.dispatch(showWarningAlert("This actor is already added"));
		} else {
			let newArray = [...selectedScores, selectedMovie];
			toggelScoreIsSelcted(index);
			setSelectedScores(newArray);
			// only keep Title,id,Type,Platform,Release_Date,Genre,Total_Score,Co_Star_Points,Headliners_Points,RT_Critics_Score,Google_User_Score,MC_Critics_Score,IMDb_User_Score,IsActive, TitleImg from newArray
			let newArr = newArray.map((movie) => {
				return {
					id: movie.id,
					Title: movie.Title,
					Type: movie.Type,
					Platform: movie.Platform,
					Release_Date: movie.Release_Date,
					Genre: movie.Genre,
					Total_Score: movie.Total_Score,
					Co_Star_Points: movie.Co_Star_Points,
					Headliners_Points: movie.Headliners_Points,
					RT_Critics_Score: movie.RT_Critics_Score,
					Google_User_Score: movie.Google_User_Score,
					MC_Critics_Score: movie.MC_Critics_Score,
					IMDb_User_Score: movie.IMDb_User_Score,
					IsActive: movie.IsActive,
					TitleImg: movie.TitleImg,
				};
			});

			setDownloadTalentPool(newArr);
		}
	};

	const removeMovie = async (Movie, index) => {
		var newArray = [...selectedScores];
		var index = newArray.indexOf(Movie);
		if (index !== -1) {
			newArray.splice(index, 1);
			toggelScoreIsSelcted(index);
			setSelectedScores(newArray);
			let newArr = newArray.map((movie) => {
				return {
					id: movie.id,
					Title: movie.Title,
					Type: movie.Type,
					Platform: movie.Platform,
					Release_Date: movie.Release_Date,
					Genre: movie.Genre,
					Total_Score: movie.Total_Score,
					Co_Star_Points: movie.Co_Star_Points,
					Headliners_Points: movie.Headliners_Points,
					RT_Critics_Score: movie.RT_Critics_Score,
					Google_User_Score: movie.Google_User_Score,
					MC_Critics_Score: movie.MC_Critics_Score,
					IMDb_User_Score: movie.IMDb_User_Score,
					IsActive: movie.IsActive,
					TitleImg: movie.TitleImg,
				};
			});

			setDownloadTalentPool(newArr);
		}
	};

	const submiteTalentPool = async () => {
		if (talentPoolName != "" && selectedScores.length >= 2) {
			setShowError(false);
			// TourTalentPool.TalentPoolName = talentPoolName;
			// TourTalentPool.ScoresList = selectedScores;
			// TourTalentPool.TalentPoolName = talentPoolName;
			let talentPoolData = {
				TalentPoolName: talentPoolName,
				ScoresList: selectedScores,
				TournamentCount: 0,
				// CreatedOn: new Date(Date.now()),
				// ModifiedOn: new Date(Date.now()),
				IsActive: true,
			};
			props.dispatch(showLoader());
			if (isEdit) {
				talentPoolData.id = editTalentPool.id;
				talentPoolData.ModifiedOn = new Date(Date.now());
				// TourTalentPool.ModifiedOn = new Date(Date.now());
			} else {
				talentPoolData.CreatedOn = new Date(Date.now());
				talentPoolData.ModifiedOn = new Date(Date.now());
			}
			console.log(talentPoolData)
			await TourTalentPoolService.upsertTourTalentPool(talentPoolData).then((resp) => {
				if (resp.data) {
					setPoolEditMode();
					props.dispatch(hideLoader());
					getTalentPoolList(currentPage);
				}
				props.dispatch(hideLoader());
			});
		} else {
			talentPoolName === ""
				? setShowError(true)
				: props.dispatch(showErrorAlert("There should be at least two titles", ""));
		}
	};

	if (props.deleteConfrim) {
		props.dispatch(showLoader());
		mulDeleteTour.forEach((element, index) => {
			const object = {
				isActive: false,
				tourTalentPoolId: mulDeleteTour[index].id,
			};
			TourTalentPoolService.updateIsActive(object).then((resp) => {
				if (resp.data) {
					getTalentPoolList(currentPage);
					props.dispatch(deleteConfrimFalse());
					props.dispatch(hideWarningAlert());
					setShowCheckBox(false);
				}
			});
		});
		props.dispatch(hideLoader());
	}

	const clearTalentPool = async () => {
		setSelectedScores([]);
		setDownloadTalentPool([]);
	};

	const [editTalentPool, setEditTalentPool] = useState({});
	const [hideTextbox, setHideTextbox] = useState(true);
	const [showError, setShowError] = useState(false);

	const enableEdit = () => {
		setHideTextbox(!hideTextbox);
	};

	const handleInputChange = (event) => {
		const value = event.currentTarget.value;
		setTalentPoolName(value);
		if (value != "") {
			setShowError(false);
		} else {
			setShowError(true);
		}
	};

	const editTourTalentPool = (talentPool) => {
	
		
			poolTitlesManage('PoolTitles')
			
	

		let scoresss = talentPool.ScoresList;
		setEditTalentPool(talentPool);
		setTalentPoolName(talentPool.TalentPoolName);
		setPoolEditMode();

		getAllMovies(scoresss);
		setIsEdit(true);
		setSelectedScores(scoresss);
		setScores(scoresss);
		// shainaz look above
		const newArray = talentPool.ScoresList;
		let newArr = newArray.map((movie) => {
			return {
				id: movie.id,
				Title: movie.Title,
				Type: movie.Type,
				Platform: movie.Platform,
				Release_Date: movie.Release_Date,
				Genre: movie.Genre,
				Total_Score: movie.Total_Score,
				Co_Star_Points: movie.Co_Star_Points,
				Headliners_Points: movie.Headliners_Points,
				RT_Critics_Score: movie.RT_Critics_Score,
				Google_User_Score: movie.Google_User_Score,
				MC_Critics_Score: movie.MC_Critics_Score,
				IMDb_User_Score: movie.IMDb_User_Score,
				IsActive: movie.IsActive,
				TitleImg: movie.TitleImg,
			};
		});

		setDownloadTalentPool(newArr);
	};

	const updateScore = async (movie) => {
		let rt = Number(movie.RT_Critics_Score);
		let go = Number(movie.Google_User_Score);
		let mc = Number(movie.MC_Critics_Score);
		let im = Number(movie.IMDb_User_Score);
		// if (rt > 0 && go > 0 && mc > 0 && im > 0) {
		if (rt <= 100 && go <= 100 && mc <= 100 && im <= 10) {
			let totalScore = ((rt + go * 1.6) / 2 + (mc + im * 10 * 1.6) / 2) / 2;
			movie.Total_Score = Number(totalScore.toFixed(2));
			movie.Co_Star_Points = Number((totalScore * 1.2).toFixed(2));
			movie.Headliners_Points = Number((totalScore * 1.4).toFixed(2));
			movie.RT_Critics_Score = rt;
			movie.Google_User_Score = go;
			movie.MC_Critics_Score = mc;
			movie.IMDb_User_Score = im;
			let newScoreState = [...scores];
			let index = newScoreState.findIndex((__) => __.id === movie.id);
			newScoreState[index]=movie;
			setScores(newScoreState);
			setSelectedScores(newScoreState);
			let updateScore=editTalentPool;
			updateScore.ScoresList=newScoreState;
			// console.log(updateScore)
			// newScoreState[index] = { ...movie };
			// setScores(newScoreState);
			if(isEdit){
				await TourTalentPoolService.updateTourTalentPool(updateScore);
			}
		}
	};

	//sorting TalentPool,addNewTalentPool(left and right side page)
	const sortTourTalentpool = (tableName, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedTourTalentpool;
		if (
			label === "TalentPoolName" ||
			label === "Title" ||
			label === "Type" ||
			label === "Platform" ||
			label === "Genre"
		) {
			sortedTourTalentpool = _.orderBy(
				tableName,
				[
					(table) =>
						(label === "TalentPoolName"
							? table.TalentPoolName
							: label === "Title"
							? table.Title
							: label === "Type"
							? !table.Type
								? (table.Type = "")
								: table.Type
							: label === "Platform"
							? !table.Platform
								? (table.Platform = "")
								: table.Platform
							: !table.Genre
							? (table.Genre = "")
							: table.Genre
						)
							.toLowerCase()
							.trim(),
				],
				orderByFlag
			);
		} else if (
			label === "TournamentCount" ||
			label === "ScoresList" ||
			label == "Total_Score" ||
			label == "Co_Star_Points" ||
			label == "Headliners_Points" ||
			label == "Release_Date"
		) {
			sortedTourTalentpool = _.orderBy(
				tableName,
				[
					(table) =>
						label == !"Release_Date"
							? Number(
									label === "TournamentCount"
										? table.TournamentCount
										: label === "ScoresList"
										? table.ScoresList.length
										: label === "Total_Score"
										? table.Total_Score
										: label == "Co_Star_Points"
										? table.Co_Star_Points
										: table.Headliners_Points
							  )
							: new Date(table.Release_Date),
				],
				orderByFlag
			);
		} else {
			sortedTourTalentpool = _.orderBy(tableName, label, orderByFlag);
		}
		let downloadSorted = sortedTourTalentpool.map((movie) => {
			return {
				id: movie.id,
				Title: movie.Title,
				Type: movie.Type,
				Platform: movie.Platform,
				Release_Date: movie.Release_Date,
				Genre: movie.Genre,
				Total_Score: movie.Total_Score,
				Co_Star_Points: movie.Co_Star_Points,
				Headliners_Points: movie.Headliners_Points,
				RT_Critics_Score: movie.RT_Critics_Score,
				Google_User_Score: movie.Google_User_Score,
				MC_Critics_Score: movie.MC_Critics_Score,
				IMDb_User_Score: movie.IMDb_User_Score,
				IsActive: movie.IsActive,
				TitleImg: movie.TitleImg,
			};
		});
		setDownloadTalentPool(downloadSorted);
		tableName === talentPools
			? setTalentPools(sortedTourTalentpool)
			: tableName === scores
			? setScores(sortedTourTalentpool)
			: setSelectedScores(sortedTourTalentpool);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const getTalentPoolSearch = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		talentPoolTable.filter((element) => {
			let tempFilter = element.TalentPoolName.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setTalentPools(newArray);
		props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getTalentPoolSearch(searchString);
	};
	const addTalentPool = () => {
		getAllMovies();
		setPoolEditMode();
		setEditMovie({});
		setScoreTalentPool([]);
	};

	const uploadedCSV = async (data, fileInfo, originalFile) => {
		props.dispatch(showLoader());
		setTalentpoolModal(false);
		// console.dir( fileInfo);

		// shainaz look here
		// const createAllActorsList = [];
		// for (let i = 1; i < data.length; i++) {
		// 	let temp = {};
		// 	temp.Actor = data[i][0];
		// 	temp.isSelected = true;
		// 	temp.Actor_Type = data[i][2];
		// 	temp.Salery = data[i][1];
		// 	temp.title = data[i][3];
		// 	temp.Type = data[i][4];
		// 	temp.Platform = data[i][5];
		// 	temp.Release_Date = data[i][6];
		// 	temp.Genre = data[i][7];
		// 	temp.Notes = data[i][8];
		// 	temp.Total_Scores = 0;
		// 	temp.IsActive = true;
		// 	createAllActorsList.push(temp);
		// }

		// group createAllActorsList

		const ScoresListssssss = [];
		const TalentPoolName = fileInfo.name;
		const TournamentCount = 0;
		const IsActive = true;
		const CreatedOn = new Date();
		const ModifiedOn = new Date();
		const arrMovie = [];
		// console.log(data)/
		await data.map((element, index) => {
			if (index !== 0  && element[0]!=undefined && element[0]!='' ) {
				if (arrMovie.includes(element[3])) {
				} else {
					arrMovie.push(element[3]);
				}
			}
		});
	//	console.log(arrMovie);

		const ScoresList = arrMovie.map((element, index) => {
			let actorlist = {};
			let tempAct = [];
			let salary = "";
			let actorType = "";
			let type = "";
			let Platform = "";
			let rel_date = "";
			let genre = "";
			let notes = "";
			let TmdbId = "";
			let isSelected = true;

			data.map((el, i) => {
				if (element == el[3]) {
					tempAct.push({
						Actor: el[0],
						Salery: el[1],
						Title: el[3],
						Type: el[4],
						Actor_Type: el[2],
						Platform: el[5],
						Release_Date: (rel_date = el[6]),
						Notes: el[8],
						Total_Scores: null,
						profile_path: null,
						IsActive: true,
						isSelected: true,
						Genre: el[7],
					});

					type = el[4];
					salary = el[1];
					actorType = el[2];
					Platform = el[5];
					rel_date = el[6];
					genre = el[7];
					notes = el[8];
					TmdbId = el[9];
					isSelected = true;
				}
			});

			return {
				Title: element,
				Type: type,
				Platform: Platform,
				Release_Date: rel_date,
				Genre: genre,
				Total_Score: 0,
				Co_Star_Points: 0,
				Headliners_Points: 0,
				RT_Critics_Score: 0,
				Google_User_Score: 0,
				MC_Critics_Score: 0,
				IMDb_User_Score: 0,
				ActorsList: tempAct,
				IsActive: true,
				CreatedOn: new Date(),
				ModifiedOn: new Date(),
				TmdbId: TmdbId,
				TitleImg: null,
			};
		});
		const finalCSVobject = {
			CreatedOn: CreatedOn,
			IsActive: IsActive,
			ModifiedOn: ModifiedOn,
			ScoresList: ScoresList,
			TalentPoolName: TalentPoolName,
			TournamentCount: TournamentCount,
		};

		// console.log(finalCSVobject);
		
		await TourTalentPoolService.upsertCSVTourTalentPool(finalCSVobject).then((resp) => {
			if (resp.data) {
				props.dispatch(showSuccessAlert("Success"));
				setTalentpoolModal(false);
				props.dispatch(hideLoader());
			}
			getTalentPoolList(currentPage);
			props.dispatch(hideLoader());
		});
		setCsvData(data);

		// const ScoresList = data.map((element, index) => {

		// 	return {
		// 		Title: null,
		// 		Type: null,
		// 		Platform: null,
		// 		Release_Date: null,
		// 		Genre: null,
		// 		Total_Score: 0,
		// 		Co_Star_Points: 0,
		// 		Headliners_Points: 0,
		// 		RT_Critics_Score: 0,
		// 		Google_User_Score: 0,
		// 		MC_Critics_Score: 0,
		// 		IMDb_User_Score: 0,
		// 		ActorsList: [null],
		// 		IsActive: true,
		// 		CreatedOn: new Date(),
		// 		ModifiedOn: new Date(),
		// 		TmdbId: null,
		// 		isSelected: true,
		// 		TitleImg: null,
		// 	};
		// });
	};
	const selectAllFun = () => {
		setMulDeleteTour(talentPools);
	};

	const deleteMultipleFun = (talentPool) => {
		const localMulDeleteTour = [...mulDeleteTour];
		if (localMulDeleteTour.includes(talentPool)) {
			const index = localMulDeleteTour.indexOf(talentPool);
			if (index >= -1) {
				localMulDeleteTour.splice(index, 1);
			}
		} else {
			localMulDeleteTour.push(talentPool);
		}
		const del = [];
		localMulDeleteTour.forEach((value) => {
			del.push(value.TalentPoolName);
		});
		setMulDeleteTour(localMulDeleteTour);
		setTalentPoolDel(del);
	};

	return (
		<div className="dashboard">
			<div>
				<AdminHeaderTop />
				<div className="d-flex">
					<Col className="left-side-nav">
						<AdminSideNav placement="start" name="start" />
					</Col>
					<div className="right-side-content">
						<div className="content border-nav" style={{ padding: "26px 40px" }}>
							<Container fluid>
								<div className="table-main desktop-view">
									<h3>Talent Pools</h3>
									{!isShowAddPool ? (
										<div>
											
											<Row>
												<Col lg={3} md={12} xs={12}>
													<InputGroup className="search-btn">
														<FormControl
															placeholder="Search"
															aria-label="Recipient's username"
															aria-describedby="basic-addon2"
															onChange={handleInputSearchChange}
														/>
														<InputGroup.Text id="basic-addon2">
															<i className="fa fa-search" aria-hidden="true"></i>
														</InputGroup.Text>
													</InputGroup>
												</Col>
												<Col>
													<div className="add-new">
														<Button
															className="red-btn"
															onClick={() =>
																!showCheckBox
																	? setShowCheckBox(true)
																	: !mulDeleteTour[0]
																	? props.dispatch(showErrorAlert("please select atleast one Talent pool"))
																	: props.dispatch(
																			showWarningAlert(
																				"Do wish to delete this Talent pool?",
																				undefined,
																				"delete-action",
																				talentPoolDel,
																				"Talent Pool Name "
																			)
																	  )
															}>
															{!showCheckBox ? "MULTIPLE DELETE " : "DELETE "}
															<i className="fa fa-trash" aria-hidden="true"></i>
														</Button>
														<button
															className="pull-right blue-btn"
															type="button"
															onClick={() => setTalentpoolModal(true)}>
															Add Talent Pool
														</button>
													</div>
												</Col>
											</Row>
											<Table striped hover>
												<thead>
													<tr>
														<th>
															{showCheckBox ? (
																<>
																	<button className="red-btn" onClick={() => setShowCheckBox(false)}>
																		{" "}
																		BACK
																	</button>
																	<button className="blue-btn" onClick={selectAllFun}>
																		SELECT ALL
																	</button>
																	<button className="red-btn" onClick={() => setMulDeleteTour([])}>
																		CLEAR ALL
																	</button>
																</>
															) : (
																<span>
																	# <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																</span>
															)}
														</th>
														<th>
															<a onClick={() => sortTourTalentpool(talentPools, "TalentPoolName")}>
																<span>
																	Talent Pool Name
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortTourTalentpool(talentPools, "ScoresList")}>
																<span>
																	Total Score Added
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortTourTalentpool(talentPools, "TournamentCount")}>
																<span>
																	Tournaments Count
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortTourTalentpool(talentPools, "CreatedOn")}>
																<span>
																	Created On
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<span>
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															</span>
														</th>
													</tr>
												</thead>
												<tbody>
													{talentPools?.map((talentPool, index) => {
														return (
															<tr key={`${index}` + talentPool.ScoresList.length}>
																<td>
																	{showCheckBox ? (
																		<Form.Check
																			type="checkbox"
																			//id={index + "checkbox1"}
																			label={""}
																			checked={mulDeleteTour.includes(talentPool)}
																			value={talentPool.id}
																			onChange={() => {
																				deleteMultipleFun(talentPool);
																			}}
																		/>
																	) : (
																		index + 1+(currentPage-1)*10
																	)}
																</td>
																<td>{talentPool.TalentPoolName}</td>
																<td>{talentPool.ScoresList.length}</td>
																<td>{talentPool.TournamentCount}</td>
																<td>
																	<Moment format="M/DD/yyyy, h:mm a">{talentPool.CreatedOn}</Moment>
																</td>

																<td>
																	<Button
																		onClick={() => {
																			editTourTalentPool(talentPool);
																			
																			
																		}}>
																		<i className="fa fa-pencil" aria-hidden="true"></i>
																	</Button>
																	<Button
																		onClick={() => {
																			setMulDeleteTour([talentPool]);
																			const name = [talentPool.TalentPoolName];
																			props.dispatch(
																				showWarningAlert(
																					"Do wish to delete this talentpool?",
																					undefined,
																					"delete-action",
																					name,
																					"Talent Pool Name"
																				)
																			);
																		}}
																		style={{ marginLeft: "5px" }}>
																		<i className="fa fa-trash" aria-hidden="true"></i>
																	</Button>
																	{/* <Link to={"/callsheet?c=new&t=" + tournament.id} onClick={() => localStorage.setItem("CallSheetId", null)}> <button type="button">Let’s Play</button> </Link> */}
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>

											<div className="pagination">

							<button className="paginationButton" onClick={()=>pagination('-')} disabled={currentPage===1?true:false}>{"<"} </button>
								<span>{currentPage}</span> 
							<button className="paginationButton"  onClick={()=>pagination('+')} disabled={currentPage===totalPage?true:false}>{">"} </button>
							<span>{totalPage}</span>
						</div>
										</div>
									) : (
										<div>
											<h4>{isEdit ? "Edit Talent Pool" : "Add Talent Pool"}</h4>
											<div className="page-ntf">
												<button
													onClick={() => {
														poolTitlesManage("All");
													}}
													className={isTitles == "All" ? "active" : null}>
													All
												</button>
												<button
													onClick={() => {
														poolTitlesManage("Titles");
													}}
													className={isTitles == "Titles" ? "active" : null}>
													Titles
												</button>
												<button
													onClick={() => {
														poolTitlesManage("PoolTitles");
													}}
													className={isTitles == "PoolTitles" ? "active" : null}>
													Pool Titles
												</button>
											</div>
											<div className="contest-row">
												<div className="contest-left" style={{ width: "70%" }}>
													<div className="rbt-box">
														{/* <Row className="search-col">
                                                        <Col lg={5} md={12} xs={12}>



                                                        </Col>
                                                        <Col lg={7} md={12} xs={12}>

                                                           

                                                        </Col>
                                                    </Row> */}

														<div
															className="table-main scroll-table p-r-10  contest-table-lv"
															style={{ maxHeight: "650px" }}>
															<Table striped hover>
																<thead>
																	<tr>
																		<th className="fixColumn">
																			<a onClick={() => sortTourTalentpool(scores, "Title")}>
																				<span>
																					Title
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>

																		{isTitles !== "All" ? null : (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Type")}>
																					<span>
																						Type
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		)}
																		{isTitles == "All" ? (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Platform")}>
																					<span>
																						Platform
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		) : null}
																		<th>
																			<a onClick={() => sortTourTalentpool(scores, "Release_Date")}>
																				<span>
																					Release Date
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>
																		{isTitles == "All" ? (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Genre")}>
																					<span>
																						Genre
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		) : null}
																		{isTitles == "All" ? (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Total_Score")}>
																					<span>
																						Total Score
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		) : null}

																		{isTitles == "All" ? (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Co_Star_Points")}>
																					<span>
																						Co Star Points
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		) : null}
																		{isTitles == "All" ? (
																			<th>
																				<a onClick={() => sortTourTalentpool(scores, "Headliners_Points")}>
																					<span>
																						Headliner Points
																						{isDesc ? (
																							<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																						) : (
																							<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																						)}
																					</span>
																				</a>
																			</th>
																		) : null}
																		<th>
																			<a onClick={() => sortTourTalentpool(scores, "RT_Critics_Score")}>
																				<span>
																					RT Critics Score
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>
																		<th>
																			<a onClick={() => sortTourTalentpool(scores, "Google_User_Score")}>
																				<span>
																					Google User Score
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>
																		<th>
																			<a onClick={() => sortTourTalentpool(scores, "MC_Critics_Score")}>
																				<span>
																					MC Critics Score
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>
																		<th>
																			<a onClick={() => sortTourTalentpool(scores, "IMDb_User_Score")}>
																				<span>
																					IMDb User Score
																					{isDesc ? (
																						<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																					) : (
																						<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																					)}
																				</span>
																			</a>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																scores.length!=0	?scores?.map((movie, index) => {
																		return (
																			<tr key={index + movie.id}>
																				<td className="fixColumn" style={{ textAlign: "left" }}>
																					{movie.isSelected ? (
																						<a className="minus-button">
																							<i
																								onClick={() => removeMovie(movie, index)}
																								className="fa fa-minus-circle"
																								aria-hidden="true"></i>
																						</a>
																					) : (
																						<a className="plus-button">
																							<i
																								onClick={() => addMovie(movie, index)}
																								className="fa fa-plus-circle"
																								aria-hidden="true"></i>
																						</a>
																					)}
																					<span> </span>
																					{movie.Title}
																				</td>
																				{isTitles !== "All" ? null : <td>{movie.Type}</td>}
																				{isTitles !== "All" ? null : <td>{movie.Platform}</td>}
																				<td>
																					<Moment format="M/DD/yyyy">{movie.Release_Date}</Moment>
																				</td>
																				{isTitles !== "All" ? null : <td>{movie.Genre}</td>}
																				{isTitles !== "All" ? null : (
																					<td>{parseFloat(movie.Total_Score).toFixed(2)}</td>
																				)}
																				{isTitles !== "All" ? null : (
																					<td>{parseFloat(movie.Co_Star_Points).toFixed(2)}</td>
																				)}
																				{isTitles !== "All" ? null : (
																					<td>{parseFloat(movie.Headliners_Points).toFixed(2)}</td>
																				)}
																				<td>
																					<div className="row-input">
																						<Form.Control
																							type="number"
																							placeholder="RT Critics Score"
																							//defaultValue={movie.RT_Critics_Score}
																							defaultValue={movie.RT_Critics_Score}
																							onBlur={(e) =>
																								updateScore({
																									...movie,
																									RT_Critics_Score: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</td>
																				<td>
																					<div className="row-input">
																						<Form.Control
																							type="text"
																							placeholder="Google User Score"
																							defaultValue={movie.Google_User_Score}
																							// defaultValue={movie.Google_User_Score}
																							onChange={(e) =>
																								updateScore({
																									...movie,
																									Google_User_Score: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</td>
																				<td>
																					<div className="row-input">
																						<Form.Control
																							type="text"
																							placeholder="MC Critics Score"
																							//defaultValue={movie.MC_Critics_Score}
																							defaultValue={movie.MC_Critics_Score}
																							onBlur={(e) =>
																								updateScore({
																									...movie,
																									MC_Critics_Score: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</td>
																				<td>
																					<div className="row-input">
																						<Form.Control
																							type="text"
																							placeholder="IMDb User Score"
																							defaultValue={movie.IMDb_User_Score}
																							// value={movie.IMDb_User_Score}
																							onBlur={(e) =>
																								updateScore({ ...movie, IMDb_User_Score: e.target.value })
																							}
																						/>
																					</div>
																				</td>
																			</tr>
																		);
																	}):<tr style={{height:'480px'}} >
																		<td colspan={isTitles == "All"?10:7} style={{paddingTop:'100px'}}>
																		<SpinnerRoundFilled size={300} />
																		<div>

																	{isTitles=='PoolTitles'?<button onClick={()=>poolTitlesManage("PoolTitles")}>Click Here</button>:<p>Please Wait....</p>}	

																		</div>

																		</td>

																	</tr>
																	}
																</tbody>
															</Table>
														</div>
														{/*  main table closed  */}
													</div>
												</div>
												{/* ========================== left contests  closed  ========================  */}
												<div className="contest-right">
													<Row className="upcoming-contest-row">
														<Col md={12}>
															<div className="callsheet-img-row">
																<img src={Callsheet} />
																<div className="ctn-cls">
																	<h2>Talent Pool*</h2>
																	<CSVLink data={downloadTalentPool}>
																		<button className="blue-btn" type="button">
																			DOWNLOAD TALENT POOL
																		</button>
																	</CSVLink>
																	<CSVReader
																		onFileLoaded={async (data, fileInfo, originalFile) => {
																			let newTalent = [];
																			for (let index = 1; index < data.length; index++) {
																				const element = await data[index];
																				const singleTalent = {
																					id: element[0],
																					Title: element[1],
																					Type: element[2],
																					Platform: element[3],
																					Release_Date: element[4],
																					Genre: element[5],
																					Total_Score: element[6],
																					Co_Star_Points: element[7],
																					Headliners_Points: element[8],
																					RT_Critics_Score: element[9],
																					Google_User_Score: element[10],
																					MC_Critics_Score: element[11],
																					IMDb_User_Score: element[12],
																					IsActive: element[13],
																					TitleImg: element[14],
																				};
																				newTalent.push(singleTalent);
																			}
																			if (newTalent.length > 0) {
																				let uploadedCsvResponse = await TalentPoolService.uploadcsv({
																					id: editTalentPool.id,
																					ScoresList: newTalent,
																				});

																				newTalent.map((element, index) => addMovie(element, index));
																			}
																		}}
																	/>
																	<Row>
																		<Col md="12" xs={12}>
																			{/* {hideTextbox ? <h4>{talentPoolName}</h4> : */}
																			<InputGroup className="search-btn">
																				<FormControl
																					placeholder="Talent Pool Name*"
																					aria-label="Recipient's username"
																					aria-describedby="basic-addon2"
																					onChange={handleInputChange}
																					defaultValue={talentPoolName}
																					// onBlur={handleOutfocuse}
																				/>
																			</InputGroup>
																			{showError ? (
																				<small style={{ fontSize: ".875em", color: "#dc3545" }}>
																					Please enter Talent Pool Name
																				</small>
																			) : (
																				""
																			)}
																		</Col>
																		{/* <Col md="3" xs={3} className="edit-btn">
                                                                        <button type="button"><i className="fa fa-pencil" aria-hidden="true" onClick={() => enableEdit()}></i></button>
                                                                    </Col> */}
																	</Row>
																</div>
															</div>
														</Col>
													</Row>
													{/* ==== upcoming-contest-row closed  ========  */}
													<div
														className="table-main scroll-table p-r-10  contest-table"
														style={{ minHeight: "600px" }}>
														<Table striped hover>
															<thead>
																<tr>
																	<th>
																		<span>
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		</span>
																	</th>
																	<th>
																		<a onClick={() => sortTourTalentpool(selectedScores, "Title")}>
																			<span>
																				Title
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortTourTalentpool(selectedScores, "Type")}>
																			<span>
																				Type
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortTourTalentpool(selectedScores, "Platform")}>
																			<span>
																				Platform
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th>
																		<a onClick={() => sortTourTalentpool(selectedScores, "Release_Date")}>
																			<span>
																				Release Date
																				{isDesc ? (
																					<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																				) : (
																					<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																				)}
																			</span>
																		</a>
																	</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{selectedScores?.map((movie, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				{movie.isSelected ? (
																					<a className="minus-button">
																						<i
																							onClick={() => removeMovie(movie, index)}
																							className="fa fa-minus-circle"
																							aria-hidden="true"></i>
																					</a>
																				) : (
																					<a className="plus-button">
																						<i
																							onClick={() => addMovie(movie, index)}
																							className="fa fa-plus-circle"
																							aria-hidden="true"></i>
																					</a>
																				)}
																			</td>
																			<td>{movie.Title}</td>
																			<td>{movie.Type}</td>
																			<td>{movie.Platform}</td>
																			<td>
																				<Moment format="M/DD/yyyy">{movie.Release_Date}</Moment>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
													<div className="pupup-btn-row">
														<Button onClick={() => setPoolEditMode()} className="red-btn">
															CANCEL
														</Button>
														{isEdit ? (
															<Button
																type="submit"
																className="blue-btn"
																style={{ marginLeft: "15px" }}
																onClick={() => submiteTalentPool()}>
																SAVE CHANGES TO TALENT POOL
															</Button>
														) : (
															<Button
																type="submit"
																className="blue-btn"
																style={{ marginLeft: "15px" }}
																onClick={() => submiteTalentPool()}>
																SAVE TALENT POOL
															</Button>
														)}
														{/* <a style={{ "width": "100%" }} hreft="#" onClick={() => clearTalentPool()}>Or Clear Talent Pool</a> */}
													</div>
												</div>
												{/* ========================== left contests  closed  ========================  */}
											</div>
										</div>
									)}
								</div>
							</Container>
						</div>
						{/*  content closed  */}
					</div>
				</div>
			</div>
			<FooterBottom />
			<AddNewTalentpool
				show={talentpoolModal}
				onButtonClick={() => onButtonClick()}
				uploadedCSV={uploadedCSV}
				inputFile={inputFile}
				onHide={() => {
					setTalentpoolModal(false);
					setCsvData("");
				}}
				addTalentPoolManually={() => addTalentPool()}
			/>
		</div>
	);
}

const mapStateToProps = (state) => ({ deleteConfrim: state.application.deleteConfrim });
export default connect(mapStateToProps)(AdminTalentPool);

function AddNewTalentpool(props) {
	const [csvBtnShow, setCsvBtnShow] = useState(false);
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup">
			<Modal.Header>
				<Button
					className="popup-close"
					onClick={() => {
						props.onHide();
						setCsvBtnShow(false);
					}}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
				<Modal.Title className="popup-heading" id="contained-modal-title-vcenter">
					ADD NEW TALENT POOL
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{csvBtnShow ? (
					<div className="movie-type-box">
						<button type="button" className="blue-btn" onClick={() => props.onButtonClick()}>
							Upload CSV File here
						</button>
						{/* <input type="file" id="file" ref={props.inputFile} style={{ display: "none" }} /> */}
						<CSVReader
							// ref={props.inputFile}
							onFileLoaded={(data, fileInfo, originalFile) => props.uploadedCSV(data, fileInfo, originalFile)}
						/>
						<button type="button" className="blue-btn">
							Download CSV File
						</button>
					</div>
				) : (
					<div className="popup-content">
						<div className="movie-type-box">
							<button type="button" className="blue-btn" onClick={() => setCsvBtnShow(!csvBtnShow)}>
								Add Talent Pool CSV File
							</button>
							<button
								className="blue-btn"
								onClick={() => {
									props.addTalentPoolManually();
									props.onHide();
								}}>
								Add Talent Pool Manually
							</button>
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}