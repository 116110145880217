import React, { Component, useEffect, useState, useReducer } from "react";
import "../../../assets/css/main.css";
import "../css/style.css";
import { useHistory } from "react-router-dom";

import LoginImg from "../../../assets/images/Login-Signup-Illustration.svg";
import Logo from "../../../assets/images/logo.png";
import Video from "../../../assets/images/Camera-Icon.svg";
import Director from "../../../assets/images/DirectorChair-Icon.svg";
import DirectorClap from "../../../assets/images/DirectorClap-Icon.svg";
import Close from "../../../assets/images/Close.svg";
import Google from "../../../assets/images/google.png";
import Facebook from "../../../assets/images/facebook.png";
import Twitter from "../../../assets/images/twitter.png";
import { Container, Row, Col, Button, Nav, Modal, Dropdown, Table, Form } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import {
	showLoader,
	hideLoader,
	showSuccessAlert,
	showWarningAlert,
	showErrorAlert,
	hideWarningAlert,
	deleteConfrimFalse,
} from "../../../common/actions/application";

import { Link } from "react-router-dom";
import UserService from "../../../services/user.service";
const axios = require("axios");

function RegisterPage(props) {
	const history = useHistory();
	// const register = () => {

	//   axios.post("https://cinemadraft-api-services.herokuapp.com/users/signup", {
	//     // name: this.state.User.name,
	//     // email: this.state.User.email,
	//     // password: this.state.User.password,
	//     role_id: "user",
	//     profile_photo: "null",
	//     profile_token: "null",
	//     created_at: new Date(),
	//     updated_at: new Date()
	//   }).then((res) => {
	//     if (res.data.error) {
	//       this.setState({ success: res.data.message });
	//     } else if (res.data.data.id) {
	//       props.history.push("/login");
	//       window.location.reload();
	//       // this.setState({ success: "Registration is Successful" });
	//       // this.setState({ isRegistration: true });
	//     }
	//   }).catch(error => {
	//     if (error.response) {
	//       console.log(error.response.data.error);
	//       // this.setState({ error: error.response.data.error });
	//     }
	//   });
	// }

	const register = async (userData) => {
		props.dispatch(showLoader());
		await UserService.registerUser(userData).then((resp) => {
			if (resp.success) {
				props.dispatch(showSuccessAlert("Successfully registered"));
				// props.history.push("/login");
				history.push("/login");
				// window.location.reload();
				props.dispatch(hideLoader());
			} else {
				props.dispatch(showErrorAlert(resp.message));
				props.dispatch(hideLoader());
			}
			props.dispatch(hideLoader());
		});
	};

	const [form, setForm] = useState({
		"Month":"01",
		"Day":"01",
		"Year":"2021"
	});

	const [errors, setErrors] = useState({});

	const [step1, setStep1] = useState(true);

	const [isNameAlreadyExist, setIsNameAlreadyExist] = useState(false);

	const [passwordShown, setPasswordShown] = useState(false);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const checkAvailability = async (field, value) => {
		setField(field, value);
		if (value.length > 4) {
			let inputParams = { screenName: value };
			await UserService.checkScreenNameAvailability(inputParams).then((resp) => {
				// if (resp.data) {
				// 	setIsNameAlreadyExist(true);
				// } else {
				// 	setIsNameAlreadyExist(false);
				// }
				setIsNameAlreadyExist(resp.data)
			});
		}
		else{
			setIsNameAlreadyExist(false);
		}
	};

	const findFormErrors = () => {
		const { email, password, Screen_Name, Gender, Month, Day, Year } = form;
		const newErrors = {};
		if (!email || email === "") newErrors.email = "cannot be blank!";
		if (!password || password === "") newErrors.password = "cannot be blank!";
		if (!Screen_Name || Screen_Name === "" || Screen_Name?.length < 5)
			newErrors.Screen_Name =
				!Screen_Name || Screen_Name === "" ? "cannot be blank!" : "screen name must be atleast 5 letters!";
		// if (!Screen_Name || Screen_Name === "")
		//   newErrors.Screen_Name = "cannot be blank!";
		// if (Screen_Name.length < 5)
		//   newErrors.Screen_Name = "screen name must be atleast 5 letters!";
		if (!Month || Month === "" || !Day || Day === "" || !Year || Year === "") newErrors.Year = true;
		if (!Gender || Gender === "") newErrors.Gender = true;

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const { email, password, Screen_Name, Date_Of_Birth, Gender, Month, Day, Year } = form;

			let data = {
				// name: this.state.User.name,
				email: email,
				password: password,
				Screen_Name: Screen_Name,
				Date_Of_Birth: moment(Month + "-" + Day + "-" + Year, "MM-DD-YYYY").format(),
				Gender: Gender,
				role_id: "user",
				profile_photo: "null",
				profile_token: "null",
				created_at: new Date(),
				updated_at: new Date(),
				IsActive: true,
			};
			register(data);
		}
	};

	const ValidityEmailPassword = () => {
		const { email, password } = form;
		const newErrors = {};
		if (!email || email?.trim() === "") newErrors.email = "cannot be blank!";
		if (!password || password?.trim() === "") newErrors.password = "cannot be blank!";

		return newErrors;
	};

	const signUpNextStep = () => {
		const newErrors = ValidityEmailPassword();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			setField("Screen_Name", "");
			setStep1(!step1);
		}
	};

	return (
		<div className="main-div">
			<div className="left">
				<div className="content">
					<div className="top">
						<Link to={"/"}>
							<img src={Logo} alt="Logo" />
						</Link>
					</div>
					<div className="middle">
						<img src={LoginImg} alt="illustration" />
					</div>
					<div className="bottom">
						<h3>
							Play the Stars.
							<strong>
								<em>Be </em>
							</strong>
							Your Own Star
						</h3>
						<div className="links">
							<button type="button"> FAQ</button>
							<img src={Video} alt="camera" />
							<button type="button"> TERMS &amp; CONDITIONS</button>
							<img src={Director} alt="director-chair" />
							<button type="button">ABOUT US</button>
							<img src={DirectorClap} alt="Director-clap" />
							<button type="button">CONTACT US</button>
						</div>
					</div>
				</div>
			</div>
			{/* ======== left closed ======== */}
			<div className="right">
				<div className="right-box">
					<div className="close">
						<img src={Logo} alt="Logo" className="logo-mobile" />
						<Link to={"/"}>
							<img src={Close} alt="cancel" />
						</Link>
					</div>
					<div className="form-content">
						<div className="tabs">
							<Link to={"/register"}>
								<button className="active"> SignUp </button>
							</Link>
							<Link to={"/login"}>
								<button className="notactive"> Login </button>
							</Link>
						</div>
						<div className="heading">
							<h1>Are you ready for your close-up?</h1>
						</div>
						<Form onSubmit={handleSubmit} role="form" className="form">
							{step1 ? (
								<Row>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="email-id">
											<Form.Label>Email address</Form.Label>
											<div className="row-input">
												<Form.Control
													defaultValue={form.email}
													type="email"
													autoComplete="off"
													placeholder="Email address"
													onChange={(e) => setField("email", e.target.value)}
													isInvalid={!!errors.email}
												/>
												<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
											</div>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="password">
											<Form.Label>Password</Form.Label>
											<div className="row-input">
												<Form.Control
													defaultValue={form.password}
													type={passwordShown ? "text" : "password"}
													autoComplete="off"
													placeholder="Password"
													onChange={(e) => setField("password", e.target.value)}
													isInvalid={!!errors.password}
												/>
												<a className="eye-pwd">
													<i
														className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
														onClick={() => {
															setPasswordShown(!passwordShown);
														}}
														aria-hidden="true"></i>
												</a>
												<Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
											</div>
										</Form.Group>
									</Col>
									<button className="login" type="button" onClick={() => signUpNextStep()}>
										<span>CONTINUE</span>
									</button>
								</Row>
							) : (
								<Row>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="Screen_Name">
											<Form.Label>Screen Name</Form.Label>
											<div className="row-input">
												<Form.Control
													defaultValue={form.Screen_Name}
													value={form.Screen_Name}
													type="text"
													placeholder="Screen Name"
													onChange={(e) => {
														checkAvailability("Screen_Name", e.target.value);
													}}
													isInvalid={!!errors.Screen_Name}
												/>
												<Form.Control.Feedback type="invalid">{errors.Screen_Name}</Form.Control.Feedback>
												{/* <span className="er">The screen name must be atleast 5 letters</span> */}
												<br />
												{isNameAlreadyExist ? (
													<span className="er">The screen name is not available</span>
												) : (
													""
												)}
											</div>
										</Form.Group>
									</Col>

									<div className="form-g ">
										<label htmlFor="email">
											<span>Birthdate</span>
										</label>
										<div className="birthday-row">
											<div className="birthday-date">
												<label htmlFor="email">
													<span>Month</span>
												</label>
												<select
													defaultValue="01"
													
													onChange={(event) => setField("Month", event.target.value)}>
													<option value="01"> January </option>
													<option value="02"> February </option>
													<option value="03"> March </option>
													<option value="04"> April </option>
													<option value="05"> May </option>
													<option value="06"> June </option>
													<option value="07"> July </option>
													<option value="08"> August </option>
													<option value="09"> September </option>
													<option value="10"> October </option>
													<option value="11"> November </option>
													<option value="12"> December </option>
												</select>
											</div>

											<div className="birthday-date">
												<label htmlFor="email">
													<span>Date</span>
												</label>
												<select
													defaultValue="01"
													onChange={(event) => setField("Day", event.target.value)}>
													<option value="01"> 1 </option>
													<option value="02"> 2 </option>
													<option value="03"> 3 </option>
													<option value="04"> 4 </option>
													<option value="05"> 5 </option>
													<option value="06"> 6 </option>
													<option value="07"> 7 </option>
													<option value="08"> 8 </option>
													<option value="09"> 9 </option>
													<option value="10"> 10 </option>
													<option value="11"> 11 </option>
													<option value="12"> 12 </option>
													<option value="13"> 13 </option>
													<option value="14"> 14 </option>
													<option value="15"> 15 </option>
													<option value="16"> 16 </option>
													<option value="17"> 17 </option>
													<option value="18"> 18 </option>
													<option value="19"> 19 </option>
													<option value="20"> 20 </option>
													<option value="21"> 21 </option>
													<option value="22"> 22 </option>
													<option value="23"> 23 </option>
													<option value="24"> 24 </option>
													<option value="25"> 25 </option>
													<option value="26"> 26 </option>
													<option value="27"> 27 </option>
													<option value="28"> 28 </option>
													<option value="29"> 29 </option>
													<option value="30"> 30 </option>
													<option value="31"> 31 </option>
												</select>
											</div>

											<div className="birthday-date">
												<label htmlFor="year">
													<span>Year</span>
												</label>
												<select
													defaultValue="2021"
													onChange={(event) => setField("Year", event.target.value)}>
													<option value="2030"> 2030 </option>
													<option value="2029"> 2029 </option>
													<option value="2028"> 2028 </option>
													<option value="2027"> 2027 </option>
													<option value="2026"> 2026 </option>
													<option value="2025"> 2025 </option>
													<option value="2024"> 2024 </option>
													<option value="2023"> 2023 </option>
													<option value="2022"> 2022 </option>
													<option value="2021"> 2021 </option>
													<option value="2020"> 2020 </option>
													<option value="2019"> 2019 </option>
													<option value="2018"> 2018 </option>
													<option value="2017"> 2017 </option>
													<option value="2016"> 2016 </option>
													<option value="2015"> 2015 </option>
													<option value="2014"> 2014 </option>
													<option value="2013"> 2013 </option>
													<option value="2012"> 2012 </option>
													<option value="2011"> 2011 </option>
													<option value="2010"> 2010 </option>
													<option value="2009"> 2009 </option>
													<option value="2008"> 2008 </option>
													<option value="2007"> 2007 </option>
													<option value="2006"> 2006 </option>
													<option value="2005"> 2005 </option>
													<option value="2004"> 2004 </option>
													<option value="2003"> 2003 </option>
													<option value="2002"> 2002 </option>
													<option value="2001"> 2001 </option>
													<option value="2000"> 2000 </option>
													<option value="1999"> 1999 </option>
													<option value="1998"> 1998 </option>
													<option value="1997"> 1997 </option>
													<option value="1996"> 1996 </option>
													<option value="1995"> 1995 </option>
													<option value="1994"> 1994 </option>
													<option value="1993"> 1993 </option>
													<option value="1992"> 1992 </option>
													<option value="1991"> 1991 </option>
													<option value="1990"> 1990 </option>
													<option value="1989"> 1989 </option>
													<option value="1988"> 1988 </option>
													<option value="1987"> 1987 </option>
													<option value="1986"> 1986 </option>
													<option value="1985"> 1985 </option>
													<option value="1984"> 1984 </option>
													<option value="1983"> 1983 </option>
													<option value="1982"> 1982 </option>
													<option value="1981"> 1981 </option>
													<option value="1980"> 1980 </option>
													<option value="1979"> 1979 </option>
													<option value="1978"> 1978 </option>
													<option value="1977"> 1977 </option>
													<option value="1976"> 1976 </option>
													<option value="1975"> 1975 </option>
													<option value="1974"> 1974 </option>
													<option value="1973"> 1973 </option>
													<option value="1972"> 1972 </option>
													<option value="1971"> 1971 </option>
													<option value="1970"> 1970 </option>
													<option value="1969"> 1969 </option>
													<option value="1968"> 1968 </option>
													<option value="1967"> 1967 </option>
													<option value="1966"> 1966 </option>
													<option value="1965"> 1965 </option>
													<option value="1964"> 1964 </option>
													<option value="1963"> 1963 </option>
													<option value="1962"> 1962 </option>
													<option value="1961"> 1961 </option>
													<option value="1960"> 1960 </option>
													<option value="1959"> 1959 </option>
													<option value="1958"> 1958 </option>
													<option value="1957"> 1957 </option>
													<option value="1956"> 1956 </option>
													<option value="1955"> 1955 </option>
													<option value="1954"> 1954 </option>
													<option value="1953"> 1953 </option>
													<option value="1952"> 1952 </option>
													<option value="1951"> 1951 </option>
													<option value="1950"> 1950 </option>
													<option value="1949"> 1949 </option>
													<option value="1948"> 1948 </option>
													<option value="1947"> 1947 </option>
													<option value="1946"> 1946 </option>
													<option value="1945"> 1945 </option>
													<option value="1944"> 1944 </option>
													<option value="1943"> 1943 </option>
													<option value="1942"> 1942 </option>
													<option value="1941"> 1941 </option>
													<option value="1940"> 1940 </option>
													<option value="1939"> 1939 </option>
													<option value="1938"> 1938 </option>
													<option value="1937"> 1937 </option>
													<option value="1936"> 1936 </option>
													<option value="1935"> 1935 </option>
													<option value="1934"> 1934 </option>
													<option value="1933"> 1933 </option>
													<option value="1932"> 1932 </option>
													<option value="1931"> 1931 </option>
													<option value="1930"> 1930 </option>
													<option value="1929"> 1929 </option>
													<option value="1928"> 1928 </option>
													<option value="1927"> 1927 </option>
													<option value="1926"> 1926 </option>
													<option value="1925"> 1925 </option>
													<option value="1924"> 1924 </option>
													<option value="1923"> 1923 </option>
													<option value="1922"> 1922 </option>
													<option value="1921"> 1921 </option>
													<option value="1920"> 1920 </option>
												</select>
											</div>
										</div>
										{errors.Year ? (
											<p className="invalid-feedback" style={{ display: "block" }}>
												cannot be blank!
											</p>
										) : (
											""
										)}
									</div>

									<div className="form-g ">
										<div className="gender-row">
											<div className="gender-col">
												<div className=" forgot login-check  form-group-sign">
													<label htmlFor="male" className="check">
														<input
															checked={form.Gender == "Male" ? true : false}
															value="Male"
															onChange={(e) => setField("Gender", e.target.value)}
															name="gender"
															type="checkbox"
															id="male"
														/>
														<span> Male </span>
													</label>
												</div>
											</div>

											<div className="gender-col">
												<div className="forgot login-check  form-group-sign">
													<label className="check" htmlFor="female">
														<input
															checked={form.Gender == "Female" ? true : false}
															value="Female"
															onChange={(e) => setField("Gender", e.target.value)}
															name="gender"
															type="checkbox"
															id="female"
														/>
														<span> Female </span>
													</label>
												</div>
											</div>

											<div className="gender-col">
												<div className="forgot login-check  form-group-sign">
													<label htmlFor="other" className="check">
														<input
															checked={form.Gender == "other" ? true : false}
															value="other"
															onChange={(e) => setField("Gender", e.target.value)}
															name="gender"
															type="checkbox"
															id="other"
														/>
														<span>other </span>
													</label>
												</div>
											</div>
										</div>
										{errors.Gender ? (
											<p className="invalid-feedback" style={{ display: "block" }}>
												cannot be blank!
											</p>
										) : (
											""
										)}
									</div>
									<button className="login" type="submit">
										<span>SIGNUP</span>
									</button>

									<div className="new-player">
										<button className="signup-go" onClick={() => signUpNextStep()}>
											<span>
												<i className="fa fa-long-arrow-left" aria-hidden="true"></i> Go Back
											</span>
										</button>
									</div>
								</Row>
							)}
						</Form>
						<form className="form">
							<div className="new-player">
								<button>
									<span> Already a player?</span>
								</button>
								<Link to={"/login"}>
									<button className="signup-go">
										<span>Login</span>
									</button>
								</Link>
							</div>
							<div className="or">
								<p>
									Or &nbsp; <span>Signup With</span>
								</p>
							</div>
							<div className="social-login-button">
								<button id="google">
									<img src={Google} alt="google" />
									<span>Continue with </span> Google
								</button>
								<button id="facebook">
									<img src={Facebook} alt="facebook" />
									<span>Continue with</span> Facebook
								</button>
								<button id="twitter">
									<img src={Twitter} alt="twitter" />
									<span>Continue with </span>Twitter
								</button>
							</div>
						</form>
						<div className="terms sign-up-terms">
							<p>
								By creating an account, you are agreeing to <span>Terms &amp; Conditions</span> of CinemaDraft
							</p>
						</div>

						<div className="bottom mobile-view-footer">
							<div className="links">
								<button type="button"> FAQ</button>
								<img src={Video} alt="camera" />
								<button type="button"> Terms &amp; Conditions</button>
								<img src={Director} alt="director-chair" />
								<button type="button">About US</button>
								<img src={DirectorClap} alt="Director-clap" />
								<button type="button">Contact US</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ========== right closed ============= */}
		</div>
	);
}

// export default RegisterPage;

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(RegisterPage);
