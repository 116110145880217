import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class ScoreService {
	getScoreById = async (id) => {
		return await axios.get(API_URL + "scores/" + id).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getAllScore = async (data) => {
		
		if(data){
			return await axios.get(`${API_URL}all-scores-details?filter[limit]=10&filter[skip]=${(data-1)*10}`).then((response) => {
				if (response.status == 200) {
					console.log(response.data)
					return response.data;
				} else {
					alert("something went wrong");
				}
			});
		}else{
			return await axios.get(`${API_URL}all-scores-details`).then((response) => {
				if (response.status == 200) {
					console.log(response.data)
					return response.data;
				} else {
					alert("something went wrong");
				}
			});
		}
		
		
	};

	upsertScore = async (inputParams) => {
		return await axios.post(API_URL + "create-scores", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteScore = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-scores", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTMdbScoreById = async (inputParams) => {
		return await axios.post(API_URL + "tmdbs", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	updateScore = async (inputParams) => {
		return await axios.post(API_URL + "update-score", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTMdbActorById = async (inputParams) => {
		return await axios.post(API_URL + "actorByTmdbId", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new ScoreService();