import axios from "axios";
import authHeader from './auth-header';

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class TalentPoolService {
	getTalentPoolList = async () => {
		return await axios.get(API_URL + "get-drafted-callsheet", { headers: authHeader() }).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};

	getTalentPoolById = async (id) => {
		return await axios.get(API_URL + "get-tournamentById/" + id).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};

	getTalentPoolByScoreId = async (inputParams) => {
		return await axios.post(API_URL + "get-actors-list-byscoreid", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	upsertActor = async (inputParams) => {
		return await axios.post(API_URL + "insert-talent-pool-by-id", inputParams).then((response) => {
			if ((response.status = 200)) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteActor = async (actorId) => {
		return await axios.delete(API_URL + "delete-actor/" + actorId).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteTalentPool = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-talentpool", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getActorDataByTmdbId = async (actorId) => {
		return await axios.get(API_URL + "get-actor-data-by-tmdbid/" + actorId).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	uploadcsv = async (inputParams) => {
		return await axios
			.post(API_URL + "update-scorelist-by-id", inputParams, { headers: authHeader() })
			.then((response) => {
				if (response.status == 200) {
					return response.data;
				} else {
					alert("something went wrong");
				}
			});
	};
}

export default new TalentPoolService();