import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, Navbar, Nav, NavDropdown, Dropdown, Table, InputGroup, Form, FormControl  } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import ScoreService from '../../services/score.service'
import { showLoader, hideLoader} from "../../common/actions/application";
import { connect } from 'react-redux';
import TournamentService from '../../services/tournament.service'
import TourTalentPoolService from "../../services/tourtalentpool.service";


function Scores(props) {

  const [Source, setSource] = useState([]);
  const [totalCheckBox, setTotalCheckBox] = useState(true);
  const [costarCheckBox, setCostarCheckBox] = useState(false);
  const [headLineCheckBox, setHeadLineCheckBox] = useState(false);
  const [selectedCriticType, setSelectedCriticType] = useState("RT Critics Score");
  const [selectedCriticType2, setSelectedCriticType2] = useState("Google Critics Score");
  const [tournaments, setTournaments] = useState([]);


  useEffect(() => {
    getSourceList();
	getTournamentsList();
  }, []);

  const getSourceList = async () => {
    props.dispatch(showLoader());
    const response = await ScoreService.getAllScore();
    response.data.forEach(num => {
      num.Total_Score = Number(num.Total_Score).toFixed(2);
    });

    setSource(response.data);
    props.dispatch(hideLoader());
  }

  const getTournamentsList = async () =>{
	const response = await TournamentService.getTournamentsList();
	if(response){
		setTournaments(response.data);
	}
  }

  const getSourceByTalentpool = async (id) =>{
	props.dispatch(showLoader());
    const response = await TourTalentPoolService.getTourTalentPoolList();
	if(response.data){
        let newArr=[];
		newArr = response.data;
		newArr=newArr.filter((element) => element.id === id)
		let scoresList=newArr[0].ScoresList
		scoresList.forEach(num => {
			num.Total_Score = Number(num.Total_Score).toFixed(2);
		  });
		setSource(scoresList);
		props.dispatch(hideLoader());
		
	}
  }

  return (
		<div className="score">
			<HeaderTop />
			<div className="content border-nav">
				<Container fluid>
					<h2 className="page-heading">Box Office Scores</h2>
					<Row>
					  <Col lg={3} md={12} xs={12}>
						<InputGroup className="search-btn">
									<FormControl
										placeholder="Search"
										aria-label="Recipient's username"
										aria-describedby="basic-addon2"
										// onChange={handleInputSearchChange}
									/>
									<InputGroup.Text id="basic-addon2">
										<i className="fa fa-search" aria-hidden="true"></i>
									</InputGroup.Text>
							</InputGroup>
					  </Col>
                      <Col style = {{flex: 0}}>
							<div className="title-scr">
								<h4>Tournament:</h4>
							</div>
					 </Col>
					 {tournaments?.map((value,index) => 
						<Col style = {{flex: 0}}>  
								<div className = "number-Btn-Text">
							   <div className="numberBtn " onClick={()=> getSourceByTalentpool(value.TourTalentPoolId)}><span>{value.Tournament_Name}</span></div>
							   
							   </div>
						</Col>	    
					)}
					 </Row>
					<br/>
					<div className="box-row">
						<div className="left-box">
							<div className="box-office-content">
								<h3>May the Scores be with you</h3>
								<div className="box-select">
									<div className="bx-select-row form-group">
										<input
											type="checkbox"
											id="score"
											defaultChecked={totalCheckBox}
											onChange={() => setTotalCheckBox(!totalCheckBox)}
										/>
										<label for="score">Total Score</label> <span className="prpl "></span>
									</div>
									<div className="bx-select-row form-group">
										<input
											type="checkbox"
											id="point"
											defaultChecked={costarCheckBox}
											onChange={() => setCostarCheckBox(!costarCheckBox)}
										/>
										<label for="point">Co-Star Points</label> <span className="pink-d"></span>
									</div>
									<div className="bx-select-row form-group ">
										<input
											type="checkbox"
											id="liner"
											defaultChecked={headLineCheckBox}
											onChange={() => setHeadLineCheckBox(!headLineCheckBox)}
										/>
										<label for="liner">Headliner Points</label> <span className="green"></span>
									</div>
								</div>
							</div>
						</div>
						<div className="right-box">
						
							<div className="scorebox">
								<div className="title-row heading-scr-bar">
									<div className="title-scr">
										<h4>Title</h4>
									</div>
									<div className="scrore-pg">
										<h4>Scores</h4>
									</div>
								</div>
								{/*title =========== row ============ closed */}
								{Source?.map((movie, index) => {
									return (
										<div key={index + movie.id} className="title-row">
											<div className="title-scr">
												<p>{movie.Title}</p>
											</div>
											<div className="scrore-pg ">
												{totalCheckBox ? (
													<div className="bartop">
														<Progress percent={Number(movie.Total_Score)} />
													</div>
												) : (
													""
												)}
												{costarCheckBox ? (
													<div className="barmiddle">
														{" "}
														<Progress percent={movie.Co_Star_Points} />{" "}
													</div>
												) : (
													""
												)}
												{headLineCheckBox ? (
													<div className="barbottom">
														<Progress percent={movie.Headliners_Points} />
													</div>
												) : (
													""
												)}
											</div>
										</div>
									);
								})}
								<div className="pr-bt-row">
									<div className="pr-x">
										<span>0.00</span>
										<span>50.00</span>
										<span>100.00</span>
										<span>150.00</span>
										<span>200.00</span>
									</div>
								</div>
							</div>
							{/*scroe box closed   */}
						</div>
					</div>
					<div className="comp-score-box">
						<div className="graph-detail">
							<div className="rt-critics">
								<Dropdown onSelect={(evt) => setSelectedCriticType(evt)}>
									<Dropdown.Toggle variant="success" id="dropdown-basic">
										{selectedCriticType}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item eventKey="RT Critics Score">RT Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="Google Critics Score">Google Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="MC Critics Score">MC Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="IMDb User Score">IMDb User Score</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								<div className="pbar-no">
									<p>136.43</p>
								</div>
							</div>

							<div className="google-critics">
								<Dropdown onSelect={(evt) => setSelectedCriticType2(evt)}>
									<Dropdown.Toggle variant="success" id="dropdown-basic">
										{selectedCriticType2}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item eventKey="RT Critics Score">RT Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="Google Critics Score">Google Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="MC Critics Score">MC Critics Score</Dropdown.Item>
										<Dropdown.Item eventKey="IMDb User Score">IMDb User Score</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								<div className="pbar-no">
									<p>141.03</p>
								</div>
							</div>
						</div>

						<h2 className="page-heading">Compare Scores</h2>
						{Source?.map((movie, index) => {
							return (
								<>
									<div className="row-comp">
										<div className="comp-box prpl-pg">
											{selectedCriticType === "RT Critics Score" ? (
												<Progress percent={movie.RT_Critics_Score} />
											) : (
												""
											)}
											{selectedCriticType === "Google Critics Score" ? (
												<Progress percent={movie.Google_User_Score} />
											) : (
												""
											)}
											{selectedCriticType === "MC Critics Score" ? (
												<Progress percent={movie.MC_Critics_Score} />
											) : (
												""
											)}
											{selectedCriticType === "IMDb User Score" ? (
												<Progress percent={movie.IMDb_User_Score} />
											) : (
												""
											)}
										</div>
										<div className="middle-box">
											<p>{movie.Title}</p>
										</div>
										<div className="comp-box green-pg">
											{selectedCriticType2 === "RT Critics Score" ? (
												<Progress percent={movie.RT_Critics_Score} />
											) : (
												""
											)}
											{selectedCriticType2 === "Google Critics Score" ? (
												<Progress percent={movie.Google_User_Score} />
											) : (
												""
											)}
											{selectedCriticType2 === "MC Critics Score" ? (
												<Progress percent={movie.MC_Critics_Score} />
											) : (
												""
											)}
											{selectedCriticType2 === "IMDb User Score" ? (
												<Progress percent={movie.IMDb_User_Score} />
											) : (
												""
											)}
										</div>
									</div>
									{/* compar row closed   */}
								</>
							);
						})}
					</div>
					{/* compar score box closed   */}
				</Container>
			</div>
			{/*  content closed  */}
			<FooterBottom />
		</div>
	);
}


// export default Scores;
const mapStateToProps = state => ({});
export default connect(mapStateToProps)(Scores);

