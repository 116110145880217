import React, { useEffect, useState } from "react";
import {
	Container,
	Row,
	Col,
	Table,
	InputGroup,
	Form,
	FormControl,
} from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import Netflix from "../../assets/images/Netflix-Logo.svg";
import Callsheet from "../../assets/images/callsheet.svg";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";

import defaultIcon from "../../assets/images/defaultIcon.jpeg";

import {
	useHistory,
	useLocation,
	BrowserRouter as Router,
} from "react-router-dom";

import AuthService from "../../services/auth.service";
import ApplicationService from "../../services/application.service";
import CallSheetService from "../../services/callsheet.service";
import TourTalentPoolService from "../../services/tourtalentpool.service";
import TournamentService from "../../services/tournament.service";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import {
	showLoader,
	hideLoader,
	showSuccessAlert,
	showWarningAlert,
	showErrorAlert,
} from "../../common/actions/application";
import {
	showMoviePopup,
	showActorPopup,
} from "../../common/actions/genericPopupAction";
import ScoreService from "../../services/score.service";
import { hideActorPopup } from "../../common/actions/genericPopupAction";
const _ = require("lodash");

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Live(props) {
	const history = useHistory();

	let query = useQuery();

	const [callSheetId, setCallSheetId] = useState(query.get("c"));
	const [talentPool, setTalentPool] = useState([]);
	const [talentPoolTable, setTalentPoolTable] = useState([]);
	const [allTalentPool, setAllTalentPool] = useState([]);
	const [actorStatus, setActorStatus] = useState();
	const [isDesc, setIsDesc] = useState(false);
	const [callSheet, setCallSheet] = useState({});
	const [callSheetActors, setCallSheetActors] = useState([]);
	const [totalSalary, setTotalSalary] = useState(0);
	const [tournamentDetails, setTournamentDetails] = useState({});
	const [userId, setUserId] = useState("");
	const [user, setUser] = useState({});
	const [playersLeft, setPlayersLeft] = useState();
	const [salaryRemaining, setSalaryRemaining] = useState(0);
	const [mCCriticsScore, setMCCriticsScore] = useState();
	const [iMDbUserScore, setIMDbUserScore] = useState();
	const [validate3Actors, setValidate3Actors] = useState(false);
	const [twoDifferentTitles, setTwoDifferentTitles] = useState(false);
	const [isGameLock, setIsGameLock] = useState(false);
	const [avgSalaryRemaining, setAvgSalaryRemaining] = useState(0);
	const [callSheetfromDb, setCallSheetfromDb] = useState(false);
	const [poolId, setPoolId] = useState("");
	const [showList, setShowList] = useState(true);
	const [talentPoolDetails, setTalentPoolDetails] = useState();
	const [talentPoolDetailsTable, setTalentPoolDetailsTable] = useState();
	const [tourTalenpoolId, setTourTalenpoolId] = useState();
	const [actorIndex, setActorIndex] = useState(0);
	const [myCallsheet, setMyCallsheeet] = useState([]);
	const [titleType, setTitleType] = useState();
	const [titleStatus, setTitleStatus] = useState();
	const [oneCD3D, setOneCD3D] = React.useState(100);

	useEffect(() => {
		if (callSheetId) {
			getCallSheetById();
		}
	}, [tournamentDetails]);

	useEffect(() => {
		getTalentPoolByTourPoolId(poolId);
	}, [callSheetfromDb]);

	useEffect(() => {
		actorSelected(props.actorData, actorIndex);
	}, [props.actorData]);

	useEffect(() => {
		getLoginUser();
		getTournamentDetails();
		getOneCD3D();
	}, []);

	const getLoginUser = () => {
		const response = AuthService.getAuth();
		if (response) {
			setUserId(response.data.id);
			setUser(response.data);
		}
	};

	const getTournamentDetails = async () => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentById(query.get("t"));
		if (response) {
			let data = response.data;
			setSalaryRemaining(data?.Prizes);
			setAvgSalaryRemaining(data?.Prizes / 10);
			setTournamentDetails(data);
			setTourTalenpoolId(data.TourTalentPoolId);
			setPoolId(data.TourTalentPoolId);
			if (query.get("c") == "" || query.get("c") == null) getTalentPoolByTourPoolId(data.TourTalentPoolId);
			setPlayersLeft(data.Total_Players - data.No_Of_Players);
			if (new Date(Date.now()) > new Date(response.data.Starts)) setIsGameLock(true);
		}
		props.dispatch(hideLoader());
	};

	const getTalentPoolByTourPoolId = async (TourPoolId) => {
		props.dispatch(showLoader());
		let actorFilterInputParams = { tourTalentPoolId: TourPoolId };
		await CallSheetService.getTalentPoolByTourPool(actorFilterInputParams).then((resp) => {
			if (resp) {
				resp.data.forEach((element) => {
					element.isSelected = false;
				});
				if (callSheetActors.length > 0) {
					let actors = [...callSheetActors];
					resp.data.forEach((element) => {
						let actor = actors.find((x) => x.id == element.id);
						if (actor) element.isSelected = true;
						else element.isSelected = false;
					});
				}

				setTalentPoolTable(resp.data);
				setTalentPool(resp.data);
				setAllTalentPool(resp.data);
				props.dispatch(hideLoader());
			}
		});
	};

	const getTourTalentPoolDetails = async (talenpoolId) => {
		props.dispatch(showLoader());
		const response = await TourTalentPoolService.getTourTalentPoolList();
		const x = response.data.filter((value) => value.id == talenpoolId);
		setTalentPoolDetailsTable(x[0].ScoresList);
		setTalentPoolDetails(x[0].ScoresList);
		props.dispatch(hideLoader());
	};

	const getCallSheetById = async () => {
		props.dispatch(showLoader());
		await CallSheetService.getCallSheetById(callSheetId).then((resp) => {
			if (resp) {
				setCallSheetActors(resp.data.ActorsList);
				setCallSheet(resp.data);
				setMCCriticsScore(resp.data.MCCriticsScore);
				setIMDbUserScore(resp.data.IMDbUserScore);
				let actors = resp.data.ActorsList;
				let salaryAdded = 0;
				actors.forEach((element) => {
					salaryAdded = salaryAdded + Number(element.Salery);
				});
				setTotalSalary(salaryAdded);
				let remaining = tournamentDetails.Prizes - salaryAdded;
				setSalaryRemaining(remaining);
				if (10 - actors.length >= 0) setAvgSalaryRemaining(remaining / (10 - actors.length));
				setCallSheetfromDb(!callSheetfromDb);
				// getTalentPoolByTourPoolId(poolId);
				props.dispatch(hideLoader());
			}
		});
	};

	const getTalentPool = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		talentPoolTable.filter((element) => {
			let tempFilter =
				element.Actor.toLowerCase().includes(serachParam.toLowerCase()) ||
				element.Title.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		if (actorStatus == "Headliner" || actorStatus == "Co-Star" || actorStatus == "Dayplayer") {
			setTalentPool(newArray.filter((actor) => actor.Actor_Type == actorStatus));
		} else {
			setTalentPool(newArray);
		}
		props.dispatch(hideLoader());
	};

	const titleSearchChange = (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		talentPoolDetailsTable.filter((element) => {
			let tempFilter = element.Title?.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		if (titleType == "Movies" || titleType == "Series") {
			setTalentPoolDetails(newArray.filter((ele) => ele.Type == titleType));
		} else {
			setTalentPoolDetails(newArray);
		}
		props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getTalentPool(searchString);
	};

	const handleInputMCCriticsChange = (event) => {
		const value = event.currentTarget.value;
		setMCCriticsScore(value);
	};

	const handleInputIMDbUserChange = (event) => {
		const value = event.currentTarget.value;
		setIMDbUserScore(value);
	};

	const addCheckedActors = () => {
		let newArr = [...talentPool];
		let checkedArr = [];
		newArr.forEach((actor) => {
			if (actor.isSelected) checkedArr.push(actor);
		});
		checkedActors(checkedArr);
	};

	const uploadCallsheet = async (actor) => {
		let csvArray = [...actor];

		const callSheetInputPrams = {
			TournamentId: query.get("t"),
			UserId: userId,
			Status: "Pending",
			ActorsList: csvArray,
			id: callSheetId,
			StartDate: tournamentDetails.Starts,
			EndDate: tournamentDetails.EndDate,
			PlayerName: user.Screen_Name,
			IsActive: true,
		};

		props.dispatch(showLoader());
		await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
			if (resp && resp.success) {
				setCallSheetId(resp.data.id);
				setCallSheetActors(csvArray);
				props.dispatch(hideLoader());
			} else {
				props.dispatch(showErrorAlert(""));
				props.dispatch(hideLoader());
			}
		});
		window.location.reload();
	};

	const checkedActors = async (actors) => {
		var newArray = [...actors];
		if (newArray.length <= 10) {
			const callSheetInputPrams = {
				TournamentId: query.get("t"),
				UserId: userId,
				Status: "Pending",
				ActorsList: newArray,
				id: callSheetId,
				StartDate: tournamentDetails.Starts,
				EndDate: tournamentDetails.EndDate,
				PlayerName: user.Screen_Name,
				IsActive: true,
			};
			props.dispatch(showLoader());
			await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
				if (resp && resp.success) {
					setCallSheetId(resp.data.id);
					setCallSheetActors(newArray);
					props.dispatch(hideLoader());
				} else {
					props.dispatch(showErrorAlert(""));
					props.dispatch(hideLoader());
				}
			});
		} else {
			props.dispatch(showWarningAlert("Selected actors are more then 10 in number"));
		}
	};

	const actorSelected = async (actor, position, e) => {
		// if (!(Number(actor.Salery) > salaryRemaining)) {
		
		const isCheck = e?.target.checked;
		if (isCheck) return deleteActor(actor, position);

		let ischecktrue = false;
		let maxplayersreached = false;
		let gameLock = false;
		if (query.get("c") === "") {
			const checkInputPrams = { tournamentId: query.get("t"), userid: userId };
			await CallSheetService.checkCallsheetCount(checkInputPrams).then((resp) => {
				ischecktrue = resp.data.ismorethan3callsheets;
				maxplayersreached = resp.data.playerCountsReached;
				gameLock = resp.data.gameLock;
			});
		}
		if (callSheetActors.length <= 9) {
			if (!ischecktrue && !maxplayersreached && !gameLock) {
				if (callSheetActors.find((actors) => actors.id == actor.id)) {
					props.dispatch(showWarningAlert("This actor is already added"));
				} else {
					props.dispatch(showLoader());
					var newArray = [...callSheetActors, actor];
					setMyCallsheeet(newArray);

					let callSheetInputPrams = {};
					if (callSheetId == "")
						callSheetInputPrams = {
							TournamentId: query.get("t"),
							UserId: userId,
							Status: "Pending",
							ActorsList: newArray,
							CreatedOn: new Date(Date.now()),
							StartDate: tournamentDetails.Starts,
							EndDate: tournamentDetails.EndDate,
							PlayerName: user.Screen_Name,
							IsActive: true,
						};
					else
						callSheetInputPrams = {
							TournamentId: query.get("t"),
							UserId: userId,
							Status: "Pending",
							ActorsList: newArray,
							id: callSheetId,
							StartDate: tournamentDetails.Starts,
							EndDate: tournamentDetails.EndDate,
						};

					await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
						if (resp && resp.success) {
							let salaryAdded = totalSalary;
							salaryAdded = salaryAdded + Number(actor.Salery);
							setTotalSalary(salaryAdded);

							let remaining = tournamentDetails.Prizes - salaryAdded;
							setSalaryRemaining(remaining);
							setAvgSalaryRemaining(remaining / (10 - newArray.length));

							toggelActorIsSelcted(position);
							setCallSheetId(resp.data.id);
							var newurl =
								window.location.protocol +
								"//" +
								window.location.host +
								window.location.pathname +
								"?c=" +
								resp.data.id +
								"&t=" +
								query.get("t");
							window.history.pushState({ path: newurl }, "", newurl);
							setCallSheetActors(newArray);
							setValidate3Actors(checkGameRules2(newArray));
							setTwoDifferentTitles(checkTwoTitles(newArray));
							props.dispatch(hideLoader());
						} else {
							props.dispatch(showErrorAlert(""));
							props.dispatch(hideLoader());
						}
					});
				}
			} else {
				if (maxplayersreached) props.dispatch(showWarningAlert("Max Players allowed to participate reached!"));
				if (ischecktrue)
					props.dispatch(showWarningAlert("You have reached your limit of Call sheets already!"));
				if (gameLock) props.dispatch(showWarningAlert("Game is Locked!"));
			}
		} else {
			props.dispatch(showWarningAlert("Selected actors are more then 10 in number"));
		}
	};

	const deleteActor = async (actor, position) => {
		var newArray = [...callSheetActors];
		var index = newArray
			.map(function (x) {
				return x.id;
			})
			.indexOf(actor.id);
		if (index !== -1) {
			newArray.splice(index, 1);
			const callSheetInputPrams = {
				TournamentId: query.get("t"),
				UserId: userId,
				Status: "Pending",
				ActorsList: newArray,
				id: callSheetId,
				StartDate: tournamentDetails.Starts,
				EndDate: tournamentDetails.EndDate,
			};
			props.dispatch(showLoader());
			await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
				if (resp && resp.success) {
					let salaryAdded = totalSalary;
					salaryAdded = salaryAdded - Number(actor.Salery);
					setTotalSalary(salaryAdded);

					let remaining = salaryRemaining + Number(actor.Salery);
					setSalaryRemaining(remaining);
					if (10 - newArray.length == 0) setAvgSalaryRemaining(0);
					else setAvgSalaryRemaining(remaining / (10 - newArray.length));

					let newPoolArr = [...talentPool];

					var index = newPoolArr
						.map(function (x) {
							return x.id;
						})
						.indexOf(actor.id);
					toggelActorIsSelcted(index);
					setCallSheetActors(newArray);
					props.dispatch(hideLoader());
				} else {
					props.dispatch(showErrorAlert(""));
					props.dispatch(hideLoader());
				}
			});
		}
	};

	const checkGameRules = () => {
		const newCallSheetActors = [...callSheetActors];
		const isHeadlinerPresent = newCallSheetActors.find((actor) => actor.Actor_Type == "Headliner");
		const isCostarPresent = newCallSheetActors.find((actor) => actor.Actor_Type == "Co-Star");
		const isDayplayerPresent = newCallSheetActors.find((actor) => actor.Actor_Type == "Dayplayer");
		return isHeadlinerPresent && isCostarPresent && isDayplayerPresent;
	};

	const checkGameRules2 = (newCallSheetActors) => {
		const isHeadlinerPresent = newCallSheetActors.filter((actor) => actor.Actor_Type == "Headliner");
		const isCostarPresent = newCallSheetActors.filter((actor) => actor.Actor_Type == "Co-Star");
		const isDayplayerPresent = newCallSheetActors.filter((actor) => actor.Actor_Type == "Dayplayer");
		return isHeadlinerPresent.length > 0 && isCostarPresent.length > 0 && isDayplayerPresent.length > 0;
	};

	const checkTwoTitles = (newCallSheetActors) => {
		let count = newCallSheetActors
			?.map((item) => item.Title)
			.filter((value, index, self) => self.indexOf(value) === index);
		return count.length > 1;
	};

	const submiteCallSheet = async () => {
		const newCallSheetActors = [...callSheetActors];
		if (newCallSheetActors.length != 10) {
			return props.dispatch(showWarningAlert("Maximum number of actors that can be added should be 10"));
		}
		if (mCCriticsScore > 100 && iMDbUserScore > 10) {
			return props.dispatch(
				showWarningAlert(
					"Enter Highest RT Critics Score (0 - 100%) & IMDb User Score (0.0 - 10.0) in correct format"
				)
			);
		}

		if (
			newCallSheetActors &&
			newCallSheetActors.length == 10 &&
			totalSalary <= tournamentDetails.Prizes &&
			checkGameRules() &&
			mCCriticsScore > 0 &&
			iMDbUserScore > 0
		) {
			const callSheetInputPrams = {
				TournamentId: query.get("t"),
				UserId: userId,
				Status: "Submited",
				ActorsList: callSheetActors,
				id: callSheetId,
				StartDate: tournamentDetails.Starts,
				EndDate: tournamentDetails.EndDate,
				SubmitedOn: new Date(Date.now()),
				MCCriticsScore: Number(mCCriticsScore),
				IMDbUserScore: Number(iMDbUserScore),
				TotalSalary: Number(totalSalary),
				IMDbUserScoreDiff: 0,
				MCCriticsScoreDiff: 0,
				RemaingSalary: tournamentDetails.Prizes - Number(totalSalary),
				IsActive: true,
			};

			props.dispatch(showLoader());
			await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
				if (resp && resp.success) {
					props.dispatch(showSuccessAlert("Your call sheet submitted successfully!"));
					props.dispatch(hideLoader());
					AuthService.setUrl("/mycontests");
					history.push("/submited?c=" + callSheetId);
				} else {
					props.dispatch(showErrorAlert(""));
					props.dispatch(hideLoader());
				}
			});
		}
	};

	const clearCallSheet = async () => {
		// reload the page on same href url
		// return window.location.reload();

		props.dispatch(showLoader());
		const callSheetInputPrams = {
			TournamentId: query.get("t"),
			UserId: userId,
			Status: "Pending",
			ActorsList: [],
			id: callSheetId,
			StartDate: tournamentDetails.Starts,
			EndDate: tournamentDetails.EndDate,
		};
		await CallSheetService.upsertCallSheetList(callSheetInputPrams).then((resp) => {
			if (resp && resp.success) {
				setCallSheetActors([]);
				let newPool = [...talentPool];
				newPool.forEach((actor) => {
					actor.isSelected = false;
				});
				setTalentPool(newPool);
				setTotalSalary(0);
				setSalaryRemaining(tournamentDetails.Prizes);
				setAvgSalaryRemaining(tournamentDetails.Prizes / 10);
				props.dispatch(hideLoader());
			} else {
				props.dispatch(showErrorAlert(""));
				props.dispatch(hideLoader());
			}
		});
	};

	const toggelActorIsSelcted = (position) => {
		props.dispatch(showLoader());
		let newArr = [...talentPool];
		newArr[position].isSelected = !newArr[position].isSelected;
		setTalentPool(newArr);
		props.dispatch(hideLoader());
	};

	const dummy = (position) => {
		// toggelActorIsSelcted(position);
		let newArr = [...talentPool];
		newArr[position].isSelected = !newArr[position].isSelected;
		setTalentPool(newArr);
	};

	const onClickHandler = (ActorType) => {
		setTalentPool(talentPoolTable.filter((actor) => actor.Actor_Type == ActorType));
		setActorStatus(ActorType);
	};

	const onTitleClickHandler = (titleType) => {
		props.dispatch(showLoader());
		setTalentPoolDetails(talentPoolDetailsTable.filter((ele) => ele.Type == titleType));
		setTitleStatus(titleType);
		props.dispatch(hideLoader());
	};

	const sortPlayerDetails = (tableName, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedplayers;
		if (label === "Salery") {
			sortedplayers = _.orderBy(tableName, [(table) => Number(table.Salery)], orderByFlag);
		} else {
			sortedplayers = _.orderBy(tableName, label, orderByFlag);
		}
		tableName === talentPool
			? setTalentPool(sortedplayers)
			: tableName === talentPoolDetails
				? setTalentPoolDetails(sortedplayers)
				: setCallSheetActors(sortedplayers);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const showScoreDetails = async (actor, label) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getScoreById(label == "ActorsList" ? actor.ScoreId : actor);
		if (response) {
			props.dispatch(showMoviePopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const showActorDetails = async (actor, callSheet) => {
		props.dispatch(showActorPopup(actor, callSheet));
	};

	const getInCD3D = (inusd) => {
		
		return (inusd * oneCD3D).toFixed(2);
	};

	const getOneCD3D = async () => {
		const oneCD3D = await ApplicationService.getCD3D();
		setOneCD3D(oneCD3D);
	};

	return (
		<div className="mycontests live">
			<HeaderTop />
			<div className="contests-tab-page pt-0 border-nav">
				<Container fluid>
					<div className="contest-row">
						<div className="contest-left">
							{/* ===== page notification closed ===== */}
							<Row className="upcoming-contest-row">
								<Col md={5}>
									<h2>{tournamentDetails.Tournament_Name}</h2>
									<p>
										Starts <Moment format="dddd, h:mm a">{tournamentDetails.Starts}</Moment>
									</p>
								</Col>
								<Col md={7}>
									<div className="contest-content-right">
									<div className="up-cont-box">
											<p>Entry fee</p>
											<span>
											<NumberFormat
													value={
														tournamentDetails.Tournament_Entry_Fee
														== undefined || tournamentDetails.Tournament_Entry_Fee
														== null
															? 0
															: tournamentDetails.Tournament_Entry_Fee

													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "}
												(
												<NumberFormat
													value={getInCD3D(tournamentDetails.Tournament_Entry_Fee
														)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
											</span>
										</div>
										<div className="up-cont-box">
											<p>Prizes</p>
											<span>
											<NumberFormat
													value={
														tournamentDetails.PrizePool == undefined || tournamentDetails.PrizePool == null
															? 0
															: tournamentDetails.PrizePool
													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "}
												(
												<NumberFormat
													value={getInCD3D(tournamentDetails.PrizePool)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
											</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p># of Players / Total Players
</p>
											<span>
												{tournamentDetails.No_Of_Players}/{tournamentDetails.Total_Players}
											</span>
										</div>
										{/*up cnt box closed */}
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="rbt-box">
								<div className="page-ntf">
									<button
										className={showList ? "active" : null}
										onClick={() => {
											setShowList(true);
										}}>
										List
									</button>
									<button
										className={!showList ? "active" : null}
										onClick={() => {
											setShowList(false);
											getTourTalentPoolDetails(tourTalenpoolId);
										}}>
										Titles
									</button>
								</div>
								{showList ? (
									<>
										<Row className="search-col">
											<Col lg={5} md={12} xs={12}>
												<InputGroup className="search-btn">
													<FormControl
														placeholder="Search"
														aria-label="Recipient's username"
														aria-describedby="basic-addon2"
														onChange={handleInputSearchChange}
													/>
													<InputGroup.Text id="basic-addon2">
														<i className="fa fa-search" aria-hidden="true"></i>
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col lg={7} md={12} xs={12}>
												<div
													className="btn-group button-tb "
													role="group"
													aria-label="Basic radio toggle button group">
													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio1"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary "
														htmlFor="btnradio1"
														onClick={() => {
															setTalentPool(talentPoolTable);
															setActorStatus("");
														}}>
														All
													</label>

													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio2"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary"
														htmlFor="btnradio2"
														onClick={() => onClickHandler("Headliner")}>
														<img src={HeadingIcn} />
														Headliner
													</label>

													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio3"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary"
														htmlFor="btnradio3"
														onClick={() => onClickHandler("Co-Star")}>
														<img src={Costar} /> Co-Star
													</label>

													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio4"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary"
														htmlFor="btnradio4"
														onClick={() => onClickHandler("Dayplayer")}>
														Dayplayer
													</label>
												</div>
											</Col>
										</Row>

										<div className="table-main scroll-table p-r-10  contest-table-lv">
											<Table striped hover>
												<thead>
													<tr>
														<th></th>
														<th>
															<span>Actor Type</span>
														</th>
														<th>
															<span>
																<a href="#" onClick={() => sortPlayerDetails(talentPool, "Actor")}>
																	<span>
																		Actor
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#" onClick={() => sortPlayerDetails(talentPool, "Title")}>
																	<span>
																		Title
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#" onClick={() => sortPlayerDetails(talentPool, "Platform")}>
																	<span>
																		Platform
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#" onClick={() => sortPlayerDetails(talentPool, "Genre")}>
																	<span>
																		Genre
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#" onClick={() => sortPlayerDetails(talentPool, "Salery")}>
																	<span>
																		Salary
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{talentPool.map((actor, index) => {
														return (
															<tr key={index + "pool"}>
																{!isGameLock ? (
																	<td>
																		{actor.isSelected ? (
																			<Form.Check
																				type="checkbox"
																				id={index + "checkbox1"}
																				label={""}
																				checked={actor.isSelected}
																				onChange={() => deleteActor(actor, index)}
																			/>
																		) : (
																			<Form.Check
																				type="checkbox"
																				id={index + "checkbox2"}
																				label={""}
																				checked={actor.isSelected}
																				onChange={() => actorSelected(actor, index)}
																			/>
																		)}
																	</td>
																) : (
																	<td></td>
																)}
																<td>
																	{actor.Actor_Type == "Co-Star" ? (
																		<img src={Costar} className="anchor-img" />
																	) : actor.Actor_Type == "Headliner" ? (
																		<img src={HeadingIcn} className="anchor-img" />
																	) : (
																		<span className="cust-dayplayer"> Dayplayer</span>
																	)}
																</td>
																<td
																	style={{
																		cursor: "pointer",
																		textAlign: "left",
																	}}
																	onClick={() => {
																		showActorDetails(actor);
																		setActorIndex(index);
																	}}>
																	{actor.profile_path ? (
																		<img
																			src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																			className="user"
																		/>
																	) : (
																		<img src={defaultIcon} className="user" />
																	)}
																	<span> </span>
																	{actor.Actor}
																</td>
																<td
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		showScoreDetails(actor, "ActorsList");
																		setActorIndex(index);
																	}}>
																	{actor.Title}
																</td>
																<td>
																	{actor.Platform == "Netflix" ? (
																		<img src={Netflix} className="cmp-logo" />
																	) : (
																		actor.Platform
																	)}
																</td>
																<td>{actor.Genre}</td>
																<td>
																	<NumberFormat
																		value={actor.Salery}
																		displayType={"text"}
																		thousandSeparator={true}
																		prefix={"$"}
																	/>
																</td>
																{!isGameLock ? (
																	<td>
																		{actor.isSelected ? (
																			<a className="minus-button" style={{ cursor: "pointer" }}>
																				<i
																					onClick={() => deleteActor(actor, index)}
																					className="fa fa-minus-circle"
																					aria-hidden="true"></i>
																			</a>
																		) : (
																			<a className="plus-button" style={{ cursor: "pointer" }}>
																				<i
																					onClick={() => actorSelected(actor, index, undefined)}
																					className="fa fa-plus-circle"
																					aria-hidden="true"></i>
																			</a>
																		)}
																	</td>
																) : (
																	<td></td>
																)}
															</tr>
														);
													})}
												</tbody>
											
											</Table>
										</div>
									</>
								) : (
									<>
										<Row className="search-col">
											<Col lg={5} md={12} xs={12}>
												<InputGroup className="search-btn">
													<FormControl
														placeholder="Search"
														aria-label="Recipient's username"
														aria-describedby="basic-addon2"
														onChange={(e) => {
															titleSearchChange(e.currentTarget.value);
														}}
													/>
													<InputGroup.Text id="basic-addon2">
														<i className="fa fa-search" aria-hidden="true"></i>
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col lg={7} md={12} xs={12}>
												<div
													className="btn-group button-tb "
													role="group"
													aria-label="Basic radio toggle button group">
													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio1"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary "
														htmlFor="btnradio1"
														onClick={() => {
															setTalentPoolDetails(talentPoolDetailsTable);
															setTitleStatus("");
														}}>
														All
													</label>

													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio2"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary"
														htmlFor="btnradio2"
														onClick={() => {
															onTitleClickHandler("Movie");
														}}>
														Movies
													</label>

													<input
														type="radio"
														className="btn-check"
														name="btnradio"
														id="btnradio3"
														autoComplete="off"
													/>
													<label
														className="btn btn-outline-primary"
														htmlFor="btnradio3"
														onClick={() => {
															onTitleClickHandler("Series");
														}}>
														{" "}
														Series
													</label>
												</div>
											</Col>
										</Row>
										<div className="table-main scroll-table p-r-10  contest-table-lv">
											<Table striped hover>
												<thead>
													<tr>
														<th>
															<span>
																<a href="#">
																	<span>
																		Title
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#">
																	<span>
																		Type
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#">
																	<span>
																		Platform
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#">
																	<span>
																		Genre
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th>
															<span>
																<a href="#">
																	<span>
																		Release Date
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</span>
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{talentPoolDetails?.map((value) => {
														return (
															<tr>
																<td
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		showScoreDetails(value.id, "Id");
																		//setActorIndex(index);
																	}}>
																	{value.Title}
																</td>
																<td>{value.Type}</td>
																<td>{value.Platform}</td>
																<td>{value.Genre}</td>
																<td>
																	<Moment format="MMMM DD, YYYY">{value.Release_Date}</Moment>
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</div>
									</>
								)}

								<div className="callsheet-btn-row">
									<button className="blue-btn" type="button" onClick={() => addCheckedActors()}>
										ADD TO CALL SHEET
									</button>
									<CSVLink data={allTalentPool}>
										<button className="blue-btn" type="button">
											DOWNLOAD TALENT POOL
										</button>
									</CSVLink>
									{/* <button className="blue-btn" type="button" onClick={() => uploadCallsheet()}> */}
									<Form.Group controlId="formFile" className="mb-3">
										<Form.Label>UPLOAD YOUR CALL SHEET</Form.Label>
										{/* <Form.Control type="file" /> */}
										<CSVReader
											onFileLoaded={async (data, fileInfo, originalFile) => {
												let newActor = [];
												for (let index = 1; index < data.length; index++) {
													const element = await data[index];
													const singleActor = {
														Actor: element[0],
														id: element[1],
														Salery: element[2],
														Actor_Type: element[3],
														Title: element[4],
														Type: element[5],
														Platform: element[6],
														Release_Date: element[7],
														Genre: element[8],
														Notes: element[9],
														Total_Scores: element[10],
														ScoreId: element[11],
														isActive: element[12],
														IsSelected: element[13],
														profile_path: element[14],
													};
													// if IsSelected === true then push
													if (singleActor.IsSelected === "TRUE") {
														newActor.push(singleActor);
													}
												}
												if (newActor.length > 0) {
													uploadCallsheet(newActor);
												}
											}}
										/>

										{/* <CSVReader
                      onFileLoaded={(data, fileInfo, originalFile) =>
                        console.dir(data, fileInfo, originalFile)
                      }
                    /> */}
									</Form.Group>
									{/* </button> */}
									<a hreft="#">
										Salary Total :
										<NumberFormat
											value={totalSalary}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
										/>
									</a>
								</div>
							</div>
						</div>
						;{/* ========================== left contests  closed  ========================  */}
						<div className="contest-right">
							<Row className="upcoming-contest-row class-51">
								<Col md={7}>
									<div className="callsheet-img-row">
										<img src={Callsheet} />
										<div className="ctn-cls">
											<h2>Call Sheet</h2>
											<p>{user.Screen_Name}</p>
										</div>
									</div>
								</Col>
								<Col md={5}>
									<CSVLink data={myCallsheet}>
										<button className="blu-border-btn">DOWNLOAD</button>
									</CSVLink>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<Row className="player-left">
								<Col md={12}>
									<div className="contest-content-right">
										<div className="up-cont-box">
											<p>Actors Left</p>
											<span>{10 - callSheetActors.length}</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Avg. Salary Remaining</p>
											<span>
												<NumberFormat
													value={avgSalaryRemaining.toFixed()}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Budget</p>

											<span>
												<NumberFormat
													value={salaryRemaining.toFixed()}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</span>
										</div>
										{/*up cnt box closed */}
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="table-main scroll-table p-r-10 ">
								<Table striped hover>
									<thead>
										<tr>
											<th></th>
											<th></th>
											<th>
												<span>
													Actor Type <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												</span>
											</th>
											<th>
												<a href="#" onClick={() => sortPlayerDetails(callSheetActors, "Title")}>
													<span>
														Title
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a href="#" onClick={() => sortPlayerDetails(callSheetActors, "Platform")}>
													<span>
														Platform
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a href="#" onClick={() => sortPlayerDetails(callSheetActors, "Salery")}>
													<span>
														Salary
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
										</tr>
									</thead>
									<tbody>
										{callSheetActors?.map((actor, index) => {
											return (
												<tr key={index}>
													{!isGameLock ? (
														<td>
															<a
																className="minus-button"
																onClick={() => deleteActor(actor, index)}
																style={{ cursor: "pointer" }}>
																<i className="fa fa-minus-circle" aria-hidden="true"></i>
															</a>
														</td>
													) : (
														<td></td>
													)}
													<td>
														<div className="user-hover">
															{actor.profile_path ? (
																<img
																	src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																	className="user"
																/>
															) : (
																<img src={defaultIcon} className="user" />
															)}
															<span>{actor.Actor}</span>
														</div>
													</td>
													<td>
														{actor.Actor_Type == "Co-Star" ? (
															<img src={Costar} className="anchor-img" />
														) : actor.Actor_Type == "Headliner" ? (
															<img src={HeadingIcn} className="anchor-img" />
														) : (
															<span className="cust-dayplayer"> Dayplayer</span>
														)}
													</td>
													<td>{actor.Title}</td>
													<td>
														{actor.Platform == "Netflix" ? (
															<img src={Netflix} className="cmp-logo" />
														) : (
															actor.Platform
														)}
													</td>
													<td>
														<NumberFormat
															value={actor.Salery}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>
							<div className="tiebreaker-box">
								<Row>
									<Col md={12}>
										<h6>Tiebreaker</h6>
									</Col>
								</Row>
								<div className="tiebreaker-row">
									<div className="tiebreaker-cnt">
										<p>Enter Highest RT Critics Score (0 - 100%)</p>
									</div>
									<div className="btntie">
										<InputGroup className="txt-score">
											<FormControl
												type="number"
												min="0"
												max="100"
												step="any"
												placeholder="0-100%"
												aria-label="Enter Highest RT  Critics Score (0 - 100%)"
												aria-describedby="Enter Highest RT  Critics Score (0 - 100%)"
												defaultValue={callSheet?.MCCriticsScore}
												onChange={handleInputMCCriticsChange}
												disabled={isGameLock}
											/>
										</InputGroup>
									</div>
								</div>

								<div className="tiebreaker-row">
									<div className="tiebreaker-cnt">
										<p>Enter Highest IMDb User Score (0.0 - 10.0)</p>
									</div>
									<div className="btntie">
										<InputGroup className="txt-score">
											<FormControl
												type="number"
												min="0"
												max="10"
												step={0.1}
												precision={2}
												placeholder="0.0 - 10"
												aria-label="Enter Highest IMDb  User Score (0.0 - 10.0)"
												aria-describedby="Enter Highest IMDb  User Score (0.0 - 10.0)"
												defaultValue={callSheet?.IMDbUserScore}
												onChange={handleInputIMDbUserChange}
												disabled={isGameLock}
											/>
										</InputGroup>
									</div>
								</div>
							</div>
							<div className="trm-cnd">
								{validate3Actors ? (
									<p className="right-text">
										Must include at least one Actors from all 3 Actor Types (Headiner, Co-Star, Dayplayer)
									</p>
								) : (
									<p>
										Must include at least one Actors from all 3 Actor Types (Headiner, Co-Star, Dayplayer)
									</p>
								)}

								{callSheetActors.length === 10 ? (
									<p className="right-text">
										Your Call Sheet is incomplete. Please draft 10 Actors - no more. no less
									</p>
								) : (
									<p>Your Call Sheet is incomplete. Please draft 10 Actors - no more. no less</p>
								)}
								{twoDifferentTitles ? (
									<p className="right-text">Must have at least one Actor from 2 different Titles</p>
								) : (
									<p>Must have at least one Actor from 2 different Titles</p>
								)}

								{mCCriticsScore <= 100 && iMDbUserScore <= 10 ? (
									<p className="right-text">
										You must enter both Highest RT Critics Score & Highest IMDb User Score
									</p>
								) : (
									<p>You must enter both Highest RT Critics Score & Highest IMDb User Score</p>
								)}
							</div>
							<div className="import-button">
									
										<button className="blu-border-btn">
											IMPORT
										</button>
							
							

								{/* <Col lg={4}>
                  <div>
                    <CSVLink data={myCallsheet}>
                      <button
                        className="blue-btn"
                        style={{ position: "center" }}
                      >
                        DOWNLOAD CALLSHEET
                      </button>
                    </CSVLink>
                  </div>
                </Col> */}

							
									<div className="callsheet-submit">
										<button
											type="button"
											className="blue-btn"
											onClick={() => submiteCallSheet()}
											style={{ position: "left", width: "700px" }}>
											SUBMIT MY CALL SHEET
										</button>
										
									</div>
									</div>
									<div className="clear-callsheet">
									<a hreft="#" onClick={() => clearCallSheet()} >
											or Clear Call Sheet
										</a>
								    </div>
						</div>
						; ;
					</div>
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({
	actorData: state.genericsPopup.addActor,
});
export default connect(mapStateToProps)(Live);
             