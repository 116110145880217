import React, { useEffect, useState } from "react";
import { Container, Table ,Col, InputGroup,FormControl} from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';
import NumberFormat from "react-number-format";
import MyContestsService from '../../services/mycontests.service'
import { showLoader } from "../../common/actions/application";
import { hideLoader } from "../../common/actions/application";
import { connect } from 'react-redux';
import AuthService from '../../services/auth.service'

function Live(props) {
	const [callSheets, setCallSheets] = useState([]);
	let [upcommingContestsData, setUpcommingContestsData] = useState({});
	const [tournamnets, setTournamnets] = useState([]);
	const [totalCallSheetsCount, setTotalCallSheetsCount] = useState(0);
	const [totalCurrentlyWinning, setTotalCurrentlyWinning] = useState(0);
	const [myCallsheetDetails, setMyCallsheetDetails] = useState({});

	useEffect(() => {
		getLoginUser();
	}, []);

	const getTimeRemaing = (tournamentStartDate) => {
		const future = moment(tournamentStartDate);
		return future.endOf("day").fromNow();
	};

	const getLoginUser = () => {
		const response = AuthService.getAuth();
		if (response) {
			setMyCallsheetDetails(response.data);
			getMyCallSheet(response.data.id);
		}
	};

	const getMyCallSheet = async (getuserId) => {
		props.dispatch(showLoader());
		let inputParams = { userid: getuserId };
		const response = await MyContestsService.getLiveCallSheetsByUserId(inputParams);
		if (response) {
			let dataObject = [];
			dataObject = response.data;
			let total = 0;
			dataObject.forEach((element, i) => {
				total = total + element?.Callsheets?.length;
			});
			let data = dataObject.filter((value) => value?.Callsheets?.length > 0);
			for (let i = 0; i < data.length; i++) {
				let noOfCallsheetsInSingleTournament = data[i].Callsheets.length;
				let PrizesRanking = data[i].PrizesRanking;
				data[i].currentWinning = 0;

				if (PrizesRanking[0].From !== "") {
					PrizesRanking.forEach((rank) => {
						for (let j = 1; j <= noOfCallsheetsInSingleTournament; j++) {
							if (parseInt(rank.From) <= j && parseInt(rank.To) >= j) {
								data[i].currentWinning = data[i].currentWinning + parseInt(rank.PrizeMoney);
							}
						}
					});
				}
			}
			setTotalCallSheetsCount(total);
			setTournamnets(data);
			let totalCurrentlyWinning = 0;
			data.forEach((element) => (totalCurrentlyWinning = totalCurrentlyWinning + element.currentWinning));
			setTotalCurrentlyWinning(totalCurrentlyWinning);
		}
		props.dispatch(hideLoader());
	};

	return (
		<div className="mycontests live">
			<HeaderTop />
			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycontests"}>
							<button>Upcoming </button>
						</Link>
						<Link to={"/live"}>
							<button className="active">
								Live <span className="ntf-no">{tournamnets.length}</span>
							</button>
						</Link>
						<Link to={"/completed"}>
							<button>Completed</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="upcoming-contest-row">
						<h2>My Live Contests</h2>

						<div className="up-contest-content">
							<div className="up-cont-box">
								<p>My Call Sheets</p>
								<span>{totalCallSheetsCount}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>My Live Contests</p>
								<span>{tournamnets.length}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>Currently Winning</p>
								<span>{totalCurrentlyWinning}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<Link to={"/"}>
									<button className="blue-btn">ENTER MORE CONTESTS</button>
								</Link>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					{tournamnets?.map((tournamnet, index) => (
						<CollapseDivs key={index + "tournamet"} tournamnet={tournamnet} props={props} />
					))}
				</Container>
			</div>
			{/*  content closed  */}
			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Live);

const _ = require("lodash");

function CollapseDivs(props) {
	const [tournamnet, setTournamnet] = useState(props.tournamnet);
	const [callSheets, setAllCallSheets] = useState([]);
	const [open, setOpen] = useState(false);
	const [callsheetTable, setCallsheetTable] = useState([]);
	const [isDesc, setIsDesc] = useState(false);
	const [callsheetId, setCallsheetId] = useState(props.tournamnet.Callsheets[0].id);

	const getCallSheets = async () => {
		setOpen(!open);
		if (!open) {
			let inputParams = { tournamentId: tournamnet.id };
			const response = await MyContestsService.getCallSheetsByTourId(inputParams);
			if (response) {
				let filterObject = [];
				filterObject = response.data;
				let statusFilter = filterObject.filter((value) => value?.Status === "Submited");
				setCallsheetTable(statusFilter);
				setAllCallSheets(statusFilter);
			}
		}
	};

	const getPlayerSearch = (searchParam) => {
		props.props.dispatch(showLoader());
		let newArray = [];
		callsheetTable.filter((element) => {
			let tempFilter = element.PlayerName.toLowerCase().includes(searchParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setAllCallSheets(newArray);
		props.props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getPlayerSearch(searchString);
	};

	const sortPlayerDetails = (CallSheets, label) => {
		props.props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedPlayers;
		label === "PlayerName"
			? (sortedPlayers = _.orderBy(
					CallSheets,
					[(callsheet) => callsheet.PlayerName.toLowerCase().trim()],
					orderByFlag
			  ))
			: (sortedPlayers = _.orderBy(CallSheets, label, orderByFlag));
		setAllCallSheets(sortedPlayers);
		setIsDesc(!isDesc);
		props.props.dispatch(hideLoader());
	};

	return (
		<div className="smash-box live-content">
			<div className="c-smash-row">
				<div className="smash-heading">
					<h3>{tournamnet.Tournament_Name}</h3>
				</div>
				<div className="smash-content">
					<div className="smash-cnt-box">
						<p>Top Prize</p>
					</div>
					{/* smash box closed */}
					<div className="smash-cnt-box">
						<p># of Players / Total Players
</p>
						<span>
							{tournamnet.No_Of_Players}/{tournamnet.Total_Players}
						</span>
					</div>
					{/* smash box closed */}
					<div className="smash-cnt-box">
						<p>Remaining Time</p>
						<span>
							<Moment format="M/DD/yyyy, h:mm a">{tournamnet.Starting_in}</Moment>
						</span>
					</div>
					{/* smash box closed */}
					<div className="smash-cnt-box">
						<p>Currently Winning</p>
						<span>{tournamnet.currentWinning}</span>
					</div>
					{/* smash box closed */}
					<div className="smash-cnt-box">
						{!open ? (
							<button className="blu-border-btn" onClick={() => getCallSheets()}>
								VIEW CONTEST
							</button>
						) : (
							""
						)}
					</div>
					{/* smash box closed */}
					{open ? (
						<Link to={"/contestlive?c=" + callsheetId + "&t=" + tournamnet.id}>
							<button className="blu-border-btn">VIEW MY CALL SHEET</button>
						</Link>
					) : (
						""
					)}
				</div>
				{/* ==== smashcontent closed == */}
			</div>
			{/* =========  smashcontent closed ====== */}
			<Collapse in={open}>
				<div className="table-main scroll-table p-r-10" style={{ height: "auto" }}>
					<Col lg={3} md={12} xs={12}>
						<InputGroup className="search-btn">
							<FormControl
								placeholder="Search"
								aria-label="Recipient's username"
								aria-describedby="basic-addon2"
								onChange={handleInputSearchChange}
							/>
							<InputGroup.Text id="basic-addon2">
								<i className="fa fa-search" aria-hidden="true"></i>
							</InputGroup.Text>
						</InputGroup>
					</Col>
					<Table striped hover>
						<thead>
							<tr>
								<th>
									<span>
										Rank <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
									</span>
								</th>
								<th>
									<a onClick={() => sortPlayerDetails(callSheets, "PlayerName")}>
										<span>
											Player Name
											{isDesc ? (
												<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
											) : (
												<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											)}
										</span>
									</a>
								</th>
								<th>
									<a onClick={() => sortPlayerDetails(callSheets, "Score")}>
										<span>
											Score
											{isDesc ? (
												<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
											) : (
												<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											)}
										</span>
									</a>
								</th>
								<th>
									<a onClick={() => sortPlayerDetails(callSheets, "TotalSalary")}>
										<span>
											Budget
											{isDesc ? (
												<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
											) : (
												<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											)}
										</span>
									</a>
								</th>
								<th>
									<a onClick={() => sortPlayerDetails(callSheets, "RemaingSalary")}>
										<span>
											Remaining Salary
											{isDesc ? (
												<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
											) : (
												<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											)}
										</span>
									</a>
								</th>
								<th>
									<a onClick={() => sortPlayerDetails(callSheets, "Winnings")}>
										<span>
											Winnings
											{isDesc ? (
												<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
											) : (
												<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											)}
										</span>
									</a>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{callSheets.map((callSheet, index) => {
								return (
									<tr key={index + callSheet.id}>
										<td>{index + 1}</td>
										<td>{callSheet.PlayerName}</td>
										<td>{callSheet.CallSheetTotal}</td>
										<td>
											<NumberFormat
												value={callSheet.TotalSalary}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</td>
										<td>
											<NumberFormat
												value={callSheet.RemaingSalary}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</td>
										<td>$Pending</td>
										<td>
											<Link
												to={"/contestlive?c=" + callSheet.id + "&t=" + tournamnet.id + "&c1=" + callsheetId}>
												<button>VIEW CALL SHEET</button>
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			</Collapse>
		</div>
	);
}


