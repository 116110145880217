const initialState = {
    tournamentPopupShow: false,
    tournamentData: {},
    moviePopupShow: false,
    movieData: {},
    actorPopupShow: false,
    actorData: {},
    callsheetData: {},
    delCallsheet: false,
    callSheetData: {},
    delConCallsheet: false,
    userConfrimDelete: false,
    addActor: {},

};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SHOW_TOURNAMENT":
            return { ...state, tournamentPopupShow: true, tournamentData: action.data };

        case "HIDE_TOURNAMENT":
            return { ...state, tournamentPopupShow: false };

        case "SHOW_MOVIE":
            return { ...state, moviePopupShow: true, movieData: action.data };

        case "HIDE_MOVIE":
            return { ...state, moviePopupShow: false };

        case "SHOW_ACTOR":
            return { ...state, actorPopupShow: true, actorData: action.data, callsheetData: action.callsheetData };

        case "HIDE_ACTOR":
            return { ...state, actorPopupShow: false };

        case "SHOW_DELETE_CALLSHEET":
            return { ...state, delCallsheet: true, callSheetData: action.data };

        case "HIDE_DELETE_CALLSHEET":
            return { ...state, delCallsheet: false };

        case "SHOW_DELETE_CONF_CALLSHEET":
            return { ...state, delConCallsheet: true };

        case "HIDE_DELETE_CONF_CALLSHEET":
            return { ...state, delConCallsheet: false };

        case "USER_DELETE_CONF_CALLSHEET":
            return { ...state, userConfrimDelete: true };

        case "USER_DELETE_CONF_CALLSHEET_FALSE":
            return { ...state, userConfrimDelete: false };
           
       case "ADD_ACTOR":
            return { ...state, addActor : action.data};
            


        default:
            return state;
    }
};

