import React, {useEffect, useState } from 'react';
import { Container,Form,Accordion } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import { connect } from 'react-redux';
import { showLoader, hideLoader} from "../../common/actions/application";
import { Link } from "react-router-dom";
import userService from '../../services/user.service';

function Settings(props) {

  const [userNotificationSettings, setUserNotificationSettings] = useState({
    "id": 1,
    "PotentialInTheMoney": { "Push": true, "Email": true },
    "InTheMoney": { "Push": true, "Email": true, "SMS": true },
    "FeaturedTournamentReminders": { "Push": true, "Email": true },
    "UnfilledTournamentReminders": { "Push": true, "Email": true, "SMS": true },
  });

  useEffect(() => {
    getPlayersList();
  }, []);

  const getPlayersList = async () => {
    props.dispatch(showLoader());
    // const response = await TourTalentPoolService.getTourTalentPoolList(); 

    let response = {
      "id": 1,
      "PotentialInTheMoney": { "Push": true, "Email": true },
      "InTheMoney": { "Push": true, "Email": true, "SMS": true },
      "FeaturedTournamentReminders": { "Push": true, "Email": true },
      "UnfilledTournamentReminders": { "Push": true, "Email": true, "SMS": true },
    };
    setUserNotificationSettings(response);
    props.dispatch(hideLoader());
  }

  const setField = (parent, child, value) => {
    let newValue = userNotificationSettings;
    newValue[parent][child] = value
    setUserNotificationSettings({ ...userNotificationSettings, newValue })
    userService.notificationSettings({ ...userNotificationSettings });
  }

  return (
		<div className="settingtab">
			<HeaderTop />

			<div className="content border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/profile"}>
							<button>Profile</button>
						</Link>
						<Link to={"/transactionhistory"}>
							<button>Transactions </button>
						</Link>
						<Link to={"/settings"}>
							<button className="active">Settings</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="d-flex justify-content-between setting-box">
						<div className="left-side">
							<button className="dark-button">
								<span className="icn">
									<i className="fa fa-bell" aria-hidden="true"></i>
								</span>
								<span>Notifications</span>
							</button>
						</div>
						{/* ======= left side closed =============== */}
						<div className="right-side">
							<h3>Notification Settings</h3>

							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										Potential ‘In the Money’ Alerts
										<div className="accdn-icn">
											{userNotificationSettings.PotentialInTheMoney.Push ? <span>Push</span> : ""}
											{userNotificationSettings.PotentialInTheMoney.Email ? <span>Email</span> : ""}
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<p>Lets you know when you are about to be in the money.</p>
										<p className="sml-text">Where you receive these notifications</p>
										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Push
												</p>
											</div>
											<div
												className={
													userNotificationSettings.PotentialInTheMoney.Push
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.PotentialInTheMoney.Push ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.PotentialInTheMoney.Push}
														onChange={(e) => {
															setField("PotentialInTheMoney", "Push", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Email
												</p>
											</div>
											<div
												className={
													userNotificationSettings.PotentialInTheMoney.Email
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.PotentialInTheMoney.Email ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.PotentialInTheMoney.Email}
														onChange={(e) => {
															setField("PotentialInTheMoney", "Email", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>
										In the Money Alerts
										<div className="accdn-icn">
											{userNotificationSettings.InTheMoney.Push ? <span>Push</span> : ""}
											{userNotificationSettings.InTheMoney.Email ? <span>Email</span> : ""}
											{userNotificationSettings.InTheMoney.SMS ? <span className="sms-hvr">SMS</span> : ""}
											{/* <span>Push</span> <span>Email</span> <span className="sms-hvr">SMS</span> */}
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<p>Lets you know when you are about to be in the money.</p>
										<p className="sml-text">Where you receive these notifications</p>

										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Push
												</p>
											</div>
											<div
												className={
													userNotificationSettings.InTheMoney.Push
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.InTheMoney.Push ? <span>On</span> : <span>Off</span>}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.InTheMoney.Push}
														onChange={(e) => {
															setField("InTheMoney", "Push", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>

										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Email
												</p>
											</div>
											<div
												className={
													userNotificationSettings.InTheMoney.Email
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.InTheMoney.Email ? <span>On</span> : <span>Off</span>}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.InTheMoney.Email}
														onChange={(e) => {
															setField("InTheMoney", "Email", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> SMS
												</p>
											</div>
											<div
												className={
													userNotificationSettings.InTheMoney.SMS ? "on-btn on-off-btn" : "off-btn on-off-btn"
												}>
												{userNotificationSettings.InTheMoney.SMS ? <span>On</span> : <span>Off</span>}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.InTheMoney.SMS}
														onChange={(e) => {
															setField("InTheMoney", "SMS", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="2">
									<Accordion.Header>
										Featured Tournament Reminders
										<div className="accdn-icn">
											{userNotificationSettings.FeaturedTournamentReminders.Push ? <span>Push</span> : ""}
											{userNotificationSettings.FeaturedTournamentReminders.Email ? <span>Email</span> : ""}
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<p>Lets you know when you are about to be in the money.</p>
										<p className="sml-text">Where you receive these notifications</p>
										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Push
												</p>
											</div>
											<div
												className={
													userNotificationSettings.FeaturedTournamentReminders.Push
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.FeaturedTournamentReminders.Push ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.FeaturedTournamentReminders.Push}
														onChange={(e) => {
															setField("FeaturedTournamentReminders", "Push", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>

										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Email
												</p>
											</div>
											<div
												className={
													userNotificationSettings.FeaturedTournamentReminders.Email
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.FeaturedTournamentReminders.Email ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.FeaturedTournamentReminders.Email}
														onChange={(e) => {
															setField("FeaturedTournamentReminders", "Email", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="3">
									<Accordion.Header>
										Unfilled Tournament Reminders
										<div className="accdn-icn">
											{userNotificationSettings.UnfilledTournamentReminders.Push ? <span>Push</span> : ""}
											{userNotificationSettings.UnfilledTournamentReminders.Email ? <span>Email</span> : ""}
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<p>Lets you know when you are about to be in the money.</p>
										<p className="sml-text">Where you receive these notifications</p>
										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Push
												</p>
											</div>
											<div
												className={
													userNotificationSettings.UnfilledTournamentReminders.Push
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.UnfilledTournamentReminders.Push ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.UnfilledTournamentReminders.Push}
														onChange={(e) => {
															setField("UnfilledTournamentReminders", "Push", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>

										<div className="ntf-stg-row">
											<div className="ntf-cnt">
												<p>
													<i className="fa fa-envelope-o" aria-hidden="true"></i> Email
												</p>
											</div>
											<div
												className={
													userNotificationSettings.UnfilledTournamentReminders.Email
														? "on-btn on-off-btn"
														: "off-btn on-off-btn"
												}>
												{userNotificationSettings.UnfilledTournamentReminders.Email ? (
													<span>On</span>
												) : (
													<span>Off</span>
												)}
												<Form>
													<Form.Check
														type="switch"
														id="custom-switch"
														label=""
														checked={userNotificationSettings.UnfilledTournamentReminders.Email}
														onChange={(e) => {
															setField("UnfilledTournamentReminders", "Email", e.target.checked);
														}}
													/>
												</Form>
											</div>
										</div>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
						{/* ===========  right side closed ============= */}
					</div>
					{/*setting box closed */}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(Settings)


