import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Lobby from '../src/view/dashboard/index'
import Profile from '../src/view/profileTab/index'
import MyCallsheet from '../src/view/myCallSheet/index'
import DashbordPage from '../src/view/myContests/index'
import Admin from '../src/view/adminComponents/tournamnetDashboard'
import Actors from './view/adminActors/index'
import Movie from '../src/view/movieComponents/movieAdmin.js'
import LiveTab from '../src/view/liveTab/index'
import LoginPage from '../src/view/authentication/Login/index'
import RegisterPage from '../src/view/authentication/SignUp/index'
import live from '../src/view/editCallSheet'
import ForgotPassword from '../src/view/authentication/ForgotPassword/index'
import Scores from '../src/view/score/index'
import Support from '../src/view/support/index'
import Live from '../src/view/liveTab/index'
import TermsAndConditions from '../src/view/terms-and-Conditions/index'
import CompletedTab from '../src/view/completedTab/index'
import MyCallsheetExpire from '../src/view/myCallSheetExpire/index'
import Settings from '../src/view/settingTab/index'
import TransactionsHistory from '../src/view/transactionsHistory/index'
import ContestResult from '../src/view/mycontestComplete/index'
import MyContestCompleteResult from '../src/view/myContestCompleteResult/index'
import FullPageLoader from "./components/fullPageLoader/index";
import Components from "./components/alerts/index";
// import ContestsTab from "../src/view/contestsTab/index";
// import MyCallsheetEdit from "../src/view/myCallSheetEdit/index";
import ContestLive from "../src/view/myContestsLive/index";
import CallSheetEntered from "../src/view/myContests/callSheetEntered";
import TournamentDetailsPopup from "../src/view/genericsPopups/tournamentPopup/index";
import MovieDeatilsPopup from "../src/view/genericsPopups/moviePopups/index";
import ActorDeatilsPopup from "../src/view/genericsPopups/actorPopup/index"
import DeleteCallsheetPopup from "../src/view/genericsPopups/deleteCallsheetPopup/index"
import DeleteCallSheetConfrim from "../src/view/genericsPopups/deleteCallsheetPopup/deleteConfrim"
import AdminDashboard from '../src/view/adminComponents/tournamnetDashboard'
import MyCallSheetLive from '../src/view/myCallSheetLive/index'
import MovieAdmin from '../src/view/movieComponents/movieAdmin'
import AdminLiveCallsheets from './view/adminLiveCallsheets/index'
import AdminTalentPool from './view/adminTalentPool/index'
import SignUpPage from './view/authentication/SignUp-2/index'
import AdminPrivateRoute from './AdminPrivateRoute'
import AdminSettings from './view/adminSettings/index'
import AdminStatsDashboard from './view/adminStatsDashboard/index'
import MyExpire from './view/Expire/index'
import AdminActors from './view/adminActors/index'
import AdminNotifications from './view/adminNotifications/index'



import { Switch, Route, Link } from "react-router-dom";

import AuthVerify from "./services/auth-verify";
import AuthService from "./services/auth.service";
import PrivateRoute from "./PrivateRoute";
import AdminPlayers from "./view/adminPlayers/index"

function App() {
	const logOut = () => {
		AuthService.logout();
	};

	return (
		<>
			<FullPageLoader />
			<Components.SuccessAlert />
			<Components.ErrorAlert />
			<Components.WarningAlert />
			<TournamentDetailsPopup />
			<MovieDeatilsPopup />
			<ActorDeatilsPopup />
			<DeleteCallsheetPopup />
			<DeleteCallSheetConfrim />
			<Switch>
				<Route exact path={["/", "/home"]} component={Lobby} />
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/register" component={RegisterPage} />
				<Route exact path="/forgotpassword" component={ForgotPassword} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute exact path="/mycontests" component={DashbordPage} />
				<PrivateRoute exact path="/submited" component={CallSheetEntered} />
				<PrivateRoute exact path="/live" component={Live} />
				<PrivateRoute exact path="/completed" component={CompletedTab} />
				<PrivateRoute exact path="/mycallsheet" component={MyCallsheet} />
				<PrivateRoute exact path="/mycallsheetlive" component={MyCallSheetLive} />
				<PrivateRoute exact path="/expired" component={MyCallsheetExpire} />
				<PrivateRoute exact path="/settings" component={Settings} />
				<PrivateRoute exact path="/transactionhistory" component={TransactionsHistory} />
				<PrivateRoute exact path="/scores" component={Scores} />
				<Route exact path="/faq" component={Support} />
				<Route exact path="/tnc" component={TermsAndConditions} />
				<PrivateRoute exact path="/callsheet" component={live} />
				<PrivateRoute exact path="/view-result" component={ContestResult} />
                <PrivateRoute exact path="/view-result-callsheet" component={MyContestCompleteResult}/>
				{/* <PrivateRoute exact path="/contests" component={ContestsTab} /> */}
				{/* <PrivateRoute exact path="/edit" component={MyCallsheetEdit} /> */}
				<PrivateRoute exact path="/contestlive" component={ContestLive} />
				<PrivateRoute exact path="/myexpired" component={MyExpire} />

				{/* Admin Pages */}
				<AdminPrivateRoute exact path="/admin-dashboard" component={AdminStatsDashboard} />
				<AdminPrivateRoute exact path="/admin-tournament-dashboard" component={AdminDashboard} />
				<AdminPrivateRoute exact path="/admin-movies" component={MovieAdmin} />
				<AdminPrivateRoute exact path="/admin-actors" component={Actors} />
				{/* <PrivateRoute exact path="/admin-callsheets" component={Callsheetadmin} /> */}
				<AdminPrivateRoute exact path="/admin-live-callsheets" component={AdminLiveCallsheets} />
				<AdminPrivateRoute exact path="/admin-talent-pool" component={AdminTalentPool} />
				<AdminPrivateRoute exact path="/admin-settings" component={AdminSettings} />
				<AdminPrivateRoute exact path="/admin-players" component={AdminPlayers} />
				<AdminPrivateRoute exact path="/admin-actors" component={AdminActors} />
				<AdminPrivateRoute exact path="/admin-notifications" component={AdminNotifications} />
			</Switch>
			<AuthVerify logOut={logOut} />
		</>
	);
}

export default App;
