import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormControl, InputGroup, Dropdown } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import { Link } from "react-router-dom";
import ScoreService from '../../services/score.service'
import MyCallSheetService from '../../services/mycallsheet.service'
import AuthService from '../../services/auth.service'
import TournamentService from '../../services/tournament.service'
import { connect } from 'react-redux';
import { showLoader, hideLoader } from "../../common/actions/application";
import { showActorPopup, showMoviePopup } from "../../common/actions/genericPopupAction";
import Moment from 'react-moment';
import moment from 'moment';
import NumberFormat from "react-number-format";
import MyContestsService from '../../services/mycontests.service'
import defaultIcon from "../../assets/images/defaultIcon.jpeg";

function MyCallsheetExpire(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [callSheets, setCallSheets] = useState([]);
  const [callSheetsTable, setCallSheetsTable] = useState([]);
  const [scoreDetails, setScoreDetails] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");


  const [tournamentDetails, setTournamentDetails] = useState({});
  const [modalShowTournament, setModalShowTournament] = useState(false);

  useEffect(() => {
    getLoginUser();
    getTournamentsList();
    getMyCallSheet();
  }, []);

  const getTournamentsList = async () => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentsList();
    response.data.forEach(element => {
      element.Starting_in = getTimeRemaing(element.Starting_in);
    });
    setTournaments(response.data);
    props.dispatch(hideLoader());
  }

  const getTimeRemaing = (tournamentStartDate) => {
    const future = moment(tournamentStartDate);
    return future.endOf('day').fromNow();
  }

  const getLoginUser = () => {
    const response = AuthService.getAuth();
    if (response) {
      setUserId(response.data.id);
      setUserName(response.data.name);
      getMyCallSheet(response.data.id);
    }
  }

  const getMyCallSheet = async (getuserId) => {
    let inputParams = { "userid": getuserId }
    props.dispatch(showLoader());
    const response = await MyContestsService.getCompletedCallSheets(inputParams);
    if (response) {
      setCallSheets(response.data);
      setCallSheetsTable(response.data);
    }
    props.dispatch(hideLoader());
  }

  const showScoreDetails = async (actor) => {
    props.dispatch(showLoader());
    const response = await ScoreService.getScoreById(actor.ScoreId);
    if (response) {
      // setModalShow(true)
      // setScoreDetails(response.data);
      props.dispatch(showMoviePopup(response.data));
    }
    props.dispatch(hideLoader());
  }

  const showTournamentDetails = async (tournament) => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentById(tournament.id);
    if (response) {
      setModalShowTournament(true)
      setTournamentDetails(response.data);
    }
    props.dispatch(hideLoader());
  }

  return (
		<div className="mycontests">
			<HeaderTop />
			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycallsheet"}>
							<button>Upcoming </button>
						</Link>
						<Link to={"/mycallsheetlive"}>
							<button>Live </button>
						</Link>
						<Link to={"/expired"}>
							<button className="active">Expired</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="upcoming-contest-row">
						<h2>My Expired Call Sheets</h2>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					<Row className="upcoming-contest-row mb-4 mcl-row">
						<Col md={4}>
							<InputGroup className="search-btn">
								<FormControl
									placeholder="Search"
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
								/>
								<InputGroup.Text id="basic-addon2">
									<i className="fa fa-search" aria-hidden="true"></i>
								</InputGroup.Text>
							</InputGroup>
						</Col>
						<Col md={8}>
							<div className="contest-content-right btn-exp-cnt">
								<div
									className="btn-group button-tb "
									role="group"
									aria-label="Basic radio toggle button group">
									<input
										type="radio"
										className="btn-check"
										name="btnradio"
										id="btnradio1"
										autocomplete="off"
										checked
									/>
									<label
										onClick={() => setCallSheets(callSheetsTable)}
										className="btn btn-outline-primary "
										for="btnradio1">
										All
									</label>

									<input
										type="radio"
										className="btn-check"
										name="btnradio"
										id="btnradio2"
										autocomplete="off"
									/>
									<label
										onClick={() =>
											setCallSheets(callSheetsTable.filter((callsheet) => callsheet.PlayerRank == "1"))
										}
										className="btn btn-outline-primary"
										for="btnradio2">
										<img src={Costar} /> Won
									</label>
								</div>

								<div className="short-btn">
									<label>Sort By:</label>
									<Dropdown>
										<Dropdown.Toggle variant="success" id="dropdown-basic">
											Date Completed
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Total Winnings</Dropdown.Item>
											<Dropdown.Item href="#/action-2">Total Salary</Dropdown.Item>
											<Dropdown.Item href="#/action-3">Total Score</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>

								<Dropdown className="drop-btn">
									<Dropdown.Toggle variant="success" id="dropdown-basic">
										View Last 3 Days
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">View Last 7 Days</Dropdown.Item>
										<Dropdown.Item href="#/action-2">View Last 15 Days</Dropdown.Item>
										<Dropdown.Item href="#/action-3">View Last 30 Days</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</Col>
					</Row>
					{/* ==== upcoming-contest-row closed  ========  */}
					{callSheets?.filter((value) => value.UserId == userId).map((callSheet, index) => {
						return (
							<>
								<div className="smash-box">
									<div className="c-smash-row">
										<div className="smash-heading">
											<h3>{callSheet.PlayerName} </h3>
											<span>
												Created <Moment format="MMM DD, yyyy h:mm a">{callSheet.Tournament[0].Starts}</Moment>
											</span>
										</div>
										<div className="smash-content">
											<div className="smash-cnt-box">
												<p>Total Winnings</p>
												<span>75%</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p>Total Salary</p>
												<span>
													<NumberFormat
														value={callSheet.TotalSalary}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
													/>
												</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p>Total Score</p>
												<span>{callSheet.CallSheetTotal}</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p>Completed</p>
												<span>
													<Moment format="MM/DD/yyyy, h:mm a">{callSheet.EndDate}</Moment>
												</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<Link to={"/myexpired?c=" + callSheet.id}>
													<button className="blu-border-btn">VIEW COMPLETED CONTESTS</button>
												</Link>
											</div>
											{/* smash box closed */}
											{/* smash box closed */}
										</div>
										{/* ==== smashcontent closed == */}
									</div>
									{/* =========  smashcontent closed ====== */}
									<div className="smash-card-row">
										{callSheet.ActorsList.map((actor, index) => {
											return (
												<>
													<div className="col-card">
														<div className="card-box">
															<div className="card-images">
																{actor.profile_path ? (
																	<img
																		src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																		className="user"
																	/>
																) : (
																	<img src={defaultIcon} className="user" />
																)}
															</div>
															<div className="content-card">
																<div className="heading-icn">
																	{actor.Actor_Type == "Co-Star" ? (
																		<img src={Costar} className="anchor-img" />
																	) : actor.Actor_Type == "Headliner" ? (
																		<img src={HeadingIcn} className="anchor-img" />
																	) : (
																		<span className="cust-dayplayer"> Dayplayer</span>
																	)}
																</div>
																<h4
																	className="card-name"
																	onClick={() => props.dispatch(showActorPopup(actor, callSheet))}>
																	{actor.Actor}
																</h4>
																{/* <h4 className="card-name"><a   onClick={() => setModalShow(true)}>{actor.Actor}</a></h4> */}
																{/* <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
																<p onClick={() => showScoreDetails(actor)}>{actor.Title}</p>
																{/* <Adventure
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
																<p className="card-rate">
																	<NumberFormat
																		value={actor.Salery}
																		displayType={"text"}
																		thousandSeparator={true}
																		prefix={"$"}
																	/>
																</p>
															</div>
														</div>
													</div>
													{/* card closed */}
												</>
											);
										})}
									</div>
									{/* ========= card row closed ============= */}
								</div>
								{/* ========================== smash  closed  ========================  */}
							</>
						);
					})}
				</Container>
			</div>
			{/*  content closed  */}
			<FooterBottom />
		</div>
	);
}
// export default MyCallsheetExpire;
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(MyCallsheetExpire)


