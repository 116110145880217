
export const showTournamentPopup = (tournamentData) => dispatch => {
    dispatch({
        type: "SHOW_TOURNAMENT",
        data:tournamentData
    })
}

export const hideTournamentPopup = () => dispatch => {
    dispatch({
        type: "HIDE_TOURNAMENT"
    })
}

export const showMoviePopup = (movieData) => dispatch => {
    dispatch({
        type: "SHOW_MOVIE",
        data: movieData
    })
}

export const hideMoviePopup = () => dispatch => {
    dispatch({
        type: "HIDE_MOVIE"
    })
}

export const showActorPopup = (actorData,callsheet) => dispatch => {
    dispatch({
        type: "SHOW_ACTOR",
        data: actorData,
        callsheetData: callsheet
    })
}

export const addActor = (value) => dispatch => {
    dispatch({
      type: "ADD_ACTOR",
      data : value
    })
  }

export const hideActorPopup = () => dispatch => {
    dispatch({
        type: "HIDE_ACTOR"
    })
}

export const showDelCallSheet = (callSheetData) => dispatch => {
    dispatch({
        type: "SHOW_DELETE_CALLSHEET",
        data: callSheetData
    })
}

export const hideDelCallSheet = () => dispatch => {
    dispatch({
        type: "HIDE_DELETE_CALLSHEET"
    })
}

export const showDelConfCallSheet = () => dispatch => {
    dispatch({
        type: "SHOW_DELETE_CONF_CALLSHEET"
    })
}

export const hideDelConfCallSheet = () => dispatch => {
    dispatch({
        type: "HIDE_DELETE_CONF_CALLSHEET"
    })
}

export const userDelConfCallSheet = () => dispatch => {
    dispatch({
        type: "USER_DELETE_CONF_CALLSHEET"
    })
}

export const userDelConfCallSheetFalse = () => dispatch => {
    dispatch({
        type: "USER_DELETE_CONF_CALLSHEET_FALSE"
    })
}
