export const cd3dPools = [
    {
      id: 'CD3D',
    //   logo: 'single-assets/BIFI.png',
      name: 'CinemaDraft Token',
      token: 'CD3D',
      tokenDescription: 'CD3D',
      tokenAddress: '0x364bcC8A51Fc1438eA3e77ad8EBdB363a64478f5',
      tokenDecimals: 18,
      depositsPaused: false,
      status: 'active',
      platform: 'https://cd3d-silk.vercel.app/',
      buyTokenUrl: 'https://app.apeswap.finance/#/swap?outputCurrency=0x9108c36DC1DCbf08187D4f4D4579D72e6A35d979',
    }
]  