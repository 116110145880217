import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class AuthService {

  login(email, password) {
    return axios
			.post(API_URL + "users/login", {
				email,
				password,
			})
			.then((response) => {
				if (response.data.success) {
					window.localStorage.setItem("user", JSON.stringify(response.data));
					window.localStorage.setItem("token", JSON.stringify(response.data.userDetails.Profile_Token));
					window.localStorage.setItem("profile_photo", response.data.data.profile_photo);
					return response.data;
				}
			});
  }

  getAuth() {
    return JSON.parse(localStorage.getItem("user"));
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "users/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    let user_details = JSON.parse(localStorage.getItem('user'))
    if (user_details) return JSON.parse(localStorage.getItem('user'));


    
  }

  setUrl(url){
    localStorage.setItem("url", JSON.stringify(url));
  }

  getUrl() {
    return JSON.parse(localStorage.getItem("url"));
  }

}

export default new AuthService();