import React, {useEffect} from 'react';
import { Row, Col, Button, Modal, Table,InputGroup, FormControl} from "react-bootstrap";
import { connect } from 'react-redux';
import './tournamentPopup.css'
import Moment from 'react-moment';
import NumberFormat from "react-number-format";
import { hideTournamentPopup } from "../../../common/actions/genericPopupAction";
import ApplicationService from '../../../services/application.service';
import { useHistory, Link } from "react-router-dom";

function TournamentDetailsPopup(props) {
	const [oneCD3D, setOneCD3D] = React.useState(100);
	const history = useHistory();

	const onHide = () => {
		props.dispatch(hideTournamentPopup());
	};

	useEffect(() => {
		getOneCD3D();
	}, [])
	

	const getInCD3D = (inusd) => {
	
		return (inusd * oneCD3D).toFixed(2);
	};

	const getOneCD3D = async () => {
		const oneCD3D = await ApplicationService.getCD3D();
		setOneCD3D(oneCD3D);
	};
const checkForExisting = async (tour) => {
	
	
		var newurl = "?c=&t=" + tour;
		onHide()

		history.push("/callsheet" + newurl);
		// props.dispatch(showWarningAlert("something went wrong"));
	
	
};
	return (
		<Modal  {...props} size="lg" className='my-3 mainModel tour-popup' aria-labelledby="contained-modal-title-vcenter" centered>
		<div className='innerModel'>
		<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize"}}>
					<strong>{props.tournamentDetails.Tournament_Name}</strong>
				</Modal.Title>
				<Button className="popup-close my-3 mx-2" onClick={() => onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="smash-pop-box-row">
					<div className="smash-pop-box">
						<h5>Starts</h5>
						<p>
							<Moment format="M/DD/yyyy, h:mm a">{props.tournamentDetails.Starts}</Moment>
						</p>
					</div>

					<div className="smash-pop-box">
						<h6>Entry Fee</h6>
						<p className='pTag'>
						<NumberFormat
													value={getInCD3D(props.tournamentDetails.Tournament_Entry_Fee)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												{" "}
												(<NumberFormat
													value={
														props.tournamentDetails.Tournament_Entry_Fee == undefined || props.tournamentDetails.Tournament_Entry_Fee== null
															? 0
															: props.tournamentDetails.Tournament_Entry_Fee
													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>)
						</p>
					</div>
				
					<div className="smash-pop-box">
						<h6>Prizes</h6>
						<p className='pTag'>
						
							
												<NumberFormat
													value={getInCD3D(props.tournamentDetails.PrizePool)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												
						</p>
					</div>

					<div className="smash-pop-box">
						<h6># of Players / Total Players </h6>

						<p className='pTag'>
							{props.tournamentDetails.No_Of_Players}/{props.tournamentDetails.Total_Players}
						</p>
					</div>

					<div className="smash-pop-box">
						<h6>Starting in</h6>
						{/* <p>2d 4hrs 3mins</p> */}
						<p className='pTag'>{props.tournamentDetails.Starting_in}</p>
					</div>
				</div>

				<div className="create-callsheet">
					<Row>
						<Col md="8" className="ply-gm">
							<p>Create a call sheet to play.</p>
							<h4>Shall. We. Play. A. Game?</h4>
							<Link to={"/callsheet?c=&t=" + props.tournamentDetails?.id}>
            <div className='draft-call-sheet'>
				<Button className="blue-btn " onClick={() => onHide()} >DRAFT A NEW CALLSHEET</Button>
				</div>
          </Link>
						</Col>
						{props.tournamentDetails.PrizePool !== 0 ? (
							<Col md="4">
								<div className="prize-box">
									<h4>Prizes</h4>
									<div className="table-main scroll-table p-r-10 ">
										<Table striped hover>
											<thead>
												<tr>
													<th>
														<span>
															Rank <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														</span>
													</th>
													<th>
														<span>
															Prizes <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														</span>
													</th>
												</tr>
											</thead>
											<tbody>
												{props.tournamentDetails.PrizesRanking?.map((ranking, index) => {
													return (
														<tr>
															<td>
																{ranking.From === ranking.To
																	? ranking.From
																	: ranking.From + " - " + ranking.To}
															</td>
															<td>
																{/* {ranking.PrizeMoney} */}
																
																<NumberFormat
													value={
														ranking.PrizeMoney == undefined || ranking.PrizeMoney == null
															? 0
															: ranking.PrizeMoney
													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "} 
												(
												<NumberFormat
													value={getInCD3D(ranking.PrizeMoney)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
								</div>
							</Col>
						) : (
							""
						)}
					</Row>
				</div>
				<div className="create-callsheet">
          <Row className="upcoming-contest-row mb-4">
          <Col>
          <p>Entrants</p>
          </Col>
          
          <Col md={4} sm={6}>
           
							<InputGroup className="search-btn">
								<FormControl
									placeholder="Search"
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
								/>
								<InputGroup.Text id="basic-addon2">
									<i className="fa fa-search" aria-hidden="true"></i>
								</InputGroup.Text>
							</InputGroup>
						</Col>
          </Row>
          <div className="ent-row">
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
          </div>
          </div>
			</Modal.Body>
			
		</div>
		</Modal>
	);
}


const mapStateToProps = state => ({ show: state.genericsPopup.tournamentPopupShow, tournamentDetails: state.genericsPopup.tournamentData });
export default connect(mapStateToProps)(TournamentDetailsPopup)
