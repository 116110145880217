import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import User from "../../assets/images/user.jpg";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import { Link } from "react-router-dom";
import ScoreService from "../../services/score.service";
import MyContestsService from "../../services/mycontests.service";
import AuthService from "../../services/auth.service";
import TournamentService from "../../services/tournament.service";
import Moment from "react-moment";
import moment from "moment";
import NumberFormat from "react-number-format";
import { showLoader, hideLoader } from "../../common/actions/application";
import { connect } from "react-redux";
import { showTournamentPopup, showMoviePopup, showActorPopup } from "../../common/actions/genericPopupAction";
import defaultIcon from "../../assets/images/defaultIcon.jpeg";

function DashbordPage(props) {
	const [callSheets, setCallSheets] = useState([]);
	const [userId, setUserId] = useState("");

	const [tournaments, setTournaments] = useState([]);
	let [upcommingContestsData, setUpcommingContestsData] = useState({});

	const getTimeRemaing = (tournamentStartDate) => {
		const now = moment();
		const future = moment(tournamentStartDate);
		const diff = future.diff(now);
		const diffDuration = moment.duration(diff);
		if (future > now)
			return diffDuration.days() + "d " + diffDuration.hours() + "hrs " + diffDuration.minutes() + "mins";
		else
			return (
				diffDuration.days() * -1 +
				"d " +
				diffDuration.hours() * -1 +
				"hrs " +
				diffDuration.minutes() * -1 +
				"mins " +
				"ago"
			);
	};

	useEffect(() => {
		getLoginUser();
		getTournamentsList();
	}, []);

	const getTournamentsList = async () => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentsList();
		response.data.forEach((element) => {
			element.Starting_in = getTimeRemaing(element.Starting_in);
		});
		setTournaments(response.data.filter((value) => new Date(Date.now()) < new Date(value.Starts)));
		props.dispatch(hideLoader());
	};

	const showTournamentDetails = async (tournament) => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentById(tournament.id);
		if (response) {
			props.dispatch(showTournamentPopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const getLoginUser = () => {
		const response = AuthService.getAuth();
		if (response) {
			setUserId(response.data.id);
			getMyCallSheet(response.data.id);
		}
	};

	const getMyCallSheet = async (getuserId) => {
		props.dispatch(showLoader());
		let inputParams = { userid: getuserId };
		const response = await MyContestsService.getCallSheetsByStatus(inputParams);
		if (response) {
			let potentialWinning = 0;
			let callSheets = response.data;
			let contestCount = callSheets
				?.map((item) => item.TournamentId)
				.filter((value, index, self) => self.indexOf(value) === index);
			contestCount.forEach((element) => {
				let findedCallSheet = callSheets?.find((x) => x.TournamentId == element);
				potentialWinning = potentialWinning + findedCallSheet?.Tournament[0]?.PrizesRanking[0]?.Rank;
			});

			let dataObject = {};
			dataObject.ContestCount = contestCount?.length;
			dataObject.PotentialWinning = potentialWinning;

			setUpcommingContestsData(dataObject);
			setCallSheets(response.data);
		}
		props.dispatch(hideLoader());
	};

	const showScoreDetails = async (actor) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getScoreById(actor.ScoreId);
		if (response) {
			props.dispatch(showMoviePopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const showActorDetails = async (actor, callSheet) => {
		props.dispatch(showActorPopup(actor, callSheet));
	};

	return (
		<div className="mycontests">
			<HeaderTop />

			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycontests"}>
							<button className="active">
								Upcoming <span className="ntf-no">{upcommingContestsData.ContestCount}</span>
							</button>
						</Link>
						<Link to={"/live"}>
							<button>Live </button>
						</Link>
						<Link to={"/completed"}>
							<button>Completed</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="upcoming-contest-row">
						<h2>My Upcoming Contests</h2>
						<div className="up-contest-content">
							<div className="up-cont-box">
								<p>My Call Sheets</p>
								<span>{callSheets.length}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>My Upcoming Contests</p>
								<span>{upcommingContestsData.ContestCount}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>Potential Winnings</p>
								<span>
									<NumberFormat
										value={upcommingContestsData.PotentialWinning}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>
								</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box btn-sm-100">
								<button className="blue-btn">REPLACE AN ACTOR IN ALL CALL SHEETS</button>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					{callSheets?.map((callSheet, index) => {
						return (
							<div key={index + callSheet.MCCriticsScore} className="smash-box">
								<div className="c-smash-row">
									<div className="smash-heading">
										<h3>{callSheet?.Tournament[0]?.Tournament_Name}</h3>
										<span>
											Starts <Moment format="dddd, h:mm a">{callSheet.Tournament[0]?.Starts}</Moment>
										</span>
									</div>
									<div className="smash-content">
										<div className="smash-cnt-box">
											<p>MC Critics Score</p>
											<span>{callSheet.MCCriticsScore}%</span>
										</div>
										{/* smash box closed */}
										{callSheet.IsTiebreaker ? (
											<div className="smash-cnt-box">
												<p className="pink-text">
													<i className="fa fa-angle-double-left" aria-hidden="true"></i> Tiebreaker
													<i className="fa fa-angle-double-right" aria-hidden="true"></i>
												</p>
											</div>
										) : (
											""
										)}
										<div className="smash-cnt-box">
											<p>IMDb User Score</p>
											<span>{callSheet.IMDbUserScore}</span>
										</div>
										{/* smash box closed */}
										<div className="smash-cnt-box">
											<p>Prizes</p>
											<span>
												<NumberFormat
													value={callSheet.Tournament[0]?.PrizePool}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</span>
										</div>
										<div className="smash-cnt-box">
											<p># of Players / Total Players
</p>
											<span>
												{callSheet.Tournament[0]?.No_Of_Players}/{callSheet.Tournament[0]?.Total_Players}
											</span>
										</div>
										{/* smash box closed */}
										<div className="smash-cnt-box">
											<Link to={"/callsheet?c=" + callSheet.id + "&t=" + callSheet.Tournament[0]?.id}>
												<button className="blu-border-btn">EDIT CALL SHEET</button>
											</Link>
										</div>
										{/* smash box closed */}
									</div>
								</div>
								{/* =========  smashcontent closed ====== */}
								<div className="smash-card-row">
									{callSheet.ActorsList.map((actor, index) => {
										return (
											<div key={index + actor.Title} className="col-card">
												<div className="card-box">
												<div className="card-images">	
														{actor.profile_path ? (
																<img
																	src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																	className="user"
																/>
															) : (
																<img src={defaultIcon} className="user" />
															)}
															</div>
													<div className="content-card">
														<div className="heading-icn">
															{actor.Actor_Type == "Co-Star" ? (
																<img src={Costar} className="anchor-img" />
															) : actor.Actor_Type == "Headliner" ? (
																<img src={HeadingIcn} className="anchor-img" />
															) : (
																<span className="cust-dayplayer"> Dayplayer</span>
															)}
														</div>
														<h4 className="card-name" onClick={() => showActorDetails(actor, callSheet)}>
															{actor.Actor}
														</h4>
														<p onClick={() => showScoreDetails(actor)}>{actor.Title}</p>
														<p className="card-rate">
															<NumberFormat
																value={actor.Salery}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<h3>Contests with Call Sheet</h3>
								<div className="table-main cls-sheet-tbl">
									<Table striped hover>
										<thead>
											<tr>
												<th>Tournament Name </th>
												<th>Starts</th>
												<th>Prizes</th>
												<th># of Players / Total Players
</th>
												<th>Starting in</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{tournaments.filter((value) => value.TourTalentPoolId === (callSheet?.Tournament[0]?.TourTalentPoolId)).map((tournament, index) => {
												return (
													<tr key={index + tournament.PrizePool}>
														<td>
															<a onClick={() => showTournamentDetails(tournament)}>
																{tournament.Tournament_Name}
															</a>
														</td>
														<td>
															<Moment format="M/DD/yyyy, h:mm a">{tournament.Starts}</Moment>
														</td>
														<td>
															<NumberFormat
																value={tournament.PrizePool}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</td>
														<td>
															{tournament.No_Of_Players}/{tournament.Total_Players}
														</td>
														<td>{tournament.Starting_in}</td>

														<td>
															<Link
																to={"/callsheet?c=&t=" + tournament.id}
																onClick={() => localStorage.setItem("CallSheetId", null)}>
																<button>VIEW CONTEST</button>
															</Link>
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							</div>
						);
					})}
				</Container>
			</div>

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(DashbordPage);
