
import { connectors } from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

export const getNetworkConnectors = t => {
    switch (56) {
        case 56:
            return {
                network: 'binance',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                    walletconnect: {
                        package: WalletConnectProvider,
                        options: {
                            rpc: {
                                1: 'https://bsc-dataseed.binance.org/',
                                56: 'https://bsc-dataseed.binance.org/',
                            },
                        },
                    },
                    'custom-binance': {
                        display: {
                            name: 'Binance',
                            description: 'Binance Chain Wallet',
                            logo: require(`../../assets/images/wallets/binance-wallet.png`),
                        },
                        package: 'binance',
                        connector: async (ProviderPackage, options) => {
                            const provider = window.BinanceChain;
                            await provider.enable();
                            return provider;
                        },
                    },
                    'custom-math': {
                        display: {
                            name: 'Math',
                            description: 'Math Wallet',
                            logo: require(`../../assets/images/wallets/math-wallet.svg`),
                        },
                        package: 'math',
                        connector: connectors.injected,
                    },
                    'custom-twt': {
                        display: {
                            name: 'Trust',
                            description: 'Trust Wallet',
                            logo: require(`../../assets/images/wallets/trust-wallet.svg`),
                        },
                        package: 'twt',
                        connector: connectors.injected,
                    },
                    'custom-safepal': {
                        display: {
                            name: 'SafePal',
                            description: 'SafePal App',
                            logo: require(`../../assets/images/wallets/safepal-wallet.svg`),
                        },
                        package: 'safepal',
                        connector: connectors.injected,
                    },
                },
            };
        case 128:
            return {
                network: 'heco',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                    // walletconnect: {
                    //   package: WalletConnectProvider,
                    //   options: {
                    //     rpc: {
                    //       1: 'https://http-mainnet.hecochain.com',
                    //       128: 'https://http-mainnet.hecochain.com',
                    //     },
                    //   },
                    // },
                    'custom-math': {
                        display: {
                            name: 'Math',
                            description: 'Math Wallet',
                            logo: require(`../../assets/images/wallets/math-wallet.svg`),
                        },
                        package: 'math',
                        connector: connectors.injected,
                    },
                },
            };
        case 43114:
            return {
                network: 'avalanche',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                    // walletconnect: {
                    //   package: WalletConnectProvider,
                    //   options: {
                    //     rpc: {
                    //       1: 'https://api.avax.network/ext/bc/C/rpc',
                    //       43114: 'https://api.avax.network/ext/bc/C/rpc',
                    //     },
                    //   },
                    // },
                },
            };
        case 137:
            return {
                network: 'polygon',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                    walletconnect: {
                        package: WalletConnectProvider,
                        options: {
                            network: 'matic',
                            rpc: {
                                1: 'https://rpc-mainnet.maticvigil.com/',
                                137: 'https://rpc-mainnet.maticvigil.com/',
                            },
                        },
                    },
                },
            };
        case 250:
            return {
                network: 'fantom',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                    // walletconnect: {
                    //   package: WalletConnectProvider,
                    //   options: {
                    //     rpc: {
                    //       1: 'https://rpcapi.fantom.network',
                    //       250: 'https://rpcapi.fantom.network',
                    //     },
                    //   },
                    // },
                },
            };
        case 1666600000:
            return {
                network: 'harmony',
                cacheProvider: true,
                providerOptions: {
                    injected: {
                        display: {
                            name: 'Injected',
                            description: 'Home-BrowserWallet',
                        },
                    },
                },
            };
        default:
            return {};
    }
};