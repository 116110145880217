import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container,Table } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/main.css";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import { hideLoader, showLoader } from "../../common/actions/application";
import {
  showActorPopup,
  showMoviePopup,
} from "../../common/actions/genericPopupAction";
import FooterBottom from "../../components/footer/index";
import HeaderTop from "../../components/header/index";
import AuthService from "../../services/auth.service";
import mycontestsService from "../../services/mycontests.service";
import ScoreService from "../../services/score.service";
import TournamentService from "../../services/tournament.service";
import "./style.css";
import defaultIcon from "../../assets/images/defaultIcon.jpeg";

function MyCallSheetLive(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [callSheets, setCallSheets] = useState([]);
  const [scoreDetails, setScoreDetails] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  let [upcommingContestsData, setUpcommingContestsData] = useState({});

  const [tournamentDetails, setTournamentDetails] = useState({});
  const [modalShowTournament, setModalShowTournament] = useState(false);

  useEffect(() => {
    getLoginUser();
    getTournamentsList();
    //getMyCallSheet();
  }, []);

  const getTournamentsList = async () => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentsList();
    response.data.forEach((element) => {
      element.Starting_in = getTimeRemaing(element.Starting_in);
    });
    setTournaments(response.data);
    props.dispatch(hideLoader());
  };

  const getTimeRemaing = (tournamentStartDate) => {
    const future = moment(tournamentStartDate);
    return future.endOf("day").fromNow();
  };

  const getLoginUser = () => {
    const response = AuthService.getAuth();
    if (response) {
      setUserId(response.data.id);
      setUserName(response.data.name);
      getMyCallSheet(response.data.id);
    }
  };

  const getMyCallSheet = async (getuserId) => {
    let inputParams = { userid: getuserId };
    props.dispatch(showLoader());
    const response = await mycontestsService.getLiveCallSheets(inputParams);
    if (response) {
      let callSheets = response.data;
      let contestCount = callSheets
        ?.map((item) => item.TournamentId)
        .filter((value, index, self) => self.indexOf(value) === index);
      let dataObject = {};
      dataObject.ContestCount = contestCount?.length;
      setUpcommingContestsData(dataObject);
      setCallSheets(response.data);
    }
    props.dispatch(hideLoader());
  };

  const showScoreDetails = async (actor) => {
    props.dispatch(showLoader());
    const response = await ScoreService.getScoreById(actor.ScoreId);
    if (response) {
      // setModalShow(true)
      // setScoreDetails(response.data);
      props.dispatch(showMoviePopup(response.data));
    }
    props.dispatch(hideLoader());
  };

  const showTournamentDetails = async (tournament) => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentById(tournament.id);
    if (response) {
      setModalShowTournament(true);
      setTournamentDetails(response.data);
    }
    props.dispatch(hideLoader());
  };

  return (
    <div className="mycontests">
      <HeaderTop />
      <div className="border-nav">
        <Container fluid>
          <div className="page-ntf">
            <Link to={"/mycallsheet"}>
              <button>Upcoming </button>
            </Link>
            <Link to={"/mycallsheetlive"}>
              <button className="active">
                Live<span className="ntf-no">{callSheets.length}</span>
              </button>
            </Link>
            <Link to={"/expired"}>
              <button>Expired</button>
            </Link>
          </div>
          {/* ===== page notification closed ===== */}
          <div className="upcoming-contest-row">
            <h2>My Live Call Sheets</h2>
            <div className="up-contest-content">
              <div className="up-cont-box">
                <p>My Live Call Sheets</p>
                <span>{callSheets.length}</span>
              </div>
              {/*up cnt box closed */}
              <div className="up-cont-box">
                <p>My Live Contests</p>
                <span>{upcommingContestsData.ContestCount}</span>
              </div>
              {/*up cnt box closed */}
              <div className="up-cont-box btn-sm-100">
                <Link to={"/"}>
                  <button className="blue-btn">ENTER MORE CONTESTS</button>
                </Link>
              </div>
              {/*up cnt box closed */}
            </div>
          </div>
          {/* ==== upcoming-contest-row closed  ========  */}
          {callSheets?.map((callSheet, index) => {
            return (
              <>
                <div className="smash-box">
                  <div className="c-smash-row">
                    <div className="smash-heading">
                      <h3>{callSheet.PlayerName} </h3>
                      <span>
                        Created
                        <Moment format="MMM DD, yyyy h:mm a">
                          {callSheet.Tournament[0].Starts}
                        </Moment>
                      </span>
                    </div>
                    <div className="smash-content">
                      <div className="smash-cnt-box">
                        <p>Total Winnings</p>
                        <span>75%</span>
                      </div>
                      {/* smash box closed */}
                      {callSheet.IsTiebreaker ? (
                        <div className="smash-cnt-box">
                          <p className="pink-text">
                            <i
                              className="fa fa-angle-double-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Tiebreaker
                            <i
                              className="fa fa-angle-double-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="smash-cnt-box">
                        <p>Total Salary</p>
                        <span>
                          <NumberFormat
                            value={callSheet.TotalSalary}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </span>
                      </div>
                      {/* smash box closed */}
                      <div className="smash-cnt-box">
                        <p>Total Score</p>
                        <span>{callSheet.CallSheetTotal}</span>
                      </div>
                      {/* smash box closed */}
                      <div className="smash-cnt-box">
                        <Link to={"/live"}>
                          <button className="blu-border-btn">
                            VIEW LIVE CONTESTS
                          </button>
                        </Link>
                      </div>
                      {/* smash box closed */}
                      {/* smash box closed */}
                    </div>
                    {/* ==== smashcontent closed == */}
                  </div>
                  {/* =========  smashcontent closed ====== */}
                  <div className="smash-card-row">
                    {callSheet.ActorsList.map((actor, index) => {
                      return (
                        <>
                          <div className="col-card">
                            <div className="card-box">
                            <div className="card-images">	
														{actor.profile_path ? (
																<img
																	src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																	className="user"
																/>
															) : (
																<img src={defaultIcon} className="user" />
															)}
															</div>
                              <div className="content-card">
                                <div className="heading-icn">
                                  {actor.Actor_Type == "Co-Star" ? (
                                    <img src={Costar} className="anchor-img" />
                                  ) : actor.Actor_Type == "Headliner" ? (
                                    <img
                                      src={HeadingIcn}
                                      className="anchor-img"
                                    />
                                  ) : (
                                    <span className="cust-dayplayer">
                                      {" "}
                                      Dayplayer
                                    </span>
                                  )}
                                </div>
                                <h4
                                  className="card-name"
                                  onClick={() =>
                                    props.dispatch(
                                      showActorPopup(actor, callSheet)
                                    )
                                  }
                                >
                                  {actor.Actor}
                                </h4>
                                {/* <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
                                <p onClick={() => showScoreDetails(actor)}>
                                  {actor.Title}
                                </p>
                                {/* <Adventure
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
                                <p className="card-rate">
                                  <NumberFormat
                                    value={actor.Salery}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* card closed */}
                        </>
                      );
                    })}
                  </div>
                  {/* ========= card row closed ============= */}
                </div>
                {/* ========================== smash  closed  ========================  */}
                <h3>Contests with Call Sheet</h3>
									<div className="table-main cls-sheet-tbl">
										<Table striped hover>
											<thead>
												<tr>
													<th>Tournament Name </th>
													<th>Starts</th>
													<th>Prizes</th>
													<th># of Players / Total Players
</th>
													<th>Starting in</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{tournaments.map((tournament, index) => {
													return (
														<tr key={index}>
															<td>
																<a onClick={() => showTournamentDetails(tournament)}>
																	{tournament.Tournament_Name}
																</a>
																{/* <MyVerticallyCenteredModal
                                  show={modalShowTournament}
                                  onHide={() => setModalShowTournament(false)} tournamentDetails={tournamentDetails} /> */}
															</td>
															<td>
																<Moment format="M/DD/yyyy, h:mm a">{tournament.Starts}</Moment>
															</td>
															<td>
																<NumberFormat
																	value={tournament?.PrizePool}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"$"}
																/>
															</td>
															<td>
																{tournament.No_Of_Players}/{tournament.Total_Players}
															</td>
															<td>{tournament.Starting_in}</td>
															{/* <td>2d 4hrs 3mins</td> */}
															<td>
																<Link
																	to={"/callsheet?c=&t=" + tournament.id}
																	onClick={() => localStorage.setItem("CallSheetId", null)}>
																	<button>VIEW CONTEST</button>
																</Link>
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
              </>
            );
          })}
        </Container>
      </div>
      {/*  content closed  */}
      <FooterBottom />
    </div>
  );
}
// export default MyCallsheetExpire;
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(MyCallSheetLive);
