import Web3Modal from 'web3modal';

import { getNetworkConnectors } from '../../common/network/getNetworkData';

export const createWeb3Modal = () => {
  const connectors = getNetworkConnectors();
  const modal = new Web3Modal(connectors);

  if (modal.cachedProvider && !(modal.cachedProvider in connectors.providerOptions)) {
    modal.clearCachedProvider();
  }

  return modal;
};
