import axios from "axios";
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BASE_URL;

class LookupDataService {
	getLookUpData = async () => {
		return await axios.get(API_URL + "look-up-data", { headers: authHeader() }).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};

	upsertLookUpData = async (data) => {
		return await axios.post(API_URL + "upsert-look-up-data", data).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};

	deleteLookUpDataById = async (id) => {
		return await axios.delete(API_URL + "look-up-data/" + id).then((response) => {
			if (response) {
				return response.data;
			}
		});
	};
}

export default new LookupDataService();