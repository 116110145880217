import React from 'react';
import {Button,Modal,Table } from "react-bootstrap";
import { connect } from 'react-redux';
import Delete from '../../../assets/images/Delete-Icon.svg';
import { hideDelCallSheet,userDelConfCallSheet } from "../../../common/actions/genericPopupAction";


function DeleteCallsheetPopup(props) {

    const onHide = () => {
        props.dispatch(hideDelCallSheet());
    }

    const deleteConfrim = () => {
        props.dispatch(hideDelCallSheet());
        // props.dispatch(showDelConfCallSheet());
        props.dispatch(userDelConfCallSheet());
    }

    const deleteCancel = () => {
        props.dispatch(hideDelCallSheet());
    }

    return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="adventure-popup"
				className="main-popup delete-swg">
				<Modal.Header>
					<Modal.Title className="popup-heading" id="contained-modal-title-vcenter">
						<img src={Delete} /> Delete {props.callsheetData.PlayerName}
					</Modal.Title>
					<Button className="popup-close" onClick={() => onHide()}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<div className="popup-content delete-swag-popup">
						<p className="p-text">
							You have 3 contests entered with SwagOFFICIAL (2). Are you sure you want to delete this Call
							Sheet?
						</p>
						<h2>Contests with Call Sheet</h2>

						<div className="table-main scroll-table p-r-10 cls-sheet-tbl">
							<Table striped hover>
								<thead>
									<tr>
										<th>
											<span>Tournament Name</span>
										</th>
										<th>
											<span>Prizes</span>
										</th>
										<th>
											<span># of Players / Total Players
</span>
										</th>
										<th>
											<span>Starting in</span>
										</th>
										<th>
											<span>Potential Winnings</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>$100K Celebration Smash Tournament</td>
										<td>$100,000</td>
										<td>44/50</td>
										<td>2d 4hrs 3mins</td>
										<td>$36.34</td>
									</tr>

									<tr>
										<td>$100K Celebration Smash Tournament</td>
										<td>$100,000</td>
										<td>44/50</td>
										<td>2d 4hrs 3mins</td>
										<td>$36.34</td>
									</tr>

									<tr>
										<td>$100K Celebration Smash Tournament</td>
										<td>$100,000</td>
										<td>44/50</td>
										<td>2d 4hrs 3mins</td>
										<td>$36.34</td>
									</tr>

									<tr>
										<td>$100K Celebration Smash Tournament</td>
										<td>$100,000</td>
										<td>44/50</td>
										<td>2d 4hrs 3mins</td>
										<td>$36.34</td>
									</tr>
								</tbody>
							</Table>
						</div>

						<div className="pupup-btn-row">
							<a href="" onClick={() => deleteCancel()}>
								Cancel
							</a>
							<button className="blue-btn" type="button" onClick={() => deleteConfrim()}>
								DELETE
							</button>
						</div>
					</div>
					{/* ============ popup-content closed ===========  */}
				</Modal.Body>
			</Modal>
		);
}


const mapStateToProps = state => ({ show: state.genericsPopup.delCallsheet, callsheetData: state.genericsPopup.callSheetData });
export default connect(mapStateToProps)(DeleteCallsheetPopup)