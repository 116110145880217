import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from 'react-redux';
import Successful from '../../../assets/images/Successful.svg';
import { useHistory } from "react-router-dom";
import { hideDelConfCallSheet } from "../../../common/actions/genericPopupAction";


function DeleteCallSheetConfrim(props) {
    const history = useHistory();
    const onHide = () => {
        props.dispatch(hideDelConfCallSheet());
    }
    const backToCallsheet = () => {
        props.dispatch(hideDelConfCallSheet());
        history.push('/mycallsheet');
    }
    return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="adventure-popup"
				className="main-popup delete-popup">
				<Button className="popup-close" onClick={() => onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>

				<Modal.Body>
					<div className="popup-content delete-success b-t">
						<img src={Successful} />
						<h2>
							<br /> Successfully Deleted
						</h2>

						<div className="pupup-btn-center">
							<button className="blue-btn" onClick={() => backToCallsheet()}>
								BACK TO CALL SHEET
							</button>
						</div>
					</div>
					{/* ============ popup-content closed ===========  */}
				</Modal.Body>
			</Modal>
		);
}


const mapStateToProps = state => ({ show: state.genericsPopup.delConCallsheet });
export default connect(mapStateToProps)(DeleteCallSheetConfrim)