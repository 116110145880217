import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class UserService {
	getUserById = async (id) => {
		return await axios.get(API_URL + "get-user-profile/" + id).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	notificationSettings = async (inputParams) => {
		console.log("inputParams", inputParams);
		return await axios.post(API_URL + "users/update-notification-settings", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	uploadProfilePic = async (data) => {
		return await axios.post(API_URL + "files", data).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	uploadProfilePic = async (data) => {
		return await axios.post(API_URL + "files", data).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	updateUser = async (data) => {
		return await axios.post(API_URL + "users/update-user", data).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	changePassword = async (data) => {
		return await axios.post(API_URL + "users/changePassword", data).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getPlayer = async (data) => {
		return await axios.get(API_URL + "users/get-user-list").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deletePlayer = async (playerId) => {
		return await axios.delete(API_URL + "users/" + playerId).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deletePlayer2 = async (inputParams) => {
		return await axios.post(API_URL + "update-isactive-user", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	checkScreenNameAvailability = async (inputParams) => {
		return await axios.post(API_URL + "check-screen-name-availability", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	registerUser = async (inputParams) => {
		return await axios.post(API_URL + "users/signup", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	makeEmplyee = async (inputParams) => {
		return await axios.post(API_URL + "make-employee", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	updateClaimedMoney = async (data) => {
		return await axios.post(API_URL + "update-claimed-money", data).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new UserService();
