import React, { useState } from 'react';
import { Offcanvas, Button, Nav } from "react-bootstrap";
import './style.css'


function AdminSideNav(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(true);
    const handleShow = () => setShow(true);

    return (
			<>
				<Nav defaultActiveKey="/home" className="flex-column side-bar">
					<Nav.Item>
						<Nav.Link href="/admin-dashboard">DASHBOARD</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/admin-tournament-dashboard">TOURNAMENTS</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-movies" href="/admin-movies">
							TITLES LIST
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-actors" href="/admin-actors">
							ACTORS
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-live-callsheets" href="/admin-live-callsheets">
							CALLSHEETS
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-talent-pool" href="/admin-talent-pool">
							TALENT POOL
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-players" href="/admin-players">
							PLAYERS
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-settings" href="/admin-settings">
							SETTINGS
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="/admin-notifications" href="/admin-notifications">
							NOTIFICATIONS
						</Nav.Link>
					</Nav.Item>
				</Nav>
			</>
		);
}

export default AdminSideNav;