import React, {useEffect,useState } from 'react';
import { Container, Row, Col, Dropdown, Table, InputGroup, FormControl } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from "../../common/actions/application";
import TransactionService from '../../services/transaction.service'
import { Link } from "react-router-dom";

const TransactionsHistory = (props) => {

  const [transactionsList, setTransactionsList] = useState([]);

  useEffect(() => {
    getTransactionsList();
  }, []);

  const getTransactionsList = async () => {
    props.dispatch(showLoader());
    let inputParams = { "transactionType": "", "details": "" }
    const response = await TransactionService.getTransactions(inputParams);
    setTransactionsList(response);
    props.dispatch(hideLoader());
  }

  return (
		<div className="mycontests live">
			<HeaderTop />

			<div className="content contests-tab-page pt-0 border-nav">
				<Container fluid>
					<div className="transaction-box">
						<div className="page-ntf">
							<Link to={"/profile"}>
								<button>Profile</button>
							</Link>
							<Link to={"/transactionhistory"}>
								<button className="active">Transactions </button>
							</Link>
							<Link to={"/settings"}>
								<button>Settings</button>
							</Link>
						</div>
						{/* ===== page notification closed ===== */}
						<div className="th-sticky">
							<Row className="upcoming-contest-row">
								<Col md={5}>
									<h2>Transaction History</h2>
								</Col>
								<Col md={7}>
									<div className="contest-content-right">
										<div className="up-cont-box">
											<p>Total Winnings</p>
											<span>$1,254.97</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Withdrawn</p>
											<span>$1,200.24</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Available for Withdrawal</p>
											<span>$254.73</span>
										</div>
										{/*up cnt box closed */}
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<Row className="search-col">
								<Col lg={5}>
									<InputGroup className="search-btn">
										<FormControl
											placeholder="Search"
											aria-label="Recipient's username"
											aria-describedby="basic-addon2"
										/>
										<InputGroup.Text id="basic-addon2">
											<i className="fa fa-search" aria-hidden="true"></i>
										</InputGroup.Text>
									</InputGroup>
								</Col>
								<Col lg={7} className="d-flex align-items-center justify-content-end  drop-slc">
									<div className="button-tb mt-0">
										<a className="active">All</a>
										<a>
											<img src={HeadingIcn} /> Won
										</a>
										<a>
											<img src={Costar} /> Withdrawn
										</a>
									</div>

									<Dropdown className="all-trans">
										<Dropdown.Toggle variant="success" id="dropdown-basic" className="dark-button">
											All Transactions
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">january </Dropdown.Item>
											<Dropdown.Item href="#/action-2">February</Dropdown.Item>
											<Dropdown.Item href="#/action-3">March</Dropdown.Item>
											<Dropdown.Item href="#/action-3">April</Dropdown.Item>
											<Dropdown.Item href="#/action-3">May</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</div>
						<div className="table-main scroll-table p-r-10  transation-table cls-sheet-tbl ">
							<Table striped hover>
								<thead>
									<tr>
										<th>
											<span>
												Transaction ID <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Date <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Details <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Won <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Withdrawn <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Available Balance <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
									</tr>
								</thead>
								{/* <tbody>
                  {transactionsList?.map((transaction, index) => {
                    return (
                      <tr key={index}>
                        <td>#359574019</td>
                        <td>June 25, 2020</td>
                        <td>$100K Celebration Smash Tournament</td>
                        <td>$74.32</td>
                        <td>- $0.00</td>
                        <td>$122.82</td>
                      </tr>
                    );
                  })}

                </tbody> */}
							</Table>
						</div>
						{/*  main table closed  */}
					</div>
					{/* ========================== history  closed  ========================  */}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(TransactionsHistory)


