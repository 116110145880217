import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Navbar, Nav, NavDropdown, Dropdown, Table, InputGroup, Form, FormControl } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
// import HeaderTop from '../../components/header/index';
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import Netflix from '../../assets/images/Netflix-Logo.svg';
import Callsheet from '../../assets/images/callsheet.svg';
import Callcopy from '../../assets/images/Import - Icon.svg'
import Blankuser from '../../assets/images/blank-user.svg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import { connect } from 'react-redux';

import axios from "axios";
import { useHistory, Link, useLocation, BrowserRouter as Router } from "react-router-dom";

import AuthService from '../../services/auth.service'
import TalentPoolService from '../../services/talentpool.service'
import CallSheetService from '../../services/callsheet.service'
import TournamentService from '../../services/tournament.service'
import Moment from 'react-moment';
import NumberFormat from "react-number-format";
import { showLoader, hideLoader, showSuccessAlert, showWarningAlert, showErrorAlert } from "../../common/actions/application";

import AdminSideNav from "../../components/adminSidebar/index";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MyVerticallyCenteredModal(props) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="add-tr">
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">Add Actor</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Actor Type</Form.Label>
									<Form.Control type="text" placeholder="	Actor Type" />
								</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Actor</Form.Label>
									<Form.Control type="text" placeholder="Actor" />
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Title</Form.Label>
									<Form.Control type="text" placeholder="Title" />
								</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Platform</Form.Label>
									<Form.Control type="text" placeholder="Platform" />
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Genre</Form.Label>
									<Form.Control type="text" placeholder="Genre" />
								</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Salary</Form.Label>
									<Form.Control type="text" placeholder="Salary" />
								</Form.Group>
							</Col>
						</Row>

						<Row className="add-tr-btn">
							<Col md={6}>
								<Button className="red-btn" onClick={() => props.onHide()}>
									CANCEL
								</Button>
								<Button className="blue-btn">SAVE</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}

const _ = require("lodash");

function AdminActors(props) {
	const [talentPool, setTalentPool] = useState([]);
	const [talentPoolTable, setTalentPoolTable] = useState([]);
	const [editActor, setEditActor] = useState({});
	const [deleteActor, setDeleteActor] = useState({});
	const [isDesc, setIsDesc] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [currentPage,SetCurrentPage]=useState(1);
	const [totalPage,SetTotalPage]=useState(1);

	const [modalShow, setModalShow] = React.useState(false);

	useEffect(() => {
		getAllTalentPool(currentPage);
	}, []);

	const getAllTalentPool = async (cpage) => {
		props.dispatch(showLoader());
		await CallSheetService.getTalentpoolFilter(cpage).then((resp) => {
			if (resp) {
				// resp.data.forEach(element => {
				//   element.isSelected = false;
				// });
	            SetTotalPage(Math.ceil(resp.data.count.count/10))

				setTalentPool(resp.data.talentPool);
				setTalentPoolTable(resp.data.talentPool);
				props.dispatch(hideLoader());
			}
		});
	};

	const handleOnChange = (position) => {
		toggelActorIsSelcted(position);
	};

	const toggelActorIsSelcted = (position) => {
		let newArr = [...talentPool];
		newArr[position].isSelected = !newArr[position].isSelected;
		setTalentPool(newArr);
		updateActor(newArr[position]);
	};

	const [actorType, setActorType] = useState([
		{ TypeId: 1, ValueId: 1, Value: "Headliner" },
		{ TypeId: 1, ValueId: 2, Value: "Co-Star" },
		{ TypeId: 1, ValueId: 2, Value: "Dayplayer" },
	]);

	const updateActor = async (actor) => {
		props.dispatch(showLoader());
		await TalentPoolService.upsertActor(actor).then((resp) => {
			if (resp.data && resp.success) {
				props.dispatch(hideLoader());
			}
			// if (editActor != {} && resp.success) {
			//   props.dispatch(showSuccessAlert("Successfully updated the actor."));
			// }
			props.dispatch(hideLoader());
		});
	};

	const getActorSearch = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		talentPoolTable.filter((element) => {
			let tempFilter = element.Actor.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setTalentPool(newArray);
		props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getActorSearch(searchString);
	};
	const pagination=(action)=>{
		if(action==="+"){
			SetCurrentPage(currentPage+1)
			getAllTalentPool(currentPage+1)

		}else{
			SetCurrentPage(currentPage-1)
			getAllTalentPool(currentPage-1)
			
		}

	}
	const sortActorDetails = (talentPool, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedActors;
		if (label === "Actor" || label === "Title" || label === "Platform" || label === "Genre") {
			sortedActors = _.orderBy(
				talentPool,
				[
					(actor) =>
						(label === "Actor"
							? actor.Actor
							: label === "Title"
							? !actor.Title
								? (actor.Title = "")
								: actor.Title
							: label === "Platform"
							? !actor.Platform
								? (actor.Platform = "")
								: actor.Platform
							: !actor.Genre
							? (actor.Genre = "")
							: actor.Genre
						)
							.toLowerCase()
							.trim(),
				],
				orderByFlag
			);
		} else sortedActors = _.orderBy(talentPool, label, orderByFlag);
		setTalentPool(sortedActors);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	return (
		<div className="dashboard">
			<AdminHeaderTop />
			<div className="d-flex flex-wrap">
				<Col className="left-side-nav">
					<AdminSideNav placement="start" name="start" />
				</Col>
				<div className="right-side-content">
					<div className="content">
						<Container fluid>
							<div className="contest-row">
								<div className="actor">
									{/* <div className="page-ntf">
                <button>Contests </button>
                <button className="active">Live </button>
                <button>Completed </button>
              </div> ===== page notification closed ===== */}

									{/* <div className="add-new add-actor">
                    <button className="pull-right blue-btn" type="button" onClick={() => {
                      setModalShow(true); setIsEdit(true);
                    }} >  Add New Actor </button>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)} editActor={editActor} />

                  </div> */}
									<div className="rbt-box">
										<h3>Actors List</h3>
										<Col lg={3} md={12} xs={12}>
											<InputGroup className="search-btn">
												<FormControl
													placeholder="Search"
													aria-label="Recipient's username"
													aria-describedby="basic-addon2"
													onChange={handleInputSearchChange}
												/>
												<InputGroup.Text id="basic-addon2">
													<i className="fa fa-search" aria-hidden="true"></i>
												</InputGroup.Text>
											</InputGroup>
										</Col>
										<div className="table-main scroll-table p-r-10">
											<Table striped hover>
												<thead>
													<tr>
														<th>#</th>
														<th>Add/Remove</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Actor")}>
																<span>
																	Actor
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Actor_Type")}>
																<span>
																	Actor Type
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Title")}>
																<span>
																	Title
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Platform")}>
																<span>
																	Platform
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Genre")}>
																<span>
																	Genre
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th>
															<a onClick={() => sortActorDetails(talentPool, "Salery")}>
																<span>
																	Salary
																	{isDesc ? (
																		<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																	) : (
																		<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																	)}
																</span>
															</a>
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{talentPool.map((actor, index) => {
														return (
															<tr key={"actor" + index}>
																<td>{index + 1+(currentPage-1)*10}</td>
																<td>
																	{actor.isSelected ? (
																		<a className="minus-button" style={{ cursor: "pointer" }}>
																			<i
																				onClick={() => handleOnChange(index)}
																				className="fa fa-minus-circle fa-2x"
																				aria-hidden="true"></i>
																		</a>
																	) : (
																		<a className="plus-button" style={{ cursor: "pointer" }}>
																			<i
																				onClick={() => handleOnChange(index)}
																				className="fa fa-plus-circle fa-2x"
																				aria-hidden="true"></i>
																		</a>
																	)}
																</td>
																<td>
																	<div className="row-input">
																		<img
																			src={
																				actor.profile_path
																					? "https://image.tmdb.org/t/p/original" + actor.profile_path
																					: User
																			}
																			className="user"
																			style={{
																				maxWidth: 35,
																				width: 35,
																				height: 35,
																				position: "absolute",
																				left: "-27px",
																			}}
																		/>
																		<Form.Control
																			style={{ marginLeft: "12px" }}
																			//defaultValue={actor.Actor}
																			value={actor.Actor}
																			type="text"
																			placeholder="Actor Name"
																			onChange={(e) => {
																				actor.Actor = e.target.value;
																			}}
																			onBlur={(e) => updateActor(actor)}
																		/>
																	</div>
																</td>
																<td>
																	<div className="row-input">
																		<Form.Group className="mb-3" controlId="formBasicPassword">
																			<Form.Select
																				//defaultValue={actor.Actor_Type}
																				value={actor.Actor_Type}
																				onChange={(e) => {
																					actor.Actor_Type = e.target.value;
																					updateActor(actor);
																				}}>
																				<option value="0">Select Actor Type</option>
																				{actorType?.map((option) => (
																					<option value={option.Value}>{option.Value}</option>
																				))}
																			</Form.Select>
																		</Form.Group>
																	</div>
																</td>
																<td>{actor.Title}</td>
																<td>
																	{actor.Platform == "Netflix" ? (
																		<img alt='image is not present' src={Netflix} className="cmp-logo" />
																	) : (
																		actor.Platform
																	)}
																</td>
																<td>{actor.Genre}</td>
																<td>
																	<div className="row-input">
																		<Form.Control
																			type="text"
																			placeholder="Actor Salary"
																			//defaultValue={actor.Salery}
																			value={actor.Salery}
																			onChange={(e) => {
																				actor.Salery = e.target.value;
																			}}
																			onBlur={(e) => updateActor(actor)}
																		/>
																	</div>
																</td>
																<td>
																	<div className="row-input">
																		<Form.Control
																			type="text"
																			placeholder="Actor Notes"
																			//defaultValue={actor.Notes}
																			value={actor.Notes}
																			onChange={(e) => {
																				actor.Notes = e.target.value;
																			}}
																			onBlur={(e) => updateActor(actor)}
																		/>
																	</div>
																</td>
																{/* <td>
                                  <Button
                                    onClick={() => {
                                      setEditActor(actor);
                                      setModalShow(true);
                                      setIsEdit(true);
                                    }}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                  </Button>
                                  {actor.IsActive ? <Button
                                    onClick={() => {
                                      setDeleteActor(actor);
                                      props.dispatch(
                                        showWarningAlert(
                                          "Do you wish to Deactivate this actor?",
                                          undefined,
                                          "delete-action"
                                        )
                                      );
                                    }}
                                    style={{ marginLeft: "5px" }}>
                                    Deactivate
                                      <i className="fa fa-trash" aria-hidden="true"></i>  
                                  </Button> :
                                    <Button
                                      onClick={() => {
                                        setDeleteActor(actor);
                                        props.dispatch(
                                          showWarningAlert(
                                            "Do you wish to Activate this actor?",
                                            undefined,
                                            "delete-action"
                                          )
                                        );
                                      }}
                                      style={{ marginLeft: "5px" }}>
                                      Activate
                                      <i className="fa fa-trash" aria-hidden="true"></i> 
                                    </Button>
                                  }
                                </td> */}
															</tr>
														);
													})}
												</tbody>
											</Table>
										</div>	
										
										<div className="pagination">

														<button className="paginationButton" onClick={()=>pagination('-')} disabled={currentPage===1?true:false}>{"<"} </button>
														<span>{currentPage}</span> 
														<button className="paginationButton"  onClick={()=>pagination('+')} disabled={currentPage===totalPage?true:false}>{">"} </button>
														<span>{totalPage}</span>
										</div>

									</div>
								</div>
								{/* ========================== left contests  closed  ========================  */}
							</div>
						</Container>
					</div>
					{/*  content closed  */}
				</div>
			</div>
			<FooterBottom />
		</div>
	);
}

// export default Live;
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(AdminActors)


