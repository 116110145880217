import React, { Component, useEffect, useState, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Modal,
  Dropdown,
  Table,
  Form,
  InputGroup, FormControl
} from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from "../../components/footer/index";
import HeaderBanner from "../../assets/images/HeaderBanner.svg";

import TournamentService from "../../services/tournament.service";
import UserService from "../../services/user.service";

import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import {
  showLoader,
  hideLoader,
  showSuccessAlert,
  showWarningAlert,
  showErrorAlert,
  hideWarningAlert,
  deleteConfrimFalse,
} from "../../common/actions/application";
import { showTournamentPopup } from "../../common/actions/genericPopupAction";
import { useConnectWallet } from "../../common/redux/hooks";
import {
  createNewTournament,
  getAllTournaments,
} from "../../common/web3/api/fetchTournaments";
import { tournamentfactoryContract } from "../../common/web3/contract";

import AdminSideNav from "../../components/adminSidebar/index";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import TourTalentPoolService from "../../services/tourtalentpool.service";
import ApplicationService from '../../services/application.service'
import { forEach } from "lodash";
import { map } from "jquery";
import LiveTournamentPopUp from "../genericsPopups/liveTournamentPopup";

const _ = require("lodash");

function AdminDashboard(props) {
	const [modalShow, setModalShow] = React.useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const { web3, address } = useConnectWallet();
	const [tournaments, setTournaments] = useState([]);
	const [tournamentsTable, setTournamentsTable] = useState([]);
	const [editTournamnet, setEditTournamnet] = useState({});
	const [deleteTour, setDeleteTour] = useState({});
	const [mulDeleteTour, setMulDeleteTour] = useState([]);
	const [isDesc, setIsDesc] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [tourDel, setTourDel] = useState([]);
	const [oneCD3D, setOneCD3D] = React.useState(100);
	const [prizesRankings, setPrizesRankings] = useState([
		{
			From: "",
			To: "",
			PrizeMoney: "",
		},
		{
			From: "",
			To: "",
			PrizeMoney: "",
		},
		{
			From: "",
			To: "",
			PrizeMoney: "",
		},
	]);

	const getTimeRemaing = (tournamentStartDate) => {
		const future = moment(tournamentStartDate?.toString());
		return future.endOf("day").fromNow();
	};
	useEffect(() => {
		getTournamentsList();
	}, [isCompleted]);

	// useEffect(() => {
	//     getUserData();
	// }, []);

	useEffect(() => {
		// getTournaments();
	}, [address, web3]);

	// const getUserData = async() => {
	//     const response = await UserService.getUser();
	// }

	useEffect(() => {
		getOneCD3D();
		
	}, []);

	const getTournaments = async () => {
		if (address) {
			let contractAddress = tournamentfactoryContract.contractAddress;
			const promise = new Promise((resolve, reject) => {
				getAllTournaments({ web3, address, contractAddress })
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {});
			});
			return promise;
		}
	};

	const getTournamentsList = async () => {
		props.dispatch(showLoader());
		const response = isCompleted
			? await TournamentService.getCompletedTournamentsList()
			: await TournamentService.getAllTournamentsList();
		props.dispatch(hideLoader());
		response.data.forEach((element) => {
			element.Starting_in = getTimeRemaing(element.Starting_in);
		});
		setTournaments(response.data);
		setTournamentsTable(response.data);
	};

	const showTournamentDetails = async (tournament) => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentById(tournament.id);
		if (response) {
			// setModalShow(true);
			response.data.Starting_in = getTimeRemaing(response.data.Starting_in);
			props.dispatch(showTournamentPopup(response.data));
			// setTournamentDetails(response.data);
		}
		props.dispatch(hideLoader());
	};

	const upsertTournament = async (tourData) => {
		props.dispatch(showLoader());
		if (isEdit) tourData.id = editTournamnet.id;
		 tourData.Low_Ball_Pool=0
		
		await TournamentService.addTournament(tourData).then((resp) => {
			if (resp.data) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getTournamentsList();
			}
			props.dispatch(hideLoader());
		});
	};

	// if (props.deleteConfrim) {
	// 	props.dispatch(showLoader());
	// 	let object = {
	// 		isActive: false,
	// 		tournamentId: deleteTour.id,
	// 	};
	// 	TournamentService.deleteTour2(object).then((resp) => {
	// 		if (resp.data) {
	// 			getTournamentsList();
	// 			props.dispatch(deleteConfrimFalse());
	// 			props.dispatch(hideWarningAlert());
	// 		}
	// 	});
	// 	props.dispatch(hideLoader());
	// }

	if (props.deleteConfrim) {
		props.dispatch(showLoader());
		mulDeleteTour.forEach((element, index) => {
			let object = {
				isActive: false,
				tournamentId: mulDeleteTour[index].id,
			};
			TournamentService.deleteTour2(object).then((resp) => {
				if (resp.data) {
					getTournamentsList();
					props.dispatch(deleteConfrimFalse());
					props.dispatch(hideWarningAlert());
					setShowCheckBox(false);
				}
			});
		});

		props.dispatch(hideLoader());
	}

	const sortTournamentDetails = (tournaments, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedtournaments;
		label === "Tournament_Name" || label === "PrizePool"
			? (sortedtournaments = _.orderBy(
					tournaments,
					[
						(tournament) =>
							label === "Tournament_Name"
								? tournament.Tournament_Name.toLowerCase().trim()
								: Number(tournament.PrizePool),
					],
					orderByFlag
			  ))
			: (sortedtournaments = _.orderBy(tournaments, label, orderByFlag));
		setTournaments(sortedtournaments);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const getInCD3D = (inusd) => {
		return (inusd * oneCD3D).toFixed(2);
	};

	const getOneCD3D = async () => {
		const oneCD3D = await ApplicationService.getCD3D();
		setOneCD3D(oneCD3D);
	};

	const getTournamentSearch = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		tournamentsTable.filter((element) => {
			let tempFilter = element.Tournament_Name.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setTournaments(newArray);
		props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getTournamentSearch(searchString);
	};

	const setInitialValues = () => {
		setEditTournamnet({});
		setIsEdit(false);
		setPrizesRankings([
			{
				From: "",
				To: "",
				PrizeMoney: "",
				IsLowBall: false,
			},
			{
				From: "",
				To: "",
				PrizeMoney: "",
				IsLowBall: false,
			},
			{
				From: "",
				To: "",
				PrizeMoney: "",
				IsLowBall: false,
			},
		]);

		setModalShow(true);

	};

	const selectAllFun = () => {
		setMulDeleteTour(tournaments);
		setIsAllChecked(true);
	};

	const deleteMultipleFun = (tournament) => {
		const localMulDeleteTour = [...mulDeleteTour];
		if (localMulDeleteTour.includes(tournament)) {
			const index = localMulDeleteTour.indexOf(tournament);
			if (index >= -1) {
				localMulDeleteTour.splice(index, 1);
			}
		} else {
			localMulDeleteTour.push(tournament);
		}
		const del = [];
		localMulDeleteTour.forEach((value) => {
			del.push(value.Tournament_Name);
		});
		setMulDeleteTour(localMulDeleteTour);
		setTourDel(del);
	};

	return (
		<div className="dashboard">
			<AdminHeaderTop />
			<div className="d-flex flex-wrap">
				<Col className="left-side-nav">
					<AdminSideNav placement="start" name="start" />
				</Col>
			

				<div className="right-side-content">
					<div className="content">
						<Container fluid>
							<div className="table-main p-r-10 desktop-view">
								<h3>Tournament List</h3>
								<Row>
									<Col lg={3} md={12} xs={12}>
										<InputGroup className="search-btn">
											<FormControl
												placeholder="Search"
												aria-label="Recipient's username"
												aria-describedby="basic-addon2"
												onChange={handleInputSearchChange}
											/>
											<InputGroup.Text id="basic-addon2">
												<i className="fa fa-search" aria-hidden="true"></i>
											</InputGroup.Text>
										</InputGroup>
									</Col>
									<Col>
										<div className="add-new">
											{/* <button className="pull-right blue-btn" type="button" onClick={() => winner()}>  Winner </button> */}
											<Button
												className="red-btn"
												onClick={() =>
													!showCheckBox
														? setShowCheckBox(true)
														: !mulDeleteTour[0]
														? props.dispatch(showErrorAlert("please select atleast one tournament"))
														: props.dispatch(
																showWarningAlert(
																	"Do wish to delete this Tournament?",
																	undefined,
																	"delete-action",
																	tourDel,
																	"Tournaments"
																)
														  )
												}>
												{!showCheckBox ? "MULTIPLE DELETE " : "DELETE "}
												<i className="fa fa-trash" aria-hidden="true"></i>
											</Button>
											<button
												className="pull-right blue-btn"
												type="button"
												onClick={() => setInitialValues()}>
												Add New Tournament
											</button>

											<TournamentModal
												show={modalShow}
												isEdit={isEdit}
												editTournamnet={editTournamnet}
												onHide={() => setModalShow(false)}
												submiteTour={(tourData) => upsertTournament(tourData)}
												prizesRanks={prizesRankings}
											/>
										</div>
									</Col>
								</Row>
								<div className="page-ntf">
									<button
										className={isCompleted ? null : "active"}
										onClick={() => {
											setIsCompleted(false);
										}}>
										Live
									</button>
									<button
										onClick={() => {
											setIsCompleted(true);
										}}
										className={isCompleted ? "active" : null}>
										Completed
									</button>
									{/* Shainaz Look Here */}
								</div>
								<Table striped hover>
									<thead>
										<tr>
											<th>
												{showCheckBox ? (
													<>
														<button className="red-btn" onClick={() => setShowCheckBox(false)}>
															{" "}
															BACK
														</button>
														<button className="blue-btn" onClick={selectAllFun}>
															SELECT ALL
														</button>
														<button className="red-btn" onClick={() => setMulDeleteTour([])}>
															CLEAR ALL
														</button>
													</>
												) : null}
											</th>
											<th>
												<a onClick={() => sortTournamentDetails(tournaments, "Tournament_Name")}>
													<span>
														Tournament Name
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a onClick={() => sortTournamentDetails(tournaments, "Starts")}>
													<span>
														Starts
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a onClick={() => sortTournamentDetails(tournaments, "PrizePool")}>
													<span>
														Prizes
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a onClick={() => sortTournamentDetails(tournaments, "No_Of_Players")}>
													<span>
													# of Players / Total Players

														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											{/* <th><span>Starting in <i className="fa fa-long-arrow-down" aria-hidden="true"></i></span></th> */}
											<th>
												<a onClick={() => sortTournamentDetails(tournaments, "start_the_game")}>
													<span>
														Is Game Live
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<span>
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{tournaments.map((tournament, index) => {
											return (
												<tr key={`${index}` + tournament.id}>
													<td>
														{showCheckBox ? (
															<Form.Check
																type="checkbox"
																// id={index + "checkbox1"}
																label={""}
																checked={mulDeleteTour.includes(tournament)}
																value={tournament.id}
																onChange={() => {
																	deleteMultipleFun(tournament);
																}}
															/>
														) : null}
													</td>

													<td>
														<a onClick={() => showTournamentDetails(tournament)}>
															{tournament.Tournament_Name}
														</a>
													</td>
													<td>
														<Moment format="M/DD/yyyy, hh:mm">{tournament.Starts}</Moment>
													</td>
													<td>
														{/* {tournament.Prizes / ApplicationService.getCD3D() + " CD3D"} */}
														<NumberFormat
													value={
														tournament.PrizePool == undefined || tournament.PrizePool == null
															? 0
															: tournament.PrizePool
													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "}
												(
												<NumberFormat
													value={getInCD3D(tournament.PrizePool)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
													</td>
													<td>
														{tournament.No_Of_Players}/{tournament.Total_Players}
													</td>
													{/* <td>{tournament.Starting_in}</td> */}
													<td>{tournament.start_the_game ? "Yes" : "No"}</td>
													{/* <td>2d 4hrs 3mins</td> */}
													<td>
														<Button
															onClick={() => {
																setEditTournamnet(tournament);
																setIsEdit(true);
																setModalShow(true);
																setPrizesRankings(tournament.PrizesRanking);
															}}>
															<i className="fa fa-pencil" aria-hidden="true"></i>
														</Button>
														<Button
															onClick={() => {
																setMulDeleteTour([tournament]);
																const name = [tournament.Tournament_Name];
																props.dispatch(
																	showWarningAlert(
																		"Do you wish to delete this tournamnet?",
																		undefined,
																		"delete-action",
																		name,
																		"Tournaments"
																	)
																);
															}}
															style={{ marginLeft: "5px" }}>
															<i className="fa fa-trash" aria-hidden="true"></i>
														</Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>
							<div className="mobile-table">
								{tournaments.map((tournament, index) => {
									return (
										<div key={`${index}` + tournaments.id} className="table-row" id={index}>
											<div className="table-cnt">
												<div className="tbl-heading">
													<a onClick={() => showTournamentDetails(tournament)}>
														<p>{tournament.Tournament_Name} </p>
													</a>
													{/* <MyVerticallyCenteredModal
                                                    show={modalShow}
                                                    onHide={() => setModalShow(false)} tournamentDetails={tournamentDetails} /> */}
												</div>
												<div className="td-ctn">
													<p>
														<Moment format="M/DD/yyyy">{tournament.Starts}</Moment>
													</p>
													<p>{tournament.PrizePool}</p>
													<p>
														{tournament.No_Of_Players}/{tournament.Total_Players}
													</p>
												</div>
											</div>
											<div className="ply-btn">
												<Button
													onClick={() => {
														setEditTournamnet(tournament);
														setIsEdit(true);
														setModalShow(true);
														setPrizesRankings(tournament.PrizesRanking);
													}}>
													<i className="fa fa-pencil" aria-hidden="true"></i>
												</Button>
												<Button style={{ marginLeft: "5px" }}>
													<i className="fa fa-trash" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
									);
								})}
							</div>
							{/* =============  mobile table closed =============== */}
							{/*  main table closed  */}
						</Container>
					</div>
					{/*  content closed  */}
				</div>
			</div>
			<FooterBottom />
		</div>
	);
}

// export default Lobby;

const mapStateToProps = (state) => ({
	deleteConfrim: state.application.deleteConfrim,
});
export default connect(mapStateToProps)(AdminDashboard);

function TournamentModal(props) {
	const [talentPools, setTalentPools] = useState();
	const [selectedTalentPool, setSelectedTalentPool] = useState("");
	const [lowball, setLowBall] = useState("");
	// const [editTournamnet, setEditTournamnet] = useState({...props.editTournamnet});
	const [dolVal, setDolval] = useState([]);
	const [errors, setErrors] = useState({});
	const [numbArray, setNumbArray] = useState();
	const [oneCD3D, setOneCD3D] = React.useState(100);

	const [prizesRankings, setPrizesRankings] = useState([]);
	const [form, setForm] = useState({
		Tournament_Name: "",
		Starts: "",
		EndDate: "",
		Total_Players: 0,
		MinCallsheets: 0,
		Prizes: 0,
		Tournament_Entry_Fee: 0,
		rank1: "",
		start_the_game: false,
		PrizePool: 0,
		MaxCallsheets: 0,
		Low_Ball_Pool: 0,
	});

	const getTourTalentPoolList = async () => {
		// props.dispatch(showLoader());
		const response = await TourTalentPoolService.getTourTalentPoolList();
		setTalentPools(response.data);
		// props.dispatch(hideLoader());
	};

	useEffect(() => {
		setErrors({});
	}, [props.show]);

	useEffect(() => {
		getTourTalentPoolList();
		// setPrizesRankings([]);
		getOneCD3D();
	}, []);

	useEffect(() => {
		setPrizesRankings([]);
		setPrizesRankings(props.prizesRanks);
		setField("Prizes", 100000);
	}, [props.prizesRanks, props.show]);

	useEffect(() => {
		setForm({
			Tournament_Name: props.editTournamnet.Tournament_Name,
			Starts: props.editTournamnet.Starts,
			EndDate: props.editTournamnet.EndDate,
			Total_Players: props.editTournamnet.Total_Players,
			MinCallsheets: props.editTournamnet.MinCallsheets,
			Prizes: props.editTournamnet.Prizes ? props.editTournamnet.Prizes : 100000,
			Tournament_Entry_Fee: props.editTournamnet.Tournament_Entry_Fee,
			start_the_game: props.editTournamnet.start_the_game,
			updated_at: new Date(Date.now()),
			PrizePool: props.editTournamnet.PrizePool,
			PrizesRanking: props.editTournamnet.PrizesRanking,
			MaxCallsheets: props.editTournamnet.MaxCallsheets,
			// Low_Ball_Pool: props.editTournamnet.Low_Ball_Pool,
		});
		setSelectedTalentPool(props.editTournamnet.TourTalentPoolId);
		lowBallPoolRange(Number(props.editTournamnet.Total_Players))
	}, [props.editTournamnet]);

	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		props.editTournamnet[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const {
			Tournament_Name,
			Starts,
			EndDate,
			Total_Players,
			MinCallsheets,
			Prizes,
			Tournament_Entry_Fee,
			// PrizePool,
			MaxCallsheets,
			Low_Ball_Pool,
		} = form;
		const newErrors = {};

		if (!Tournament_Name || Tournament_Name === "") newErrors.Tournament_Name = "cannot be blank!";
		if (!Starts || Starts === "") newErrors.Starts = "cannot be blank!";
		if (!EndDate || EndDate === "") newErrors.EndDate = "cannot be blank!";
		if (!Total_Players || Total_Players === "") newErrors.Total_Players = "cannot be blank!";
		if (!MinCallsheets || MinCallsheets === "") newErrors.MinCallsheets = "cannot be blank!";
		// if (!Prizes || Prizes === "") newErrors.Prizes = "cannot be blank!";
		// if (!Tournament_Entry_Fee || Tournament_Entry_Fee === "")
		//   newErrors.Tournament_Entry_Fee = "cannot be blank!";
		// if (!PrizePool || PrizePool === "") newErrors.PrizePool = "cannot be blank!";
		if (!MaxCallsheets || MaxCallsheets === "") newErrors.MaxCallsheets = "cannot be blank!";
		if (selectedTalentPool == "" || selectedTalentPool == undefined)
			newErrors.TalentPool = "cannot be blank!";
		// if (!Low_Ball_Pool || Low_Ball_Pool === "")
		//   newErrors.Low_Ball_Pool = "cannot be blank!";
		if (Number(form.Tournament_Entry_Fee) > 0) {
			let check = true;
			const prize = [...prizesRankings];
			prize.forEach((element) => {
				if (element.From === "" || element.To === "" || element.PrizeMoney === "") {
					check = false;
				}
			});
			if (!check) newErrors.prizesRankings = true;
		}

		return newErrors;
	};

	const addnewrank = (e) => {
		setPrizesRankings([
			...prizesRankings,
			{
				From: "",
				To: "",
				PrizeMoney: "",
				IsLowBall: false,
			},
		]);
	};
	const addnewlowballrank = (e) => {
		setPrizesRankings([
			...prizesRankings,
			{
				From: "Lowball-1",
				To: "Lowball-1",
				PrizeMoney: "",
				IsLowBall: true,
			},
		]);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const {
				Tournament_Name,
				Starts,
				EndDate,
				Total_Players,
				MinCallsheets,
				Prizes,
				Tournament_Entry_Fee,
				rank1range,
				rank1,
				rank2range,
				rank2,
				rank3range,
				rank3,
				start_the_game,
				PrizePool,
				MaxCallsheets,
				// Low_Ball_Pool,
			} = form;

			let data = {
				Tournament_Name: Tournament_Name,
				Starts: Starts,
				Prizes: Number(Prizes),
				No_Of_Players: 0,
				Total_Players: Number(Total_Players),
				MinCallsheets: Number(MinCallsheets),
				Starting_in: Starts,
				start_the_game: start_the_game == undefined ? false : start_the_game,
				EndDate: EndDate,
				PrizesRanking: prizesRankings,
				Tournament_Entry_Fee: Number(Tournament_Entry_Fee),
				created_at: new Date(Date.now()),
				updated_at: new Date(Date.now()),
				TourTalentPoolId: selectedTalentPool,
				IsActive: true,
				PrizePool: Number(PrizePool),
				MaxCallsheets: Number(MaxCallsheets),
				// Low_Ball_Pool: Number(Low_Ball_Pool),
				PrizePool: PrizePool,
			};
			// rank2 != undefined ?? data.PrizesRanking.push({ "Rank": Number(rank2) });
			// rank3 != undefined ?? data.PrizesRanking.push({ "Rank": Number(rank3) });
			// setErrors({});
			props.onHide();
			props.submiteTour(data);
			window.location.reload();
		}
	};

	const deleteRank = (index) => {
		const temp = [...prizesRankings];
		temp.splice(index, 1);
		setPrizesRankings(temp);
	};

	// const getInCD3D = (inusd) => {
	// 	return (inusd / ApplicationService.getCD3D()).toFixed(2);
	// };

	const getOneCD3D = async () => {
		const oneCD3D = await ApplicationService.getCD3D();
		setOneCD3D(oneCD3D);
	};

	const getInDollar = (inusd, index) => {
		let prizeArr = dolVal;
		prizeArr.splice(index, 1, (inusd * oneCD3D).toFixed(2));
		setDolval(prizeArr);

	};

	const getInDollarforEdit = (inusd)=>{
		return (inusd * oneCD3D).toFixed(2)

	}
	
	const updatePrizePool = () => {
		// let pool = form.PrizePool == undefined ? 0 : Number(form.PrizePool);
		let pool = 0;
		prizesRankings.forEach((element) => {
			if(element.From.includes('Lowball')){
				pool=pool+(Number(element.To.split('-')[1])-Number(element.From.split('-')[1])+1)*Number(element.PrizeMoney);

			}else{
				pool = pool + (Number(element.To) - Number(element.From) + 1) * Number(element.PrizeMoney);

			}
		});
		// let pool = prizesRankings.reduce((a, v) => a = Number(a) + Number(v.PrizeMoney), 0);
		props.editTournamnet["PrizePool"] = pool;
		setForm({
			...form,
			PrizePool: pool,
		});
	};

	const commaSeparators = (num) => {
		let numParts = num.toString().split(".");
		numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return numParts.join(".");
	};

	const lowBallPoolRange = (num) => {
		let numArray = [];
		for (var i = 1; i < num ; i++) {
			numArray.push(i);
		}
		setNumbArray(numArray);
	};
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="tour-popup" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.isEdit ? "Edit Tournament" : "Add New Tournament"}
				</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
				{/* <Button>Edit Scores</Button>  */}
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form onSubmit={handleSubmit} role="form">
						<Row>
							<Col md={6}>
								<div className="create-callsheet">
									<h4>Tournament Detail</h4>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Tournament Name*</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editTournamnet.Tournament_Name}
												type="text"
												placeholder="Tournament Name"
												onChange={(e) => setField("Tournament_Name", e.target.value)}
												isInvalid={!!errors.Tournament_Name}
											/>
											<Form.Control.Feedback type="invalid">{errors.Tournament_Name}</Form.Control.Feedback>
										</div>
									</Form.Group>

									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Talent Pool*</Form.Label>
										<div className="row-input">
											{/* <Form.Control defaultValue={props.editTournamnet.Tournament_Name} type="text" placeholder="Tournament Name"
                                                onChange={e => setField('Tournament_Name', e.target.value)}
                                                isInvalid={!!errors.Tournament_Name} /> */}
											<Form.Select
												onChange={(event) => setSelectedTalentPool(event.target.value)}
												value={selectedTalentPool}>
												<option value="">Select Talent Pool</option>
												{talentPools?.map((option) => (
													<option key={option.id} value={option.id}>
														{option.TalentPoolName}
													</option>
												))}
											</Form.Select>
											{/* <Form.Control.Feedback type="invalid">
                        {errors.selectedTalentPool}
                      </Form.Control.Feedback> */}
											{errors.TalentPool == "cannot be blank!" ? (
												<div style={{ display: "block" }} className="invalid-feedback">
													cannot be blank!
												</div>
											) : (
												""
											)}
										</div>
									</Form.Group>

									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>Start Date*</Form.Label>
												<div className="row-input">
													<DateRangePicker
														initialSettings={{
															singleDatePicker: true,
															timePicker: true,
															startDate: moment(props.editTournamnet.Starts).format("M/DD/yyyy hh:mm A"),
															locale: {
																format: "M/DD/yyyy hh:mm A",
															},
														}}
														onCallback={(date) => {
															setField("Starts", date.format());
														}}
														// isInvalid={!!errors.Starts}
													>
														<input type="text" className="form-control col-4" />
													</DateRangePicker>
													{/* <Form.Control type="date" placeholder=""
                                                        onChange={e => setField('startDate', e.target.value)}
                                                        isInvalid={!!errors.startDate} /> */}
													{/* <Form.Control.Feedback type="invalid">
                            {errors.Starts}
                          </Form.Control.Feedback> */}
													{errors.Starts == "cannot be blank!" ? (
														<div style={{ display: "block" }} className="invalid-feedback">
															Click apply after entring date!
														</div>
													) : (
														""
													)}
												</div>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>End Date*</Form.Label>
												<div className="row-input">
													<DateRangePicker
														initialSettings={{
															singleDatePicker: true,
															timePicker: true,
															startDate: moment(props.editTournamnet.EndDate).format("M/DD/yyyy hh:mm A"),
															locale: {
																format: "M/DD/yyyy hh:mm A",
															},
														}}
														onCallback={(date) => {
															setField("EndDate", date.format());
														}}>
														<input type="text" className="form-control col-4" />
													</DateRangePicker>
													{errors.EndDate == "cannot be blank!" ? (
														<div style={{ display: "block" }} className="invalid-feedback">
															Click apply after entring date!
														</div>
													) : (
														""
													)}
													{/* <Form.Control type="date" placeholder=""
                                                        onChange={e => setField('endDate', e.target.value)}
                                                        isInvalid={!!errors.endDate} /> */}
													{/* <Form.Control.Feedback type="invalid">
                            {errors.EndDate}
                          </Form.Control.Feedback> */}
												</div>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Max Players Allowed*</Form.Label>
												<div className="row-input">
													<Form.Control
														value={props.editTournamnet.Total_Players}
														type="number"
														placeholder="Max Players Allowed"
														// onChange={(e) => setField("Total_Players", e.target.value) }
														onChange={(e) => {
															setField("Total_Players", e.target.value);
															lowBallPoolRange(Number(e?.target.value) + 1);
														}}
														isInvalid={!!errors.Total_Players}
													/>
													<Form.Control.Feedback type="invalid">{errors.Total_Players}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Min Call Sheets Allowed*</Form.Label>
												<div className="row-input">
													<Form.Control
														value={props.editTournamnet.MinCallsheets}
														type="number"
														placeholder="Min Call Sheets Allowed"
														onChange={(e) => setField("MinCallsheets", e.target.value)}
														isInvalid={!!errors.MinCallsheets}
													/>
													<Form.Control.Feedback type="invalid">{errors.MinCallsheets}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
									</Row>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										{/* <Form.Label>Make It Live</Form.Label> */}
										<div className="row-input">
											<Form.Check
												checked={props.editTournamnet.start_the_game}
												style={{ color: "red" }}
												type="checkbox"
												id="live"
												label="Make It Live"
												onChange={(e) => setField("start_the_game", e.target.checked)}
											/>
										</div>
									</Form.Group>
									<Row>
										<Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Maximum Total Salary*</Form.Label>
												{/* <Form.Control type="text" placeholder="Maximum Total Salary" /> */}
												<div className="row-input">
													<Form.Control
														// defaultValue={100000}
														value={props.editTournamnet.Prizes}
														type="number"
														placeholder="Maximum Total Salary"
														onChange={(e) => setField("Prizes", e.target.value)}
														isInvalid={!!errors.Prizes}
													/>
													<Form.Control.Feedback type="invalid">{errors.Prizes}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
										{/* <Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Low Ball Pool</Form.Label>
												
												<div className="row-input">
													<Form.Control
														value={props.editTournamnet.Low_Ball_Pool}
														type="number"
														placeholder="Low Ball Pool"
														onChange={(e) => setField("Low_Ball_Pool", e.target.value)}
														isInvalid={!!errors.Low_Ball_Pool}
													/>
													<Form.Control.Feedback type="invalid">{errors.Low_Ball_Pool}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col> */}
									</Row>
									<Row>
										<Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Entry Fee</Form.Label>
												{/* <Form.Control type="text" placeholder="Maximum Total Salary" /> */}
												<div className="row-input">
													<Form.Control
														value={props.editTournamnet.Tournament_Entry_Fee}
														type="number"
														placeholder="Entry Fee"
														onChange={(e) => setField("Tournament_Entry_Fee", e.target.value)}
														isInvalid={!!errors.Tournament_Entry_Fee}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.Tournament_Entry_Fee}
													</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Max Call Sheets Allowed(Per User)*</Form.Label>

												<div className="row-input">
													<Form.Control
														value={props.editTournamnet.MaxCallsheets}
														type="number"
														placeholder="Max Callsheets Allowed"
														onChange={(e) => setField("MaxCallsheets", e.target.value)}
														isInvalid={!!errors.MaxCallsheets}
													/>
													<Form.Control.Feedback type="invalid">{errors.MaxCallsheets}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>

							<Col md={6}>
								<div className="create-callsheet">
									<h4>Prizes</h4>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Prize Pool</Form.Label>

										<div className="row-input">
											{/* <Form.Control
                        value={props.editTournamnet.PrizePool}
                        type="number"
                        placeholder="Prize Pool"
                        onChange={(e) =>
                          setField("PrizePool", e.target.value)
                        }
                        isInvalid={!!errors.PrizePool}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.PrizePool}
                      </Form.Control.Feedback> */}

											<NumberFormat
												thousandSeparator={true}
												className="form-control"
												value={props.editTournamnet.PrizePool}
												placeholder="Prize Pool"
												onChange={(e) => setField("PrizePool", e.target.value)}
											/>
										</div>
									</Form.Group>
									<div className="btn-row">
										<Button className="btn-sm" style={{ marginLeft: "15px" }} onClick={() => addnewrank()}>
											Add new Prize
										</Button>
										<Button
											className="btn-sm"
											style={{ marginLeft: "15px" }}
											onClick={() => addnewlowballrank()}>
											Add new Lowball Prize
										</Button>
									</div>
									{/* {prizesRankings?.map((rankObject, index) => (
                    <PriceRanking rankObject={rankObject} priceIndex={index} />
                  ))} */}
									<div className="table-main scroll-table p-r-10  contest-table-lv">
										<Table striped hover>
											<thead>
												<tr>
													<th>#</th>

													<th>
														<span>From</span>
													</th>
													<th>
														<span>To</span>
													</th>
													<th>
														<span>Prize CD3D($)</span>
													</th>
													<th></th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{prizesRankings?.map((rankObject, index) => {
													return (
														<>
															{rankObject.IsLowBall ? (
																<tr key={index + "rank"}>
																	<td>{index + 1}</td>
																	<td>
																		<div className="row-input">
																			<Form.Select
																				onChange={(e) => {
																					rankObject.From = e.target.value;
																					updatePrizePool();
																				
																				}}>
																					{props.isEdit ?	
																					<option selected disabled hidden>{prizesRankings[2]?.From}</option>:
																					<option selected disabled hidden>Please Select lowest</option>}
																				
																				
																				{numbArray?.map((val, index) => {
																					return <option value={`Lowball-${val}`}>Lowball {val }</option>;
																				})}
																			</Form.Select>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Select
																				onChange={(e) => {
																					rankObject.To = e.target.value;
																					updatePrizePool();
																				
																				}}>
																				{props.isEdit ?	
																					<option selected disabled hidden>{prizesRankings[prizesRankings.length - 1].To}</option>:
																					<option selected disabled hidden>Please Select lowest</option>}
																				
																				{numbArray?.map((val) => {
																					return (
																						<option value={`Lowball-${val}`}>Lowball {val }</option>
																					);
																				})}
																			</Form.Select>
																		</div>
																	</td>
																	<td>
																		
																		<div className="row-input">
																			<Form.Control
																				defaultValue={rankObject.PrizeMoney || ""}
																				type="number"
																				placeholder="Prize"
																				onLoad ={ (e) => {
																					rankObject.PrizeMoney = e.target.value;
																					updatePrizePool();
																					getInDollar(rankObject.PrizeMoney, index);

																				}}
																				onChange={(e) => {
																					rankObject.PrizeMoney = e.target.value;
																					updatePrizePool();
																					getInDollar(e.target.value, index);

																				}}
																			/>
																		</div>{rankObject.PrizeMoney?
																		<span>${getInDollarforEdit(rankObject.PrizeMoney)}</span> :<span>{dolVal[index]}</span>}
																		
																		
																	</td>
																	<td>
																		<Button onClick={() => deleteRank(index)} style={{ marginLeft: "5px" }}>
																			<i className="fa fa-trash" aria-hidden="true"></i>
																		</Button>
																	</td>
																</tr>
															) : (
																<tr key={index + "rank"}>
																	<td>{index + 1}</td>
																	<td>
																		<div className="row-input">
																			<Form.Control
																				defaultValue={rankObject.From || ""}
																				type="number"
																				placeholder="From"
																				onChange={(e) => {
																					rankObject.From = e.target.value;
																					updatePrizePool();
																				
																				}}
																			/>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Control
																				defaultValue={rankObject.To || ""}
																				type="number"
																				placeholder="To"
																				onChange={(e) => {
																					rankObject.To = e.target.value;
																					updatePrizePool();

																				}}
																			/>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Control
																				defaultValue={rankObject.PrizeMoney || ""}
																				type="number"
																				placeholder="Prize"
																				onChange={(e) => {
																					rankObject.PrizeMoney = e.target.value;
																					updatePrizePool();
																					getInDollar(e.target.value, index);
																				}}
																			/>
																		</div>
																		{rankObject.PrizeMoney?<span>${getInDollarforEdit(rankObject.PrizeMoney)}</span>:<span>${dolVal[index]}</span>}
																		 
																		{/* {(dolVal * ApplicationService.getCD3D()).toFixed(2)}{" "}
																		&nbsp;$ */}
																	</td>
																	<td>
																		<Button onClick={() =>{
																			deleteRank(index)

																		}}
																			 style={{ marginLeft: "5px" }}>
																			<i className="fa fa-trash" aria-hidden="true"></i>
																		</Button>
																	</td>
																</tr>
															)}
														</>
													);
												})}
											</tbody>
										</Table>
										{errors.prizesRankings == true ? (
											<div style={{ display: "block" }} className="invalid-feedback">
												please fill all the fields!
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</Col>
						</Row>
						<div className="pupup-btn-row">
							<Button onClick={()=>{
							props.onHide()
								window.location.reload()
							}} className="red-btn">
								CANCEL
							</Button>
							{props.isEdit ? (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									EDIT TOURNAMENT
								</Button>
							) : (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									SAVE TOURNAMENT
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}


function PriceRanking(props) {
  const [rankRange1, setRankRange1] = useState("");
  const [rankRange2, setRankRange2] = useState("");
  const [rankPrize, setRankPrize] = useState("");
  return (

    <></>
    // <Row>
    //   <Col md={6}>
    //     <Form.Group
    //       className="mb-3 form-box"
    //       controlId="formBasicPassword"
    //     >
    //       <Form.Label>Rank-{props.priceIndex + 1} Range</Form.Label>
    //       <div className="row-input">
    //         <Row>
    //           <Col md={6}>   <Form.Control
    //             type="number"
    //             placeholder="x"
    //             onChange={(e) => setRankRange1(e.target.value)}
    //           // isInvalid={!!errors.rank3range}
    //           /></Col>
    //           <Col md={6}> <Form.Control
    //             type="number"
    //             placeholder="x"
    //             onChange={(e) => setRankRange2(e.target.value)}
    //           // isInvalid={!!errors.rank3range}
    //           /></Col>
    //         </Row>

    //         {/* <Form.Control.Feedback type="invalid">
    //         {errors.rank3range}
    //       </Form.Control.Feedback> */}
    //       </div>

    //     </Form.Group> </Col>
    //   <Col md={6}>

    //     <Form.Group
    //       className="mb-3 form-box"
    //       controlId="formBasicPassword"
    //     >
    //       <Form.Label>Rank-{props.priceIndex + 1} Prize Amount</Form.Label>
    //       <div className="row-input">
    //         <Form.Control
    //           type="number"
    //           placeholder="Prize Amount"
    //           onChange={(e) => setRankPrize(e.target.value)}
    //         // isInvalid={!!errors.rank3}
    //         />
    //         {/* <Form.Control.Feedback type="invalid">
    //         {errors.rank3}
    //       </Form.Control.Feedback> */}
    //       </div>  </Form.Group>
    //   </Col>
    // </Row>
  );

}