import { Link, Route, Redirect } from 'react-router-dom'
import AuthService from '../src/services/auth.service'
import { useHistory } from "react-router-dom";

function AdminPrivateRoute({ component, path }) {
    const history = useHistory();
    // history.goBack()
    if (AuthService.getAuth()?.data?.role_id  != "admin")
        return <Redirect
            to={{
                pathname: "/login"
            }}
        />
    else
        return <Route path={path} component={component} />;
}

export default AdminPrivateRoute;