import React, { Component, useEffect, useState, useReducer } from 'react'; 
import '../../assets/css/main.css'; 
import { connect } from 'react-redux'; 
import 'bootstrap-daterangepicker/daterangepicker.css'; 

import StatsService from "../../services/stats.service";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Tournament Overlay Statistics",
		},
	},
};

function TournamentOverlayGraph() {
	const [overlays, setOverlays] = useState({});
	const [labels, setLabelArr] = useState([]);
	useEffect(() => {
		StatsService.getOverlayStats().then(async (resp) => {
			if (resp) {
				setOverlays(resp);
				setLabelArr(resp.labels[0]);
			}
		});
	}, [0]);

	let data = {
		labels,
		datasets: [
			{
				label: "GPP",
				data: overlays.gpp,
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Total Fee Collected",
				data: overlays.total,
				backgroundColor: "rgb(75, 192, 192)",
			},
			{
				label: "Overlay",
				data: overlays.overlay,
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};
	return (
		<>
			<h4> Tournament Overlay Statistics</h4>
			<Bar options={options} data={data} />
		</>
	);
}
// export default Lobby;

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(TournamentOverlayGraph)






