import React, { memo, useCallback, useRef, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Button, Nav, Modal, Dropdown, Table, Form, InputGroup, FormControl } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import AdminHeaderTop from '../../components/adminHeader/index';
import FooterBottom from '../../components/footer/index';
import Callsheet from '../../assets/images/callsheet.svg';

import TournamentService from '../../services/tournament.service'
// import UserService from '../../services/user.service'

import Moment from 'react-moment';
import moment from 'moment';
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { showLoader, hideLoader, showSuccessAlert, showWarningAlert, showErrorAlert, hideWarningAlert, deleteConfrimFalse } from "../../common/actions/application";

import AdminSideNav from '../../components/adminSidebar/index';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import UserService from '../../services/user.service';
import { CSVLink } from "react-csv";

const _ = require("lodash");

function AdminPlayers(props) {
	const [modalShow, setModalShow] = React.useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [players, setPlayers] = useState([]);
	const [editPlayer, setEditPlayer] = useState({});
	const [deletePlayer, setDeletePlayer] = useState({});
	const [isDesc, setIsDesc] = useState(false);
	const [makeEmployee, setMakeEmployee] = useState({});
	const [empModalShow, setEmpModalShow] = useState(false);
	const [playersTable, setPlayersTable] = useState([]);
	const [playerstat, setPlayerstat] = useState();
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [playerDel, setPlayerDel] = useState([]);
	const [mulDeleteTour, setMulDeleteTour] = useState([]);
	const [playerCsv, setPlayerCsv] = useState([])

	const editPlayerFun = (data) => {
		setEditPlayer(data);
		setModalShow(true);
		setIsEdit(true);
	};

	useEffect(() => {
		getPlayersList();
	}, []);

	const getPlayersList = async () => {
		props.dispatch(showLoader());
		const response = await UserService.getPlayer();
		// let response3 = [{
		//     "id": 1, "name": "Comedy", "email": "a@gmail.com", "Screen_Name": "Comedy",
		//     "Gender": "Male", "Date_Of_Birth": "2021-11-25T14:31:24.919+00:00", "CreatedOn": "11/11/2021"
		// }];
		setPlayers(response);
		setPlayersTable(response);
		setPlayerCsv(response);
		props.dispatch(hideLoader());
	};

	const upsertPlayer = async (data) => {
		props.dispatch(showLoader());
		if (isEdit) data.id = editPlayer.id;
		// await UserService.addPlayer(data).then((resp) => {
		//     if (resp.data) {
		//         setModalShow(false)
		//         props.dispatch(hideLoader());
		//         getPlayersList();
		//     }
		//     props.dispatch(hideLoader());
		// })
	};

	if (props.deleteConfrim) {
		props.dispatch(showLoader());
		mulDeleteTour.forEach((element, index) => {
		let object = {
			isActive: false,
			userId: mulDeleteTour[index].id,
		};
		UserService.deletePlayer2(object).then((resp) => {
			if (resp.data) {
				getPlayersList();
				props.dispatch(deleteConfrimFalse());
				props.dispatch(hideWarningAlert());
				setShowCheckBox(false);
			}
		});
	});
		props.dispatch(hideLoader());
	}

	const makeEmpConfrimfun = () => {
		props.dispatch(showLoader());
		let object = {
			isActive: true,
			userId: makeEmployee.id,
		};
		UserService.makeEmplyee(object).then((resp) => {
			if (resp.data) {
				getPlayersList();
				setEmpModalShow(false);
			}
		});
		props.dispatch(hideLoader());
	};

	const sortPlayerDetails = (players, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedplayers;
		if (label === "Screen_Name" || label === "name" || label === "email" || label === "index") {
			sortedplayers = _.orderBy(
				players,
				[
					(player) =>
						(label === "Screen_Name"
							? !player.Screen_Name
								? (player.Screen_Name = "")
								: player.Screen_Name
							: label === "name"
							? !player.name
								? (player.name = "")
								: player.name
							: player.email
						)
							.toLowerCase()
							.trim(),
				],
				orderByFlag
			);
		} else sortedplayers = _.orderBy(players, label, orderByFlag);
		setPlayers(sortedplayers);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const getPlayerSearch = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		playersTable.filter((element) => {
			let tempFilter = element.Screen_Name.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});

		if (playerstat == false || playerstat == true) {
			setPlayers(newArray.filter((player) => player.MakeEmployee == playerstat));
		} else {
			setPlayers(newArray);
		}

		props.dispatch(hideLoader());
	};

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getPlayerSearch(searchString);
	};

	const selectAllFun = () => {
		setMulDeleteTour(players);
	};

	const deleteMultipleFun = (player) => {
		const localMulDeleteTour = [...mulDeleteTour];
		if (localMulDeleteTour.includes(player)) {
			const index = localMulDeleteTour.indexOf(player);
			if (index >= -1) {
				localMulDeleteTour.splice(index, 1);
			}
		} else {
			localMulDeleteTour.push(player);
		}
		const del =[]
		localMulDeleteTour.forEach((value)=>{
			del.push(value.Screen_Name)
		})
		setMulDeleteTour(localMulDeleteTour);
		setPlayerDel(del)
	}

	return (
		<div className="dashboard">
			<div>
				<AdminHeaderTop />
				<div className="d-flex">
					<Col className="left-side-nav">
						<AdminSideNav placement="start" name="start" />
					</Col>
					<div className="right-side-content">
						<div className="content border-nav" style={{ padding: "26px 40px" }}>
							<Container fluid>
								<div className="table-main desktop-view">
									<h3>Players</h3>
									<Row>
										<Col lg={3} md={12} xs={12}>
											<InputGroup className="search-btn">
												<FormControl
													placeholder="Search"
													aria-label="Recipient's username"
													aria-describedby="basic-addon2"
													onChange={handleInputSearchChange}
												/>
												<InputGroup.Text id="basic-addon2">
													<i className="fa fa-search" aria-hidden="true"></i>
												</InputGroup.Text>
											</InputGroup>
										</Col>
										<div className="add-new">
											{/* <button className="pull-right blue-btn" type="button" onClick={() => winner()}>  Winner </button> */}
											<CSVLink data={playerCsv}>
											<Button className="blue-btn">Download Players CSV</Button>
											</CSVLink>
											<Button
												className="red-btn"
												onClick={() =>
													!showCheckBox
														? setShowCheckBox(true)
														: !mulDeleteTour[0]
														? props.dispatch(showErrorAlert("please select atleast one player"))
														: props.dispatch(
																showWarningAlert(
																	"Do wish to delete this Tournament?",
																	undefined,
																	"delete-action",
																	playerDel,
																	"Tournaments"
																)
														  )
												}>
												{!showCheckBox ? "MULTIPLE DELETE " : "DELETE "}
												<i className="fa fa-trash" aria-hidden="true"></i>
											</Button>
										
										</div>
										<Col>
											<Dropdown className="pull-right short-btn">
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													All Players:
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item
														href="#/action-1"
														onClick={() => {
															setPlayers(playersTable);
															setPlayerstat();
														}}>
														All
													</Dropdown.Item>
													<Dropdown.Item
														href="#/action-2"
														onClick={() => {
															setPlayers(playersTable.filter((player) => player.MakeEmployee === false));
															setPlayerstat(false);
														}}>
														Players
													</Dropdown.Item>
													<Dropdown.Item
														href="#/action-3"
														onClick={() => {
															setPlayers(playersTable.filter((player) => player.MakeEmployee === true));
															setPlayerstat(true);
														}}>
														Employee
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</Col>
									</Row>
									<Table striped hover className='admin-players-table'>
										<thead>
											<tr>
												<th>
													{showCheckBox ? (
														<><button className="red-btn"
																		onClick={() =>
																			setShowCheckBox(false)
																		}> BACK
																		</button>
															<button className="blue-btn" onClick={selectAllFun}>
																SELECT ALL
															</button>
															<button className="red-btn" onClick={() => setMulDeleteTour([])}>
																CLEAR ALL
															</button>
														</>
													) : (
														"#"
													)}
												</th>
												<th>
													<a onClick={() => sortPlayerDetails(players, "Screen_Name")}>
														<span>
															Screen Name
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerDetails(players, "name")}>
														<span>
															Name
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerDetails(players, "email")}>
														<span>
															Email
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												{/* <th><a  >
                                                    <span>
                                                        Is Employee
                                                        {isDesc ? <i className="fa fa-long-arrow-up" aria-hidden="true"></i> :
                                                            <i className="fa fa-long-arrow-down" aria-hidden="true"></i>}
                                                    </span>
                                                </a></th> */}
												<th>
													<a onClick={() => sortPlayerDetails(players, "Gender")}>
														<span>
															Gender
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerDetails(players, "Date_Of_Birth")}>
														<span>
															Date Of Birth
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerDetails(players, "created_at")}>
														<span>
															Created On
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<span>
														<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
											</tr>
										</thead>

										<tbody>
											{players?.map((player, index) => {
												return (
													<tr key={index + player.id}>
														<td>
															{showCheckBox ? (
																<Form.Check
																	type="checkbox"
																	// id={index + "checkbox1"}
																	label={""}
																	checked={mulDeleteTour.includes(player)}
																	value={player.id}
																	onChange={() => {
																		deleteMultipleFun(player);
																	}}
																/>
															) : (
																index + 1
															)}
														</td>
														<td>
															{player.Screen_Name}&nbsp;
															{player.MakeEmployee ? <span className="emp-badge">Employee</span> : ""}
														</td>
														<td>{player.name}</td>
														<td>{player.email}</td>
														{/* <td>{player.MakeEmployee ? "Yes" : "No"}</td> */}
														<td>{player.Gender}</td>
														<td>
															<Moment format="M/DD/yyyy">{player.Date_Of_Birth}</Moment>
														</td>
														<td>
															<Moment format="M/DD/yyyy, h:mm a">{player.created_at}</Moment>
														</td>
														<td>
															<Button
																onClick={() => {
																	editPlayerFun(player);
																}}>
																<i className="fa fa-pencil" aria-hidden="true"></i>
															</Button>
															<Button
																onClick={() => {
																	setMulDeleteTour([player]);
																	const name = [player.Screen_Name];
																	props.dispatch(
																		showWarningAlert(
																			"Do wish to delete this player?",
																			undefined,
																			"delete-action",
																			name,
																			"Player Name"
																		)
																	);
																}}
																style={{ marginLeft: "5px" }}>
																<i className="fa fa-trash" aria-hidden="true"></i>
															</Button>
															<Button
																onClick={() => {
																	props.dispatch(
																		showWarningAlert(
																			"Do wish to ban this player?",
																			undefined,
																			"delete-action"
																		)
																	);
																}}
																style={{ marginLeft: "5px" }}>
																Ban Player
															</Button>
															{player.MakeEmployee ? (
																""
															) : (
																<Button
																	onClick={() => {
																		setMakeEmployee(player);
																		setEmpModalShow(true);
																		// props.dispatch(showWarningAlert("Do wish to make this player an Employee?", undefined));
																	}}
																	style={{ marginLeft: "5px" }}>
																	Make Employee
																</Button>
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							</Container>
						</div>
						{/*  content closed  */}
					</div>
				</div>
			</div>
			<FooterBottom />
			<PlayerModal
				show={modalShow}
				isEdit={isEdit}
				editPlayer={editPlayer}
				onHide={() => setModalShow(false)}
				onSubmited={(data) => upsertPlayer(data)}
			/>
			<MakeEmployee
				show={empModalShow}
				onHide={() => setEmpModalShow(false)}
				makeEmpConfrim={() => makeEmpConfrimfun()}
			/>
		</div>
	);
}

const mapStateToProps = (state) => ({ deleteConfrim: state.application.deleteConfrim });
export default connect(mapStateToProps)(AdminPlayers);

function PlayerModal(props) {
	const [talentPools, setTalentPools] = useState();
	const [selectedTalentPool, setSelectedTalentPool] = useState("");

	const [form, setForm] = useState({});

	useEffect(() => {
		setErrors({});
	}, [props.show]);

	useEffect(() => {
		setForm({
			name: props.editPlayer.name,
			email: props.editPlayer.email,
			Gender: props.editPlayer.Gender,
			Screen_Name: props.editPlayer.Screen_Name,
			Date_Of_Birth: props.editPlayer.Date_Of_Birth,
			IsActive: true,
		});
		// setTalentPool(props.editTournamnet.TourTalentPoolId);
	}, [props.editPlayer]);

	const [errors, setErrors] = useState({});
	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		props.editPlayer[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { name, email, Gender, Screen_Name, Date_Of_Birth, IsActive } = form;
		const newErrors = {};

		if (!name || name === "") newErrors.name = "cannot be blank!";
		if (!email || email === "") newErrors.email = "cannot be blank!";
		if (!Gender || Gender === "") newErrors.Gender = "cannot be blank!";
		if (!Screen_Name || Screen_Name === "") newErrors.Screen_Name = "cannot be blank!";
		if (!Date_Of_Birth || Date_Of_Birth === "") newErrors.Date_Of_Birth = "cannot be blank!";
		if (!IsActive || IsActive === "") newErrors.IsActive = "cannot be blank!";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			const { name, email, Gender, Screen_Name, Date_Of_Birth, IsActive } = form;

			let data = {
				name: name,
				email: email,
				Gender: Gender,
				Screen_Name: Screen_Name,
				Date_Of_Birth: Date_Of_Birth,
				IsActive: true,
				MakeEmployee: false,
			};
			props.onHide();
			props.onSubmited(data);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">Edit Player Details</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<Form onSubmit={handleSubmit} role="form">
						<Row>
							<Col md={12}>
								<div className="create-callsheet">
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Screen Name*</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editPlayer.Screen_Name}
												type="text"
												placeholder="Screen Name"
												onChange={(e) => setField("Screen_Name", e.target.value)}
												isInvalid={!!errors.Screen_Name}
											/>
											<Form.Control.Feedback type="invalid">{errors.Screen_Name}</Form.Control.Feedback>
										</div>
									</Form.Group>

									<Row>
										<Col md={6}>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Name*</Form.Label>
												<div className="row-input">
													<Form.Control
														defaultValue={props.editPlayer.name}
														type="text"
														placeholder="Name"
														onChange={(e) => setField("name", e.target.value)}
														isInvalid={!!errors.name}
													/>
													<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Date Of Birth*</Form.Label>
												<div className="row-input">
													{/* <Form.Control defaultValue={props.editPlayer.Date_Of_Birth} type="text" placeholder="Date Of Birth"
                                                        onChange={e => setField('Date_Of_Birth', e.target.value)}
                                                        isInvalid={!!errors.Date_Of_Birth} /> */}
													<DateRangePicker
														initialSettings={{
															singleDatePicker: true,
															startDate: moment(props.editPlayer.Date_Of_Birth).format("M/DD/yyyy"),
															locale: {
																format: "M/DD/yyyy",
															},
														}}
														onCallback={(date) => {
															setField("Date_Of_Birth", date.format());
														}}>
														<input type="text" className="form-control col-4" />
													</DateRangePicker>
													<Form.Control.Feedback type="invalid">{errors.Date_Of_Birth}</Form.Control.Feedback>
												</div>
											</Form.Group>
										</Col>
									</Row>

									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Email*</Form.Label>
										<div className="row-input">
											<Form.Control
												defaultValue={props.editPlayer.email}
												type="text"
												placeholder="Email"
												onChange={(e) => setField("email", e.target.value)}
												isInvalid={!!errors.email}
											/>
											<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
										</div>
									</Form.Group>

									<Row>
										<Col md={6}>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Gender*</Form.Label>
												<div className="row-input">
													<Form.Select onChange={(e) => setField("Gender", e.target.value)}>
														<option>Select gender</option>
														<option value="male">Male</option>
														<option value="female">Female</option>
													</Form.Select>
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" controlId="formBasicPassword">
												<Form.Label>Is player active?*</Form.Label>
												<div className="row-input">
													<Form.Select onChange={(e) => setField("IsActive", e.target.value)}>
														<option>Select</option>
														<option value="true">YES</option>
														<option value="false">No</option>
													</Form.Select>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
								
							</Col>
						</Row>
						<div className="pupup-btn-row">
							<Button onClick={props.onHide} className="red-btn">
								CANCEL
							</Button>
							{props.isEdit ? (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									EDIT PLAYER
								</Button>
							) : (
								<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
									SAVE ACTOR TYPE
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function MakeEmployee(props) {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="main-popup delete-swg adventure-popup">
			<Modal.Header>
				<Modal.Title
					className="popup-heading"
					id="contained-modal-title-vcenter"
					style={{ color: "#e0a800" }}>
					<i className="fa fa-exclamation-triangle fa-1x" aria-hidden="true"></i> Make player an employee?
				</Modal.Title>
				<Button className="popup-close" onClick={() => props.onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="popup-content delete-swag-popup" style={{ borderTop: "1px solid #e0a800" }}>
					<p>Do you wish to make this player an Employee?</p>
					<div className="pupup-btn-row">
						<button className="blue-btn" onClick={() => props.makeEmpConfrim()}>
							YES
						</button>
						<button style={{ marginLeft: "5px" }} className="red-btn" onClick={() => props.onHide()}>
							NO
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}








