export const profileData = data => dispatch => {
  dispatch({
    type: "PROFILE_DATA",
    data
  });
};

export const showLoader = () => dispatch => {
  dispatch({
    type: "SHOW_LOADER"
  })
}

export const hideLoader = () => dispatch => {
  dispatch({
    type: "HIDE_LOADER"
  })
}

export const showSuccessAlert = (message) => dispatch => {
  dispatch({
    type: "SHOW_SUCCESS_ALERT",
    successMessage: message
  })
}

export const hideSuccessAlert = () => dispatch => {
  dispatch({
    type: "HIDE_SUCCESS_ALERT"
  })
}

export const showWarningAlert = (message, header, wActionType, value,heading) => dispatch => {
  dispatch({
    type: "SHOW_WARNING_ALERT",
    warningMessage: message,
    header: header,
    wActionType: wActionType,
    value : value,
    heading : heading
  })
}

export const hideWarningAlert = () => dispatch => {
  dispatch({
    type: "HIDE_WARNING_ALERT"
  })
}

export const showErrorAlert = (message, header) => dispatch => {
  dispatch({
    type: "SHOW_ERROR_ALERT",
    errorMessage: message,
    header: header
  })
}

export const hideErrorAlert = () => dispatch => {
  dispatch({
    type: "HIDE_ERROR_ALERT"
  })
}

export const setUrl = (url) => dispatch => {
  dispatch({
    type: "SET_URL",
    url: url
  })
}


export const deleteConfrim = () => dispatch => {
  dispatch({
    type: "DELETE_CONFRIM"
  })
}

export const deleteConfrimFalse = () => dispatch => {
  dispatch({
    type: "DELETE_CONFRIM_FALSE"
  })
}

export const RT_Critics_Score = (value) => dispatch => {
  dispatch({
    type: "RT_Critics_Score",
    RT_Critics_Score: value
  })
}

export const Google_User_Score = (value) => dispatch => {
  dispatch({
    type: "Google_User_Score",
    Google_User_Score: value
  })
}

export const MC_Critics_Score = (value) => dispatch => {
  dispatch({
    type: "MC_Critics_Score",
    MC_Critics_Score: value
  })
}

export const IMDb_User_Score = (value) => dispatch => {
  dispatch({
    type: "IMDb_User_Score",
    IMDb_User_Score: value
  })
}

