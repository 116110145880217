import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Container, Row, Col, Button, Modal, Nav, NavDropdown, Dropdown, Table } from "react-bootstrap";

import { hideSuccessAlert, hideWarningAlert, hideErrorAlert, deleteConfrim } from "../../common/actions/application";
import { set, valuesIn } from 'lodash';


function SuccessAlert(props) {
	
	const hide = () => {
		props.dispatch(hideSuccessAlert());
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup main-popup delete-swg">
			<Modal.Header>
				<Modal.Title
					className="popup-heading"
					id="contained-modal-title-vcenter"
					style={{ color: "#218838" }}>
					<i className="fa fa-check-circle fa-1x" aria-hidden="true"></i> &nbsp;Success
				</Modal.Title>
				<Button className="popup-close" type="button" onClick={() => hide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="popup-content delete-swag-popup" style={{ borderTop: "1px solid #218838" }}>
					<p>{props.message}</p>
					<div className="pupup-btn-row">
						<button className="blue-btn" onClick={() => hide()}>
							OK
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

const mapSuccessStateToProps = (state) => ({
	show: state.application.successAlert,
	message: state.application.successMessage,
});

function WarningAlert(props) {

	const [nameArray, setNameArray] = useState([props.delValue]);

	const hide = () => {
		props.dispatch(hideWarningAlert());
	};
	const deleteConf = () => {
		props.dispatch(deleteConfrim());
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup main-popup delete-swg">
			<Modal.Header>
				<Modal.Title
					className="popup-heading"
					id="contained-modal-title-vcenter"
					style={{ color: "#e0a800" }}>
					<i className="fa fa-exclamation-triangle fa-1x" aria-hidden="true"></i> &nbsp;
					{props.header === undefined ? "Warning" : props.header}
				</Modal.Title>
				<Button className="popup-close" onClick={() => hide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="popup-content delete-swag-popup" style={{ borderTop: "1px solid #e0a800" }}>
					{props.message === "show-cust-msg" ? (
						<div className="trm-cnd">
							<p className="right-text">
								Must include at least one Actors from all 3 Actor Types (Headiner, Co-Star, Dayplayer)
							</p>
							<p>Your Call Sheet is incomplete. Please draft 10 Actors - no more. no less</p>
							<p>Must have at least one Actor from 2 different Titles</p>
							<p>You must enter both Highest RT Critics Score & Highest IMDb User Score</p>
						</div>
					) : (
						<div>
							<p>{props.message}</p>
							{props.delValue !== undefined ? (
								<div className="table-main desktop-view">
									<Table striped hover>
										<thead>
											<tr>
												<th>#</th>
												<th>{props.heading}</th>
											</tr>
										</thead>
										<tbody>
											{props.delValue.map((value, index) => {
												return (
													<tr>
														<td>{index + 1}</td>
														<td>{value}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							) : null}
						</div>
					)}
					<div className="pupup-btn-row">
						{props.wActionType === "delete-action" ? (
							<>
								<button className="blue-btn" onClick={() => deleteConf()}>
									YES
								</button>
								<button style={{ marginLeft: "5px" }} className="red-btn" onClick={() => hide()}>
									NO
								</button>
							</>
						) : (
							<button className="blue-btn" onClick={() => hide()}>
								OK
							</button>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

const mapWarningStateToProps = (state) => ({
	show: state.application.warningAlert,
	message: state.application.warningMessage,
	header: state.application.warningHeader,
	wActionType: state.application.wActionType,
	delValue : state.application.delValue,
	heading : state.application.heading,
});

function ErrorAlert(props) {
	const hide = () => {
		props.dispatch(hideErrorAlert());
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup main-popup delete-swg">
			<Modal.Header>
				<Modal.Title className="popup-heading" id="contained-modal-title-vcenter">
					<i className="fa fa-times-circle fa-1x" aria-hidden="true"></i> &nbsp;Error!
				</Modal.Title>
				<Button className="popup-close" onClick={() => hide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="popup-content delete-swag-popup">
					{props.message != "" ? (
						<p className="p-text">{props.message}</p>
					) : (
						<p className="p-text"> Somthing went wrong!</p>
					)}
				</div>
				<div className="pupup-btn-row">
					<button className="blue-btn" onClick={() => hide()}>
						OK
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

const mapErrorStateToProps = state => ({ show: state.application.errorAlert, message: state.application.errorMessage })

export default
    {
        SuccessAlert: connect(mapSuccessStateToProps)(SuccessAlert),
        ErrorAlert: connect(mapErrorStateToProps)(ErrorAlert),
        WarningAlert: connect(mapWarningStateToProps)(WarningAlert)
    }
