import React, {
  memo,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Modal,
  Dropdown,
  Table,
} from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import HeaderBanner from "../../assets/images/HeaderBanner.svg";

import TournamentService from "../../services/tournament.service";
import ApplicationService from "../../services/application.service";
import AuthService from "../../services/auth.service";

import Moment from "react-moment";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import {
  showLoader,
  hideLoader,
  showSuccessAlert,
  showWarningAlert,
} from "../../common/actions/application";
import { showTournamentPopup } from "../../common/actions/genericPopupAction";
import { useConnectWallet } from "../../common/redux/hooks";
import { erc20ABI } from "../../common/web3/abi";
import LiveTournamentPopUp from "../genericsPopups/liveTournamentPopup";

const _ = require("lodash");

function Lobby(props) {
  const history = useHistory();
  const { web3, address } = useConnectWallet();

  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false)
  const [oneCD3D, setOneCD3D] = React.useState(100);
  const [tournaments, setTournaments] = useState([]);
  const [modalTourData, setModalTourData] = useState("")
  const [isLive, setIsLive] = useState(true);
  const [isDesc, setIsDesc] = useState(false);
  // const [tournamentDetails, setTournamentDetails] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const getTimeRemaing = (tournamentStartDate) => {
    // const future = moment(tournamentStartDate?.toString());
    // return future.endOf("day").fromNow();
    const now = moment();
    const future = moment(tournamentStartDate);
    const diff = future.diff(now);
    const diffDuration = moment.duration(diff);
    if (future > now)
      return (
        diffDuration.days() * 24 +
        diffDuration.hours() +
        "hrs " +
        diffDuration.minutes() +
        "mins"
      );
    else return "Running";
  };

  const getOneCD3D = async () => {
    const oneCD3D = await ApplicationService.getCD3D();
    setOneCD3D(oneCD3D);
  };
  useEffect(() => {
    getTournamentsList(true);
    getOneCD3D();
  }, []);

  // useEffect(() => {
  //     getAccount();
  // }, [address, web3]);

  const getAccount = async () => {
    if (address) {
      // const bal = await web3?.eth.getBalance(address)
      // .then(console.log);
      // const accounts = await web3.eth.getAccounts();
      // 0x8f711d8f7815397ace4C297E8dd3bBF338983650
      const tokenInst = new web3.eth.Contract(
        erc20ABI,
        "0x8f711d8f7815397ace4C297E8dd3bBF338983650"
      );
      const balance = await tokenInst.methods.balanceOf(address).call();
    }
  };

  const getTournamentsList = async (bool) => {
    setIsLive(bool);
    props.dispatch(showLoader());
    const response = bool
      ? await TournamentService.getTournamentsList()
      : await TournamentService.getCompletedTournamentsList();
    props.dispatch(hideLoader());
    response.data.forEach((element) => {
      element.Starting_in = getTimeRemaing(element.Starting_in);
    });
    setTournaments(response.data);
  };

  const showTournamentDetails = async (tournament) => {
    props.dispatch(showLoader());
	setShow(true);
	setModalTourData(tournament)
    const response = await TournamentService.getTournamentById(tournament.id);
    if (response) {
      response.data.Starting_in = getTimeRemaing(response.data.Starting_in);
      props.dispatch(showTournamentPopup(response.data));
      //setTournamentDetails(response.data);
    }
    props.dispatch(hideLoader());
  };

  const sortTournamentDetails = (tournaments, label) => {
    props.dispatch(showLoader());
    let orderByFlag = isDesc ? "desc" : "asc";
    let sortedtournaments;
    if (label === "Tournament_Name") {
      sortedtournaments = _.orderBy(
        tournaments,
        [(tournament) => tournament.Tournament_Name.toLowerCase().trim()],
        orderByFlag
      );
    } else sortedtournaments = _.orderBy(tournaments, label, orderByFlag);
    setTournaments(sortedtournaments);
    setIsDesc(!isDesc);
    props.dispatch(hideLoader());
  };

  const getInCD3D = (inusd) => {
    return (inusd * oneCD3D).toFixed(2);
  };

  useEffect(() => {
    let userData = AuthService.getAuth();
    if (userData) setUserDetails(userData.data);
  }, []);

  const checkForExisting = async (tour) => {
    props.dispatch(showLoader());
    let object = { userid: userDetails.id, tournamentId: tour.id };
    const response = await TournamentService.checkForExistingCallsheet(object);
    if (response && response.success) {
      var newurl = "?c=" + response.data[0].id + "&t=" + tour.id;
      history.push("/callsheet" + newurl);
      props.dispatch(hideLoader());
    } else {
      var newurl = "?c=&t=" + tour.id;
      history.push("/callsheet" + newurl);
      // props.dispatch(showWarningAlert("something went wrong"));
    }
    props.dispatch(hideLoader());
  };

  return (
    <div className="dashboard">
      <HeaderTop />
      <div className="content">
        <Container fluid>
          <div className="banner">
            <div className="content-bnr">
              <h2>
                Play the stars.
                <br />
                <strong>
                  <em>BE </em>
                </strong>
                your own star.
              </h2>
            </div>

            {/* ===== page ntf  closed ===== */}
            <img src={HeaderBanner} className="banner-img" alt="banner" />
          </div>
          {/* <LiveTournamentPopUp
		  show = {show}
		  onHide = {() => setShow(false)}
		  tournament = {modalTourData}
			/> */}
          <div className="table-main scroll-table p-r-10 desktop-view">
            <div className="page-ntf">
              <button
                className={isLive ? "active" : ""}
                onClick={() => getTournamentsList(true)}
              >
                Live{" "}
                {isLive ? (
                  <span className="ntf-no">{tournaments.length}</span>
                ) : null}
              </button>
              <button
                className={isLive ? "" : "active"}
                onClick={() => getTournamentsList(false)}
              >
                Completed{" "}
                {isLive ? null : (
                  <span className="ntf-no">{tournaments.length}</span>
                )}
              </button>
            </div>
            <Table striped hover>
              <thead>
                <tr>
                  <th>
                    <a
                      onClick={() =>
                        sortTournamentDetails(tournaments, "Tournament_Name")
                      }
                    >
                      <span>
                        Tournament Name
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>
                  <th>
                    <a
                      onClick={() =>
                        sortTournamentDetails(tournaments, "Starts")
                      }
                    >
                      <span>
                        Starts
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>
                  <th>
                    <a
                      onClick={() => sortTournamentDetails(tournaments, "fee")}
                    >
                      <span>
                        Entry fee
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>
                  <th>
                    <a
                      onClick={() =>
                        sortTournamentDetails(tournaments, "Prizes")
                      }
                    >
                      <span>
                        Prizes
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>
                  <th>
                    <a
                      onClick={() =>
                        sortTournamentDetails(tournaments, "No_Of_Players")
                      }
                    >
                      <span>
                        # of Players / Total Players
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>

                  <th>
                    <a
                      onClick={() =>
                        sortTournamentDetails(tournaments, "Starts")
                      }
                    >
                      <span>
                        Starting in
                        {isDesc ? (
                          <i
                            className="fa fa-long-arrow-up"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-long-arrow-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </a>
                  </th>
                  <th>
                    <span>
                      <i
                        className="fa fa-long-arrow-down"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tournaments.map((tournament, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a onClick={() => {showTournamentDetails(tournament) }}>
                          {tournament.Tournament_Name}
                        </a>
                        </td>
                      <td>
                        <Moment format="M/DD/yyyy, h:mm a">
                          {tournament.Starts}
                        </Moment>
                      </td>
                      <td>
                        <NumberFormat
                          value={
                            tournament.Tournament_Entry_Fee == undefined ||
                            tournament.Tournament_Entry_Fee == null
                              ? 0
                              : tournament.Tournament_Entry_Fee
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" CD3D"}
                        />{" "}
                        (
                        <NumberFormat
                          value={getInCD3D(tournament.Tournament_Entry_Fee)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        )
                      </td>
                      <td>
                        {/* {tournament.Prizes / ApplicationService.getCD3D() + " CD3D"} */}
                        <NumberFormat
                          value={
                            tournament.PrizePool == undefined ||
                            tournament.PrizePool == null
                              ? 0
                              : tournament.PrizePool
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" CD3D"}
                        />{" "}
                        (
                        <NumberFormat
                          value={getInCD3D(tournament.PrizePool)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        )
                      </td>

                      <td>
                        {tournament.No_Of_Players}/{tournament.Total_Players}
                      </td>
                      <td>{isLive ? tournament.Starting_in : "Completed"}</td>
                      {/* <td>2d 4hrs 3mins</td> */}
                      <td>
                        {tournament.Starting_in === "Running" ? (
                          <Link to={"/contestlive?t=" + tournament.id}>
                            <button>View Contest</button>
                          </Link>
                        ) : (
                          <button
                            onClick={() => checkForExisting(tournament)}
                            type="button"
                          >
                            Let’s Play
                          </button>
                        )}
                        {/* <Link
													to={"/callsheet?c=&t=" + tournament.id}
													onClick={() => localStorage.setItem("CallSheetId", null)}>
													
													<button type="button">Let’s Play</button>
												</Link> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="mobile-table">
            {tournaments.map((tournament, index) => {
              return (
                <div
                  className="table-row"
                  id={index}
                  key={index + tournament.id}
                >
                  <div className="table-cnt">
                    <div className="tbl-heading">
                      <a onClick={() => showTournamentDetails(tournament)}>
                        <p>{tournament.Tournament_Name} </p>
                      </a>
                      {/* <MyVerticallyCenteredModal
                                                    show={modalShow}
                                                    onHide={() => setModalShow(false)} tournamentDetails={tournamentDetails} /> */}
                    </div>
                    <div className="td-ctn">
                      <p>
                        <Moment format="dddd, h:mm a">
                          {tournament.Starts}
                        </Moment>
                      </p>
                      <p>{tournament.PrizePool}</p>
                      <p>
                        {tournament.No_Of_Players}/{tournament.Total_Players}
                      </p>
                    </div>
                  </div>
                  <div className="ply-btn">
                    <Button onClick={() => checkForExisting(tournament)}>
                      <i className="fa fa-play" aria-hidden="true"></i>
                    </Button>
                    {/* <Link
												to={"/callsheet?c=&t=" + tournament.id}
												onClick={() => localStorage.setItem("CallSheetId", null)}>
												<Button  onClick={() => checkForExisting(tournament)} >
													<i className="fa fa-play" aria-hidden="true"></i>
												</Button>
											</Link> */}
                  </div>
                </div>
              );
            })}
          </div>
          {/* =============  mobile table closed =============== */}
          {/*  main table closed  */}
        </Container>
      </div>
      {/*  content closed  */}

      <FooterBottom />
    </div>
  );
}
// export default Lobby;

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Lobby);
