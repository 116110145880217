import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Dropdown, InputGroup, FormControl } from "react-bootstrap";
import '../../../assets/css/main.css';
import '../css/style.css';
import { Link } from "react-router-dom";

import LoginImg from '../../../assets/images/ForgotPassword.svg'
import Logo from '../../../assets/images/logo.png'
import Video from '../../../assets/images/Camera-Icon.svg'
import Director from '../../../assets/images/DirectorChair-Icon.svg'
import DirectorClap from '../../../assets/images/DirectorClap-Icon.svg'
import Close from '../../../assets/images/Close.svg'


function ForgotPassword() {

  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const findFormErrors = () => {
    const { emailid } = form
    const newErrors = {}
    // oldpassword errors
    if (!emailid || emailid === '') newErrors.emailid = 'cannot be blank!'
    // else if (oldpassword.length > 30) newErrors.oldpassword = 'name is too long!'

    return newErrors
  }

  const handleSubmit = e => {
    e.preventDefault()
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      alert('Thank you for your feedback!')
    }
  }

  return (
		<div className="main-div forgot-pwd">
			<div className="left">
				<div className="content">
					<div className="top">
						<img src={Logo} alt="Logo" />
					</div>

					<div className="middle">
						<img src={LoginImg} alt="illustration" />
					</div>

					<div className="bottom">
						<h3>
							Play the Stars.
							<strong>
								<em>Be</em>
							</strong>
							Your Own Star
						</h3>
						<div className="links">
							<button type="button"> FAQ</button>
							<img src={Video} alt="camera" />
							<button type="button"> TERMS &amp; CONDITIONS</button>
							<img src={Director} alt="director-chair" />
							<button type="button">ABOUT US</button>
							<img src={DirectorClap} alt="Director-clap" />
							<button type="button">CONTACT US</button>
						</div>
					</div>
				</div>
			</div>
			{/* ======== left closed ======== */}
			<div className="right">
				<div className="right-box">
					<div className="close">
						<img src={Logo} alt="Logo" className="logo-mobile" />
						<Link to={"/"}>
							<img src={Close} alt="cancel" />
						</Link>
					</div>
					<div className="form-content">
						<img src={LoginImg} alt="illustration" className="mobile-banner" />

						<div className="heading">
							<h1>Forgot your Password? Bueller? Bueller?</h1>
						</div>

						<Form className="form" onSubmit={handleSubmit} role="form">
							<div className="form-g">
								{/* <label for="email">
                  <span>Email address</span>
                </label><input name="email" type="" /> */}
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<div className="row-input">
										<Form.Control
											type="email"
											placeholder="email id"
											onChange={(e) => setField("emailid", e.target.value)}
											isInvalid={!!errors.emailid}
										/>
										{/* <a   className="eye-pwd"><i className="fa fa-eye-slash" aria-hidden="true"></i></a> */}
										<Form.Control.Feedback type="invalid">{errors.emailid}</Form.Control.Feedback>
									</div>
								</Form.Group>
							</div>

							<button className="login">
								<span>Send Reset Link</span>
							</button>

							<div className="new-player">
								<button>
									<span> Remember Password? </span>
								</button>
								<Link to="/login">
									<button className="signup-go">
										<span>Login</span>
									</button>
								</Link>
							</div>
						</Form>

						<div className="bottom mobile-view-footer">
							<div className="links">
								<button type="button"> FAQ</button>
								<img src={Video} alt="camera" />
								<button type="button"> Terms &amp; Conditions</button>
								<img src={Director} alt="director-chair" />
								<button type="button">About US</button>
								<img src={DirectorClap} alt="Director-clap" />
								<button type="button">Contact US</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ========== right closed ============= */}
		</div>
	);
}


export default ForgotPassword;


