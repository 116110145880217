const initialState = {
    data: {},
    loading: false,
    successAlert: false,
    successMessage: "",
    warningAlert: false,
    warningMessage: "",
    warningHeader: "",
    wActionType: "",
    errorAlert: false,
    errorMessage: "",
    errorHeader: "",
    setSelectedMenuUrl: "",
    deleteConfrim: false,
    RT_Critics_Score: 0,
    Google_User_Score: 0,
    MC_Critics_Score: 0,
    IMDb_User_Score: 0,
    delValue: [], 
    heading: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "PROFILE_DATA":
            return {
                ...state,
                data: { ...action.data }
            };

        case "SHOW_LOADER":
            return { ...state, loading: true };

        case "HIDE_LOADER":
            return { ...state, loading: false };

        case "SHOW_SUCCESS_ALERT":
            return { ...state, successAlert: true, successMessage: action.successMessage };

        case "HIDE_SUCCESS_ALERT":
            return { ...state, successAlert: false };

        case "SHOW_WARNING_ALERT":
            return {
                ...state, warningAlert: true,
                warningMessage: action.warningMessage, warningHeader: action.header,
                wActionType: action.wActionType,
                delValue : action.value,
                heading : action.heading
            };

        case "HIDE_WARNING_ALERT":
            return { ...state, warningAlert: false };

        case "SHOW_ERROR_ALERT":
            return { ...state, errorAlert: true, errorMessage: action.errorMessage, errorHeader: action.header };

        case "HIDE_ERROR_ALERT":
            return { ...state, errorAlert: false };

        case "SET_URL":
            return { ...state, setSelectedMenuUrl: action.url };

        case "DELETE_CONFRIM":
            return { ...state, deleteConfrim: true };


        case "DELETE_CONFRIM_FALSE":
            return { ...state, deleteConfrim: false };

        case "RT_Critics_Score":
            return { ...state, RT_Critics_Score: action.RT_Critics_Score };

        case "Google_User_Score":
            return { ...state, Google_User_Score: action.Google_User_Score };

        case "MC_Critics_Score":
            return { ...state, MC_Critics_Score: action.MC_Critics_Score };

        case "IMDb_User_Score":
            return { ...state, IMDb_User_Score: action.IMDb_User_Score };

        default:
            return state;
    }
};

