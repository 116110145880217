import React, { Component, useEffect, useState, useReducer } from 'react';
import { Container, Row, Col, Button, Nav, Modal, Dropdown, Table, Form } from "react-bootstrap";
import '../../assets/css/main.css';
import AdminHeaderTop from '../../components/adminHeader/index';
import FooterBottom from '../../components/footer/index';
import HeaderBanner from '../../assets/images/HeaderBanner.svg'

import TournamentService from '../../services/tournament.service'
import UserService from '../../services/user.service'

import Moment from 'react-moment';
import moment from 'moment';
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';

import { showLoader, hideLoader, showSuccessAlert, showWarningAlert, showErrorAlert, hideWarningAlert } from "../../common/actions/application";
import { showTournamentPopup } from "../../common/actions/genericPopupAction";
import { useConnectWallet } from '../../common/redux/hooks';
import { createNewTournament, getAllTournaments } from '../../common/web3/api/fetchTournaments';
import { tournamentfactoryContract } from '../../common/web3/contract'

import AdminSideNav from '../../components/adminSidebar/index';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import TourTalentPoolService from '../../services/tourtalentpool.service'
import TournamentGraph from "./tournamentGraph"
import TournamentOverlayGraph from "./tourOverlayGraph"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";

import StatsService from "../../services/stats.service";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Call sheet Statistics",
		},
	},
};

function AdminStatsDashboard(props) {
	const [callsheets, setCallsheets] = useState([]);
	const [globalData, setGlobalData] = useState({
		contestsEntered: 0,
		contestsCompleted: 0,
		contestsWithdrawn: 0,
	});
	const [labels, setLabelArr] = useState([]);

	useEffect(() => {
		StatsService.getGlobalStats().then(async (resp) => {
			if (resp) {
				setGlobalData(resp);
			}
		});
	}, [0]);
	useEffect(() => {
		StatsService.getCallSheetStats().then(async (resp) => {
			if (resp) {
				setCallsheets(resp);
				setLabelArr(resp.lables);
			}
		});
	}, [0]);

	let data = {
		labels,
		datasets: [
			{
				label: "Entered",
				data: callsheets.Entered,
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Completed",
				data: callsheets.Completed,
				backgroundColor: "rgb(75, 192, 192)",
			},
			{
				label: "Withdrawn",
				data: callsheets.Withdrawn,
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};

	return (
		<div className="dashboard">
			<AdminHeaderTop />

			<div className="d-flex flex-wrap">
				<Col className="left-side-nav">
					<AdminSideNav placement="start" name="start" />
				</Col>

				<div className="right-side-content">
					<div className="content">
						<Container fluid>
							<div className="table-main p-r-10 desktop-view">
								<h3>Admin Statistics</h3>
								<div className="add-new"></div>
								<Row>
									<Col lg={6}>
										<h4> Call sheet Statistics</h4>
										<Bar options={options} data={data} />
									</Col>
									<Col lg={6}>
										<TournamentGraph />
									</Col>
								</Row>
								<br />
								<Row>
									<Col lg={6}>
										<TournamentOverlayGraph />
									</Col>
									<Col lg={6}>
										<div className="table-main p-r-10 desktop-view">
											<h4>Global Statistics</h4>
											<Table striped hover>
												<tbody>
													<tr>
														<td>Contests Entered</td>
														<td>{globalData.contestsEntered}</td>
													</tr>
													<tr>
														<td>Contests Completed</td>
														<td>{globalData.contestsCompleted}</td>
													</tr>
													<tr>
														<td>Contests Withdrawn</td>
														<td>{globalData.contestsWithdrawn}</td>
													</tr>
													<tr>
														<td>Total Session Time</td>
														<td>1000</td>
													</tr>
													<tr>
														<td>Average Session Time</td>
														<td>1000</td>
													</tr>
													<tr>
														<td>Total Contest Entrants</td>
														<td>1000</td>
													</tr>
													<tr>
														<td>Total Contest Prizes</td>
														<td>1000</td>
													</tr>
													<tr>
														<td>Total Entrants</td>
														<td>1000</td>
													</tr>
													<tr>
														<td>Total Prizes Awarded</td>
														<td>1000</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
					{/*  content closed  */}
				</div>
			</div>
			<FooterBottom />
		</div>
	);
}
// export default Lobby;

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(AdminStatsDashboard)






