import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormControl, InputGroup,Table } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import Callsheet from '../../assets/images/callsheet.svg';
import Netflix from '../../assets/images/Netflix-Logo.svg';
import { Link, useLocation } from "react-router-dom";
import ScoreService from '../../services/score.service'
import Moment from 'react-moment';
import { showLoader, hideLoader } from "../../common/actions/application";
import { connect } from 'react-redux';
import CallSheetService from '../../services/callsheet.service'
import TournamentService from '../../services/tournament.service'
import NumberFormat from "react-number-format";
import Costar from '../../assets/images/Co-Star-Icon.svg';
import {showMoviePopup, showActorPopup } from "../../common/actions/genericPopupAction";
import defaultIcon from "../../assets/images/defaultIcon.jpeg"

const _ = require("lodash");
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ContestResult(props) {

  let query = useQuery();
  const [selectedCallSheet, setSelectedCallSheet] = useState({});
  const [callSheet, setCallSheet] = useState([]);
  const [callSheetActors, setCallSheetActors] = useState([]);
  const [tournamentDetails, setTournamentDetails] = useState([]);
  const [tournamentResults, setTournamentResults] = useState([]);
  const [isCallSheetSelected, setIsCallSheetSelected] = useState(false);
  const [allTournamentResults, setAllTournamentResults] = useState([]);
  const [isDesc, setIsDesc] = useState(false);

  useEffect(() => {
    getCallSheetById();
  }, []);

  const getCallSheetById = async () => {
    props.dispatch(showLoader());
    await CallSheetService.getCallSheetById(query.get("c")).then((resp) => {
      if (resp) {
        setCallSheetActors(resp.data.ActorsList);
        setCallSheet(resp.data);
        props.dispatch(hideLoader());
        getTournamentDetails(resp.data.TournamentId)
        getTournamentResultList(resp.data.TournamentId);
      }
    });
  }

  const getTournamentDetails = async (TournamentId) => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentById(TournamentId);
    if (response) {
      let data = response.data;
      setTournamentDetails(data);
    }
    props.dispatch(hideLoader());
  }

  const getTournamentResultList = async (TournamentId) => {
    props.dispatch(showLoader());
    let inputParams = { tournamentId: TournamentId };
    const response = await CallSheetService.getTournamentResult(inputParams);
    if (response) {
      let data = response.data;
      setAllTournamentResults(data)
      setTournamentResults(data);

    }
    props.dispatch(hideLoader());
  }

  const getSelectedCallSheet = async (callSheetId) => {
    props.dispatch(showLoader());
    await CallSheetService.getCallSheetById(callSheetId).then((resp) => {
      if (resp) {
        setSelectedCallSheet(resp.data);
        props.dispatch(hideLoader());
      }
    });
  }

  const showScoreDetails = async (actor) => {
    props.dispatch(showLoader());
    const response = await ScoreService.getScoreById(actor.ScoreId);
    if (response) {
      props.dispatch(showMoviePopup(response.data));
    }
    props.dispatch(hideLoader());
  }

  const getFilteredPlayers = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		allTournamentResults.filter((element) => {
			let tempFilter = (element.PlayerName.toLowerCase().includes(serachParam.toLowerCase()));
			if (tempFilter) {
				newArray.push(element);
			}
		})
		setTournamentResults(newArray);
		props.dispatch(hideLoader());
};
  
  const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getFilteredPlayers(searchString);
	};

  const sortPlayerTable = (tableName , label) =>{
	props.dispatch(showLoader());
	let orderByFlag = isDesc ? "desc" : "asc";
	let sortedplayers;
	if (label === "PlayerName"){
		sortedplayers = _.orderBy(tableName,[(table) => table.PlayerName],orderByFlag)
	}
	else{
		sortedplayers = _.orderBy(tableName, label, orderByFlag);
	}
	setTournamentResults(sortedplayers)
	setIsDesc(!isDesc);
	props.dispatch(hideLoader());
  }

  return (
		<div className="mycontestscmp">
			<HeaderTop />

			<div className="border-nav">
				<Container fluid>
					<div className="contest-row">
						<div className={isCallSheetSelected ? "contest-left" : ""}>
							<div className="page-ntf">
								<Link to={"/mycontests"}>
									<button>
										Upcoming <span className="ntf-no">4</span>
									</button>
								</Link>
								<Link to={"/live"}>
									<button>Live </button>
								</Link>
								<Link to={"/completed"}>
									<button className="active">Completed</button>
								</Link>
							</div>
							{/* ===== page notification closed ===== */}
							<Row>
								<Col md={12}>
									<h3 className="heading-wh">Some you win, some you learn.</h3>
								</Col>
							</Row>
							<div className="complete-row">
								<div className="left-heading">
									<h2>
										{tournamentDetails?.Tournament_Name} {callSheet.PlayerName}
									</h2>
									<p>
										<Moment format="dddd, h:mm a">{tournamentDetails?.Starts}</Moment>
									</p>
								</div>
								<div className="up-contest-content">
									<div className="up-cont-box">
										<p>Prizes</p>
										<span>
											<NumberFormat
												value={tournamentDetails?.PrizePool}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</span>
									</div>
									{/*up cnt box closed */}

									<div className="up-cont-box">
										<p># of Players / Total Players
</p>
										<span>
											{tournamentDetails?.No_Of_Players}/{tournamentDetails?.Total_Players}
										</span>
									</div>
									{/*up cnt box closed */}

									<div className="up-cont-box">
										<p>Score</p>
										<span>{callSheet.CallSheetTotal}</span>
									</div>
									{/*up cnt box closed */}

									<div className="up-cont-box">
										<p>Rank</p>
										<span>{callSheet.PlayerRank}</span>
									</div>
									{/*up cnt box closed */}

									<div className="up-cont-box">
										<p>Winnings</p>
										<span>$0.00</span>
									</div>
									{/*up cnt box closed */}

									<div className="up-cont-box">
										<Link to={"/completed"}>
											<button className="blue-btn">VIEW ALL COMPLETED CONTESTS</button>
										</Link>
									</div>
									{/*up cnt box closed */}
								</div>
							</div>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="smash-card-row">
								{callSheetActors.map((actor, index) => {
									return (
										<>
											<div className="col-card">
												<div className="card-box">
													<div className="card-images">
														{actor.profile_path ? (
															<img
																src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																className="user"
															/>
														) : (
															<img src={defaultIcon} className="user" />
														)}
													</div>
													<div className="content-card">
														<div className="heading-icn">
															{actor.Actor_Type == "Co-Star" ? (
																<img src={Costar} className="anchor-img" />
															) : actor.Actor_Type == "Headliner" ? (
																<img src={HeadingIcn} className="anchor-img" />
															) : (
																<span className="cust-dayplayer"> Dayplayer</span>
															)}
														</div>
														<h4
															className="card-name"
															onClick={() => props.dispatch(showActorPopup(actor, callSheet))}>
															{actor.Actor}
														</h4>
														<p onClick={() => showScoreDetails(actor)}>{actor.Title}</p>
														<p className="card-rate">
															<NumberFormat
																value={actor.Salery}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</p>
													</div>
												</div>
											</div>

											{/* card closed */}
										</>
									);
								})}
							</div>
							{/* ========= card row closed ============= */}
							<div className="background-box">
								<Row>
									<Col lg={8} md={7}>
										<h3>Results</h3>
									</Col>
									<Col lg={4} md={5}>
										<InputGroup className="search-btn">
											<FormControl
												placeholder="Search Players"
												aria-label="Recipient's username"
												aria-describedby="basic-addon2"
												onChange={handleInputSearchChange}
											/>
											<InputGroup.Text id="basic-addon2">
												<i className="fa fa-search" aria-hidden="true"></i>
											</InputGroup.Text>
										</InputGroup>
									</Col>
								</Row>

								<div className="table-main scroll-table p-r-10 completed-table cls-sheet-tbl ">
									<Table striped hover>
										<thead>
											<tr>
												<th>Rank</th>
												<th>
													<a onClick={() => sortPlayerTable(tournamentResults, "PlayerName")}>
														<span>
															Player Name
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerTable(tournamentResults, "TotalSalary")}>
														<span>
															Budget
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerTable(tournamentResults, "Score")}>
														<span>
															Score
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortPlayerTable(tournamentResults, "Winnings")}>
														<span>
															Winnings
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{/* <tr>
                    <td>1</td>
                    <td>SwagOfficial</td>
                    <td>$99,300</td>
                    <td>1401.93</td>
                    <td>$3.55</td>
                    <td><button>VIEW CALL SHEET</button></td>
                  </tr> */}
											{tournamentResults.map((participant, index) => {
												return (
													<>
														<tr className={selectedCallSheet.id === participant.id ? "green-bg" : ""}>
															<td>{index + 1}</td>
															<td>{participant?.PlayerName}</td>
															<td>
																<NumberFormat
																	value={participant?.TotalSalary}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"$"}
																/>
															</td>
															<td>1401.93</td>
															<td>$3.55</td>
															<td>
																{/* <button
																	type="button"
																	onClick={() => {
																		setIsCallSheetSelected(!isCallSheetSelected);
																		getSelectedCallSheet(participant.id);
																	}}>
																	VIEW CALL SHEET
																</button> */}
																<Link
																	to={"/view-result-callsheet?c=" + query.get("c") + "&c1=" + participant.id}>
																	<button type="button">VIEW CALL SHEET</button>
																</Link>
															</td>
														</tr>
													</>
												);
											})}
										</tbody>
									</Table>
								</div>
								{/*  main table closed  */}
							</div>
						</div>
						{isCallSheetSelected ? (
							<div className="contest-right">
								<Row className="upcoming-contest-row">
									<Col md={8}>
										<div className="callsheet-img-row">
											<img src={Callsheet} />
											<div className="ctn-cls">
												<h2>Call Sheet</h2>
												{/* <p>{user.name}</p> */}
											</div>
										</div>
									</Col>
									<Col md={4}>
										<div className="contest-content-right">
											<button
												className="red-cls-btn"
												onClick={() => {
													setIsCallSheetSelected(!isCallSheetSelected);
													setSelectedCallSheet({});
												}}>
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</div>
									</Col>
								</Row>
								{/* ==== upcoming-contest-row closed  ========  */}
								<Row className="player-left">
									<Col md={12}>
										<div className="contest-content-right">
											<div className="up-cont-box">
												<p>Rank</p>
												<span>{selectedCallSheet.PlayerRank}</span>
											</div>
											{/*up cnt box closed */}
											<div className="up-cont-box">
												<p>Budget</p>
												<span>
													<NumberFormat
														value={selectedCallSheet.TotalSalary}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
													/>
												</span>
											</div>
											{/*up cnt box closed */}
											<div className="up-cont-box">
												<p>Winnings</p>
												<span>$120</span>
											</div>
											{/*up cnt box closed */}
										</div>
									</Col>
								</Row>
								{/* ==== upcoming-contest-row closed  ========  */}
								<div className="table-main scroll-table p-r-10  contest-table">
									<Table striped hover>
										<thead>
											<tr>
												<th></th>
												{/* <th></th> */}
												<th>
													<span>
														Actor Type <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
												<th>
													<span>
														Title <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
												<th>
													<span>
														Platform <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
												<th>
													<span>
														Salary <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{selectedCallSheet?.ActorsList?.map((actor, index) => {
												return (
													<tr key={index}>
														<td>
															<img
																src={
																	actor.profile_path
																		? "https://image.tmdb.org/t/p/original" + actor.profile_path
																		: User
																}
																className="user"
															/>
														</td>
														<td>
															{actor.Actor_Type == "Co-Star" ? (
																<img src={Costar} className="anchor-img" />
															) : actor.Actor_Type == "Headliner" ? (
																<img src={HeadingIcn} className="anchor-img" />
															) : (
																<span className="cust-dayplayer"> Dayplayer</span>
															)}
														</td>
														<td onClick={() => showScoreDetails(actor)}>{actor.Title}</td>
														<td>
															{actor.Platform == "Netflix" ? (
																<img src={Netflix} className="cmp-logo" />
															) : (
																actor.Platform
															)}
														</td>
														<td>
															<NumberFormat
																value={actor?.Salery}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
								{/*  main table closed  */}
								<Row className="player-left">
									<Col md={12}>
										<div className="contest-content-right">
											<div className="up-cont-box">
												<p>Prizes</p>
												<span>
													<NumberFormat
														value={tournamentDetails?.PrizePool}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
													/>
												</span>
											</div>
											<div className="up-cont-box">
												<p># of Players / Total Players
</p>
												<span>
													{tournamentDetails?.No_Of_Players}/{tournamentDetails?.Total_Players}
												</span>
											</div>
										</div>
									</Col>
								</Row>
								{/* ==== upcoming-contest-row closed  ========  */}
								<div className="callsheet-submit view-cls">
									<Link to={"/completed"}>
										<button className="blue-btn">BACK TO MY COMPLETED CONTESTS</button>
									</Link>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);

}


const mapStateToProps = state => ({})
export default connect(mapStateToProps)(ContestResult)


