import axios, { Axios } from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;
// const CD3D = process.env.REACT_APP_CD3D;

class ApplicationService {
	getCD3D = async () => {
		return await axios
			.get(API_URL + "look-up-data/cd3d-price")
			.then((response) => {
				if (response.status == 200) {
					let closingValue = response.data.data[0].close.toFixed(4);
					return parseFloat(closingValue);
				} else {
					alert("something went wrong");
				}
			})
			.catch((error) => {
				console.log(error);
				alert("something went wrong");
			});
	};
}

export default new ApplicationService();
