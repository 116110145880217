import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;

class StatsService {
	getCallSheetStats = async () => {
		return await axios.get(API_URL + "global-callsheet-stats").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	getTournamentStats = async () => {
		return await axios.get(API_URL + "global-tournament-stats").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("somthing went wrong");
			}
		});
	};

	getGlobalStats = async () => {
		return await axios.get(API_URL + "global-stats").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getOverlayStats = async () => {
		return await axios.get(API_URL + "global-overlays").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new StatsService();
