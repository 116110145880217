import React from 'react';
import { Row, Col, Button, Modal } from "react-bootstrap";
import { connect } from 'react-redux';
import HeadingIcn from '../../../assets/images/Space-Headline-Icon.svg';
import User from '../../../assets/images/user.jpg';
import AdvImag from '../../../assets/images/adventure-time.png';
import Netflix from '../../../assets/images/Netflix-Logo.svg';
import Costar from '../../../assets/images/Co-Star-Icon.svg';
import NumberFormat from "react-number-format";
import { hideMoviePopup } from "../../../common/actions/genericPopupAction";
import Moment from "react-moment";
import "../../../assets/css/main.css";
import { showActorPopup } from "../../../common/actions/genericPopupAction";
import DefaultTitleImg from "../../../assets/images/default_title_pic.jpeg"
import { size } from 'lodash';
import { addActor } from "../../../common/actions/genericPopupAction";
import defaultIcon from "../../../assets/images/defaultIcon.jpeg";

function MovieDeatilsPopup(props) {
    const onHide = () => {
        props.dispatch(hideMoviePopup());
    }
    return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="adventure-popup">
				<Button className="popup-close" onClick={() => onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
				<Modal.Body style={{ height: "auto" }}>
					<div className="adv-pop-row">
						<div
							className="banner-adv2"
							style={{
								background: props.scoreDetails.TitleImg
									? "url(" + props.scoreDetails.TitleImg + ")"
									: "url(" + DefaultTitleImg + ")",
								backgroundSize: "cover",
							}}>
							<div className="banner-adv-box">
								<div className="content-adv">
									<h2>{props.scoreDetails.Title}</h2>
									<Row>
										<Col md={6} className="rt-critics">
											<h5>RT Critics</h5>
											<p>{props.scoreDetails.RT_Critics_Score}</p>
										</Col>

										<Col md={6} className="rt-critics">
											<h5>Google User Score</h5>
											<p>{props.scoreDetails.Google_User_Score}</p>
										</Col>

										<Col md={6} className="rt-critics">
											<h5>MC Critics Score</h5>
											<p>{props.scoreDetails.MC_Critics_Score}</p>
										</Col>

										<Col md={6} className="rt-critics">
											<h5>IMDb User Score</h5>
											<p>{props.scoreDetails.IMDb_User_Score}</p>
										</Col>
									</Row>
								</div>
							</div>
						</div>
						{/* banner-adv  */}
						<div className="right-advent">
							<div className="movie-type-row">
								<div className="movie-type-box">
									<h5>Type</h5>
									<p>{props.scoreDetails.Type}</p>
								</div>

								<div className="movie-type-box">
									<h5>Platform</h5>
									<p>
										{props.scoreDetails.Platform == "Netflix" ? (
											<img src={Netflix} className="cmp-logo" />
										) : (
											props.scoreDetails.Platform
										)}
									</p>
								</div>

								<div className="movie-type-box">
									<h5>Release Date</h5>
									<p>
										<Moment format="MMM DD, yyyy">{props.scoreDetails.Release_Date}</Moment>
									</p>
								</div>

								<div className="movie-type-box">
									<h5>Genre</h5>
									<p>{props.scoreDetails.Genre}</p>
								</div>
							</div>
							{/* movie type row closed */}
							<div className="scroll-table">
								<div className="overview">
									<h4>Overview</h4>
									<p>
										{props.scoreDetails.Overview == undefined
											? "No Overview Provided"
											: props.scoreDetails.Overview}
									</p>
								</div>
								<h4>Cast</h4>
								<div className="smash-card-row user-card">
									{/* <div className="col-card">
                    <div className="card-box">
                      <div className="card-images">
                        <img src={User} />
                      </div>
                      <div className="content-card">
                        <div className="heading-icn"><img src={HeadingIcn} /></div>
                        <h4 className="card-name"><a   >Chad Robbins</a></h4>
                        <p className="card-rate">$12,000</p>
                        <button className="dark-button">ADD TO CALL SHEET</button>
                      </div>
                    </div>
                  </div> */}
									{/* card closed */}

									{props?.scoreDetails?.ActorsList?.map((actor, index) => {
										return (
											<div className="col-card">
												<div className="card-box">
													<div className="card-images">
														{actor.profile_path ? (
															<img
																src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																className="user"
															/>
														) : (
															<img src={defaultIcon} className="user" />
														)}
													</div>
													<div className="content-card">
														<div className="heading-icn">
															{actor.Actor_Type == "Co-Star" ? (
																<img src={Costar} className="anchor-img" />
															) : actor.Actor_Type == "Headliner" ? (
																<img src={HeadingIcn} className="anchor-img" />
															) : (
																<span className="cust-dayplayer"> Dayplayer</span>
															)}
														</div>
														<h4
															className="card-name"
															onClick={() => props.dispatch(showActorPopup(actor, {}))}>
															<a>{actor.Actor}</a>
														</h4>
														<p className="card-rate">
															<NumberFormat
																value={actor.Salery}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</p>
														<button
															className="dark-button"
															onClick={() => {
																props.dispatch(addActor(actor));
															}}>
															ADD TO CALL SHEET
														</button>
													</div>
												</div>
											</div>
										);
									})}
								</div>
								{/* smash-card-row   */}
							</div>
						</div>
						{/* right-advn closed  */}
					</div>
					{/* ============  adv pop row closed ===========  */}
				</Modal.Body>
			</Modal>
		);
}


const mapStateToProps = state => ({ show: state.genericsPopup.moviePopupShow, scoreDetails: state.genericsPopup.movieData });
export default connect(mapStateToProps)(MovieDeatilsPopup)