import React, { memo, useCallback, useRef, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Navbar, Nav, NavDropdown, Dropdown, Table } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

import '../../assets/css/main.css';
import './style.css';

import AuthService from "../../services/auth.service";

import Logo from '../../assets/images/logo.png'
import User from '../../assets/images/user.jpg'
import AvtBorder from '../../assets/images/avtar-border.png'


import { connect } from 'react-redux';
import { createWeb3Modal } from '../../components/web3modal/createWeb3Modal';
import { useConnectWallet, useDisconnectWallet } from '../../common/redux/hooks';
// import Avatar from '@material-ui/core/Avatar';
import { setUrl } from "../../common/actions/application";
import { fetchBalance } from '../../common/web3/api/balance';
import { cd3dPools } from '../../common/web3/cd3d'


function AdminHeaderTop(props) {
  const history = useHistory();
  const [isUserLogged, setIsUserLogged] = useState(false);

  const { connectWallet, web3, address, networkId, connected } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);
  const [shortAddress, setShortAddress] = useState('');
  const [cd3dBalance, setCd3dBalance] = useState(0);


  useEffect(() => {
    if (AuthService.getAuth() != undefined)
      setIsUserLogged(true);
  });

  const connectWalletCallback = useCallback(() => {
    connectWallet(web3Modal);
  }, [web3Modal, connectWallet]);

  const disconnectWalletCallback = useCallback(() => {
    disconnectWallet(web3, web3Modal);
  }, [web3, web3Modal, disconnectWallet]);

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/home")
      setModal(createWeb3Modal());
  }, [setModal]);

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet]);

  useEffect(() => {
    if (!connected) {
      return;
    }

    // const canvas = canvasRef.current;
    // renderIcon({ seed: address.toLowerCase() }, canvas);
    // const updatedDataUrl = canvas.toDataURL();
    // if (updatedDataUrl !== dataUrl) {
    //   setDataUrl(updatedDataUrl);
    // }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [address, connected]);

  useEffect(async () => {
    if (address) {
      let tokenAddress = cd3dPools[0].tokenAddress;
      await fetchBalance({ web3, address, tokenAddress }).then((balance) => {
        let gweibalance = web3.utils.fromWei(String(balance), 'gwei')
        setCd3dBalance(gweibalance);
      })
    }
  }, [address, setCd3dBalance]);

  const logout = () => {
    AuthService.logout();
    setIsUserLogged(false);
    AuthService.setUrl('/home');
    history.push('/login');
  }

  const settings = () => {
    history.push('/settings');
  }

  const faq = () => {
    history.push('/faq');
  }

  const tnc = () => {
    history.push('/tnc');
  }

  const profile = () => {
    history.push('/profile');
  }

  const setSelectedMenu = (selectedKey) => {
    // props.dispatch(setUrl(selectedKey));
    AuthService.setUrl(selectedKey);
  }

  const [userPic, setUserPic] = useState(window.localStorage.getItem("profile_photo"));
  return (
		<header>
			<Container fluid className="header amdin-header">
				<div className="left-header">
					<div className="logo">
						<Link to={"/"}>
							<img src={Logo} alt='logo image' />
						</Link>
					</div>
					<Nav
						activeKey={AuthService.getUrl() === null ? "/home" : AuthService.getUrl()}
						onSelect={(selectedKey) => setSelectedMenu(selectedKey)}>
						{/* <Nav.Item>
              <Nav.Link href="/home">Tour </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/mycontests" href="/mycontests">Movies </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/mycallsheet" href="/mycallsheet">Actors </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/scores" href="/scores">  Callsheets   </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="/scores" href="/scores">  Players    </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="/scores" href="/scores">  Settings     </Nav.Link>
            </Nav.Item> */}
					</Nav>
				</div>
				{/*  left header closed  */}
				<div className="header-right">
					{!isUserLogged ? (
						<>
							<Link to="/login">
								<button className="blue-btn">Login</button>
							</Link>
							&nbsp;
							<Link to="/register">
								<button className="red-btn">Register</button>
							</Link>
						</>
					) : (
						<>
							<Link to="/">
								<button className="bln-btn">Main Site</button>
							</Link>
							<button className="bln-btn">
								Balance : <span>{cd3dBalance} CD3D</span>
							</button>

							<button
								className="bln-btn"
								onClick={connected ? disconnectWalletCallback : connectWalletCallback}>
								{connected ? (
									<>
										{/* <canvas ref={canvasRef} style={{ display: 'none' }} />
                                        <Avatar
                                            alt="address"
                                            src={dataUrl}
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginRight: '4px',
                                            }}
                                        /> */}
										{shortAddress}
									</>
								) : (
									<>Wallet</>
								)}
							</button>
							<Dropdown className="header-notification">
								<Dropdown.Toggle variant="success" id="dropdown-basic">
									<i className="fa fa-bell" aria-hidden="true"></i>
									<span className="badge">4</span>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<div className="ntf-box">
										<div className="ntf-heading">
											<h3>Notification</h3>
											<div className="dot">
												<span></span>
												<span></span>
												<span></span>
											</div>
										</div>

										<div className="ntf-row ntf-border ">
											<h6>New</h6>
											<div className="ntf-item">
												<div className="ntf-item-left">
													<p>
														congratulations Lorem Ipsum is simply dummy text of the printing and typesetting
														industry.
													</p>
													<span> A few scond ago</span>
												</div>
											</div>
											{/* item closed */}
											<div className="ntf-item">
												<div className="ntf-item-left">
													<p>
														congratulations Lorem Ipsum is simply dummy text of the printing and typesetting
														industry.
													</p>
													<span> A few scond ago</span>
												</div>
											</div>
											{/* item closed */}
										</div>

										<div className="ntf-row">
											<h6>Earlier</h6>
											<div className="ntf-item">
												<div className="ntf-item-left">
													<p>
														congratulations Lorem Ipsum is simply dummy text of the printing and typesetting
														industry.
													</p>
													<span> A few scond ago</span>
												</div>
											</div>
											{/* item closed */}
											<div className="ntf-item">
												<div className="ntf-item-left">
													<p>
														congratulations Lorem Ipsum is simply dummy text of the printing and typesetting
														industry.
													</p>
													<span> A few scond ago</span>
												</div>
											</div>
											{/* item closed */}
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							{/* notification dropdown closed */}

							<Dropdown className="header-user">
								<Dropdown.Toggle variant="success" id="dropdown-basic">
									<div className="avtar">
										<div className="avtar-border" style={{ background: "url(" + AvtBorder + ")" }}></div>
										<img src={isUserLogged ? userPic : User} className="avar-image" alt="user" />
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<div className="user-detail-box" onClick={profile}>
										<div className="user-img-row">
											<div className="user-img">
												<div className="avtar-border" style={{ background: "url(" + AvtBorder + ")" }}></div>
												<img src={isUserLogged ? userPic : User} className="avar-image" alt="user" />
											</div>
										</div>
										<div className="user-detail">
											<h4>Swag Offical </h4>
											<p className="user-blnc">Balance:$32.20</p>
											<p className="see-profile">See your Profile</p>
										</div>
									</div>

									<div className="profile-stn" onClick={settings}>
										<div className="link-user">
											<Dropdown.Item href="#/action-1">
												<span className="d-flex align-items-center">
													<span className="link-user-icn">
														<i className="fa fa-cog" aria-hidden="true"></i>
													</span>
													Setting
												</span>
												<span className="link-user-arrow">
													<i className="fa fa-arrow-right" aria-hidden="true"></i>
												</span>
											</Dropdown.Item>
										</div>

										<div className="link-user" onClick={faq}>
											<Dropdown.Item href="#/action-1">
												<span className="d-flex align-items-center">
													<span className="link-user-icn">
														<i className="fa fa-question-circle" aria-hidden="true"></i>
													</span>
													Support & FAQ
												</span>
												<span className="link-user-arrow">
													<i className="fa fa-arrow-right" aria-hidden="true"></i>
												</span>
											</Dropdown.Item>
										</div>

										<div className="link-user" onClick={tnc}>
											<Dropdown.Item href="#/action-1">
												<span className="d-flex align-items-center">
													<span className="link-user-icn astrick-dp">
														<i className="fa fa-asterisk" aria-hidden="true"></i>
													</span>
													Terms & Conditions
												</span>
												<span className="link-user-arrow">
													<i className="fa fa-arrow-right" aria-hidden="true"></i>
												</span>
											</Dropdown.Item>
										</div>

										<div className="link-user" onClick={logout}>
											<Dropdown.Item href="#/action-1">
												<span className="d-flex align-items-center">
													<span className="link-user-icn">
														<i className="fa fa-sign-out" aria-hidden="true"></i>
													</span>
													Logout
												</span>
												<span className="link-user-arrow">
													<i className="fa fa-arrow-right" aria-hidden="true"></i>
												</span>
											</Dropdown.Item>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</>
					)}
				</div>
				{/*  right header closed  */}
			</Container>

			<div className="mobile-nav">
				<Nav activeKey="/home" onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
					<Nav.Item>
						<Nav.Link href="/home">LOBBY</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="link-1">MY CONTESTS</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="link-2">MY CALL SHEETS</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="link"> SCORES </Nav.Link>
					</Nav.Item>
				</Nav>
			</div>
		</header>
	);
}



// export default HeaderTop;

const mapStateToProps = state => ({ url: state.application.setSelectedMenuUrl });
export default connect(mapStateToProps)(AdminHeaderTop)