import React, { useEffect, useState } from "react";
import Creatable, { useCreatable } from "react-select/creatable";
import { Container, Row, Col, Button, Modal, Table, InputGroup, Form, FormControl } from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from "../../components/footer/index";
import User from "../../assets/images/user.jpg";
//services
import LookupDataService from "../../services/lookupdata.service";
import { connect } from "react-redux";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { SpinnerRoundFilled } from 'spinners-react';


import {
	showLoader,
	hideLoader,
	showWarningAlert,
	showErrorAlert,
	hideWarningAlert,
	showSuccessAlert,
	deleteConfrimFalse,
} from "../../common/actions/application";
import { useConnectWallet } from "../../common/redux/hooks";
import AdminSideNav from "../../components/adminSidebar/index";
import ScoreService from "../../services/score.service";
import TalentPoolService from "../../services/talentpool.service";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Moment from "react-moment";
import moment from "moment";
// import DropdownInput from "react-dropdown-input";

// var DropdownInput = require('react-dropdown-input');
const _ = require("lodash");

function MovieAdmin(props) {
	// var DropdownInput = require('react-dropdown-input');
	const [modalShow, setModalShow] = React.useState(false);
	const [modalTMdbShow, setModalTMdbShow] = React.useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isShowAddMovie, setIsShowAddMovie] = useState(false);
	const { web3, address } = useConnectWallet();
	const [editMovie, setEditMovie] = useState({});
	const [deleteActor, setDeleteActor] = useState({});
	const [deleteScore, setDeleteScore] = useState({});
	const [scoreTalentPool, setScoreTalentPool] = useState([]);
	const [Source, setSource] = useState([]);
	const [lookupData, setLookupData] = useState([]);
	const [sourceTable, setSourceTable] = useState([]);
	const [isActorEdit, setIsActorEdit] = useState(false);
	const [editActor, setEditActor] = useState({});
	
	// const [movieType, setMovieType] = useState([
	//   { TypeId: 1, ValueId: 1, Value: "Movie" },
	//   { TypeId: 1, ValueId: 2, Value: "Series" },
	// ]);

	const [movieType, setMovieType] = useState([]);
	const [movieTypeOption, setMovieTypeOptions] = useState([]);
	const [selectedMovieType, setSelectedMovieType] = useState(0);

	// const [platformType, setPlatformType] = useState([
	//   { TypeId: 1, ValueId: 1, Value: "Netflix" },
	//   { TypeId: 1, ValueId: 2, Value: "HBO" },
	// ]);

	const [platformType, setPlatformType] = useState([]);
	const [platformTypeOption, setPlatformTypeOption] = useState([]);
	// const [genreType, setGenreType] = useState([
	//   { TypeId: 1, ValueId: 1, Value: "Comedy" },
	//   { TypeId: 1, ValueId: 2, Value: "Action" },
	// ]);
	const [genreTypeOption, setGenreTypeOption] = useState([]);
	const [genreType, setGenreType] = useState([]);
	const [isTitleType, setIsTitleType] = useState(false);
	const [isPlatform, setIsPlatform] = useState(false);
	const [isGenre, setIsGenre] = useState(false);
	const [isDesc, setIsDesc] = useState(false);
	const [manulActormodalShow, setManulActorModalShow] = useState(false);
	const [mulDeleteTour, setMulDeleteTour] = useState([]);
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [titleDel, setTitleDel] = useState([]);
	const [csvBtnShow,setCsvBtnShow] = useState(false)
	const [csvData, setCsvData] = useState([])
	const [currentPage,SetCurrentPage]=useState(1);
	const [totalPage,SetTotalPage]=useState(1);

	useEffect(() => {
		getSourceList(currentPage);
		getLookUpDataList();
	}, []);

	useEffect(() => {
		setErrors({});
	}, [props.show]);

	const getLookUpDataList = async () => {
		props.dispatch(showLoader());
		const response = await LookupDataService.getLookUpData();
		setMovieType(response.filter((x) => x.TypeId == 1));
		let typeOptions = [];
		let tempA = response
			.filter((x) => x.TypeId == 1)
			.map((x) => {
				typeOptions.push({ value: x.Value, label: x.Value });
			});
		setMovieTypeOptions(typeOptions);

		setPlatformType(response.filter((x) => x.TypeId == 2));
		let platformOptions = [];
		let tempB = response
			.filter((x) => x.TypeId == 2)
			.map((x) => {
				platformOptions.push({ value: x.Value, label: x.Value });
			});
		setPlatformTypeOption(platformOptions);

		setGenreType(response.filter((x) => x.TypeId == 3));
		let genreOptions = [];
		let tempC = response
			.filter((x) => x.TypeId == 3)
			.map((x) => {
				genreOptions.push({ value: x.Value, label: x.Value });
			});
		setGenreTypeOption(genreOptions);
		setLookupData(response);
		props.dispatch(hideLoader());
	};

	const getSourceList = async (cPage) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getAllScore(cPage);
		response.data.res.forEach((num) => {
			num.Total_Score = Number(num.Total_Score).toFixed(2);
		});
		
	SetTotalPage(Math.ceil(response.data.count.count/10))
		setSourceTable(response.data.res);
		setSource(response.data.res);
		props.dispatch(hideLoader());
	};

	const getSelectedActorsListBySourceId = async (id) => {
		props.dispatch(showLoader());
		const response = await TalentPoolService.getTalentPoolByScoreId({
			scoreId: id,
		});

		// response.data.forEach(num => {
		//   num.Total_Score = Number(num.Total_Score).toFixed(2);
		// });
		
		setActors(response.data);
		props.dispatch(hideLoader());
	};

	const upsertMovie = async (movieData) => {
		props.dispatch(showLoader());
		if (isEdit) movieData.id = editMovie.id;
		await ScoreService.upsertScore(movieData).then((resp) => {
			if (resp.data && resp.success) {
				props.dispatch(showSuccessAlert("Success."));
				setEditMovie(resp.data);
				props.dispatch(hideLoader());
				getSourceList(currentPage);
				setIsShowAddMovie(false);
			}
			if (editMovie != {} && resp.success) {
				props.dispatch(showSuccessAlert("Success."));
				getSourceList(currentPage);
				setIsShowAddMovie(false);
			}
			props.dispatch(hideLoader());
		});
	};
			// const Pagination=(type)=>{
			// 	if(type==='-'){
			// 		SetCurrentPage(currentPage-1)
			// 	getSourceList(currentPage-1)

			// 	}else{
			// 		SetCurrentPage(currentPage+1)
			// 	getSourceList(currentPage+1)

			// 	}
			// 	}
	const upsertActor = async (actorData) => {
		props.dispatch(showLoader());
		if (isActorEdit) actorData.id = editActor.id;
		await TalentPoolService.upsertActor(actorData).then((resp) => {
			if (resp.data && resp.success) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getSelectedActorsListBySourceId(resp.data.ScoreId);
			}
			if (editActor != {} && resp.success) {
				props.dispatch(showSuccessAlert("Successfully updated the actor."));
			}
			props.dispatch(hideLoader());
		});
	};

	// if (props.deleteConfrim) {
	//   props.dispatch(showLoader());
	//   const response = TalentPoolService.deleteActor(deleteActor.id);
	//   if (response) {
	//     props.dispatch(hideWarningAlert());
	//     getSourceList();
	//   }
	//   props.dispatch(hideLoader());
	// };

	if (props.deleteConfrim) {
		props.dispatch(showLoader());
		mulDeleteTour.forEach((element, index) => {
		let object = {
			isActive: false,
			scoresId: mulDeleteTour[index].id,
		};
		ScoreService.deleteScore(object).then((resp) => {
			if (resp.data) {
				getSourceList(currentPage);
				props.dispatch(deleteConfrimFalse());
				props.dispatch(hideWarningAlert());
				setShowCheckBox(false);
			}
		});
	});
		props.dispatch(hideLoader());
	}

	const openAddEditMovie = async () => {
		setIsShowAddMovie(true);
	};

	const [form, setForm] = useState({});

	useEffect(() => {
		setForm({
			Title: editMovie.Title,
			Type: editMovie.Type,
			Platform: editMovie.Platform,
			Release_Date:
				editMovie.Release_Date == undefined ? moment().format("M/DD/yyyy") : editMovie.Release_Date,
			Genre: editMovie.Genre,
			Total_Score: editMovie.Total_Score,
			Co_Star_Points: editMovie.Co_Star_Points,
			Headliners_Points: editMovie.Headliners_Points,
			RT_Critics_Score: editMovie.RT_Critics_Score,
			Google_User_Score: editMovie.Google_User_Score,
			MC_Critics_Score: editMovie.MC_Critics_Score,
			IMDb_User_Score: editMovie.IMDb_User_Score,
			// "Overview": "string",
			IsActive: true,
			ModifiedOn: new Date(Date.now()),
			// TmdbId: editMovie.TmdbId + "",
			WatchType: editMovie.WatchType == undefined ? "Movie" : editMovie.WatchType,
			TitleImg: editMovie.TitleImg,
			Overview: editMovie.Overview,
		});
	}, [editMovie]);

	const [errors, setErrors] = useState({});
	// const [isEdit, setIsEdit] = useState(props.isEdit);
	const setField = (field, value) => {
		editMovie[field] = value;
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const {
			Title,
			Type,
			Platform,
			Release_Date,
			Genre,
			Total_Score,
			Co_Star_Points,
			Headliners_Points,
			RT_Critics_Score,
			Google_User_Score,
			MC_Critics_Score,
			IMDb_User_Score,
		} = form;
		const newErrors = {};

		if (!Title || Title === "") newErrors.Title = "cannot be blank!";
		// if (!Type || Type === "") newErrors.Type = "cannot be blank!";
		// if (!Platform || Platform === "") newErrors.Platform = "cannot be blank!";
		// if (!Release_Date || Release_Date === "") newErrors.Release_Date = "cannot be blank!";
		// if (!Genre || Genre === "") newErrors.Genre = "cannot be blank!";
		// if (!Total_Score || Total_Score === "") newErrors.Total_Score = "cannot be blank!";
		// if (!Co_Star_Points || Co_Star_Points === "") newErrors.Co_Star_Points = "cannot be blank!";
		// if (!Headliners_Points || Headliners_Points === "") newErrors.Headliners_Points = "cannot be blank!";
		// if (!RT_Critics_Score || RT_Critics_Score === "") newErrors.RT_Critics_Score = "cannot be blank!";
		// if (!Google_User_Score || Google_User_Score === "") newErrors.Google_User_Score = "cannot be blank!";
		// if (!MC_Critics_Score || MC_Critics_Score === "") newErrors.MC_Critics_Score = "cannot be blank!";
		// if (!IMDb_User_Score || IMDb_User_Score === "") newErrors.IMDb_User_Score = "cannot be blank!";

		return newErrors;
	};

	const checkGameRules2 = () => {
		const newCallSheetActors = [...actors];
		const isHeadlinerPresent = newCallSheetActors.filter(
			(actor) => actor.Actor_Type == "Headliner" && actor.isSelected
		);
		const isCostarPresent = newCallSheetActors.filter(
			(actor) => actor.Actor_Type == "Co-Star" && actor.isSelected
		);
		const isDayplayerPresent = newCallSheetActors.filter(
			(actor) => actor.Actor_Type == "Dayplayer" && actor.isSelected
		);
		return isHeadlinerPresent.length > 1 && isCostarPresent.length > 0 && isDayplayerPresent.length > 0;
	};

	const checkSalary = () => {
		let check = true;
		const newCallSheetActors = [...actors];
		newCallSheetActors.forEach((element) => {
			if (element.isSelected && (element.Salery === "" || !element.Salery)) {
				check = false;
			}
		});
		return check;
	};

	const handleSubmit = (e) => {
		// e.preventDefault();
		// if (checkGameRules2() && checkSalary()) { }
		// else {
		//   props.dispatch(
		//     showErrorAlert(
		//       "There’s at least 2 headliners, 1 co-star, 1 dayplayer; all salaries are filled in", ""
		//     )
		//   );
		// }
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			if (checkGameRules2() && checkSalary()) {
				let {
					Title,
					Type,
					Platform,
					Release_Date,
					Genre,
					Total_Score,
					Co_Star_Points,
					Headliners_Points,
					RT_Critics_Score,
					Google_User_Score,
					MC_Critics_Score,
					IMDb_User_Score,
					WatchType,
					TitleImg,
					Overview,
				} = form;

				actors.forEach((element) => {
					element.Title = Title;
					element.Type = Type;
					element.Platform = Platform;
					element.Release_Date = Release_Date;
					element.Genre = Genre;
					// element.Notes = Notes;
					element.Total_Scores = Number(Total_Score == "" || Total_Score == undefined ? 0 : Total_Score);
					element.profile_path = element.profile_path;
					// ScoreId: props.movieData.id,
					element.IsActive = true;
				});

				let data = {
					Title: Title,
					Type: Type,
					Platform: Platform,
					Release_Date: Release_Date,
					Genre: Genre,
					Total_Score: Number(Total_Score == "" || Total_Score == undefined ? 0 : Total_Score),
					Co_Star_Points: Number(Co_Star_Points == "" || Co_Star_Points == undefined ? 0 : Co_Star_Points),
					Headliners_Points: Number(
						Headliners_Points == "" || Headliners_Points == undefined ? 0 : Headliners_Points
					),
					RT_Critics_Score: Number(
						RT_Critics_Score == "" || RT_Critics_Score == undefined ? 0 : RT_Critics_Score
					),
					Google_User_Score: Number(
						Google_User_Score == "" || Google_User_Score == undefined ? 0 : Google_User_Score
					),
					MC_Critics_Score: Number(
						MC_Critics_Score == "" || MC_Critics_Score == undefined ? 0 : MC_Critics_Score
					),
					IMDb_User_Score: Number(
						IMDb_User_Score == "" || IMDb_User_Score == undefined ? 0 : IMDb_User_Score
					),
					CreatedOn: new Date(Date.now()),
					ModifiedOn: new Date(Date.now()),
					IsActive: true,
					ActorsList: actors.filter((element) => element.isSelected == true),
					TmdbId: editMovie.TmdbId + "",
					WatchType: WatchType,
					TitleImg: TitleImg,
					Overview: Overview,
				};
				upsertMovie(data);
			} else {
				props.dispatch(
					showErrorAlert(
						"There should be at least 2 headliners, 1 co-star and 1 dayplayer; all salaries should be filled in",
						""
					)
				);
			}
		}
	};

	// const onClickHandler = (watchType) => {
	//   setWatchType(watchType)
	// }

	// useCallback(() => {
	//   setWatchType(watchType)
	// }, [watchType]);

	// const [watchType, setWatchType] = useState("Movie");
	const [actors, setActors] = useState([]);

	const handleInputSearchChange = (event) => {
		// if (event.key === 'Enter') {
		const searchString = event.currentTarget.value;
		getMovie(searchString);
		// }
	};

	const getMovie = async (id) => {
		if (id != "") {
			setActors([]);
			let object = { TMDbId: id, WatchType: form.WatchType };
			await ScoreService.getTMdbScoreById(object).then((resp) => {
				if (resp.success) {
					// let date = new Date(resp.Release_Date)
					// resp.Release_Date = moment(
					//   new Date(resp.Release_Date)
					// ).format("M/DD/yyyy");
					let newActors = [];
					resp.Actors?.forEach((element) => {
						newActors.push({
							ActorTmdbId: element.id,
							Actor: element.name,
							isSelected: false,
							Actor_Type: "",
							Salery: "",
							profile_path: element.profile_path,
							Notes: "",
						});
					});
					setActors(newActors);

					// let newtype = [...movieType];
					// newtype.push({ TypeId: 1, ValueId: 3, Value: resp.WatchType })
					// setMovieType(newtype);

					// let newPlatform = [...platformType];
					// newPlatform.push({ TypeId: 1, ValueId: 3, Value: resp.Platform })
					// setPlatformType(newPlatform);

					// let newGenre = [...genreType];
					// newGenre.push({ TypeId: 1, ValueId: 3, Value: resp.Genre })
					// setGenreType(newGenre);

					setEditMovie(resp);
				} else {
					props.dispatch(showErrorAlert("Enter a valid TMDB number", ""));
				}
			});
		}
	};

	const handleOnChange = (position) => {
		toggelActorIsSelcted(position);
	};

	const toggelActorIsSelcted = (position) => {
		let newArr = [...actors];
		newArr[position].isSelected = !newArr[position].isSelected;
		setActors(newArr);
	};

	const [actorType, setActorType] = useState([
		{ TypeId: 1, ValueId: 1, Value: "Headliner" },
		{ TypeId: 1, ValueId: 2, Value: "Co-Star" },
		{ TypeId: 1, ValueId: 2, Value: "Dayplayer" },
	]);

	const getTotal = () => {
		let rt = Number(form.RT_Critics_Score);
		let go = Number(form.Google_User_Score);
		let mc = Number(form.MC_Critics_Score);
		let im = Number(form.IMDb_User_Score);
		if (rt <= 100 && go <= 100 && mc <= 100 && im <= 10) {
			let totalScore = ((rt + go * 1.6) / 2 + (mc + im * 10 * 1.6) / 2) / 2;
			setForm({
				...form,
				Total_Score: totalScore.toFixed(2),
				Co_Star_Points: (totalScore * 1.2).toFixed(2),
				Headliners_Points: (totalScore * 1.4).toFixed(2),
			});
			setEditMovie({
				...editMovie,
				Total_Score: totalScore.toFixed(2),
				Co_Star_Points: (totalScore * 1.2).toFixed(2),
				Headliners_Points: (totalScore * 1.4).toFixed(2),
			});

			// editMovie.Total_Score = totalScore.toFixed(2);
			// editMovie.Co_Star_Points = (totalScore * 1.2).toFixed(2);
			// editMovie.Headliners_Points = (totalScore * 1.4).toFixed(2);
		} else {
			setForm({
				...form,
				Total_Score: 0,
				Co_Star_Points: 0,
				Headliners_Points: 0,
			});
			setEditMovie({
				...editMovie,
				Total_Score: 0,
				Co_Star_Points: 0,
				Headliners_Points: 0,
			});
			// editMovie.Total_Score = 0;
			// editMovie.Co_Star_Points = 0;
			// editMovie.Headliners_Points = 0;
		}
	};
// new senerio ..........
	const updateScore = async (movie) => {
		let rt = Number(movie.RT_Critics_Score);
		let go = Number(movie.Google_User_Score);
		let mc = Number(movie.MC_Critics_Score);
		let im = Number(movie.IMDb_User_Score);
		if (rt <= 100 && go <= 100 && mc <= 100 && im <= 10) {
			let totalScore = ((rt + go * 1.6) / 2 + (mc + im * 10 * 1.6) / 2) / 2;
			movie.Total_Score = Number(totalScore.toFixed(2));
			movie.Co_Star_Points = Number((totalScore * 1.2).toFixed(2));
			movie.Headliners_Points = Number((totalScore * 1.4).toFixed(2));

			movie.RT_Critics_Score = rt;
			movie.Google_User_Score = go;
			movie.MC_Critics_Score = mc;
			movie.IMDb_User_Score = im;
			await ScoreService.updateScore(movie).then(() => {
				getSourceList(currentPage);
			});

			
		}
	};

	{
		/*
		old senerio ........................
		const updateScore = async (movie) => {
		let rt = Number(movie.RT_Critics_Score);
		let go = Number(movie.Google_User_Score);
		let mc = Number(movie.MC_Critics_Score);
		let im = Number(movie.IMDb_User_Score);
		if (rt <= 100 && go <= 100 && mc <= 100 && im <= 10) {
			let totalScore = ((rt + go * 1.6) / 2 + (mc + im * 10 * 1.6) / 2) / 2;
			movie.Total_Score = Number(totalScore.toFixed(2));
			movie.Co_Star_Points = Number((totalScore * 1.2).toFixed(2));
			movie.Headliners_Points = Number((totalScore * 1.4).toFixed(2));

			movie.RT_Critics_Score = rt;
			movie.Google_User_Score = go;
			movie.MC_Critics_Score = mc;
			movie.IMDb_User_Score = im;
			await ScoreService.updateScore(movie).then(() => {
				getSourceList();
			});

			
		}
	}; */}

	const updateNewActors = async (actorsData) => {
		let newArr = actors;
		actorsData.forEach((actor) => {
			newArr.push(actor);
		});
		setActors(newArr);
	};

	const insertLookUpData = async (type, value) => {
		let data = {
			ValueId: 1,
			Value: value,
			IsActive: true,
			CreatedOn: new Date(Date.now()),
			ModifiedOn: new Date(Date.now()),
		};
		if (type === "Type") {
			data.TypeId = 1;
		}
		if (type === "Platform") {
			data.TypeId = 2;
		}
		if (type === "Genre") {
			data.TypeId = 3;
		}
		await LookupDataService.upsertLookUpData(data).then((resp) => {
			if (resp.data) {
				setModalShow(false);
				props.dispatch(hideLoader());
				getLookUpDataList();
			}
			props.dispatch(hideLoader());
		});
	};

	//sorting Movies & Actors
	const sortMovieDetails = (tableName, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedMovies;
		if (label === "Title") {
			sortedMovies = _.orderBy(tableName, [(table) => table.Title.toLowerCase().trim()], orderByFlag);
		} else if (
			label == "Total_Score" ||
			label == "Co_Star_Points" ||
			label == "Headliners_Points" ||
			label == "Release_Date"
		) {
			sortedMovies = _.orderBy(
				tableName,
				[
					(table) =>
						label == !"Release_Date"
							? Number(
									table === "Total_Score"
										? table.Total_Score
										: label == "Co_Star_Points"
										? table.Co_Star_Points
										: table.Headliners_Points
							  )
							: new Date(table.Release_Date),
				],
				orderByFlag
			);
		} else sortedMovies = _.orderBy(tableName, label, orderByFlag);
		tableName === Source ? setSource(sortedMovies) : setActors(sortedMovies);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const getTitleSearch = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		sourceTable.filter((element) => {
			let tempFilter = element.Title.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setSource(newArray);
		props.dispatch(hideLoader());
	};

	const inputSearchChangeHandler = (event) => {
		const searchString = event.currentTarget.value;
		getTitleSearch(searchString);
	};

	const selectAllFun = () => {
		setMulDeleteTour(Source);
	};
 
	const deleteMultipleFun = (movie) => {
		const localMulDeleteTour = [...mulDeleteTour];
		if (localMulDeleteTour.includes(movie)) {
			const index = localMulDeleteTour.indexOf(movie);
			if (index >= -1) {
				localMulDeleteTour.splice(index, 1);
			}
		} else {
			localMulDeleteTour.push(movie);
		}
		const del =[]
		localMulDeleteTour.forEach((value)=>{
			del.push(value.Title)
		})
		setMulDeleteTour(localMulDeleteTour);
		setTitleDel(del)
	}

	const uploadedCSV = (data, fileInfo, originalFile) => {
		let csvScores = [];
		
		data.map((element, index) => {
			if (index !== 0 && element[1] !== undefined &&element[5]!='') {
				let tempCSV = {};


				tempCSV = {
					Co_Star_Points: element[12],
					Google_User_Score: Number(element[15]),
					Headliners_Points: element[13],
					IMDb_User_Score: Number(element[17]),
					IsActive: true,
					MC_Critics_Score: Number(element[16]),
					RT_Critics_Score: Number(element[14]),
					TmdbId:element[5],
					Total_Score:Number(element[11])
				};
				csvScores.push(tempCSV);
			}
		});
		setCsvData(csvScores);
	};

	const onButtonClick = () => {
		if (csvData[0] !== undefined) {
			// props.dispatch(showWarningAlert("Do Want to Upload CSV?"));

			csvData.forEach((element) => {
				updateScore(element);

				// console.log(element)
				// debugger
				// await ScoreService.updateScore(element).then(() => {
				// 	getSourceList();
				// });
				// props.dispatch(showWarningAlert("Data Uploaded"));
			});
			setCsvBtnShow(false);
		} else {
			props.dispatch(showWarningAlert("Please select a file for upload"));
		}
	};

	const pagination=(action)=>{
		if(action==="+"){
			SetCurrentPage(currentPage+1)
			getSourceList(currentPage+1)
		}else{
			SetCurrentPage(currentPage-1)
			getSourceList(currentPage-1)
		}

	}
	

	return (
		<div className="dashboard">
			<AdminHeaderTop />
			{/* <SearchTitlesModal
        show={modalTMdbShow}
        onHide={() => setModalTMdbShow(false)}
      /> */}
			<div className="d-flex">
				<Col className="left-side-nav">
					<AdminSideNav placement="start" name="start" />
				</Col>
				<div className="right-side-content">
					<div className="content border-nav">
						<Container fluid>
							<div className="table-main p-r-10 m-tble desktop-view scroll-x">
							<h3>Titles List</h3>   
								{!isShowAddMovie ? (
								<div>
										
										{/*<button className="pull-right blue-btn" type="button" onClick={() => winner()}>  Winner </button> */}
									
										<Row>
											<Col lg={3} md={12} xs={12}>
												<InputGroup className="search-btn">
													<FormControl
														placeholder="Search"
														aria-label="Recipient's username"
														aria-describedby="basic-addon2"
														onChange={inputSearchChangeHandler}
													/>
													<InputGroup.Text id="basic-addon2">
														<i className="fa fa-search" aria-hidden="true"></i>
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col>
												<div className="add-new">

												<button className="red-btn"  onClick={() => {
																
																setCsvBtnShow(true);
																 
																}}>UPLOAD SCORE CSV</button>

													<Modal
														{...props}
														show ={csvBtnShow}
														size="lg"
														aria-labelledby="contained-modal-title-vcenter"
														centered
														className="adventure-popup">
														<Modal.Header>
															<Button
																className="popup-close"
																onClick={() => {
																setCsvBtnShow(false);
																}}>
																<i className="fa fa-times" aria-hidden="true"></i>
															</Button>
															<Modal.Title className="popup-heading" id="contained-modal-title-vcenter">
															UPLOAD SCORE
														</Modal.Title>
													</Modal.Header>
													<Modal.Body>
														{ 
															<div className="movie-type-box">
																<button type="button" className="blue-btn"  onClick={()=> onButtonClick()} >
																	Upload CSV File here
																</button>
												
																<CSVReader
																	onFileLoaded={(data, fileInfo, originalFile) => uploadedCSV(data, fileInfo, originalFile)}
																/>
																<CSVLink data={Source}>
																<button type="button" className="blue-btn">
																	Download CSV File
																</button>
																</CSVLink>
																</div>
																		}
																	</Modal.Body>
																</Modal>


													<button
														className="red-btn"
														onClick={() =>
															!showCheckBox
																? setShowCheckBox(true)
																: !mulDeleteTour[0]
																? props.dispatch(
																		showErrorAlert("Please select atleast one title for deleting")
																  )
																: props.dispatch(
																		showWarningAlert(
																			"Do wish to delete this Title?",
																			undefined,
																			"delete-action",
																			titleDel,
																			"Title"
																		)
																  )
														}>
														{!showCheckBox ? "MULTIPLE DELETE " : "DELETE "}
														<i className="fa fa-trash" aria-hidden="true"></i>
													</button>
													<button
														className="pull-right blue-btn"
														type="button"
														onClick={() => {
															openAddEditMovie();
															setEditMovie({});
															setScoreTalentPool([]);
															setIsEdit(false);
															setActors([]);
														}}>
														Add Title
													</button>
												</div>
											</Col>
										</Row>
										<Table striped hover>
											<thead>
												<tr>
													<th>
														{showCheckBox ? (
															<>
															<button className="red-btn"
																		onClick={() =>
																			setShowCheckBox(false)
																		}> BACK
																		</button>
																<button className="blue-btn" onClick={selectAllFun}>
																	SELECT ALL
																</button>
																<button className="red-btn" onClick={() => setMulDeleteTour([])}>
																	CLEAR ALL
																</button>
															</>
														) : null}
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Title")}>
															<span>
																Title
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Type")}>
															<span>
																Type
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Platform")}>
															<span>
																Platform
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Release_Date")}>
															<span>
																Release Date
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Genre")}>
															<span>
																Genre
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Total_Score")}>
															<span>
																Total Score
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Co_Star_Points")}>
															<span>
																Co-Star Points (20% bonus pts)
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Headliners_Points")}>
															<span>
																Headliner Points (40% bonus pts)
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "RT_Critics_Score")}>
															<span>
																RT Critics Score
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "Google_User_Score")}>
															<span>
																Google User Score (60% bonus)
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "MC_Critics_Score")}>
															<span>
																MC Critics Score
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
													<th>
														<a onClick={() => sortMovieDetails(Source, "IMDb_User_Score")}>
															<span>
																IMDb User Score
																{isDesc ? (
																	<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																) : (
																	<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																)}
															</span>
														</a>
													</th>
												</tr>
											</thead>

											<tbody>
												
		
												{ Source?.map((movie, index) => {
													return (
														<tr key={index + movie.Title}>
															<td>
																{showCheckBox ? (
																	<Form.Check
																		type="checkbox"
																		//id={index + "checkbox1"}
																		label={""}
																		checked={mulDeleteTour.includes(movie)}
																		value={movie.id}
																		onChange={() => {
																			deleteMultipleFun(movie);
																		}}
																	/>
																) : null}
															</td>
															<td>{movie.Title}</td>
															<td>{movie.Type}</td>
															<td>{movie.Platform}</td>
															<td>
																<Moment format="MM/DD/yyyy">{movie.Release_Date}</Moment>
															</td>
															<td>{movie.Genre}</td>
															<td>{parseFloat(movie.Total_Score).toFixed(2)}</td>
															<td>{parseFloat(movie.Co_Star_Points).toFixed(2)}</td>
															<td>{parseFloat(movie.Headliners_Points).toFixed(2)}</td>
															<td>
																<div className="row-input">
																	<Form.Control
																		style={{ color: "black" }}
																		type="number"
																		placeholder="RT Critics Score"
																		//defaultValue={movie.RT_Critics_Score}
																		defaultValue={movie.RT_Critics_Score}
																		onBlur={(e) =>
																			updateScore({ ...movie, RT_Critics_Score: e.target.value })
																		}
																	/>
																</div>
															</td>
															<td>
																<div className="row-input">
																	<Form.Control
																		style={{ color: "black" }}
																		type="number"
																		placeholder="Google User Score"
																		defaultValue={movie.Google_User_Score}
																		//defaultValue={movie.Google_User_Score}
																		onBlur={(e) =>
																			updateScore({ ...movie, Google_User_Score: e.target.value })
																		}
																	/>
																</div>
															</td>
															<td>
																<div className="row-input">
																	<Form.Control
																		style={{ color: "black" }}
																		type="number"
																		placeholder="MC Critics Score"
																		//defaultValue={movie.MC_Critics_Score}
																		defaultValue={movie.MC_Critics_Score}
																		onBlur={(e) =>
																			updateScore({ ...movie, MC_Critics_Score: e.target.value })
																		}
																	/>
																</div>
															</td>
															<td>
																<div className="row-input">
																	<Form.Control
																		type="number"
																		min="0"
																		max="10"
																		placeholder="IMDb User Score"
																		//defaultValue={movie.IMDb_User_Score}
																		defaultValue={movie.IMDb_User_Score}
																		step="any"
																		onBlur={(e) => updateScore({ ...movie, IMDb_User_Score: e.target.value })}
																	/>
																</div>
															</td>
															<td>
																<Button
																	onClick={() => {
																		setEditMovie(movie);
																		openAddEditMovie();
																		getSelectedActorsListBySourceId(movie.id);
																		setIsEdit(true);
																	}}>
																	<i className="fa fa-pencil" aria-hidden="true"></i>
																</Button>
																<Button
																	onClick={() => {
																		setMulDeleteTour([movie]);
																		const name = [movie.Title];
																		props.dispatch(
																			showWarningAlert(
																				"Do wish to delete this movie?",
																				undefined,
																				"delete-action",
																				name,
																				"Title"
																			)
																		);
																	}}
																	style={{ marginLeft: "5px" }}>
																	<i className="fa fa-trash" aria-hidden="true"></i>
																</Button>
																{/* <Link to={"/callsheet?c=new&t=" + tournament.id} onClick={() => localStorage.setItem("CallSheetId", null)}> <button type="button">Let’s Play</button> </Link> */}
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>	
									
									
								</div>
								) : (
									<div>
										<h4>{isEdit ? "Edit Title" : "Add New Title"}</h4>
										{!isEdit ? (
											<Row>
												<Col md={6}>
													<InputGroup className="search-btn">
														<FormControl
															placeholder="Search"
															aria-label="Recipient's username"
															aria-describedby="basic-addon2"
															onBlur={handleInputSearchChange}
														/>
														<InputGroup.Text id="basic-addon2">
															<i className="fa fa-search" aria-hidden="true"></i>
														</InputGroup.Text>
													</InputGroup>
												</Col>
												<Col md={2}>
													<div
														className="btn-group button-tb"
														style={{ marginTop: "0" }}
														role="group"
														aria-label="Basic radio toggle button group">
														<input
															type="radio"
															value="Movie"
															className="btn-check"
															name="btnradio"
															id="btnradio1"
															autoComplete="off"
															defaultChecked
															onChange={(e) =>
																// setWatchType(e.target.value)
																setField("WatchType", e.target.value)
															}
														/>
														<label className="btn btn-outline-primary " htmlFor="btnradio1">
															Movie
														</label>

														<input
															type="radio"
															value="TV"
															className="btn-check"
															name="btnradio"
															id="btnradio2"
															autoComplete="off"
															onChange={(e) =>
																// setWatchType(e.target.value)
																setField("WatchType", e.target.value)
															}
														/>
														<label className="btn btn-outline-primary" htmlFor="btnradio2">
															TV
														</label>
													</div>
												</Col>
											</Row>
										) : (
											""
										)}
										<div className="add-form">
											<Form onSubmit={handleSubmit} role="form" autoComplete="off">
												<div className="pupup-btn-row">
													{/* <Button
                        onClick={() => setIsShowAddMovie(false)}
                        className="red-btn"
                      >
                        GO BACK
                      </Button> */}
													<Button onClick={() => setIsShowAddMovie(false)} className="red-btn">
														GO BACK
													</Button>
													{isEdit ? (
														<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
															EDIT TITLE
														</Button>
													) : (
														<Button type="submit" className="blue-btn" style={{ marginLeft: "15px" }}>
															SAVE TITLE
														</Button>
													)}
												</div>
												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>Title*</Form.Label>
															<div className="row-input">
																<img
																	src={editMovie.TitleImg ? editMovie.TitleImg : User}
																	className="user"
																	style={{ maxWidth: 35, width: 35, height: 35, position: "absolute" }}
																/>
																<Form.Control
																	defaultValue={editMovie.Title}
																	type="text"
																	placeholder="Title Name"
																	onChange={(e) => setField("Title", e.target.value)}
																	isInvalid={!!errors.Title}
																	style={{ width: "95%", marginLeft: "41px" }}
																/>
																<Form.Control.Feedback type="invalid">{errors.Title}</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>
																Title Type &nbsp;
																{!isTitleType ? (
																	<Button onClick={() => setIsTitleType(true)} className="btn-sm btn-primary">
																		Enter Title
																	</Button>
																) : (
																	<Button
																		onClick={() => setIsTitleType(false)}
																		className="btn-sm btn-primary">
																		Select Title
																	</Button>
																)}
															</Form.Label>
															<div className="row-input">
																{isTitleType ? (
																	<Form.Control
																		autoComplete="off"
																		defaultValue={editMovie.Type}
																		type="text"
																		placeholder="Enter Title Type"
																		onChange={(e) => setField("Type", e.target.value)}
																		isInvalid={!!errors.Type}
																		autoFocus
																		onBlur={(e) => insertLookUpData("Type", e.target.value)}
																	/>
																) : (
																	<Creatable
																		defaultInputValue={editMovie.Type}
																		defaultOptions={movieTypeOption}
																		options={movieTypeOption}
																		closeMenuOnSelect={true}
																		cacheOptions={true}
																		onChange={(e) => setField("Type", e.value)}
																		createOptionPosition="first"
																		onCreateOption={(e) => {
																			insertLookUpData("Type", e);
																			setField("Type", e);
																		}}
																		placeholder="Enter Title Type"
																		controlShouldRenderValue={true}
																		escapeClearsValue={true}
																	/>
																)}

																<Form.Control.Feedback type="invalid">{errors.Type}</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>
																Platform &nbsp;
																{!isPlatform ? (
																	<Button onClick={() => setIsPlatform(true)} className="btn-sm btn-primary">
																		Enter Platform
																	</Button>
																) : (
																	<Button onClick={() => setIsPlatform(false)} className="btn-sm btn-primary">
																		Select Platform
																	</Button>
																)}
															</Form.Label>
															<div className="row-input">
																{isPlatform ? (
																	<Form.Control
																		defaultValue={editMovie.Platform}
																		type="text"
																		placeholder="Enter Platform"
																		onChange={(e) => setField("Platform", e.target.value)}
																		isInvalid={!!errors.Platform}
																		autoFocus
																		autoComplete="off"
																		onBlur={(e) => insertLookUpData("Platform", e.target.value)}
																	/>
																) : (
																	<Creatable
																		defaultInputValue={editMovie.Platform}
																		defaultOptions={platformTypeOption}
																		options={platformTypeOption}
																		cacheOptions={true}
																		onChange={(e) => setField("Platform", e.value)}
																		createOptionPosition="first"
																		onCreateOption={(e) => insertLookUpData("Platform", e)}
																		placeholder="Enter Platform"
																		controlShouldRenderValue={true}
																		escapeClearsValue={true}
																	/>
																)}

																<Form.Control.Feedback type="invalid">
																	{errors.Platform}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														{/* <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>
                              </Form.Label>
                              <Form.Control type="date" placeholder="Release Date" />
                            </Form.Group> */}
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>Start Date</Form.Label>
															<div className="row-input">
																<DateRangePicker
																	update
																	initialSettings={{
																		singleDatePicker: true,
																		startDate: moment(editMovie.Release_Date).format("M/DD/yyyy"),
																		locale: {
																			format: "M/DD/yyyy",
																		},
																	}}
																	onCallback={(date) => {
																		setField("Release_Date", date.format());
																	}}>
																	<input type="text" className="form-control col-4" />
																</DateRangePicker>
																{/* <Form.Control type="date" placeholder=""
                                                        onChange={e => setField('startDate', e.target.value)}
                                                        isInvalid={!!errors.startDate} /> */}
																<Form.Control.Feedback type="invalid">{errors.Starts}</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>
																Genre &nbsp;
																{!isGenre ? (
																	<Button onClick={() => setIsGenre(true)} className="btn-sm btn-primary">
																		Enter Genre
																	</Button>
																) : (
																	<Button onClick={() => setIsGenre(false)} className="btn-sm btn-primary">
																		Select Genre
																	</Button>
																)}
															</Form.Label>
															<div className="row-input">
																{isGenre ? (
																	<Form.Control
																		defaultValue={editMovie.Genre}
																		type="text"
																		placeholder="Enter Genre"
																		onChange={(e) => setField("Genre", e.target.value)}
																		isInvalid={!!errors.Genre}
																		autoFocus
																		autoComplete="off"
																		onBlur={(e) => insertLookUpData("Genre", e.target.value)}
																	/>
																) : (
																	<Creatable
																		defaultInputValue={editMovie.Genre}
																		defaultOptions={genreTypeOption}
																		options={genreTypeOption}
																		cacheOptions={true}
																		onChange={(e) => setField("Genre", e.value)}
																		createOptionPosition="first"
																		onCreateOption={(e) => insertLookUpData("Genre", e)}
																		placeholder="Enter Genre"
																		controlShouldRenderValue={true}
																		escapeClearsValue={true}
																	/>
																)}
																<Form.Control.Feedback type="invalid">{errors.Genre}</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>
																Total Score
																<Button
																	className="btn-sm"
																	style={{ marginLeft: "15px" }}
																	onClick={() => getTotal()}>
																	GET TOTAL
																</Button>
															</Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.Total_Score}
																	type="number"
																	placeholder="Total Score"
																	onChange={(e) => setField("Total_Score", e.target.value)}
																	isInvalid={!!errors.Total_Score}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.Total_Score}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>Co-Star Points</Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.Co_Star_Points}
																	type="number"
																	placeholder="Co-Star Points"
																	onChange={(e) => setField("Co_Star_Points", e.target.value)}
																	isInvalid={!!errors.Co_Star_Points}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.Co_Star_Points}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>Headliner Points</Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.Headliners_Points}
																	type="number"
																	placeholder="Headliner Points"
																	onChange={(e) => setField("Headliners_Points", e.target.value)}
																	isInvalid={!!errors.Headliners_Points}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.Headliners_Points}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>RT Critics Score</Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.RT_Critics_Score}
																	type="number"
																	placeholder="RT Critics Score"
																	onChange={(e) => setField("RT_Critics_Score", e.target.value)}
																	isInvalid={!!errors.RT_Critics_Score}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.RT_Critics_Score}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label>Google User Score (60% bonus)</Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.Google_User_Score}
																	type="number"
																	placeholder="Google User Score (60% bonus)"
																	onChange={(e) => setField("Google_User_Score", e.target.value)}
																	isInvalid={!!errors.Google_User_Score}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.Google_User_Score}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label> MC Critics Score </Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.MC_Critics_Score}
																	placeholder="MC Critics Score "
																	onChange={(e) => setField("MC_Critics_Score", e.target.value)}
																	isInvalid={!!errors.MC_Critics_Score}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.MC_Critics_Score}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>

													<Col md={6}>
														<Form.Group className="mb-3" controlId="formBasicPassword">
															<Form.Label> IMDb User Score (60% bonus) </Form.Label>
															<div className="row-input">
																<Form.Control
																	value={editMovie.IMDb_User_Score}
																	type="number"
																	min="0"
																	max="10"
																	placeholder="IMDb User Score (60% bonus)"
																	onChange={(e) => setField("IMDb_User_Score", e.target.value)}
																	isInvalid={!!errors.IMDb_User_Score}
																	step="any"
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.IMDb_User_Score}
																</Form.Control.Feedback>
															</div>
														</Form.Group>
													</Col>
												</Row>
												{/* <Row className="add-tr-btn">
                          <Col md={6}>
                            <Button className="red-btn">CANCEL</Button>
                            <Button className="blue-btn">SAVE</Button>
                          </Col>
                        </Row> */}
												{/* <div className="pupup-btn-row">
                          <Button
                            className="blue-btn"
                            style={{ marginLeft: "15px" }}
                            onClick={() => getTotal()}
                          >
                            GET TOTAL
                          </Button>

                        </div> */}
											</Form>
											<br />
											<h4>
												{editMovie.Title ? editMovie.Title + " -" : "Title"} Actors List
												{isEdit ? (
													<>
														<button
															className="pull-right blue-btn"
															style={{ fontSize: "1rem", fontWeight: "400" }}
															type="button"
															onClick={() => {
																setModalShow(true);
																setIsActorEdit(true);
															}}>
															CURRENT AVAILABLE ACTOR
														</button>
														<button
															className="pull-right blue-btn"
															style={{ fontSize: "1rem", fontWeight: "400" }}
															type="button"
															onClick={() => {
																setManulActorModalShow(true);
															}}>
															SEARCH ACTOR FROM TMDB
														</button>
													</>
												) : (
													<button
														className="pull-right blue-btn"
														style={{ fontSize: "1rem", fontWeight: "400" }}
														type="button"
														onClick={() => {
															setManulActorModalShow(true);
														}}>
														SEARCH ACTOR
													</button>
												)}
											</h4>

											<ActorModal
												show={modalShow}
												isEdit={isActorEdit}
												movieData={editMovie}
												actoreslist={actors}
												onHide={() => setModalShow(false)}
												addNewActors={(actorData) => updateNewActors(actorData)}
											/>

											<ManullyActorModal
												show={manulActormodalShow}
												// isEdit={isActorEdit}
												// movieData={editMovie}
												actoreslist={actors}
												onHide={() => setManulActorModalShow(false)}
												addNewActors={(actorData) => updateNewActors(actorData)}
											/>

											<div className="table-main scroll-table p-r-10  contest-table-lv">
												<Table striped hover>
													<thead>
														<tr>
															<th></th>

															<th>
																<a onClick={() => sortMovieDetails(actors, "Actor")}>
																	<span>
																		Actor
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</th>

															<th>
																<a onClick={() => sortMovieDetails(actors, "Actor_Type")}>
																	<span>
																		Actor Type
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</th>

															<th>
																<a onClick={() => sortMovieDetails(actors, "Salery")}>
																	<span>
																		Salary
																		{isDesc ? (
																			<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
																		) : (
																			<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																		)}
																	</span>
																</a>
															</th>

															<th>
																<span>
																	Notes <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
																</span>
															</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{actors?.map((actor, index) => {
															return (
																<tr key={index + "actor"}>
																	{/* <td><div className="form-group"> 
                                    <input
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      checked={actor.isSelected}
                                      onChange={() => handleOnChange(index)}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`} ></label>
                                  </div>
                                  </td> */}

																	<td>
																		{actor.isSelected ? (
																			<a className="minus-button" style={{ cursor: "pointer" }}>
																				<i
																					onClick={() => handleOnChange(index)}
																					className="fa fa-minus-circle fa-2x"
																					aria-hidden="true"></i>
																			</a>
																		) : (
																			<a className="plus-button" style={{ cursor: "pointer" }}>
																				<i
																					onClick={() => handleOnChange(index)}
																					className="fa fa-plus-circle fa-2x"
																					aria-hidden="true"></i>
																			</a>
																		)}
																	</td>

																	<td>
																		{/* {actor.Actor} */}
																		<div className="row-input">
																			<img
																				src={
																					actor.profile_path
																						? "https://image.tmdb.org/t/p/original" + actor.profile_path
																						: User
																				}
																				className="user"
																				style={{
																					maxWidth: 35,
																					width: 35,
																					height: 35,
																					position: "absolute",
																					left: "-27px",
																				}}
																			/>
																			<Form.Control
																				style={{ marginLeft: "12px" }}
																				defaultValue={actor.Actor}
																				// value={actor.Actor}
																				type="text"
																				placeholder="Actor Name"
																				onChange={(e) => {
																					actor.Actor = e.target.value;
																				}}
																			/>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Group className="mb-3" controlId="formBasicPassword">
																				<Form.Select
																					defaultValue={actor.Actor_Type}
																					// value={actor.Actor_Type}
																					onChange={(e) => {
																						actor.Actor_Type = e.target.value;
																					}}>
																					<option value="0">Select Actor Type</option>
																					{actorType?.map((option) => (
																						<option key={option.Value} value={option.Value}>
																							{option.Value}
																						</option>
																					))}
																				</Form.Select>
																			</Form.Group>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Control
																				type="text"
																				placeholder="Actor Salary"
																				defaultValue={actor.Salery}
																				// value={actor.Salery}
																				onChange={(e) => {
																					actor.Salery = e.target.value;
																				}}
																			/>
																		</div>
																	</td>
																	<td>
																		<div className="row-input">
																			<Form.Control
																				type="text"
																				placeholder="Actor Notes"
																				defaultValue={actor.Notes}
																				// value={actor.Notes}
																				onChange={(e) => {
																					actor.Notes = e.target.value;
																				}}
																			/>
																		</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</Table>
											</div>
										</div>
									</div>
								)}
							</div>

						</Container>
						<div className="pagination">

							<button className="paginationButton" onClick={()=>pagination('-')} disabled={currentPage===1?true:false}>{"<"} </button>
								<span>{currentPage}</span> 
							<button className="paginationButton"  onClick={()=>pagination('+')} >{">"} </button>
							<span>{totalPage}</span>
						</div>
					</div>
					{/*  content closed  */}
				</div>
			</div>
			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({ deleteConfrim: state.application.deleteConfrim });
export default connect(mapStateToProps)(MovieAdmin);

function ActorModal(props) {
	const [actorType, setActorType] = useState([
		{ TypeId: 1, ValueId: 1, Value: "Headliner" },
		{ TypeId: 1, ValueId: 2, Value: "Co-Star" },
		{ TypeId: 1, ValueId: 2, Value: "Dayplayer" },
	]);

	const handleOnChange = (position) => {
		toggelActorIsSelcted(position);
	};

	const toggelActorIsSelcted = (position) => {
		let newArr = [...actorsLi];
		newArr[position].isSelected = !newArr[position].isSelected;
		setActorsLi(newArr);
	};

	const [actorsLi, setActorsLi] = useState([]);

	// useEffect(() => {
	//   if (props.actoreslist.length > 0) {
	//     let allActors = actorsLi;
	//     let allreadysctors = props.actoreslist;
	//     let newArray = allActors.filter((ar) => !allreadysctors.find(rm => rm.Actor === ar.Actor));
	//     setActorsLi(newArray);
	//   }

	// }, [actorsLi]);

	useEffect(() => {
		if (props.isEdit) getActores();
	}, [props.isEdit]);

	const getActores = async () => {
		let object = { TMDbId: props.movieData.TmdbId, WatchType: props.movieData.WatchType };
		await ScoreService.getTMdbScoreById(object).then((resp) => {
			if (resp) {
				let newActors = [];
				resp.Actors.forEach((element) => {
					newActors.push({
						ActorTmdbId: element.id,
						Actor: element.name,
						isSelected: false,
						Actor_Type: "",
						Salery: "",
						profile_path: element.profile_path,
						Notes: "",
					});
				});

				let allreadysctors = props.actoreslist;
				let newArray = newActors.filter((ar) => !allreadysctors.find((rm) => rm.Actor === ar.Actor));
				setActorsLi(newArray);
			}
		});
	};

	const saveNewActors = async () => {
		if (actorsLi.length > 0) {
			props.onHide();
			props.addNewActors(actorsLi.filter((element) => element.isSelected == true));
		} else {
			alert("Select atleast one actor");
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">Add New Actor</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<div className="table-main scroll-table p-r-10  contest-table-lv">
						<Table striped hover>
							<thead>
								<tr>
									<th></th>

									<th>
										<span>
											Actor <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
										</span>
									</th>
									{/* <th>
                    <span>
                      Actor Type <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th>
                    <span>
                      Salary <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th>
                    <span>
                      Notes <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th></th> */}
								</tr>
							</thead>
							<tbody>
								{actorsLi?.map((actor, index) => {
									return (
										<tr key={index + "actor"}>
											{/* <td><div className="form-group"> 
                                    <input
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      checked={actor.isSelected}
                                      onChange={() => handleOnChange(index)}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`} ></label>
                                  </div>
                                  </td> */}

											<td>
												{actor.isSelected ? (
													<a className="minus-button">
														<i
															onClick={() => handleOnChange(index)}
															className="fa fa-minus-circle fa-2x"
															aria-hidden="true"></i>
													</a>
												) : (
													<a className="plus-button">
														<i
															onClick={() => handleOnChange(index)}
															className="fa fa-plus-circle fa-2x"
															aria-hidden="true"></i>
													</a>
												)}
											</td>

											<td>
												<img
													src={
														actor.profile_path
															? "https://image.tmdb.org/t/p/original" + actor.profile_path
															: User
													}
													className="user"
													style={{ maxWidth: 35, width: 35, height: 35 }}
												/>
												{actor.Actor}
											</td>
											{/* <td>
                        <div className="row-input">
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Select
                              onChange={(e) => {
                                actor.Actor_Type = e.target.value;
                              }}>
                              <option value="0">Select Actor Type</option>
                              {actorType?.map((option) => (
                                <option value={option.Value}>{option.Value}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </td>
                      <td>
                        <div className="row-input">
                          <Form.Control
                            type="text"
                            placeholder="Actor Salery"
                            onChange={(e) => {
                              actor.Salery = e.target.value;
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="row-input">
                          <Form.Control
                            type="text"
                            placeholder="Actor Notes"
                            defaultValue={actor.Notes}
                            onChange={(e) => {
                              actor.Notes = e.target.value;
                            }}
                          />
                        </div>
                      </td> */}
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<div className="pupup-btn-row">
						<Button onClick={() => props.onHide()} className="red-btn">
							CANCEL
						</Button>
						(
						<Button
							onClick={() => saveNewActors()}
							type="button"
							className="blue-btn"
							style={{ marginLeft: "15px" }}>
							SAVE ACTOR
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function ManullyActorModal(props) {
	const handleOnChange = (position) => {
		toggelActorIsSelcted(position);
	};

	const toggelActorIsSelcted = (position) => {
		let newArr = [...actorsLi];
		newArr[position].isSelected = !newArr[position].isSelected;
		setActorsLi(newArr);
	};

	const [actorsLi, setActorsLi] = useState([]);

	const getActor = async (id) => {
		let object = { actorTmdbId: id };
		await ScoreService.getTMdbActorById(object).then((resp) => {
			if (resp.success) {
				let newActors = [];
				let actor = [];
				actor.push(resp.Actors);
				actor.forEach((element) => {
					newActors.push({
						ActorTmdbId: element.id,
						Actor: element.name,
						isSelected: false,
						Actor_Type: "",
						Salery: "",
						profile_path: element.profile_path,
						Notes: "",
					});
				});

				let allreadysctors = props.actoreslist;
				let newArray = newActors.filter((ar) => !allreadysctors.find((rm) => rm.Actor === ar.Actor));
				if (newArray.length == 0) alert("Actor with this name already added to this title!");
				let arry = [];
				if (newArray.length > 0) arry = [...actorsLi, newArray[0]];
				else arry = [...actorsLi];

				setActorsLi(arry);
			} else {
				alert("Actor not found with entered TMDBId.");
			}
		});
	};

	const saveNewActors = async () => {
		if (actorsLi.length > 0) {
			props.onHide();
			props.addNewActors(actorsLi.filter((element) => element.isSelected == true));
			setActorsLi([]);
		} else {
			alert("Select atleast one actor");
		}
	};

	const handleInputSearchChange = (event) => {
		// if (event.key === 'Enter') {
		const searchString = event.currentTarget.value;
		if (Number(searchString) > 0) getActor(Number(searchString));
		else {
			alert("Please enter valid actor TMDBid");
		}
		// }
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">Add New Actor</Modal.Title>
				<Button className="popup-close" onClick={props.onHide}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="add-form">
					<InputGroup className="search-btn">
						<FormControl
							placeholder="Paste Actor's tmdbID (for eg:-12345 from 'https://www.themoviedb.org/person/12345')"
							aria-label="Recipient's username"
							aria-describedby="basic-addon2"
							onChange={handleInputSearchChange}
						/>
						<InputGroup.Text id="basic-addon2" style={{ cursor: "pointer" }}>
							<i className="fa fa-search" aria-hidden="true"></i>
						</InputGroup.Text>
					</InputGroup>
					<div className="table-main scroll-table p-r-10  contest-table-lv">
						<Table striped hover>
							<thead>
								<tr>
									<th></th>

									<th>
										<span>
											Actor <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{actorsLi?.map((actor, index) => {
									return (
										<tr key={index + "actor"}>
											<td>
												{actor.isSelected ? (
													<a className="minus-button">
														<i
															onClick={() => handleOnChange(index)}
															className="fa fa-minus-circle fa-2x"
															aria-hidden="true"></i>
													</a>
												) : (
													<a className="plus-button">
														<i
															onClick={() => handleOnChange(index)}
															className="fa fa-plus-circle fa-2x"
															aria-hidden="true"></i>
													</a>
												)}
											</td>

											<td>
												<img
													src={
														actor.profile_path
															? "https://image.tmdb.org/t/p/original" + actor.profile_path
															: User
													}
													className="user"
													style={{ maxWidth: 35, width: 35, height: 35 }}
												/>
												{actor.Actor}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<div className="pupup-btn-row">
						<Button onClick={() => props.onHide()} className="red-btn">
							CANCEL
						</Button>
						(
						<Button
							onClick={() => saveNewActors()}
							type="button"
							className="blue-btn"
							style={{ marginLeft: "15px" }}>
							SAVE ACTOR
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
