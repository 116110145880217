import axios from "axios";

// const API_URL = "https://cinemadraft-api-services.herokuapp.com/";
const API_URL = process.env.REACT_APP_BASE_URL;

class CallSheetService {
	getCallSheetById = async (id) => {
		return await axios.get(API_URL + "call-sheets/" + id).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getMyCallSheets = async () => {
		return await axios.get(API_URL + "call-sheets").then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	upsertCallSheetList = async (inputPrams) => {
		return await axios.post(API_URL + "create-call-sheets", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	deleteActorInCallSheet = async (inputPrams) => {
		return await axios.post(API_URL + "delete-actor-from-call-sheet", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	submitCallSheet = async (inputPrams) => {
		return await axios.post(API_URL + "submit-call-sheet", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("someething went wrong");
			}
		});
	};

	clearCallSheet = async (inputPrams) => {
		return await axios.post(API_URL + "clear-call-sheet", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTournamentById = async (id) => {
		return await axios.get(API_URL + "get-tournamentById/" + id).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTalentpoolFilter = async (currentPage) => {
		
		return await axios.get(`${API_URL}get-all-actors-list?filter[limit]=10&filter[skip]=${(currentPage-1)*10}`).then((response) => {
			if (response.status == 200) {
				console.log(response.data)
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTournamentResult = async (inputPrams) => {
		return await axios.post(API_URL + "get-tournament-results", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getTalentPoolByTourPool = async (inputPrams) => {
		return await axios.post(API_URL + "get-scorelist", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	checkCallsheetCount = async (inputPrams) => {
		
		return await axios.post(API_URL + "check-callsheet-count", inputPrams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};

	getCallSheetByTalentpoolId = async (inputParams) => {
		return await axios.post(API_URL + "live-callsheets-by-talentPoolId", inputParams).then((response) => {
			if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
		});
	};
}

export default new CallSheetService();