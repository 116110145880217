import React from "react";
import { Container, FormControl, InputGroup, Accordion } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from "../../components/footer/index";
import Faq from '../../assets/images/Support-FAQb-anner.png';


const Support = () => {
  return (
		<div className="supportPage">
			<HeaderTop />

			<div className="content-support">
				<Container fluid>
					<div className="Banner" style={{ backgroundImage: "url(" + Faq + ")", backgroundSize: "auto" }}>
						<div className="banner-content">
							<h1>Everybody needs help sometimes</h1>
							<InputGroup className="search-btn">
								<FormControl
									placeholder="Search"
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
								/>
								<InputGroup.Text id="basic-addon2">
									<i className="fa fa-search" aria-hidden="true"></i>
								</InputGroup.Text>
							</InputGroup>
						</div>
					</div>
					<div className="d-flex justify-content-between support-box">
						<div className="left-side">
							<h3>Topic</h3>
							<button className="dark-button active">
								<span>Lobby</span>
							</button>
							<button className="dark-button">
								<span>Talent Pool</span>
							</button>
							<button className="dark-button">
								<span>Call Sheet</span>
							</button>
							<button className="dark-button">
								<span>Settings</span>
							</button>
							<button className="dark-button">
								<span>Lobby</span>
							</button>
							<button className="dark-button">
								<span>Talent Pool</span>
							</button>
							<button className="dark-button">
								<span>Settings</span>
							</button>
							<button className="dark-button">
								<span>Talent Pool</span>
							</button>
						</div>
						{/* ======= left side closed =============== */}
						<div className="right-side">
							<div className="accordion-sectiion">
								<Accordion>
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="1">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="2">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="3">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="4">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="5">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="6">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="7">
										<Accordion.Header>
											What is Lorem Ipsum?
											<div className="accdn-icn">
												<span>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
													Ipsum has been the industry’s...
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged. It was popularised in the 1960s with the release of
												Letraset sheets containing Lorem Ipsum passages,
											</p>

											<p>
												Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
												has been the industry’s standard dummy text ever since the 1500s, when an unknown
												printer took a galley of type and scrambled it to make a type specimen book. It has
												survived not only five centuries, but also the leap into electronic typesetting,
												remaining essentially unchanged.
											</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
							<div className="support-btn-section">
								<h3>When somebody asks me a question, I tell them the answer.</h3>
								<button className="blue-btn">CONTACT SUPPORT</button>
							</div>
						</div>
						{/* ===========  right side closed ============= */}
					</div>
					{/*setting box closed */}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}
export default Support;


