import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormControl, InputGroup, Dropdown, Table } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import { Link } from "react-router-dom";
import ScoreService from '../../services/score.service'
import MyCallSheetService from '../../services/mycallsheet.service'
import AuthService from '../../services/auth.service'
import TournamentService from '../../services/tournament.service'
import { connect } from 'react-redux';
import { showLoader, hideLoader } from "../../common/actions/application";
import { showMoviePopup , showActorPopup} from "../../common/actions/genericPopupAction";
import Moment from 'react-moment';
import moment from 'moment';
import NumberFormat from "react-number-format";
import defaultIcon from "../../assets/images/defaultIcon.jpeg";
import Won from '../../assets/images/won.png';
import {
  useHistory,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import CallSheetService from "../../services/callsheet.service";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MyExpire(props) {
  let query = useQuery();

  const [modalShow, setModalShow] = React.useState(false);
  const [callsheetActors, setCallsheetActors] = useState([]);
  const [callsheetDetails, setCallsheetDetails] = useState([]);

  useEffect(() => {
    getCallsheet();
  }, []);

  const getCallsheet = async () => {
    await CallSheetService.getCallSheetById(query.get("c")).then((resp) => {
      if (resp) {
        setCallsheetDetails(resp.data);
        setCallsheetActors(resp.data.ActorsList);
      }
    });
  };  

  const showScoreDetails = async (actor) => {
    props.dispatch(showLoader());
    const response = await ScoreService.getScoreById(actor.ScoreId);
    if (response) {
      // setModalShow(true)
      // setScoreDetails(response.data);
      props.dispatch(showMoviePopup(response.data));
    }
    props.dispatch(hideLoader());
  }


  return (
		<div className="mycontests">
			<HeaderTop />
			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycallsheet"}>
							<button>Upcoming </button>
						</Link>
						<Link to={"/mycallsheetlive"}>
							<button>Live </button>
						</Link>
						<Link to={"/expired"}>
							<button className="active">Expired</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="complete-row">
						<div className="left-heading">
							<h2>My Expired Call Sheets</h2>
						</div>

						<div className="up-contest-content">
							<div className="up-cont-box">
								<p>My Expired Call Sheets</p>
								<span>--</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>Completed Contests</p>
								<span>--</span>
							</div>
							{/*up cnt box closed */}

							<div className="up-cont-box">
								<Link to={"/"}>
									<button className="blue-btn">Create New Call Sheet</button>
								</Link>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					<div className="bg-dark-main">
						<div className="complete-row">
							<div className="left-heading">
								<h2>{callsheetDetails.PlayerName} </h2>
								<p>Created {moment(callsheetDetails.CreatedOn).format("MMMM Do, YYYY h:mm a")}</p>
							</div>

							<div className="up-contest-content">
								<div className="up-cont-box">
									<p>Total Winnings</p>
									<span>--</span>
								</div>
								{/*up cnt box closed */}
								<div className="up-cont-box">
									<p>Total Salary</p>
									<span>
										<NumberFormat
											value={callsheetDetails.TotalSalary}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
										/>
									</span>
								</div>
								{/*up cnt box closed */}
								<div className="up-cont-box">
									<p>Total Score</p>
									<span>{callsheetDetails.CallSheetTotal}</span>
								</div>
								{/*up cnt box closed */}

								<div className="up-cont-box">
									<Link to={"/expired"}>
										<button className="blu-border-btn">VIEW EXPIRED CALLSHEETS</button>
									</Link>
								</div>
								{/*up cnt box closed */}
							</div>
						</div>
						{/* ==== upcoming-contest-row closed  ========  */}
						<div className="smash-card-row">
							{callsheetActors.map((callsheetActor, index) => (
								<div className="col-card">
									<div className="card-box">
										<div className="card-images">
											{callsheetActor.profile_path ? (
												<img
													src={"https://image.tmdb.org/t/p/original" + callsheetActor.profile_path}
													className="user"
												/>
											) : (
												<img src={defaultIcon} className="user" />
											)}
										</div>
										<div className="content-card">
											<div className="heading-icn">
												{callsheetActor.Actor_Type == "Co-Star" ? (
													<img src={Costar} className="anchor-img" />
												) : callsheetActor.Actor_Type == "Headliner" ? (
													<img src={HeadingIcn} className="anchor-img" />
												) : (
													<span className="cust-dayplayer"> Dayplayer</span>
												)}
											</div>
											<h4 className="card-name">
												<a onClick={() => props.dispatch(showActorPopup(callsheetActor, callsheetDetails))}>
													{callsheetActor.Actor}
												</a>
											</h4>
											<a onClick={() => showScoreDetails(callsheetActor)}>{callsheetActor.Title}</a>
											<p className="card-rate">
												<NumberFormat
													value={callsheetActor.Salery}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="table-main scroll-table p-r-10 desktop-view">
							<Table striped hover>
								<thead>
									<tr>
										<th> </th>
										<th>
											<span>
												Tournament Name <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Date <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Score <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Rank <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Winnings <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<img src={Won} />
										</td>
										<td>
											<a> $100K Celebration Smash Tournament </a>
										</td>
										<td>Friday, 7:00PM</td>
										<td>$100,000</td>
										<td>44/50</td>
										<td>2d 4hrs 3mins</td>
										<td>
											<button>View Results</button>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
					{/* ========= div bg ============= */}
				</Container>
			</div>
			{/*  content closed  */}
			<FooterBottom />
		</div>
	);
}
// export default MyCallsheetExpire;
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(MyExpire)


