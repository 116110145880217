import React, { Component, useEffect, useState, useReducer } from 'react'; 
import '../../assets/css/main.css'; 
import { connect } from 'react-redux'; 
import 'bootstrap-daterangepicker/daterangepicker.css'; 
import StatsService from "../../services/stats.service";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Tournament Statistics",
		},
	},
};

function TournamentGraph() {
	const [tournament, setTournament] = useState([]);
	const [labels, setLabelArr] = useState([]);
	useEffect(() => {
		StatsService.getTournamentStats().then(async (resp) => {
			if (resp) {
				setTournament(resp);
				setLabelArr(resp.labels[0]);
			}
		});
	}, [0]);

	let data = {
		labels: labels,
		datasets: [
			{
				label: "Entered",
				data: tournament.entered,
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Completed",
				data: tournament.completed,
				backgroundColor: "rgb(75, 192, 192)",
			},
			{
				label: "Withdrawn",
				data: tournament.withdrawn,
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};
	return (
		<>
			<h4> Tournament Statistics</h4>
			<Bar options={options} data={data} />
		</>
	);
}
// export default Lobby;

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(TournamentGraph);
    
    
    
    
    
    
