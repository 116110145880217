import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Nav,
  NavDropdown,
  Dropdown,
  Table,
  FormControl, InputGroup,
} from "react-bootstrap";
import "../../../assets/css/main.css";
import "./style.css";
import HeadingIcn from "../../../assets/images/Space-Headline-Icon.svg";
import HeaderTop from "../../../components/header/index";
import FooterBottom from "../../../components/footer/index";
import Successful from "../../../assets/images/Successful.svg";
import User from "../../../assets/images/user.jpg";
import { connect } from "react-redux";
import TournamentService from "../../../services/tournament.service";
import Moment from "react-moment";
import moment from "moment";
import CallSheetService from "../../../services/callsheet.service";
import {
  showLoader,
  hideLoader,
  showSuccessAlert,
} from "../../../common/actions/application";
import NumberFormat from "react-number-format";
import ApplicationService from "../../../services/application.service";
import { Link } from "react-router-dom";
import Costar from "../../../assets/images/Co-Star-Icon.svg";
import defaultIcon from "../../../assets/images/defaultIcon.jpeg";
import {
  showActorPopup,
  showMoviePopup,
} from "../../../common/actions/genericPopupAction";
import ScoreService from "../../../services/score.service";
import ConfirmContesty from "../../confirmContestyEntryPopup";


function ContestWithCallsheet(props) {
  const [tournament, setTournament] = useState();
  const [callsheetActors, setCallsheetActors] = useState();
  const [callsheets, setCallsheets] = useState();
  const [callSheetActors, setCallSheetActors] = useState();
  const [callsheetData, setCallsheetData] = useState();
  const [contestyModalShow, setContestyModalShow] = useState(false);
  const [tourId, setTourId] = useState();
  const [oneCD3D, setOneCD3D] = React.useState(100);

  const getInCD3D = (inusd) => {
		return (inusd * oneCD3D).toFixed(2);
	};

	const getOneCD3D = async () => {
		const oneCD3D = await ApplicationService.getCD3D();
		setOneCD3D(oneCD3D);
	};

  useEffect(() => {
    getTournamentDetails();
  }, [props.tourId]);

  useEffect(() => {
		getOneCD3D();
		}, []);


  useEffect(() => {
    getCallSheetByTalentpoolId();
  }, [props.talentpoolId, props.userId]);

  const getTimeRemaining = (tournamentStartDate) => {
    // const future = moment(tournamentStartDate);
    // return future.endOf("day").fromNow();
    const now = moment();
    const future = moment(tournamentStartDate);
    const diff = future.diff(now);
    const diffDuration = moment.duration(diff);
    return (
      diffDuration.days() * 24 +
      diffDuration.hours() +
      "hrs " +
      diffDuration.minutes() +
      "mins"
    );
  };

  const getTournamentDetails = async () => {
    const response = await TournamentService.getTournamentById(props.tourId);
    if (response) {
      response.data.Starting_in = getTimeRemaining(response.data.Starting_in);
      setTournament(response.data);
    }
  };

  const showScoreDetails = async (actor) => {
    props.props.dispatch(showLoader());
    const response = await ScoreService.getScoreById(actor.ScoreId);
    if (response) {
      props.props.dispatch(showMoviePopup(response.data));
    }
    props.props.dispatch(hideLoader());
  };

  const getCallSheetByTalentpoolId = async () => {
		let inputParams = {
			talentPoolId: props.talentpoolId,
			userId: props.userId,
		};
		const response = await CallSheetService.getCallSheetByTalentpoolId(inputParams);
		if (response) {
			setCallsheets(response.data);
			setCallSheetActors(response.data[0]?.ActorsList);
			setCallsheetData(response.data[0]);
		}
	};

  return (
    <>
      <Modal
        {...props}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="adventure-popup main-popup cnf-contest"
      >
        <Modal.Header>
          <Modal.Title
            className="popup-heading"
            id="contained-modal-title-vcenter"
          >
            {tournament?.Tournament_Name}
          </Modal.Title>
          <Button onClick={() => props.onHide()} className="popup-close">
            <i className="fa fa-times" aria-hidden="true"></i>
          </Button>
          <Link to={"/callsheet?c=&t=" + tournament?.id}>
            <Button className="blue-btn " >DRAFT A NEW CALLSHEET</Button>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="smash-pop-box-row">
            <div className="smash-pop-box">
              <h5>Start</h5>
              <p>
                <Moment format="dddd, h:mm a">{tournament?.Starts}</Moment>
              </p>
            </div>
            <div className="smash-pop-box">
              <h5>Entry Fee</h5>
              <p>
              <NumberFormat
													value={
														tournament?.Tournament_Entry_Fee
                            == undefined || tournament?.Tournament_Entry_Fee
                            == null
															? 0
															: tournament.Tournament_Entry_Fee

													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "}
												(
												<NumberFormat
													value={getInCD3D(tournament?.Tournament_Entry_Fee
                            )}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
              </p>
            </div>
            <div className="smash-pop-box">
              <h5>Prizes</h5>
              <p>
               <NumberFormat
													value={
														tournament?.PrizePool == undefined || tournament?.PrizePool == null
															? 0
															: tournament.PrizePool
													}
													displayType={"text"}
													thousandSeparator={true}
													suffix={" CD3D"}
												/>
												{" "}
												(
												<NumberFormat
													value={getInCD3D(tournament?.PrizePool)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
												)
              </p>
            </div>

            <div className="smash-pop-box">
              <h5># of Players / Total Players
</h5>
              <p>
                {tournament?.No_Of_Players}/{tournament?.Total_Players}
              </p>
            </div>

            <div className="smash-pop-box">
              <h5>Starting in</h5>
              {/* <p>2d 4hrs 3mins</p> */}
              <p>{tournament?.Starting_in}</p>
            </div>
          </div>
          <div className="create-callsheet">
            <p>
              You have {callsheets?.length} call sheets that can be used in this
              contest.Select a call sheet below or draft a new one
            </p>
            <Row>
              <Col md="8" className="ply-gm">
                <Row>
                  <Col>
                    <Dropdown className="drop-btn">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {callsheetData
                          ? callsheetData?.PlayerName
                          : "Your callSheets"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {callsheets?.map((value, index) => {
                          return (
                            <Dropdown.Item
                              href={"#/action-" + index}
                              onClick={() => {
                                setCallSheetActors(value.ActorsList);
                                setCallsheetData(value);
                              }}
                            >
                              {value?.PlayerName}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p>
                      Created{" "}
                      <Moment format="MMMM DD, hh:mm a">
                        {callsheetData?.CreatedOn}
                      </Moment>
                    </p>
                  </Col>
                  <Col className="select-callsheet-spacing">
                    <button className="blu-border-btn bttn" onClick ={ ()=>{ setContestyModalShow(true) ;props.onHide()}}>
                      SELECT CALLSHEET
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="scroll-crd">
                      <div className="smash-card-row user-card">
                        {callSheetActors?.map((actor, index) => {
                          return (
                            <div className="col-card">
                              <div className="card-box">
                              <div className="card-images">	
														{actor.profile_path ? (
																<img
																	src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																	className="user"
																/>
															) : (
																<img src={defaultIcon} className="user" />
															)}
															</div>
                                <div className="content-card">
                                  <div className="heading-icn">
                                    {actor.Actor_Type == "Co-Star" ? (
                                      <img
                                        src={Costar}
                                        className="anchor-img"
                                      />
                                    ) : actor.Actor_Type == "Headliner" ? (
                                      <img
                                        src={HeadingIcn}
                                        className="anchor-img"
                                      />
                                    ) : (
                                      <span className="cust-dayplayer">
                                        {" "}
                                        Dayplayer
                                      </span>
                                    )}
                                  </div>
                                  <h4
                                    className="card-name"
                                    onClick={() =>
                                      props.props.dispatch(
                                        showActorPopup(actor, callsheetData)
                                      )
                                    }
                                  >
                                    {actor.Actor}
                                  </h4>
                                  <p onClick={() => showScoreDetails(actor)}>
                                    {actor.Title}
                                  </p>
                                  <p className="card-rate">
                                    <NumberFormat
                                      value={actor.Salery}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <div className="prize-box">
                  <h4>Prizes</h4>
                  <div className="table-main scroll-table p-r-10 ">
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>
                            <span>
                              Rank{" "}
                              <i
                                className="fa fa-long-arrow-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </th>
                          <th>
                            <span>
                              Prizes{" "}
                              <i
                                className="fa fa-long-arrow-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tournament?.PrizesRanking?.map((ranking, index) => {
                          return (
                            <tr>
                              <td>
                                {ranking.From === ranking.To
                                  ? ranking.From
                                  : ranking.From + " - " + ranking.To}
                              </td>
                              <td>
                                {/* {ranking.PrizeMoney} */}
                                
                                
                                <NumberFormat
                                  value={ranking.PrizeMoney}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={"CD3D"}
                                />
                                (
                                <NumberFormat
                                  value={getInCD3D(ranking.PrizeMoney)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />)
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="create-callsheet">
          <Row className="upcoming-contest-row mb-4">
          <Col>
          <p>Entrants</p>
          </Col>
          
          <Col md={4} sm={6}>
           
							<InputGroup className="search-btn">
								<FormControl
									placeholder="Search"
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
								/>
								<InputGroup.Text id="basic-addon2">
									<i className="fa fa-search" aria-hidden="true"></i>
								</InputGroup.Text>
							</InputGroup>
						</Col>
          </Row>
          <div className="ent-row">
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
            <a href="#">Swag Offical</a>
          </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmContesty show={contestyModalShow} onHide={() => setContestyModalShow(false)} id={props.tourId} callSheetId={callsheetData?.id} props={props.props}/>
    </>
  );
}

export default ContestWithCallsheet;
