import React, { Component } from "react";
import { withRouter } from "react-router-dom";

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

class AuthVerify extends Component {
	constructor(props) {
		super(props);
		const user = JSON.parse(localStorage.getItem("user"));

		if (user == null) {
			props.logOut();
		}

		// props.history.listen((location, action) => {
		//     console.log(location);
		//     const user = JSON.parse(localStorage.getItem("user"));

		//     if (!user) {
		//         props.logOut();
		//         // const decodedJwt = parseJwt(user.accessToken);

		//         // if (decodedJwt.exp * 1000 < Date.now()) {
		//         //     props.logOut();
		//         // }
		//     }
		// });
	}

	render() {
		return <div></div>;
	}
}

export default withRouter(AuthVerify);