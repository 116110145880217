import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;

class CustomNotificationService {
     getUserDetails = async (id) => {
         return await axios.get(API_URL + "custom-notifications/tournament-user-details/" + id).then((response) =>{
            if (response.status == 200) {
				return response.data;
			} else {
				alert("something went wrong");
			}
         })
     }

     sendCustomNotifications = async (inputParams) => {
        return await axios.post(API_URL + "custom-notifications" ,inputParams).then((response) => {
            if (response.status == 200) {
               return response.data;
            } else {
               alert("something went wrong");
            }
        })
     }
}



export default new CustomNotificationService();