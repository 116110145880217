import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Navbar, Nav, NavDropdown, Dropdown, Table } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import Won from '../../assets/images/won.png';
import { Link } from "react-router-dom";
import moment from 'moment';

import MyContestsService from '../../services/mycontests.service'
import Moment from 'react-moment';
import { showLoader, hideLoader } from "../../common/actions/application";
import { connect } from 'react-redux';
import { showTournamentPopup, showMoviePopup } from "../../common/actions/genericPopupAction";
import TournamentService from '../../services/tournament.service'
import AuthService from "../../services/auth.service";

const _ = require("lodash");

function CompletedTab(props) {
  const [callSheets, setCallSheets] = useState([]);
  const [callSheetsTable, setCallSheetsTable] = useState([]);
  let [upcommingContestsData, setUpcommingContestsData] = useState({});
  const [isDesc, setIsDesc] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    getMyCallSheet();
	getLoginUser();
  }, []);

  useEffect(() =>{
	  getMyCallSheet();
  },[userId])

  const getTimeRemaing = (tournamentStartDate) => {
    const future = moment(tournamentStartDate);
    return future.endOf('day').fromNow();
  }

  const getTimeRemaining = (tournamentStartDate) => {
	// const future = moment(tournamentStartDate);
	// return future.endOf("day").fromNow();
	const now = moment();
	const future = moment(tournamentStartDate);
	const diff =future.diff(now);
	const diffDuration = moment.duration(diff)
	if(future > now)
		return (diffDuration.days()+"d "+diffDuration.hours()+"hrs "+diffDuration.minutes()+"mins")
	else
		return (((diffDuration.days())*-1)+"d "+((diffDuration.hours())*-1)+"hrs "+((diffDuration.minutes())*-1)+"mins "+"ago")
		

};

const getLoginUser = () => {
	const response = AuthService.getAuth();
	if (response) {
		setUserId(response.data.id);
	}
};


  const getMyCallSheet = async () => {
    props.dispatch(showLoader());
    let inputParams = { "userid": userId }
    const response = await MyContestsService.getCompletedCallSheets(inputParams);
    if (response) {
      response.data.forEach(element => {
        element.Tournament[0].Starting_in = getTimeRemaing(element.Tournament[0].Starts);
      });

      let potentialWinning = 0;
      let callSheets = response.data;
      let contestCount = callSheets  ?.map(item => item.TournamentId)
        .filter((value, index, self) => self.indexOf(value) === index)
      contestCount.forEach(element => {
        let findedCallSheet = callSheets?.find(x => x.TournamentId == element);
        potentialWinning = potentialWinning + findedCallSheet?.Tournament[0]?.PrizesRanking[0]?.Rank;
      });

      let dataObject = {};
      dataObject.ContestCount = contestCount?.length;
      dataObject.PotentialWinning = potentialWinning;
      setUpcommingContestsData(dataObject);
      setCallSheets(response.data);
      setCallSheetsTable(response.data)
    }
    props.dispatch(hideLoader());
  }

  const showTournamentDetails = async (tournament) => {
    props.dispatch(showLoader());
    const response = await TournamentService.getTournamentById(tournament.id);
    if (response) {
	  response.data.Starting_in = getTimeRemaining(response.data.Starting_in)	
      props.dispatch(showTournamentPopup(response.data));
    }
    props.dispatch(hideLoader());
  }

  const sortCompletedContest = (tableName, label) =>{
     props.dispatch(showLoader());
	 let orderByFlag = isDesc ? "desc" : "asc";
	 let sortedTournament;
	 if (label === "Tournament_Name"){
		sortedTournament = _.orderBy(tableName, [(table) => table.Tournament[0].Tournament_Name], orderByFlag);
	 }
	 else{
		sortedTournament = _.orderBy(tableName, [(table) => (label === "EndDate" ? table.Tournament[0].EndDate:
		                                        label === "CallSheetTotal" ? table.Tournament[0].CallSheetTotal:
												label === "PlayerRank" ? table.PlayerRank:
												table.WinningPrice)],
												 orderByFlag);
	 }
     setCallSheets(sortedTournament);
	 setIsDesc(!isDesc);
	 props.dispatch(hideLoader());   
  }

  return (
		<div className="mycontests">
			<HeaderTop />
			<div className="content border-nav" style={{ padding: "15px 0px" }}>
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycontests"}>
							<button>Upcoming </button>
						</Link>
						<Link to={"/live"}>
							<button>Live </button>
						</Link>
						<Link to={"/completed"}>
							<button className="active">
								Completed<span className="ntf-no">{upcommingContestsData.ContestCount}</span>
							</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="complete-row">
						<div className="left-heading">
							<h2>My Completed Contests</h2>
							<div className="btn-group button-tb " role="group" aria-label="Basic radio toggle button group">
								<input
									type="radio"
									className="btn-check"
									name="btnradio"
									id="btnradio1"
									autocomplete="off"
									checked
								/>
								<label
									onClick={() => setCallSheets(callSheetsTable)}
									className="btn btn-outline-primary "
									for="btnradio1">
									All
								</label>

								<input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
								<label
									onClick={() =>
										setCallSheets(callSheetsTable.filter((callSheet) => callSheet.PlayerRank == "1"))
									}
									className="btn btn-outline-primary"
									for="btnradio2">
									<img src={Costar} /> Won
								</label>
							</div>
						</div>
						<div className="up-contest-content">
							<div className="up-cont-box">
								<p>Total Winnings</p>
								<span>2</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>My Contests</p>
								<span>{upcommingContestsData.ContestCount}</span>
							</div>
							{/*up cnt box closed */}

							<div className="up-cont-box">
								<Link to={"/"}>
									<button className="blue-btn">ENTER MORE CONTESTS</button>
								</Link>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					<div className="table-main scroll-table p-r-10 completed-table cls-sheet-tbl ">
						<Table striped hover>
							<thead>
								<tr>
									<th></th>
									<th>
										<a onClick={() => sortCompletedContest(callSheets, "Tournament_Name")}>
											<span>
												Tournament Name
												{isDesc ? (
													<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
												) : (
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												)}
											</span>
										</a>
									</th>
									<th>
										<a onClick={() => sortCompletedContest(callSheets, "EndDate")}>
											<span>
												Date
												{isDesc ? (
													<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
												) : (
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												)}
											</span>
										</a>
									</th>
									<th>
										<a onClick={() => sortCompletedContest(callSheets, "CallSheetTotal")}>
											<span>
												Score
												{isDesc ? (
													<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
												) : (
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												)}
											</span>
										</a>
									</th>
									<th>
										<a onClick={() => sortCompletedContest(callSheets, "PlayerRank")}>
											<span>
												Rank
												{isDesc ? (
													<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
												) : (
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												)}
											</span>
										</a>
									</th>
									<th>
										<a onClick={() => sortCompletedContest(callSheets, "WinningPrice")}>
											<span>
												Winnings
												{isDesc ? (
													<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
												) : (
													<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
												)}
											</span>
										</a>
									</th>
									<th>
										<span></span>
									</th>
								</tr>
							</thead>
							<tbody>
								{/* <tr>
                  <td><img src={Won} /></td>
                  <td>$100K Celebration Smash Tournament</td>
                  <td>June 25th, 2020 7:00 PM</td>
                  <td>1172.03</td>
                  <td>112/564</td>
                  <td>$0.00</td>
                  <td><button>VIEW RESULTS</button></td>
                </tr> */}
								{callSheets?.map((callSheet, index) => {
									return (
										<>
											<tr>
												<td>
													<img src={Won} />
												</td>
												<td>
													<a onClick={() => showTournamentDetails(callSheet.Tournament[0])}>
														{callSheet.Tournament[0].Tournament_Name} {callSheet.PlayerName}
													</a>
												</td>
												<td>
													<Moment format="MMM DD, yyyy h:mm a">{callSheet.Tournament[0].EndDate}</Moment>
												</td>
												<td>{callSheet.CallSheetTotal}</td>
												<td>
													{callSheet.PlayerRank}/{callSheet.Tournament[0].Total_Players}
												</td>
												<td>$0.00</td>
												<td>
													<Link to={"/view-result?c=" + callSheet.id}>
														<button type="button">VIEW RESULTS</button>
													</Link>
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</Table>
					</div>
					{/*  main table closed  */}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}


// export default CompletedTab;
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(CompletedTab)


