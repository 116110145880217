import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import User from "../../assets/images/user.jpg";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import { Link } from "react-router-dom";
import ScoreService from "../../services/score.service";
import MyCallSheetService from "../../services/mycallsheet.service";
import AuthService from "../../services/auth.service";
import TournamentService from "../../services/tournament.service";
import Moment from "react-moment";
import moment from "moment";
import NumberFormat from "react-number-format";
import defaultIcon from "../../assets/images/defaultIcon.jpeg";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../common/actions/application";
import {
	showTournamentPopup,
	showMoviePopup,
	showDelCallSheet,
	showDelConfCallSheet,
	userDelConfCallSheetFalse,
	showActorPopup,
} from "../../common/actions/genericPopupAction";

function MyCallsheet(props) {
	// const [modalShow, setModalShow] = React.useState(false);
	const [callSheets, setCallSheets] = useState([]);
	// const [scoreDetails, setScoreDetails] = useState({});
	const [tournaments, setTournaments] = useState([]);
	const [userId, setUserId] = useState("");
	const [userName, setUserName] = useState("");
	const [deletingCallSheetId, setDeletingCallSheetId] = useState("");

	// const [tournamentDetails, setTournamentDetails] = useState({});
	// const [modalShowTournament, setModalShowTournament] = useState(false);
	let [upcommingContestsData, setUpcommingContestsData] = useState({});

	useEffect(() => {
		getLoginUser();
		getTournamentsList();
		getMyCallSheet();
	}, []);

	useEffect(() =>{
		getMyCallSheet();
	},[userId]);

	const getTournamentsList = async () => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentsList();
		response.data.forEach((element) => {
			element.Starting_in = getTimeRemaing(element.Starting_in);
		});
		setTournaments(response.data);
		props.dispatch(hideLoader());
	};

	const getTimeRemaing = (tournamentStartDate) => {
		// const future = moment(tournamentStartDate);
		// return future.endOf("day").fromNow();
		const now = moment();
		const future = moment(tournamentStartDate);
		const diff =future.diff(now);
		const diffDuration = moment.duration(diff)
		if (future > now)
			return diffDuration.days() * 24 + diffDuration.hours() + "hrs " + diffDuration.minutes() + "mins";
		else return "Running";

	};

	const getLoginUser = () => {
		const response = AuthService.getAuth();
		if (response) {
			setUserId(response.data.id);
			setUserName(response.data.name);
			getMyCallSheet(response.data.id);
		}
	};

	const getMyCallSheet = async () => {
		let inputParams = { userid: userId};
		props.dispatch(showLoader());
		const response = await MyCallSheetService.getCallSheetsByStatus(inputParams);
		if (response) {
			let potentialWinning = 0;
			let callSheets = response.data;
			let contestCount = callSheets
				?.map((item) => item.TournamentId)
				.filter((value, index, self) => self.indexOf(value) === index);
			contestCount.forEach((element) => {
				let findedCallSheet = callSheets?.find((x) => x.TournamentId == element);
				potentialWinning = potentialWinning + findedCallSheet?.Tournament[0]?.PrizesRanking[0]?.Rank;
			});

			let dataObject = {};
			dataObject.ContestCount = contestCount?.length;
			dataObject.PotentialWinning = potentialWinning;
			setUpcommingContestsData(dataObject);
			setCallSheets(response.data);
		}
		props.dispatch(hideLoader());
	};

	const showScoreDetails = async (actor) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getScoreById(actor.ScoreId);
		if (response) {
			// setModalShow(true)
			// setScoreDetails(response.data);
			props.dispatch(showMoviePopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const showTournamentDetails = async (tournament) => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentById(tournament.id);
		if (response) {
			// setModalShowTournament(true)
			// setTournamentDetails(response.data);
			props.dispatch(showTournamentPopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const deleteCallSheet = async (callsheet) => {
		setDeletingCallSheetId(callsheet.id);
		props.dispatch(showDelCallSheet(callsheet));
	};

	if (props.userConfrimDelete) {
		props.dispatch(showLoader());
		let object = {
			isActive: false,
			callSheetId: deletingCallSheetId,
		};
		MyCallSheetService.deleteCallSheetsById2(object).then((resp) => {
			if (resp.data) {
				getMyCallSheet();
				props.dispatch(userDelConfCallSheetFalse());
				props.dispatch(showDelConfCallSheet());
			}
		});
		props.dispatch(hideLoader());
	}

	return (
		<div className="mycontests">
			<HeaderTop />

			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycallsheet"}>
							<button className="active">
								Upcoming <span className="ntf-no">{callSheets.length}</span>
							</button>
						</Link>
						<Link to={"/mycallsheetlive"}>
							<button>Live </button>
						</Link>
						<Link to={"/expired"}>
							<button>Expired</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="upcoming-contest-row">
						<h2>My Upcoming Call Sheets</h2>
						<div className="up-contest-content">
							<div className="up-cont-box">
								<p>My Upcoming Call Sheets</p>
								<span>{callSheets.length}</span>
							</div>
							{/*up cnt box closed */}
							<div className="up-cont-box">
								<p>My Upcoming Contests</p>
								<span>{upcommingContestsData.ContestCount}</span>
							</div>
							{/*up cnt box closed */}

							<div className="up-cont-box btn-sm-100">
								<button className="blue-btn">REPLACE AN ACTOR IN ALL CALL SHEETS</button>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
					{callSheets?.filter((value) => value.UserId == userId).map((callSheet, index) => {
						return (
							<div key={index + callSheet.id} className="smash-box">
								<div className="c-smash-row">
									<div className="smash-heading">
										<h3>{callSheet.PlayerName}</h3>
										<span>
											Starts <Moment format="MMM DD, yyyy h:mm a">{callSheet.Tournament[0].Starts}</Moment>
										</span>
									</div>
									<div className="smash-content">
										<div className="smash-cnt-box">
											<p>MC Critics Score</p>
											<span>{callSheet.MCCriticsScore}%</span>
										</div>
										{/* smash box closed */}
										{callSheet.IsTiebreaker ? (
											<div className="smash-cnt-box">
												<p className="pink-text">
													<i className="fa fa-angle-double-left" aria-hidden="true"></i> Tiebreaker
													<i className="fa fa-angle-double-right" aria-hidden="true"></i>
												</p>
											</div>
										) : (
											""
										)}
										<div className="smash-cnt-box">
											<p>IMDb User Score</p>
											<span>{callSheet.IMDbUserScore}</span>
										</div>
										{/* smash box closed */}
										<div className="smash-cnt-box">
											<p>Total Salary</p>
											<span>
												<NumberFormat
													value={callSheet.Tournament[0]?.Prizes}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</span>
										</div>
										{/* smash box closed */}
										<div className="smash-cnt-box">
											<Link to={"/callsheet?c=" + callSheet.id + "&t=" + callSheet.TournamentId}>
												<button className="blu-border-btn">EDIT CALL SHEET</button>
											</Link>
										</div>
										{/* smash box closed */}
										<div className="smash-cnt-box closed ">
											<button className="delete-btn" type="button" onClick={() => deleteCallSheet(callSheet)}>
												<i className="fa fa-trash" aria-hidden="true"></i>
											</button>
										</div>
										{/* smash box closed */}
									</div>
									{/* ==== smashcontent closed == */}
								</div>
								{/* =========  smashcontent closed ====== */}
								<div className="smash-card-row">
									{callSheet.ActorsList.map((actor, index) => {
										return (
											<div key={index + actor.id} className="col-card">
												<div className="card-box">
													<div className="card-images">
														{actor.profile_path ? (
															<img
																src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																className="user"
															/>
														) : (
															<img src={defaultIcon} className="user" />
														)}
													</div>

													<div className="content-card">
														<div className="heading-icn">
															{actor.Actor_Type == "Co-Star" ? (
																<img src={Costar} className="anchor-img" />
															) : actor.Actor_Type == "Headliner" ? (
																<img src={HeadingIcn} className="anchor-img" />
															) : (
																<span className="cust-dayplayer"> Dayplayer</span>
															)}
														</div>
														<h4
															className="card-name"
															onClick={() => props.dispatch(showActorPopup(actor, callSheet))}>
															{actor.Actor}
														</h4>
														{/* <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
														<p onClick={() => showScoreDetails(actor)}>{actor.Title}</p>
														{/* <Adventure
                                    show={modalShow}
                                    onHide={() => setModalShow(false)} /> */}
														<p className="card-rate">
															<NumberFormat
																value={actor.Salery}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
								{/* ========= card row closed ============= */}
								<h3>Contests with Call Sheet</h3>
								<div className="table-main cls-sheet-tbl">
									<Table striped hover>
										<thead>
											<tr>
												<th>Tournament Name </th>
												<th>Starts</th>
												<th>Prizes</th>
												<th># of Players / Total Players
</th>
												<th>Starting in</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{tournaments.filter((value) => value.TourTalentPoolId == (callSheet?.Tournament[0]?.TourTalentPoolId)).map((tournament, index) => {
												return (
													<tr key={index + tournament.id}>
														<td>
															<a onClick={() => showTournamentDetails(tournament)}>
																{tournament.Tournament_Name}
															</a>
															{/* <MyVerticallyCenteredModal
                                  show={modalShowTournament}
                                  onHide={() => setModalShowTournament(false)} tournamentDetails={tournamentDetails} /> */}
														</td>
														<td>
															<Moment format="M/DD/yyyy, h:mm a">{tournament.Starts}</Moment>
														</td>
														<td>
															<NumberFormat
																value={tournament?.PrizePool}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
														</td>
														<td>
															{tournament?.No_Of_Players}/{tournament?.Total_Players}
														</td>
														<td>{tournament?.Starting_in}</td>
														{/* <td>2d 4hrs 3mins</td> */}
														<td>
														<Link to={"/contestlive?t=" + tournament?.id}>
															<button className="blu-border-btn">VIEW CONTEST</button>
														</Link>
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
								{/*  main table closed  */}
							</div>
						);
					})}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}
// export default MyCallsheet;
const mapStateToProps = (state) => ({ userConfrimDelete: state.genericsPopup.userConfrimDelete });
export default connect(mapStateToProps)(MyCallsheet);
