import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import { Link, useLocation } from "react-router-dom";
import ScoreService from '../../services/score.service'
import AuthService from '../../services/auth.service'
import TournamentService from '../../services/tournament.service'
import CallSheetService from '../../services/callsheet.service'
import Moment from 'react-moment';
import moment from 'moment';
import NumberFormat from "react-number-format";
import { showLoader, hideLoader } from "../../common/actions/application";
import { connect } from 'react-redux';
import { showTournamentPopup, showMoviePopup, showActorPopup} from "../../common/actions/genericPopupAction";
import ConfirmContesty from "../confirmContestyEntryPopup";
import { Button } from "bootstrap";
import ContestWithCallsheet from "../genericsPopups/contestWithCallsheet";
import defaultIcon from "../../assets/images/defaultIcon.jpeg"
//import ConfirmContesty from "../genericsPopups/src\view\confirmContestyEntryPopup\index.js";

const _ = require("lodash");
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CallSheetEntered(props) {
	let query = useQuery();
	const [callSheet, setCallSheet] = useState([]);
	const [callSheetActors, setCallSheetActors] = useState([]);
	const [userId, setUserId] = useState("");
	const [tournaments, setTournaments] = useState([]);
	const [id, setId] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [contestModal, setContestModal] = useState(false);
	const [talentpoolId, setTalentpoolId] = useState(false);

	useEffect(() => {
		getLoginUser();
		getTournamentsList();
	}, []);

	const getTournamentsList = async () => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentsList();
		response.data.forEach((element) => {
			element.Starting_in = getTimeRemaing(element.Starting_in);
		});
		setTournaments(response.data.filter((value) => new Date(Date.now()) < new Date(value.Starts)));
		props.dispatch(hideLoader());
	};

	const showTournamentDetails = async (tournament) => {
		props.dispatch(showLoader());
		const response = await TournamentService.getTournamentById(tournament.id);
		if (response) {
			props.dispatch(showTournamentPopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const getLoginUser = () => {
		const response = AuthService.getAuth();
		if (response) {
			setUserId(response.data.id);
			getCallSheetById();
		}
	};

	const getTimeRemaing = (tournamentStartDate) => {
		const now = moment();
		const future = moment(tournamentStartDate);
		const diff = future.diff(now);
		const diffDuration = moment.duration(diff);
			
		return diffDuration.days() * 24 + diffDuration.hours()+"hrs "+diffDuration.minutes()+"mins";
	};

	const getCallSheetById = async () => {
		props.dispatch(showLoader());
		await CallSheetService.getCallSheetById(query.get("c")).then((resp) => {
			if (resp) {
				resp.data.Tournament[0].Starting_in = getTimeRemaing(resp.data.Tournament[0].Starting_in);
				let newArry = [];
				let sortedActors;
				newArry.push(resp.data);
				setCallSheet(newArry);
				sortedActors = _.orderBy(resp.data.ActorsList, [(data) => Number(data.Salery)], "desc");
				setCallSheetActors(sortedActors);
				props.dispatch(hideLoader());
			}
		});
	};

	const showScoreDetails = async (actor) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getScoreById(actor.ScoreId);
		if (response) {
			// setModalShow(true)
			// setScoreDetails(response.data);
			props.dispatch(showMoviePopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	const showActorDetails = async (actor) => {
		props.dispatch(showActorPopup(actor));
	};


	return (
		<div className="mycontests">
			<HeaderTop />

			<div className="border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/mycontests"}>
							<button>
								Upcoming <span className="ntf-no">4</span>
							</button>
						</Link>
						<Link to={"/live"}>
							<button>Live </button>
						</Link>
						<Link to={"/completed"}>
							<button>Completed</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="upcoming-contest-row">
						<h2>Call Sheet Entered!</h2>
						<div className="up-contest-content">
							<div className="up-cont-box btn-sm-100">
								<Link to={"/"}>
									<button className="blue-btn">ENTER MORE CONTESTS</button>
								</Link>
							</div>
							{/*up cnt box closed */}
						</div>
					</div>
					{/* ==== upcoming-contest-row closed  ========  */}
				
					{callSheet?.map((callSheet, index) => {
						return (
							<>
								<div className="smash-box">
									<div className="c-smash-row">
										<div className="smash-heading">
											<h3>{callSheet?.Tournament[0]?.Tournament_Name}</h3>
											<span>
												Starts <Moment format="MM/DD/yyyy, h:mm a">{callSheet.Tournament[0]?.Starts}</Moment>
											</span>
										</div>
										<div className="smash-content">
											<div className="smash-cnt-box">
												<p>MC Critics Score</p>
												<span>{callSheet.MCCriticsScore}%</span>
											</div>
											{/* smash box closed */}
											{callSheet.IsTiebreaker ? (
												<div className="smash-cnt-box">
													<p className="pink-text">
														<i className="fa fa-angle-double-left" aria-hidden="true"></i> Tiebreaker
														<i className="fa fa-angle-double-right" aria-hidden="true"></i>
													</p>
												</div>
											) : (
												""
											)}
											<div className="smash-cnt-box">
												<p>IMDb User Score</p>
												<span>{callSheet.IMDbUserScore}</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p>Prizes</p>
												<span>
													<NumberFormat
														value={callSheet.Tournament[0]?.PrizePool}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
													/>
												</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p># of Players / Total Players
</p>
												<span>
													{callSheet.Tournament[0]?.No_Of_Players}/{callSheet.Tournament[0]?.Total_Players}
												</span>
											</div>
											{/* smash box closed */}
											<div className="smash-cnt-box">
												<p>Starting In</p>
												<span>{callSheet.Tournament[0].Starting_in}</span>
											</div>
											<div className="smash-cnt-box">
												<Link to={"/callsheet?c=" + callSheet.id + "&t=" + callSheet.Tournament[0]?.id}>
													<button className="blu-border-btn"> EDIT CALL SHEET</button>
												</Link>
											</div>
											{/* smash box closed */}
										</div>
										{/* ==== smashcontent closed == */}
									</div>
									{/* =========  smashcontent closed ====== */}
									<div className="smash-card-row">
										{callSheetActors.map((actor, index) => {
											return (
												<>
													<div className="col-card">
														<div className="card-box">
														<div className="card-images">	
														<div className="card-images">	
														{actor.profile_path ? (
																<img
																	src={"https://image.tmdb.org/t/p/original" + actor.profile_path}
																	className="user"
																/>
															) : (
																<img src={defaultIcon} className="user" />
															)}
															</div>
															</div>
															<div className="content-card">
																<div className="heading-icn">
																	{actor.Actor_Type == "Co-Star" ? (
																		<img src={Costar} className="anchor-img" />
																	) : actor.Actor_Type == "Headliner" ? (
																		<img src={HeadingIcn} className="anchor-img" />
																	) : (
																		<span className="cust-dayplayer"> Dayplayer</span>
																	)}
																</div>
																<h4
																	className="card-name"
																	onClick={() => props.dispatch(showActorPopup(actor, callSheet))}>
																	{actor.Actor}
																</h4>
																<p onClick={() => showScoreDetails(actor)}>{actor.Title}</p>
																<p className="card-rate">
																	<NumberFormat
																		value={actor.Salery}
																		displayType={"text"}
																		thousandSeparator={true}
																		prefix={"$"}
																	/>
																</p>
															</div>
														</div>
													</div>
													{/* card closed */}

													{/* ========= card row closed ============= */}
												</>
											);
										})}
									</div>
									<h3>Add Call Sheet to Another Contest</h3>
									<div className="table-main cls-sheet-tbl">
										<Table striped hover>
											<thead>
												<tr>
													<th>Tournament Name </th>
													<th>Starts</th>
													<th>Prizes</th>
													<th># of Players / Total Players
</th>
													<th>Starting in</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{tournaments.filter((value) => value.TourTalentPoolId === (callSheet?.Tournament[0]?.TourTalentPoolId)).map((tournament, index) => {
													return (
														<tr key={index}>
															<td
																onClick={() => {
																	setContestModal(true);
																	setTalentpoolId(tournament.TourTalentPoolId);
																	setId(tournament.id);
																}}>
																{/* <a   onClick={() => showTournamentDetails(tournament)}>
																	{tournament.Tournament_Name}
																</a> */}
																{tournament.Tournament_Name}
															</td>
															<td>
																<Moment format="M/DD/yyyy, h:mm a">{tournament.Starts}</Moment>
															</td>
															<td>
																<NumberFormat
																	value={tournament.PrizePool}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"$"}
																/>
															</td>
															<td>
																{tournament.No_Of_Players}/{tournament.Total_Players}
															</td>
															<td>{tournament.Starting_in}</td>
															{/* <td>2d 4hrs 3mins</td> */}
															<td>
																{/* <Link
																	to={"/callsheet?c=&t=" + tournament.id}
																	onClick={() => localStorage.setItem("CallSheetId", null)}>
																	{" "}
																	<button>Add Call Sheet</button>{" "}
																</Link> */}
																<button
																	onClick={() => {
																		setModalShow(true);
																		setId(tournament.id);
																	}}>
																	{" "}
																	ADD CALL SHEET
																</button>
																{/* <ConfirmContesty id = {tournament.id} ></ConfirmContesty> */}
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
									{/*  main table closed  */}
								</div>
								{/* ========================== smash  closed  ========================  */}
							</>
						);
					})}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />

			<ConfirmContesty show={modalShow} onHide={() => setModalShow(false)} id={id} callSheetId={query.get("c")} props={props}/>
            <ContestWithCallsheet show={contestModal} onHide={() => setContestModal(false)} tourId={id} talentpoolId={talentpoolId} userId={userId} props={props}/>
		</div>
	);
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(CallSheetEntered)


