import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, InputGroup, FormControl } from "react-bootstrap";
import "../../assets/css/main.css";
import "./style.css";
import HeaderTop from "../../components/header/index";
import FooterBottom from "../../components/footer/index";
import HeadingIcn from "../../assets/images/Space-Headline-Icon.svg";
import Costar from "../../assets/images/Co-Star-Icon.svg";
import User from "../../assets/images/user.jpg";
import Callsheet from "../../assets/images/callsheet.svg";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import { useHistory, Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import CallSheetService from "../../services/callsheet.service";
import TournamentService from "../../services/tournament.service";
import MyContestsService from "../../services/mycontests.service";
import NumberFormat from "react-number-format";
import {
	showLoader,
	hideLoader,
	showSuccessAlert,
	showWarningAlert,
	showErrorAlert,
} from "../../common/actions/application";
import { showTournamentPopup, showMoviePopup, showActorPopup } from "../../common/actions/genericPopupAction";
import ScoreService from "../../services/score.service";
import { map } from "lodash";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const _ = require("lodash");

function ContestLive(props) {
	let query = useQuery();

	const [tournamentId, setTournamentId] = useState(query.get("t"));
	const [callsheetActors, setCallsheetActors] = useState([]);
	const [callsheetDetails, setCallsheetDetails] = useState([]);
	const [tournamentResults, setTournamentResults] = useState([]);
	const [allTournamentResults, setAllTournamentResults] = useState([]);
	const [tournamentDetails, setTournamentDetails] = useState([]);
	const [isSelected, setIsSelected] = useState("");
	const [userData, setUserData] = useState("");
	const [userName, setUserName] = useState("");
	const [isDesc, setIsDesc] = useState(false);
	const [scoreSum, setScoreSum] = useState([]);
	const [tourScore, setTourScore] = useState("")

	useEffect(() => {
		getCallsheet();
		getCallSheets();
		getTournamentById();
		getUsername();
	}, []);

	const getCallsheet = async () => {
		await CallSheetService.getCallSheetById(query.get("c")).then((resp) => {
			if (resp) {
				setCallsheetDetails(resp.data);
				setCallsheetActors(resp.data.ActorsList);
				setIsSelected(resp.data.PlayerName);
			}
		});
	};

	// const getTournamentResultList = async () => {
	//   let inputParams = { tournamentId: query.get("t") };
	//   const response = await CallSheetService.getTournamentResult(inputParams);
	//   if (response) {
	//     let data = response.data;
	//     debugger
	//     setTournamentResults(data);
	//   }
	// }

	const getTimeRemaing = (tournamentEndDate) => {
		// const future = moment(tournamentStartDate);
		// return future.endOf("day").fromNow();
		const now = moment();
		const future = moment(tournamentEndDate);
		const diff = future.diff(now);
		const diffDuration = moment.duration(diff);
		if (future > now) {
			return diffDuration.days() + "d " + diffDuration.hours() + "hrs " + diffDuration.minutes() + "mins";
		} else {
			return (
				diffDuration.days() * -1 +
				"d " +
				diffDuration.hours() * -1 +
				"hrs " +
				diffDuration.minutes() * -1 +
				"mins " +
				"ago"
			);
		}
	};

	const getCallSheets = async () => {
		// const userData = AuthService.getAuth();
		let inputParams = { tournamentId: tournamentId };
		const response = await MyContestsService.getCallSheetsByTourId(inputParams);
		if (response) {
			let filterObject = [];
			filterObject = response.data;
			let statusFilter = filterObject.filter((value) => value?.Status === "Submited");
			setTournamentResults(statusFilter);
			let newArray = ""
			statusFilter.map((value) => {
				value.ActorsList.forEach(element => {
					newArray= Math.round(Number(newArray)+element.Total_Scores)
				});
				setScoreSum((preValue) => [...preValue, newArray])
				})
			setAllTournamentResults(statusFilter);
		}
	};

	//   const getTournamentById = async () => {
	// 	props.dispatch(showLoader());
	// 	const response = await TournamentService.getTournamentById(query.get("t"));
	// 	response.data.forEach((element) => {
	// 		element.EndDate = getTimeRemaing(element.EndDate);
	// 	});
	// 	setTournamentDetails(response.data);
	// 	props.dispatch(hideLoader());
	// };

	const getTournamentById = async () => {
		// let inputParams = { tournamentId: tournamentId }
		await TournamentService.getTournamentById(query.get("t")).then((resp) => {
			if (resp) {
				resp.data.EndDate = getTimeRemaing(resp.data.EndDate);
				setTournamentDetails(resp.data);
			}
		});
	};

	const getSelectedCallSheet = async (callSheetId) => {
		await CallSheetService.getCallSheetById(callSheetId).then((resp) => {
			if (resp) {
				setCallsheetDetails(resp.data);
				setCallsheetActors(resp.data.ActorsList);
				setIsSelected(resp.data.PlayerName);
			}
		});
	};

	const getUsername = async () => {
		await CallSheetService.getCallSheetById(query.get("c1")).then((resp) => {
			if (resp) {
				setUserData(resp.data);
				setUserName(resp.data.PlayerName);
			}
		});
	};

	const getFilteredPlayers = async (serachParam) => {
		props.dispatch(showLoader());
		let newArray = [];
		allTournamentResults.filter((element) => {
			let tempFilter = element.PlayerName.toLowerCase().includes(serachParam.toLowerCase());
			if (tempFilter) {
				newArray.push(element);
			}
		});
		setTournamentResults(newArray);
		props.dispatch(hideLoader());
	};

	//   const getFilteredPlayers = async (serachParam) => {
	// 		let playerFilterInputParams;
	// 		if (serachParam === "" || serachParam.length > 1) {
	// 			playerFilterInputParams = { player: "" };
	// 			setTournamentResults(allTournamentResults);
	// 		} else {
	// 			playerFilterInputParams = {
	// 				player: serachParam,
	// 			};
	// 		}

	// 		props.dispatch(showLoader());
	// 		let newArray = [];
	// 		allTournamentResults.filter((element) => {
	// 			debugger
	// 			let tempFilter = (element.PlayerName.toLowerCase().includes(serachParam.toLowerCase()));
	// 			if (tempFilter) {
	// 				newArray.push(element);
	// 			}
	// 		});
	// 		setTournamentResults(newArray);
	// 		props.dispatch(hideLoader());
	// };

	const handleInputSearchChange = (event) => {
		const searchString = event.currentTarget.value;
		getFilteredPlayers(searchString);
	};

	//sorting players and actors table
	const sortTableDetails = (tableName, label) => {
		props.dispatch(showLoader());
		let orderByFlag = isDesc ? "desc" : "asc";
		let sortedTable;
		if (label === "PlayerName" || label === "Actor" || label === "Platform") {
			sortedTable = _.orderBy(
				tableName,
				[
					(table) =>
						label === "PlayerName"
							? table.PlayerName.toLowerCase().trim()
							: label === "Actor"
							? table.Actor.toLowerCase().trim()
							: table.Platform.toLowerCase().trim(),
				],
				orderByFlag
			);
		}
		sortedTable = _.orderBy(tableName, label, orderByFlag);
		tableName == tournamentResults ? setTournamentResults(sortedTable) : setCallsheetActors(sortedTable);
		setIsDesc(!isDesc);
		props.dispatch(hideLoader());
	};

	const showScoreDetails = async (actor) => {
		props.dispatch(showLoader());
		const response = await ScoreService.getScoreById(actor.ScoreId);
		if (response) {
			// setModalShow(true)
			// setScoreDetails(response.data);
			props.dispatch(showMoviePopup(response.data));
		}
		props.dispatch(hideLoader());
	};

	// const getActorTotalScore = async (actor) => {
	// 	props.dispatch(showLoader());
	// 	// switch statment for actor.Actor_Type == "Headliner" or "Dayplayer" or "Co-Star"
	// 	switch (actor.Actor_Type) {
	// 		case "Headliner":
	// 			actor.TotalScore = actor.Salery + actor.Salery * 0.2;
	// 			break;
	// 		case "Co-Star":
	// 			actor.TotalScore = actor.Salery + actor.Salery * 0.2;
	// 			break;
	// 		case "Dayplayer":
	// 			actor.TotalScore = actor.Salery + actor.Salery * 0.2;
	// 			break;

	// 		default:
	// 			break;
	// 	}

	// 	props.dispatch(hideLoader());
	// };

	const showActorDetails = async (actor, callSheet) => {
		props.dispatch(showActorPopup(actor, callSheet));
	  };

	return (
		<div className="mycontests live">
			<HeaderTop />

			<div className="">
				<Container fluid>
					<div className="contest-row">
						<div className="contest-left ">
							<div className="page-ntf">
								<Link to={"/mycontests"}>
									<button>Upcoming </button>
								</Link>
								<Link to={"/live"}>
									<button className="active">Live </button>
								</Link>
								<Link to={"/completed"}>
									<button>Completed</button>
								</Link>
							</div>
							{/* ===== page notification closed ===== */}
							{/* ===== page notification closed ===== */}
							<Row className="upcoming-contest-row mb-4 mcl-row">
								<Col md={5}>
									<h2>My Live Contests</h2>
								</Col>
								<Col md={7}>
									<div className="contest-content-right">
										<Link to={"/"}>
											<button className="blue-btn">ENTER MORE CONTESTS</button>
										</Link>
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="cnt-bg">
								<Row className="upcoming-contest-row mb-4 mcl-row-1">
									<Col md={5}>
										<h2>{tournamentDetails.Tournament_Name}</h2>
										<InputGroup className="search-btn">
											<FormControl
												placeholder="Search"
												aria-label="Recipient's username"
												aria-describedby="basic-addon2"
												onChange={handleInputSearchChange}
											/>
											<InputGroup.Text id="basic-addon2">
												<i className="fa fa-search" aria-hidden="true"></i>
											</InputGroup.Text>
										</InputGroup>
									</Col>
									<Col md={7}>
										<div className="contest-content-right">
											<div className="up-cont-box">
												<p>Prizes</p>
												<NumberFormat
													value={tournamentDetails.PrizePool}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}></NumberFormat>
											</div>
											{/*up cnt box closed */}
											<div className="up-cont-box">
												<p>Currently Winning</p>
												<span>{}</span>
											</div>
											{/*up cnt box closed */}
											<div className="up-cont-box">
												<button
													onClick={() => {
														query.get("c1")
															? getSelectedCallSheet(query.get("c1"))
															: getSelectedCallSheet(query.get("c"));
													}}
													className="blu-border-btn">
													VIEW MY CALL SHEET
												</button>
											</div>
											{/*up cnt box closed */}
										</div>
									</Col>
								</Row>
								{/* ==== upcoming-contest-row closed  ========  */}
								<div className="table-main scroll-table p-r-10  contest-table">
									<Table striped hover>
										<thead>
											<tr>
												<th>
													<span>
														Rank <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
													</span>
												</th>
												<th>
													<a onClick={() => sortTableDetails(tournamentResults, "PlayerName")}>
														<span>
															Player Name
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortTableDetails(tournamentResults, "Score")}>
														<span>
															Score
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortTableDetails(tournamentResults, "TotalSalary")}>
														<span>
															Budget
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortTableDetails(tournamentResults, "RemaingSalary")}>
														<span>
															Remaining Salary
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th>
													<a onClick={() => sortTableDetails(tournamentResults, "Winnings")}>
														<span>
															Winnings
															{isDesc ? (
																<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
															) : (
																<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
															)}
														</span>
													</a>
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{tournamentResults.map((participant, index) => {
												return (
													<>
														<tr
															key={index}
															onClick={() => {
																getSelectedCallSheet(participant?.id);
																setIsSelected(participant?.PlayerName);
																setTourScore(scoreSum[index]);
															}}
															className={callsheetDetails.id === participant?.id ? "green-bg" : ""}>
															<td>{index + 1}</td>
															<td>{participant?.PlayerName}</td>
															<td>{scoreSum[index]}</td>
															<td>
																<NumberFormat
																	value={participant?.TotalSalary}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"$"}
																/>
															</td>
															<td>
																<NumberFormat
																	value={participant?.RemaingSalary}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"$"}
																/>
															</td>
															<td>{}</td>
															<td>
																{/* <button type="button" onClick={() => { getSelectedCallSheet(participant?.id); setIsSelected(true) }} className="white-border-btn">
                                    {isSelected ? "VIEWING CALLSHEET" :
                                      "VIEW CALLSHEET"}
                                  </button> */}
																{callsheetDetails.id === participant?.id ? (
																	<button type="button">VIEWING</button>
																) : (
																	<button type="button">VIEW CALL SHEET</button>
																)}
															</td>
														</tr>
													</>
												);
											})}
										</tbody>
									</Table>
								</div>
								{/*  main table closed  */}
							</div>
						</div>
						{/* ========================== left contests  closed  ========================  */}
						<div className="contest-right">
							<Row className="upcoming-contest-row">
								<Col md={8}>
									<div className="callsheet-img-row">
										<img src={Callsheet} />
										<div className="ctn-cls">
											<h2>{callsheetDetails.PlayerName}</h2>
										</div>
									</div>
								</Col>
								<Col md={4}>
									<div className="contest-content-right">
										<Link to={"/live"}>
											<button className="red-cls-btn">
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										</Link>
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<Row className="player-left">
								<Col md={12}>
									<div className="contest-content-right">
										<div className="up-cont-box">
											<p>Rank</p>
											<span>{callsheetDetails.PlayerRank}</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Budget</p>
											<span>
												<NumberFormat
													value={callsheetDetails?.TotalSalary}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												/>
											</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Value</p>
											<span>{callsheetDetails.PtsPer1k}</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Score</p>
											<span>{tourScore}</span>
										</div>
										{/*up cnt box closed */}
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="table-main scroll-table p-r-10  contest-table">
								<Table striped hover>
									<thead>
										<tr>
											<th></th>
											<th>
												<span>Actor Type</span>
											</th>
											<th>
												<a onClick={() => sortTableDetails(callsheetActors, "Actor")}>
													<span>
														Title
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a onClick={() => sortTableDetails(callsheetActors, "Salery")}>
													<span>
														Salary
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
											<th>
												<a onClick={() => sortTableDetails(callsheetActors, "Score")}>
													<span>
														Score
														{isDesc ? (
															<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
														) : (
															<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														)}
													</span>
												</a>
											</th>
										</tr>
									</thead>
									<tbody>
										{callsheetActors.map((callsheetActor, index) => {
											// getActorTotalScore(callsheetActor);
											return (
												<tr key={index}>
													<td>
														<div className="user-hover">
															<img
																src={
																	callsheetActor.profile_path
																		? "https://image.tmdb.org/t/p/original" + callsheetActor.profile_path
																		: User
																}
																className="user"
																onClick={() => {
                                                                   showActorDetails(callsheetActor);
																}}
															/>
															<span>{callsheetActor.Actor}</span>
														</div>
													</td>
													<td>
														{callsheetActor.Actor_Type == "Co-Star" ? (
															<img src={Costar} className="anchor-img" />
														) : callsheetActor.Actor_Type == "Headliner" ? (
															<img src={HeadingIcn} className="anchor-img" />
														) : (
															<span className="cust-dayplayer"> Dayplayer</span>
														)}
													</td>
													<td style={{ cursor: "pointer" }} onClick={() => showScoreDetails(callsheetActor)}>
														{callsheetActor.Title}
													</td>
													<td>{callsheetActor.Salery}</td>
													<td>{callsheetActor.Total_Scores}</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>
							{/*  main table closed  */}
							<Row className="player-left">
								<Col md={12}>
									<div className="contest-content-right">
										<div className="up-cont-box">
											<p>Remaining Time</p>
											<span>{tournamentDetails.EndDate}</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p># of Players / Total Players
</p>
											<span>
												{tournamentDetails.No_Of_Players} /{tournamentDetails.Total_Players}
											</span>
										</div>
										{/*up cnt box closed */}
									</div>
								</Col>
							</Row>
							<Row className="player-left">
								<Col md={12}>
									<div className="contest-content-right">
										<div className="up-cont-box">
											<p>MC Critics Score</p>
											<span>{callsheetDetails?.MCCriticsScore}</span>
										</div>
										<div className="up-cont-box">
											<p>IMDb User Score</p>
											<span>{callsheetDetails?.IMDbUserScore}</span>
										</div>
									</div>
								</Col>
							</Row>
							{/* ==== upcoming-contest-row closed  ========  */}
							<div className="callsheet-submit view-cls">
								{/* {callsheetDetails.PlayerName !== userName?<button onClick={() =>query.get("c1")? getSelectedCallSheet(query.get("c1")):getSelectedCallSheet(query.get("c"))} className="blue-btn">
                VIEW MY CALL SHEET</button>:
                <Link to={"/live"}><button className="blue-btn">
                BACK TO MY LIVE CONTEST</button></Link>} */}
								{(query.get("c1") && callsheetDetails.id !== query.get("c1")) ||
								(!query.get("c1") && callsheetDetails.id !== query.get("c")) ? (
									<button
										onClick={() =>
											query.get("c1")
												? getSelectedCallSheet(query.get("c1"))
												: getSelectedCallSheet(query.get("c"))
										}
										className="blue-btn">
										VIEW MY CALL SHEET
									</button>
								) : (
									<Link to={"/live"}>
										<button className="blue-btn">BACK TO MY LIVE CONTEST</button>
									</Link>
								)}
							</div>
						</div>
						{/* ========================== left contests  closed  ========================  */}
					</div>
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />
		</div>
	);
}

const mapStateToProps = (state) => ({
	deleteConfrim: state.application.deleteConfrim,
});
export default connect(mapStateToProps)(ContestLive);
