import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Nav, Modal, Dropdown, Table, Form } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'
import '../../assets/css/main.css';
import './style.css';
import AdminHeaderTop from '../../components/adminHeader/index';
import FooterBottom from '../../components/footer/index';
import HeadingIcn from '../../assets/images/Space-Headline-Icon.svg';
import User from '../../assets/images/user.jpg';
import { Link } from "react-router-dom";
import moment from 'moment';
import NumberFormat from "react-number-format";
import MyContestsService from '../../services/mycontests.service'
import { showLoader } from "../../common/actions/application";
import { hideLoader } from "../../common/actions/application";
import { connect } from 'react-redux';
import AdminSideNav from '../../components/adminSidebar/index';
import CallSheetService from '../../services/callsheet.service'
import Callsheet from '../../assets/images/callsheet.svg';
import Netflix from '../../assets/images/Netflix-Logo.svg';
import Costar from '../../assets/images/Co-Star-Icon.svg';
import tournamentService from "../../services/tournament.service";
import Moment from "react-moment";


function AdminLiveCallsheets(props) {
	const [tournamnets, setTournamnets] = useState([]);
	const [totalCallSheetsCount, setTotalCallSheetsCount] = useState(0);
	

	let [upcommingContestsData, setUpcommingContestsData] = useState({});

	useEffect(() => {
		getMyCallSheet();
	}, []);

	const getTimeRemaing = (tournamentStartDate) => {
		const future = moment(tournamentStartDate);
		return future.endOf("day").fromNow();
	};

	const getMyCallSheet = async (getuserId) => {
		props.dispatch(showLoader());
		let inputParams = {};
		const response = await MyContestsService.getAllLiveCallSheets(inputParams);
		if (response) {
			// response.data.forEach(element => {
			//     element.Tournament[0].Starting_in = getTimeRemaing(element.Tournament[0].EndDate);
			// });
			// let potentialWinning = 0;
			// let callSheets = response.data;
			// let contestCount = callSheets?.map(item => item.TournamentId)
			//     .filter((value, index, self) => self.indexOf(value) === index)
			// contestCount.forEach(element => {
			//     let findedCallSheet = callSheets?.find(x => x.TournamentId == element);
			//     potentialWinning = potentialWinning + findedCallSheet?.Tournament[0]?.PrizesRanking[0]?.Rank;
			// });

			// let dataObject = {};
			// dataObject.ContestCount = contestCount?.length;
			// dataObject.PotentialWinning = potentialWinning;

			// setUpcommingContestsData(dataObject);
			let dataObject = [];
			dataObject = response.data;
			let total = 0;
			dataObject.forEach((element) => {
				total = total + element?.Callsheets?.length;
			});
			setTotalCallSheetsCount(total);
			setTournamnets(response.data);
		}
		props.dispatch(hideLoader());
	};

	return (
		<div className="mycontests live">
			<div>
				<AdminHeaderTop />
				<div className="d-flex">
					<Col md={1} className="left-side-nav">
						<AdminSideNav placement="start" name="start" />
					</Col>
					<div className="right-side-content">
						<div className="content border-nav">
							<Container fluid>
								<div className="page-ntf">
									<Link to={"/admin-upcoming"}>
										<button>Upcoming</button>
									</Link>
									<Link to={"/admin-live"}>
										<button className="active">Live</button>
									</Link>
									<Link to={"/admin-completed"}>
										<button>Completed</button>
									</Link>
								</div>
								<div className="upcoming-contest-row">
									<h2>Live Contests</h2>
									<div className="up-contest-content">
										<div className="up-cont-box">
											<p>Live Call Sheets</p>
											<span>{totalCallSheetsCount}</span>
										</div>
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<p>Live Contests</p>
											<span>{tournamnets.length}</span>
										</div>
										{/*up cnt box closed */}
										{/* <div className="up-cont-box">
                                        <p>Currently Winning</p>
                                        <span>$25.75</span>
                                    </div> */}
										{/*up cnt box closed */}
										<div className="up-cont-box">
											<button className="blue-btn">COMPUTE ALL TOURNAMENTS</button>
										</div>
										{/*up cnt box closed */}
									</div>
								</div>
								{/* ==== upcoming-contest-row closed  ========  */}
								{tournamnets?.map((Tournamnet, index) => (
									<CollapseDivs key={index + "tournamet"} Tournamnet={Tournamnet} Tourprops={props} />
								))}
							</Container>
						</div>
						{/*  content closed  */}
					</div>
				</div>
			</div>
			<FooterBottom />
			
		</div>
	);
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(AdminLiveCallsheets);

function CollapseDivs(props) {
	const [tournamnet, setTournamnet] = useState(props.Tournamnet);
	const [callSheets, setCallSheets] = useState([]);
	const [open, setOpen] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	const getCallSheetsByTourId = async () => {
		setOpen(!open);
		if (!open) {
			let inputParams = { tournamentId: tournamnet.id };
			const response = await MyContestsService.getCallSheetsByTourId(inputParams);
			if (response) {
				setCallSheets([]);
				setCallSheets(response.data);
			}
		}
	};
	const getComputeWinner = async () => {
		setOpen(!open);
		if (!open) {
			props.Tourprops.dispatch(showLoader());
			let inputParams = { tournamentId: tournamnet.id };
			const response = await MyContestsService.computeTournamentResults(inputParams);
			if (response) {
				const response2 = await MyContestsService.getTournamentResults(inputParams);
				if (response2) {
					setCallSheets(response2.data);
					props.Tourprops.dispatch(hideLoader());
				}
			}
		}
		props.Tourprops.dispatch(hideLoader());
	};

	const [isCallSheetSelected, setIsCallSheetSelected] = useState(false);

	const [selectedCallSheet, setSelectedCallSheet] = useState({});

	const getSelectedCallSheet = async (callSheetId) => {
		props.Tourprops.dispatch(showLoader());
		setIsCallSheetSelected(!isCallSheetSelected);
		await CallSheetService.getCallSheetById(callSheetId).then((resp) => {
			if (resp) {
				setSelectedCallSheet(resp.data);
				props.Tourprops.dispatch(hideLoader());
			}
		});
	};

	const completeTournamentById = async (tournamentId) => {
		try {
			return tournamentService.updateTourStatus({ tournamnetId: tournamentId, isActive: false });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="smash-box live-content">
			<div className="contest-row">
				<div className={isCallSheetSelected ? "contest-left" : ""}>
					<div className="c-smash-row">
						<div className="smash-heading">
							<h3>{tournamnet.Tournament_Name}</h3>
						</div>
						<div className="smash-content">
							<div className="smash-cnt-box">
								<p>Call Sheets</p>
								<span>
									{tournamnet.Callsheets.length}
									{/* <NumberFormat value={tournamnet?.PrizesRanking[0]?.Rank} displayType={'text'}
                            thousandSeparator={true} prefix={'$'}
                        /> */}
								</span>
							</div>
							{/* smash box closed */}
							{/* <div className="smash-cnt-box">
                    <p># of Players / Total Players
</p>
                    <span>{tournamnet.No_Of_Players}/{tournamnet.Total_Players}</span>
                </div> */}
							{/* smash box closed */}
							{/* <div className="smash-cnt-box">
                    <p>Remaining Time</p>
                    <span>{tournamnet.Starting_in}</span>
                </div> */}
							{/* smash box closed */}
							{/* <div className="smash-cnt-box">
                    <p>Currently Winning</p>
                    <span>$0.00</span>
                </div>  */}
							{/* smash box closed */}
							
							<div className="smash-cnt-box">
								<button className="blu-border-btn" onClick={() => getComputeWinner()}>
									COMPUTE WINNER
								</button>
							</div>
							{/* smash box closed */}
							<div className="smash-cnt-box">
								<button className="blu-border-btn" onClick={() => getCallSheetsByTourId()}>
									VIEW CONTEST
								</button>
							</div>
							{/* {open?<div className="smash-cnt-box">
								<button className="blu-border-btn" onClick={() => completeTournamentById(tournamnet.id)}>
									Complete Tournament
								</button>
							</div>:""} */}
							{open?<div className="smash-cnt-box">
								<button className="blu-border-btn" onClick={() => setModalShow(true)}>
									Complete Tournament
								</button>
							</div>:""}
							{/* smash box closed */}
						</div>
						{/* ==== smashcontent closed == */}
					</div>
					{/* =========  smashcontent closed ====== */}
					<Collapse in={open}>
						<div className="table-main scroll-table p-r-10" style={{ minHeight: "600px" }}>
							<Table striped hover>
								<thead>
									<tr>
										<th>
											<span>
												Rank <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Player Name <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Score <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												MC Critics Score <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												IMDb User Score <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Budget <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Remaining Budget <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												MC Critics Score Diff <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												IMDb User Score Diff <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Winnings <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{callSheets.map((callSheet, index) => {
										return (
											<>
												<tr>
													<td>{index + 1}</td>
													<td>{callSheet.PlayerName}</td>
													<td>{callSheet.CallSheetTotal}</td>
													<td>{callSheet.MCCriticsScore}</td>
													<td>{callSheet.IMDbUserScore}</td>
													<td>
														<NumberFormat
															value={callSheet.TotalSalary}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
														/>
													</td>
													<td>
														<NumberFormat
															value={callSheet.RemaingSalary}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
														/>
													</td>
													<td>{callSheet.MCCriticsScoreDiff}</td>
													<td>{callSheet.IMDbUserScoreDiff}</td>
													<td>$Pending</td>
													<td>
														<button
															type="button"
															onClick={() => {
																getSelectedCallSheet(callSheet.id);
															}}>
															VIEW CALL SHEET
														</button>
													</td>
												</tr>
											</>
										);
									})}
								</tbody>
							</Table>
						</div>
					</Collapse>
				</div>
				{isCallSheetSelected ? (
					<div className="contest-right">
						<Row className="upcoming-contest-row">
							<Col md={8}>
								<div className="callsheet-img-row">
									<img src={Callsheet} />
									<div className="ctn-cls">
										<h2>Call Sheet</h2>
										{/* <p>{user.name}</p> */}
									</div>
								</div>
							</Col>
							<Col md={4}>
								<div className="contest-content-right">
									<button
										className="red-cls-btn"
										onClick={() => {
											setIsCallSheetSelected(!isCallSheetSelected);
											setSelectedCallSheet({});
										}}>
										<i className="fa fa-times" aria-hidden="true"></i>
									</button>
								</div>
							</Col>
						</Row>
						{/* ==== upcoming-contest-row closed  ========  */}
						<Row className="player-left">
							<Col md={12}>
								<div className="contest-content-right">
									<div className="up-cont-box">
										<p>Rank</p>
										<span>5</span>
									</div>
									{/*up cnt box closed */}
									<div className="up-cont-box">
										<p>Budget</p>
										<span>
											<NumberFormat
												value={selectedCallSheet.TotalSalary}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</span>
									</div>
									{/*up cnt box closed */}
									<div className="up-cont-box">
										<p>Winnings</p>
										<span>$120</span>
									</div>
									{/*up cnt box closed */}
								</div>
							</Col>
						</Row>
						{/* ==== upcoming-contest-row closed  ========  */}
						<div className="table-main scroll-table p-r-10  contest-table">
							<Table striped hover>
								<thead>
									<tr>
										<th></th>
										{/* <th></th> */}
										<th>
											<span>
												Actor Type <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Title <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Platform <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
										<th>
											<span>
												Salary <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{selectedCallSheet?.ActorsList?.map((actor, index) => {
										return (
											<tr key={index}>
												<td>
													<td>
														<img
															src={
																actor.profile_path
																	? "https://image.tmdb.org/t/p/original" + actor.profile_path
																	: User
															}
															className="user"
														/>
													</td>
												</td>
												<td>
													{actor.Actor_Type == "Co-Star" ? (
														<img src={Costar} className="anchor-img" />
													) : actor.Actor_Type == "Headliner" ? (
														<img src={HeadingIcn} className="anchor-img" />
													) : (
														<span className="cust-dayplayer"> Dayplayer</span>
													)}
												</td>
												<td>{actor.Title}</td>
												<td>
													{actor.Platform == "Netflix" ? (
														<img src={Netflix} className="cmp-logo" />
													) : (
														actor.Platform
													)}
												</td>
												<td>
													<NumberFormat
														value={actor?.Salery}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
						{/*  main table closed  */}
						<Row className="player-left">
							<Col md={12}>
								<div className="contest-content-right">
									<div className="up-cont-box">
										<p>Prizes</p>
										{/* <span> <NumberFormat value={tournamentDetails?.Prizes} displayType={'text'}
                                        thousandSeparator={true} prefix={'$'} /></span> */}
									</div>
									<div className="up-cont-box">
										<p># of Players / Total Players</p>
										{/* <span>{tournamentDetails?.No_Of_Players}/{tournamentDetails?.Total_Players}</span> */}
									</div>
								</div>
							</Col>
						</Row>
						{/* ==== upcoming-contest-row closed  ========  */}
						<div className="callsheet-submit view-cls">
							<button
								type="button"
								className="blue-btn"
								onClick={() => {
									setIsCallSheetSelected(!isCallSheetSelected);
									setSelectedCallSheet({});
								}}>
								Close Call Sheet
							</button>
						</div>
					</div>
				) : (
					""
				)}
			</div>
			<CompleteTournament
				show={modalShow}
				onHide={() => setModalShow(false)}
				tournamnet={tournamnet}
			/>
		</div>
	);
}

function CompleteTournament(props) {
    const [tournamnet, setTournamnet] = useState(props.tournamnet);

	return (
		<Modal
		{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup">
			<Modal.Header>
				<Modal.Title
					className="popup-heading"
					id="contained-modal-title-vcenter"
					>
					 Complete Tournament
				</Modal.Title>
				<Button className="popup-close" onClick={() => props.onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
				</Modal.Header>
				<Modal.Body>
				
				  <div className="popup-content"> 
				  <h2>{tournamnet?.Tournament_Name}</h2>
				  </div> 
				  <div className="pop-row">
				   <div className="movie-type-box">
                <h5>Prizes</h5>
                <p>{tournamnet?.PrizesPool}</p>
              </div> 

			  <div className="movie-type-box">
                <h5># of Players / Total Players
</h5>
                <p>
				{tournamnet?.No_Of_Players}/{tournamnet?.Total_Players}
                </p>
              </div>

			  <div className="movie-type-box">
                <h5>Starts on</h5>
                <p>
                  <Moment format="dddd,hh:mm a">{tournamnet?.tournamentStarts}</Moment>
                </p>
              </div>
			  </div>
			  <div className="pupup-btn-row">
			  <p onClick={props.onHide}>
                CANCEL
              </p>
			  <button  className="pink-btn" >
				  Complete
			  </button>
			  </div>
				
				</Modal.Body>
			</Modal>
	)
}




