import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Modal, Dropdown, InputGroup, FormControl } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import HeaderTop from '../../components/header/index';
import FooterBottom from '../../components/footer/index';
import User from '../../assets/images/user.jpg';
import { Link } from "react-router-dom";
import UserService from '../../services/user.service';
import AuthService from "../../services/auth.service";
import { connect } from 'react-redux';
import { showLoader, hideLoader, showSuccessAlert, showWarningAlert } from "../../common/actions/application";


const Profile = (props) => {
	const [userDetails, setUserDetails] = useState([]);
	const [hideEmailTextbox, setHideEmailTextbox] = useState(true);
	const [hidePasswordOption, sethidePasswordOption] = useState(true);
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [showError, setShowError] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [oldPasswordShown, setOldPasswordShown] = useState(false);
	const [passwordShown, setPasswordShown] = useState(false);
	const [conPasswordShown, setConPasswordShown] = useState(false);
	const [withdrawModalShow, setWithdrawModalShow] = useState(false);
	const [withdraw, setWithdraw] = useState({});
	const [profilePicUrl, setProfilePicUrl] = useState(localStorage.getItem("profile_photo"));

	useEffect(() => {
		let userData = AuthService.getAuth();
		setUserDetails(userData.data);
		//getUserDetails();
	}, []);

	const getUserDetails = async () => {
		const response = await UserService.getUserById(userDetails.id);
		if (response) {
			setUserDetails(response.data);
		}
	};

	// const getUserDetails = async () => {
	//   const response = await AuthService.getAuth();
	//   setUserDetails(response.data);
	// }

	const handleInputChange = (event) => {
		const query = event.currentTarget.value;
		setUserDetails({ ...userDetails, email: query });
		let validEmail = /^([^@]+@[^@]+\.[^@]+)?$/.test(query);
		if (validEmail) {
			setShowError(false);
		} else {
			setShowError(true);
		}
	};

	const handleOutfocuse = (event) => {
		const query = event.currentTarget.value;
		let validEmail = /^([^@]+@[^@]+\.[^@]+)?$/.test(query);
		if (validEmail) {
			props.dispatch(showLoader());
			UserService.updateUser({ email: query });
			props.dispatch(hideLoader());
		} else {
			setShowError(true);
		}
	};

	const enableEditEmail = () => {
		setHideEmailTextbox(!hideEmailTextbox);
	};

	const enableChangePassword = () => {
		sethidePasswordOption(false);
	};

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { oldpassword, password, confirmpassword } = form;
		const newErrors = {};
		// oldpassword errors
		if (!oldpassword || oldpassword === "") newErrors.oldpassword = "cannot be blank!";
		// else if (oldpassword.length > 30) newErrors.oldpassword = 'name is too long!'
		// password errors
		if (!password || password === "") newErrors.password = "cannot be blank!";
		// confirmpassword errors
		if (!confirmpassword || confirmpassword === "") newErrors.confirmpassword = "cannot be blank!";

		if (password != confirmpassword)
			newErrors.confirmpassword = "new password and confirm password should be same";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// get our new errors
		const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
			// No errors! Put any logic here for the form submission!
			// alert('Thank you for your feedback!')

			// const { oldpassword, password, confirmpassword } = form

			props.dispatch(showLoader());
			let data = { email: userDetails.email, password: form.oldpassword, newPassword: form.password };
			UserService.changePassword(data).then(() => {
				props.dispatch(hideLoader());
				props.dispatch(showSuccessAlert("Password changed successfully"));
			});
		}
	};

	const onImageChange = async(event) => {
		setSelectedImage(event.target.files[0]);
		const data = new FormData();
		data.append("file", event.target.files[0]);
		data.append("userId", userDetails.id);
		 props.dispatch(showLoader());
		await UserService.uploadProfilePic(data).then((data) => {
			setProfilePicUrl(data.imgUrl);
			localStorage.removeItem("profile_photo")
			localStorage.setItem("profile_photo", data.imgUrl);
			props.dispatch(hideLoader());
			
		});
	    window.location.reload()
		//props.dispatch(showSuccessAlert("Profile picture changed successfully"));
	};

	const upload = (event) => {
		document.getElementById("profilePic").click();
	};

	const withdrawFundsConfrimfun = async () => {
		props.dispatch(showLoader());
		let object = {
			id: withdraw.id,
			ClaimedMoney: withdraw.ClaimedMoney + withdraw.TotalMoneyWon,
			TotalMoneyWon: 0,
		};
		await UserService.updateClaimedMoney(object).then((resp) => {
			if (resp.data) {
				getUserDetails();
				setWithdrawModalShow(false);
			}
		});
		props.dispatch(hideLoader());
	};

	return (
		<div className="profiletab">
			<HeaderTop />

			<div className="content border-nav">
				<Container fluid>
					<div className="page-ntf">
						<Link to={"/profile"}>
							<button className="active">Profile</button>
						</Link>
						<Link to={"/transactionhistory"}>
							<button>Transactions </button>
						</Link>
						<Link to={"/settings"}>
							<button>Settings</button>
						</Link>
					</div>
					{/* ===== page notification closed ===== */}
					<div className="d-flex justify-content-between profile">
						<div className="left-side">
							<div className="profile-user">
								<div className="profile-user-border"></div>
								<img src={profilePicUrl == "null" ? User : profilePicUrl} />
								<Dropdown className="edit-img">
									<Dropdown.Toggle variant="success" id="dropdown-basic">
										<span>
											<i className="fa fa-camera" aria-hidden="true"></i>
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<a>
											<span>
												<i className="fa fa-eye" aria-hidden="true"></i>
											</span>
											View Profile Picture
										</a>
										<a onClick={() => upload()}>
											<span>
												<i className="fa fa-file-image-o" aria-hidden="true"></i>
											</span>
											Upload Profile Picture
											<input
												hidden
												id="profilePic"
												type="file"
												accept=".png, .jpg"
												name=""
												onChange={(event) => onImageChange(event)}
											/>
										</a>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="user-detail">
								<h2>{userDetails.Screen_Name}</h2>
								<p>Account Balance : {userDetails.TotalMoneyWon} CD3D</p>
								<button
									className="pink-btn"
									onClick={() => {
										setWithdraw(userDetails);
										setWithdrawModalShow(true);
									}}>
									WITHDRAW FUNDS
								</button>
							</div>
						</div>
						{/* ======= left side closed =============== */}
						<div className="right-side profile-detail">
							<div className="right-side-box">
								<h3>About You</h3>
								<Row>
									<Col md="12" className="name-row">
										<h4>{userDetails.Screen_Name}</h4>
										<p>Screen Name</p>
									</Col>
								</Row>
								<Row>
									<Col md="9" xs={9} className="name-row">
										{hideEmailTextbox ? (
											<h4>{userDetails.email}</h4>
										) : (
											<InputGroup className="search-btn">
												<FormControl
													placeholder="Email ID"
													aria-label="Recipient's username"
													aria-describedby="basic-addon2"
													onChange={handleInputChange}
													value={userDetails.email}
													onBlur={handleOutfocuse}
												/>
												<InputGroup.Text id="basic-addon2">
													<i className="fa fa-envelope" aria-hidden="true"></i>
												</InputGroup.Text>
											</InputGroup>
										)}
										{showError ? (
											<small style={{ fontSize: ".875em", color: "#dc3545" }}>Email is not valid</small>
										) : (
											""
										)}
										<p>Email ID</p>
									</Col>
									<Col md="3" xs={3} className="edit-btn">
										<button type="button">
											<i className="fa fa-pencil" aria-hidden="true" onClick={() => enableEditEmail()}></i>
										</button>
									</Col>
								</Row>
								<Row>
									<Col md="12" className="name-row">
										<h4>January 17, 1982</h4>
										<p>Birth Date</p>
									</Col>
								</Row>

								<Row>
									<Col md="12" className="name-row">
										<h4>Male</h4>
										<p>Gender</p>
									</Col>
								</Row>
							</div>

							<div className="right-side-box change-pwd-box">
								<h3>Change Password</h3>
								{hidePasswordOption ? (
									<div className="change-btn-row">
										<div className="change-btn-bx">
											<button className="blue-btn" onClick={() => enableChangePassword()}>
												CHANGE PASSWORD
											</button>
										</div>
										<div className="fgt-pw">
											<Link to="/forgotpassword">
												<a>Forgot Password</a>
											</Link>
										</div>
									</div>
								) : (
									<div className="forgot-pwd">
										<Form onSubmit={handleSubmit} role="form">
											<div className="form-grp">
												<Form.Group className="mb-3" controlId="formBasicEmail">
													<Form.Label>Old Password</Form.Label>
													<div className="row-input">
														<Form.Control
															type={oldPasswordShown ? "text" : "password"}
															placeholder="old password"
															onChange={(e) => setField("oldpassword", e.target.value)}
															isInvalid={!!errors.oldpassword}
														/>
														<a className="eye-pwd">
															<i
																className={!oldPasswordShown ? "fa fa-eye-slash" : "fa fa-eye"}
																onClick={() => {
																	setOldPasswordShown(!oldPasswordShown);
																}}
																aria-hidden="true"></i>
														</a>
														<Form.Control.Feedback type="invalid">{errors.oldpassword}</Form.Control.Feedback>
													</div>
												</Form.Group>

												<Form.Group className="mb-3" controlId="formBasicPassword">
													<Form.Label>New Password</Form.Label>
													<div className="row-input">
														<Form.Control
															type={passwordShown ? "text" : "password"}
															placeholder="new password"
															onChange={(e) => setField("password", e.target.value)}
															isInvalid={!!errors.password}
														/>
														<a className="eye-pwd">
															<i
																className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
																onClick={() => {
																	setPasswordShown(!passwordShown);
																}}
																aria-hidden="true"></i>
														</a>
														<Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
													</div>
												</Form.Group>

												<Form.Group className="mb-3" controlId="formBasicPassword">
													<Form.Label>Confirm Password</Form.Label>
													<div className="row-input">
														<Form.Control
															type={conPasswordShown ? "text" : "password"}
															placeholder="confirm password"
															onChange={(e) => setField("confirmpassword", e.target.value)}
															isInvalid={!!errors.confirmpassword}
														/>
														<a className="eye-pwd">
															<i
																className={!conPasswordShown ? "fa fa-eye-slash" : "fa fa-eye"}
																onClick={() => {
																	setConPasswordShown(!conPasswordShown);
																}}
																aria-hidden="true"></i>
														</a>
														<Form.Control.Feedback type="invalid">
															{errors.confirmpassword}
														</Form.Control.Feedback>
													</div>
												</Form.Group>
											</div>

											<div className="sbm-row">
												<Link to="/forgotpassword">
													<a>Forgot Password</a>
												</Link>
												<Button className="pink-btn" type="submit">
													Confirm
												</Button>
											</div>
										</Form>
									</div>
								)}
							</div>
						</div>
						{/* ===========  right side closed ============= */}
					</div>
					{/*setting box closed */}
				</Container>
			</div>
			{/*  content closed  */}

			<FooterBottom />

			<WithdrawFunds
				show={withdrawModalShow}
				onHide={() => setWithdrawModalShow(false)}
				withdrawFundsConfrim={() => withdrawFundsConfrimfun()}
			/>
		</div>
	);
};
// export default Profile;
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Profile);

function WithdrawFunds(props) {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="adventure-popup">
			//className="main-popup delete-swg"
			<Modal.Header>
				<Modal.Title
					className="popup-heading"
					id="contained-modal-title-vcenter"
					style={{ color: "#e0a800" }}>
					<i className="fa fa-exclamation-triangle fa-1x" aria-hidden="true"></i> Withdraw Your Funds?
				</Modal.Title>
				<Button className="popup-close" onClick={() => props.onHide()}>
					<i className="fa fa-times" aria-hidden="true"></i>
				</Button>
			</Modal.Header>
			<Modal.Body>
				<div className="popup-content delete-swag-popup" style={{ borderTop: "1px solid #e0a800" }}>
					<p>Do you wish to Withdraw your funds?</p>
					<div className="pupup-btn-row">
						<button className="blue-btn" onClick={() => props.withdrawFundsConfrim()}>
							YES
						</button>
						<button style={{ marginLeft: "5px" }} className="red-btn" onClick={() => props.onHide()}>
							NO
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}


