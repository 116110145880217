import React from "react";
import { Container } from "react-bootstrap";
import '../../assets/css/main.css';
import './style.css';
import Video from '../../assets/images/Camera-Icon.svg'
import Director from '../../assets/images/DirectorChair-Icon.svg'
import DirectorClap from '../../assets/images/DirectorClap-Icon.svg'
import { Link } from "react-router-dom";


const Footer = () => {
  return (

    <footer>
      <Container fluid>
        <div className="footer-links">
          <Link to={"/faq"}> <button type="button"> FAQ</button> </Link>
          <img src={Video} alt="camera" />
          <Link to={"/tnc"}> <button type="button"> TERMS &amp; CONDITIONS</button></Link>
          <img src={Director} alt="director-chair" />
          <button type="button">ABOUT US</button>
          <img src={DirectorClap} alt="Director-clap" />
          <button type="button">CONTACT US</button>
        </div>
      </Container>
    </footer>

  )
}

export default Footer;
