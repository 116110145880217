import React, { Component, useEffect, useState, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Modal,
  Dropdown,
  Table,
  Form,
  InputGroup, FormControl
} from "react-bootstrap";
import "../../assets/css/main.css";
import AdminHeaderTop from "../../components/adminHeader/index";
import FooterBottom from "../../components/footer/index";
import AdminSideNav from "../../components/adminSidebar/index";
import Creatable, { useCreatable } from "react-select/creatable";
import Select from 'react-select';
import TournamentService from "../../services/tournament.service";
import CustomNotificationService from "../../services/customNotification.service"
import { showSuccessAlert } from "../../common/actions/application";

function AdminNotifications(props) {
	const [tournamentList, setTournamentList] = useState([]);
	const [allTournament, setAllTournament] = useState([]);
	const [selectedTournamentTime, setSelectedTournamentTime] = useState(null);
	const [form, setForm] = useState({});
	const [tourId, setTourId] = useState();
	const [playerDetails, setPlayerDetails] = useState([]);
	const [message, setMessage] = useState();
	
	useEffect(() => {
		getTournamentsList(true);
	}, []);

	const getTournamentsList = async () => {
		const response = await TournamentService.getAllTournamentsList();
		let tournamentList = [];
		setAllTournament(response.data);
		response.data.map((x) => {
			tournamentList.push({ value: x.id, label: x.Tournament_Name });
		});

		setTournamentList(tournamentList);
	};

	const getPlayerEmail = async (id) => {
		const response = await CustomNotificationService.getUserDetails(id);
		if (response) {
			let playerOptions = [];
			playerOptions.push({ value: response.data, label: "All" });
			response.data.forEach((email) => {
				playerOptions.push({ value: email, label: email });
			});
			setPlayerDetails(playerOptions);
			const tournament = allTournament.find((x) => x.id === id);
			setSelectedTournamentTime(tournament.Starting_in);

			// selectedTournamentTime - 12 hours
			let date = new Date(tournament.Starting_in);
			date.setHours(date.getHours() - 12);
		}
	};

	const setField = (field, value) => {
		if (field == "tournamentIds" || field == "emails" || field == "playerEmail") {
			setForm({
				...form,
				[field]: value,
			});
		}
		setForm({
			...form,
			[field]: value,
		});
	};

    const submitHandler = async () =>{
       const response = await CustomNotificationService.sendCustomNotifications(form);
	   if(response){
          props.dispatch(showSuccessAlert("message sent"))
	   }
	}

	const setMessageFun = (value) => {
		const getTimeInPt =  new Date(selectedTournamentTime).toLocaleTimeString('en-US', { timeZone: 'America/New_York' });
		const getTimeInUtc = new Date(selectedTournamentTime).toUTCString().slice(16, 26); 
		value == new Date(selectedTournamentTime).setHours(
			new Date(selectedTournamentTime).getHours() - 12)?setMessage("Don't forget to fill out your call sheet! The Big Show begins at "+ getTimeInPt + " PT/" + getTimeInUtc + " UTC"):
		value == new Date(selectedTournamentTime).setHours(
			new Date(selectedTournamentTime).getHours() - (3/2))?setMessage("90 minutes to enter a call sheet for this weekend's action! Contest starts at " + getTimeInPt + " PT. Register for the Feature Presentation NOW."):
	    value == new Date(selectedTournamentTime).setHours(
			new Date(selectedTournamentTime).getHours() - (1/2))?setMessage("Your call sheet is invalid and your contest starts in 30 minutes!"):setMessage("")
		}

	return (
		<div className="dashboard">
			<AdminHeaderTop />

			<div className="d-flex flex-wrap">
				<Col className="left-side-nav">
					<AdminSideNav placement="start" name="start" />
				</Col>

				<div className="right-side-content">
					<div className="content">
						<Container fluid>
							<div className="table-main p-r-10 desktop-view">
								<h3>Send Custom Notifications to Users</h3>
								<Form role="form">
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Tournament Name*</Form.Label>
										<Creatable
											options={tournamentList}
											closeMenuOnSelect={true}
											cacheOptions={true}
											onChange={(e) => {
												setField("tournamentIds", e.value);
												getPlayerEmail(e.value);
											}}
											// createOptionPosition="first"
											// // onCreateOption={(e) => {
											// // 	insertLookUpData("Type", e);
											// // 	setField("Type", e);
											// // }}
											placeholder="Enter Tournament Name"
											controlShouldRenderValue={true}
											escapeClearsValue={true}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Tournament Players*</Form.Label>
										<Select
											classNamePrefix="select"
											// defaultValue={playerDetails[0]}
											isDisabled={false}
											isMulti={true}
											isLoading={false}
											isClearable={true}
											isRtl={false}
											onChange={(e) => {
												setField("playerEmail", e.value);
											}}
											isSearchable={true}
											options={playerDetails}
										/>
									</Form.Group>

									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Send At</Form.Label>
										<Form.Select
											type="select"
											className="form-control"
											id="exampleFormControlSelect1"
											placeholder="Select"
											onChange={(e) => {
												setField("sendAt", e.target.value);
												setMessageFun(e.target.value);
											}}>
											<option value="1">Select</option>
											<option
												value={new Date(selectedTournamentTime).setHours(
													new Date(selectedTournamentTime).getHours() - 12
												)}>
												Starting in 12H
											</option>
											<option 
											    value={new Date(selectedTournamentTime).setHours(
													new Date(selectedTournamentTime).getHours() - (3/2)
												)}>
												Starting in 90 mins</option>
											<option 
											    value={new Date(selectedTournamentTime).setHours(
													new Date(selectedTournamentTime).getHours() - (1/2)
												)}>
												Starting in 30 mins</option>
										</Form.Select>
									</Form.Group>

									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>User Email</Form.Label>
										<Creatable
											isMulti
											//options={}
											closeMenuOnSelect={true}
											cacheOptions={true}
											onChange={(e) => {
												setField("emails", e.value);
											}}
											placeholder="Enter Email of players are NOT IN TOURNAMENT to send notifications"
											controlShouldRenderValue={true}
											escapeClearsValue={true}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Subject*</Form.Label>
										<div className="row-input">
											<Form.Control placeholder="Subject" />
										</div>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Message*</Form.Label>
										<div className="row-input">
											<Form.Control value={message} placeholder="Type Your Message Here" />
										</div>
									</Form.Group>
									<div className="pupup-btn-row">
										<button className="red-btn">CANCEL</button>
										<button type="submit" onClick={submitHandler} className="blue-btn" style={{ marginLeft: "15px" }}>
											SEND
										</button>
									</div>
								</Form>
							</div>
						</Container>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AdminNotifications;