import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Table } from "react-bootstrap";
import { connect } from 'react-redux';
import HeadingIcn from '../../../assets/images/Space-Headline-Icon.svg';
import User from '../../../assets/images/user.jpg';
import Costar from '../../../assets/images/Co-Star-Icon.svg';
import NumberFormat from "react-number-format";
import { hideActorPopup } from "../../../common/actions/genericPopupAction";
import { addActor } from "../../../common/actions/genericPopupAction";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
// import faker from 'faker';
import Moment from "react-moment";

import TalentPoolService from "../../../services/talentpool.service"


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    // plugins: {
    //     legend: {
    //         position: "top",
    //     },
    //     title: {
    //         display: false,
    //         text: "Statistics",
    //     },
    // },
};

function ActorDeatilsPopup(props) {

    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const [labels, setLabelArr] = useState([]);
    const [dataArr, setDataArr] = useState([]);
    const [actors, setActors] = useState([]);
    const [avgScore, setAvgScore] = useState(0);

    const onHide = () => {
        props.dispatch(hideActorPopup());
    }

	const addToCallSheet = (value) => {
        props.dispatch(addActor(value));
    }

    useEffect(async () => {
        // getActorData();
        if (props.actorData.ActorTmdbId != undefined)
            await TalentPoolService.getActorDataByTmdbId(props.actorData.ActorTmdbId).then((resp) => {
            // await TalentPoolService.getActorDataByTmdbId(1493898).then((resp) => {
                if (resp.data && resp.success) {
									let actors = resp.data?.filter((item) => item.Actor == props.actorData.Actor);
									setActors(actors);
									let titles = actors
										?.map((item) => item.Title)
										.filter((value, index, self) => self.indexOf(value) === index);
									let Scores = actors
										?.map((item) => item.Total_Scores)
										.filter((value, index, self) => self.indexOf(value) === index);
									// debugger
									// let newArray = actors?.filter((ar) => titles.find(rm => rm === ar.Title));
									let avgScore = 0;
									Scores?.forEach((element) => {
										avgScore = avgScore + element;
									});
									setLabelArr(titles);
									setDataArr(Scores);
									setAvgScore(avgScore / Scores?.length);
								}
            });
    }, [props.actorData]);


    // const getActorData = async () => {
    //     await TalentPoolService.getActorDataByTmdbId(props.actorData.ActorTmdbId).then((resp) => {
    //         if (resp.data && resp.success) {
    //             debugger
    //         }
    //     });
    // }

    // useEffect(() => {


    //     let actors = props.callsheetData.ActorsList
    //         ?.filter((item) => item.Actor == props.actorData.Actor)
    //     setActors(actors);
    //     let titles = actors
    //         ?.map((item) => item.Title)
    //         .filter((value, index, self) => self.indexOf(value) === index);
    //     let Scores = actors
    //         ?.map((item) => item.Total_Scores)
    //         .filter((value, index, self) => self.indexOf(value) === index);
    //     // debugger
    //     // let newArray = actors?.filter((ar) => titles.find(rm => rm === ar.Title));
    //     setLabelArr(titles);
    //     setDataArr(Scores)
    // }, [props.callsheetData]);

    let data = {
        labels,
        datasets: [
            {
                label: "Scores",
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                data: dataArr,
                backgroundColor: "rgba(0,216,195, 0.5)",
            }
        ],
    };
    return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="adventure-popup">
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
					<Button onClick={() => onHide()} className="popup-close">
						<i className="fa fa-times" aria-hidden="true"></i>
					</Button>
				</Modal.Header>
				<Modal.Body style={{ height: "auto" }}>
					<div className="popup-profile">
						<div className="profile-box">
							<div className="profile-img">
								{/* <img src={User} /> */}
								<img
									src={
										props.actorData.profile_path
											? "https://image.tmdb.org/t/p/original" + props.actorData.profile_path
											: User
									}
									className="user"
								/>
							</div>
							<div className="profile-detail">
								<h4>
									{props.actorData.Actor}
									{props.actorData.Actor_Type == "Co-Star" ? (
										<img src={Costar} className="anchor-img" />
									) : props.actorData.Actor_Type == "Headliner" ? (
										<img src={HeadingIcn} className="anchor-img" />
									) : (
										<span className="cust-dayplayer"> Dayplayer</span>
									)}
								</h4>
								<p className="time">{props.actorData.Title}</p>
								<p>{props.actorData.Notes}</p>
								<p className="salary">
									Salary :
									<NumberFormat
										value={props.actorData.Salery}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>
								</p>
							</div>
						</div>
						{/* ======= pfofile box ============= */}
						 <Button onClick={() => {addToCallSheet(props.actorData); onHide()}}  className="blue-btn" >ADD TO  CALL SHEET</Button> 
					</div>

					<div className="score-history">
						<Row>
							<Col md="6" className="score-draft">
								<div className="score-draft-heading">
									<h4>Score History</h4>
									<p>Avg Score : {avgScore}</p>
								</div>
								<Bar options={options} data={data} />
							</Col>

							<Col md="6">
								<div className="score-date-tble">
									<div className="table-main scroll-table p-r-10 ">
										<Table striped hover>
											<thead>
												<tr>
													<th>
														<span>
															Date <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														</span>
													</th>
													<th>
														<span>
															Time <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														</span>
													</th>
													<th>
														<span>
															Score <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
														</span>
													</th>
												</tr>
											</thead>
											<tbody>
												{actors?.map((actor, index) => {
													return (
														<tr>
															<td>
																<Moment format="MM/DD/yyyy">{actor?.Release_Date} </Moment>
															</td>
															<td>{actor.Title}</td>
															<td>{actor.Total_Scores}</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
		);
}


const mapStateToProps = state => ({ show: state.genericsPopup.actorPopupShow, actorData: state.genericsPopup.actorData, callsheetData: state.genericsPopup.callsheetData });
export default connect(mapStateToProps)(ActorDeatilsPopup)